import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import { Dropdown, Label } from "@fluentui/react";
import {
  VehicleTypesQueryParams,
  VehicleTypesClient,
} from "../../infrastructure/api/VehicleTypesClient";

export class VehicleTypeSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeStatus: "",
      loading: false,
      selectedVehicleType: null,
      vehicleTypes: [],
    };
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
  }

  classNames = mergeStyleSets({
    dropdown: { borderRadius: "5px" },
  });

  render() {
    const {
      onSelect,
      vehicleTypeId,
      hideLabel,
      required,
      disableSelectDefault,
    } = this.props;
    return (
      <>
        {!hideLabel && (
          <Label>
            Type{" "}
            {required && <span style={{ color: "rgb(164, 38, 44)" }}>*</span>}
          </Label>
        )}
        <Dropdown
          placeholder="Select a Vehicle Type"
          selectedKey={
            this.state.selectedVehicleType
              ? this.state.selectedVehicleType.id
              : vehicleTypeId
              ? vehicleTypeId
              : null
          }
          options={this.state.vehicleTypes.map((item) => {
            return {
              key: item.id,
              text: item.name,
            };
          })}
          onChanged={(item) => {
            this.setState({
              selectedVehicleType: {
                id: item.key,
                name: item.text,
              },
            });

            onSelect({
              id: item.key,
              name: item.text,
            });
          }}
          styles={{ title: { borderRadius: "5px" } }}
        />
      </>
    );
  }

  async populateData() {
    const { setOptions, vehicleTypeId, onSelect, disableSelectDefault } =
      this.props;
    var vehicleTypes = await VehicleTypesClient.List(
      new VehicleTypesQueryParams().Sort("name", "asc")
    );
    this.setState({
      vehicleTypes: vehicleTypes.data.data,
    });

    if (setOptions) {
      setOptions(vehicleTypes.data.data);
    }

    if (!disableSelectDefault) {
      if (vehicleTypeId) {
        var vehicleType = vehicleTypes.data.data.filter(
          (vehicleType) => vehicleType.id === vehicleTypeId
        )[0];

        if (vehicleType) {
          onSelect({
            id: vehicleType.id,
            name: vehicleType.name,
          });

          // this.setState({
          //   selectedVehicleType: {
          //     id: vehicleType.id,
          //     name: vehicleType.name,
          //   },
          // });
        }
      } else if (!vehicleTypeId && this.state.selectedVehicleType === null) {
        var vehicleType = vehicleTypes.data.data[0];

        if (vehicleType) {
          onSelect({
            id: vehicleType.id,
            name: vehicleType.name,
          });
        }
      }
    }
  }
}
