import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import {
  HomeClient,
  HomeQueryParams,
} from "../../infrastructure/api/HomeClient";
import { FocusZone, Spinner, List, Image, Shimmer } from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";

export class PlanTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      total: 0,
      search: "",
      sort: "companyName",
      direction: "asc",
      showSuspended: false,
      status: "",
    };
    this.onRenderCell = this.onRenderCell.bind(this);
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
  }

  classNames = mergeStyleSets({
    container: {
      overflow: "auto",
      maxHeight: 1000,
    },
    itemCell: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: "border-box",
        borderBottom: `1px solid ${getTheme().semanticColors.bodyDivider}`,
        display: "flex",
        selectors: {
          "&:hover": { background: getTheme().palette.neutralLight },
        },
      },
    ],
    itemImage: {
      flexShrink: 0,
    },
    itemContent: {
      marginLeft: 10,
      overflow: "hidden",
      flexGrow: 1,
    },
    itemName: [
      getTheme().fonts.xLarge,
      {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    ],
    itemIndex: {
      fontSize: getTheme().fonts.small.fontSize,
      color: getTheme().palette.neutralTertiary,
      marginBottom: 0,
    },
    chevron: {
      alignSelf: "center",
      marginLeft: 10,
      color: getTheme().palette.neutralTertiary,
      fontSize: getTheme().fonts.large.fontSize,
      flexShrink: 0,
    },
    contentClass: {
      backgroundColor: getTheme().palette.neutralLight,
      color: getTheme().palette.neutralDark,
      lineHeight: "50px",
      padding: "0 20px",
    },
  });

  render() {
    return (
      //<FocusZone direction={FocusZoneDirection.vertical}>
      <div className={this.classNames.container} data-is-scrollable>
        {this.state.data && !this.state.loading && (
          <List items={this.state.data} onRenderCell={this.onRenderCell} />
        )}
        {this.state.loading && (
          <div>
            <Shimmer style={{ padding: "5px 20px" }} />
            <Shimmer style={{ padding: "5px 20px" }} width="75%" />
            <Shimmer style={{ padding: "5px 20px" }} width="50%" />
          </div>
        )}
      </div>
      //</FocusZone>
    );
  }

  onRenderCell(item, index, isScrolling) {
    var user = item.users[0];
    var days = ["S", "M", "T", "W", "T", "F", "S"];
    var daysLong = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    var bg = "#dcf0f2";
    var textColor = "black";

    const { selectedDate, onClick } = this.props;

    var isSelected =
      new Date(item.date).getTime() == new Date(selectedDate).getTime();

    if (user.myDay && user.myDay.parts && user.myDay.parts.length > 0) {
      if (user.myDay.parts[0].state == "workFromOffice") {
        bg = "#fcc250";
      } else if (user.myDay.parts[0].state == "workFromHome") {
        bg = "#00838f";
        textColor = "white";
      } else if (user.myDay.parts[0].state == "notWorking") {
        bg = "#EF6E6C";
        textColor = "white";
      }
    }

    var isDisabled = false;
    if (
      new Date(new Date(item.date).toDateString()).getTime() <
      new Date(new Date().toDateString()).getTime()
    )
      isDisabled = true;

    return (
      <div
        className={this.classNames.itemCell + (isDisabled ? "" : " hover")}
        data-is-focusable={true}
        style={{ backgroundColor: "white", borderRadius: "5px" }}
        onClick={() => {
          if (onClick && !isDisabled) onClick(new Date(item.date), user.myDay);
        }}
      >
        <div
          style={{
            height: "50px",
            width: "50px",
            borderRadius: "50%",
            borderColor: bg,
            borderStyle: "solid",
            borderWidth: "3px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              height: "35px",
              width: "35px",
              borderRadius: "50%",
              backgroundColor: isSelected ? bg : "transparent",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <strong style={{ color: isSelected ? textColor : "black" }}>
              {days[new Date(item.date).getDay()]}
            </strong>
          </div>
        </div>
        <div
          className={this.classNames.itemContent}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {user.myDay &&
            user.myDay.parts &&
            user.myDay.parts[0] &&
            user.myDay.parts[0].state == "workFromOffice" && (
              <>
                <div className={this.classNames.itemIndex}>
                  <Icon
                    iconName="Location"
                    style={{ position: "relative", top: "1px" }}
                  />{" "}
                  {user.myDay.parts[0].site && user.myDay.parts[0].site.name}
                  {!user.myDay.parts[0].site && <i>Site not specified</i>}
                </div>
                {user.checkIn && (
                  <div className={this.classNames.itemIndex}>
                    <Icon
                      iconName="CellPhone"
                      style={{ position: "relative", top: "1px" }}
                    />{" "}
                    {user.checkIn.asset.name}
                  </div>
                )}
              </>
            )}
          {!user.myDay && (
            <div className={this.classNames.itemIndex}>
              Start planning for {daysLong[new Date(item.date).getDay()]}
            </div>
          )}
          {user.myDay &&
            user.myDay.parts &&
            user.myDay.parts[0] &&
            user.myDay.parts[0].state == "notWorking" && (
              <div className={this.classNames.itemIndex}>
                {user.myDay.parts[0].notWorkingReason &&
                  user.myDay.parts[0].notWorkingReason.name}
                {!user.myDay.parts[0].notWorkingReason && (
                  <i>Reason not specified</i>
                )}
              </div>
            )}
          {user.myDay &&
            user.myDay.parts &&
            user.myDay.parts[0] &&
            user.myDay.parts[0].state == "workFromHome" && (
              <div className={this.classNames.itemIndex}>Working from home</div>
            )}
        </div>
      </div>
    );
  }

  async populateData(skip, take) {
    const { selectedDate, teamId, date, siteId, userId } = this.props;

    this.setState({
      loading: true,
    });

    var response = await HomeClient.DayView(
      new HomeQueryParams()
        .WithSiteId(siteId)
        .WithTeamId(teamId)
        .WithUserId(userId)
        .WithNumberOfDays(7)
        .WithParam("startDate", date)
    );

    if (response.data) {
      this.setState({
        data: response.data,
      });
    }

    this.setState({
      loading: false,
    });
  }
}
