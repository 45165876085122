import React, { Component, useContext } from "react";
import "./WorkPlanSiteDropdown.css";
import { ReactComponent as WorkFromOfficeLogo } from "../../../assets/svg/marker.svg";
import { ReactComponent as InfoLogo } from "../../../assets/svg/info.svg";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import { NoticeModal } from "./NoticeModal";

export class WorkPlanSiteDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
      defaultValue: {},
      showModal: false,
      notices: [],
    };
  }

  componentDidMount() {
    this.getSites();
  }

  async getSites() {
    const { defaultValue, items } = this.props;

    if (defaultValue?.name) this.manageNotices(defaultValue);
    else this.setState({ sites: items });
  }

  manageNotices(defaultValue) {
    const { items } = this.props;
    this.setState({ defaultValue: defaultValue }, () => {
      if (items && items.length > 0) {
        var site = items.filter((site) => site.id === defaultValue.id)[0];

        if (site && site?.notices) {
          this.setState({ notices: site?.notices, sites: items });
        }
      }
    });
  }

  componentWillReceiveProps(props) {
    const { defaultValue } = props;

    if (defaultValue?.id !== this.state.defaultValue?.id) {
      this.manageNotices(defaultValue);
    }
  }

  classNames = mergeStyleSets({
    buttonShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)" },
        },
      },
    ],
  });

  render() {
    const { showDropdown, setShowDropdown, items, defaultValue, onSelect } =
      this.props;

    var onSiteColor = localStorage.getItem("theme_OnSite");

    return (
      <>
        <NoticeModal
          notices={this.state.notices}
          showModal={this.state.showModal}
          onClose={() => {
            this.setState({ showModal: false });
          }}
          site={this.state.defaultValue}
          siteId={this.state.defaultValue?.id}
        />
        <div className="dropdown-wrapper">
          <div
            style={{
              borderRadius: "5px",
              minWidth: "150px",
              textAlign: "left",
              padding: "10px",
              display: "flex",
              marginBottom: "15px",
              background: "white",
              width: "auto",
              justifyContent: "space-between",
            }}
            className="trigger-button"
          >
            <div
              onClick={() => {
                this.setState({ showDropdown: !this.state.showDropdown });
              }}
              style={{ display: "flex", cursor: "pointer" }}
            >
              <WorkFromOfficeLogo
                fill={onSiteColor}
                stroke={onSiteColor}
                height="20"
                width="20"
                style={{ overflow: "visible" }}
              />
              <div style={{ paddingLeft: "10px" }}>
                {this.state.defaultValue?.name}
              </div>
            </div>
            {this.state.notices && this.state.notices.length > 0 && (
              <div
                style={{ paddingLeft: "10px" }}
                onClick={() => {
                  this.setState({ showModal: true });
                }}
              >
                <InfoLogo
                  fill={onSiteColor}
                  stroke={onSiteColor}
                  height="20"
                  width="20"
                  style={{ overflow: "visible", cursor: "pointer" }}
                />
              </div>
            )}
          </div>
          <ul className={this.state.showDropdown ? "active" : ""}>
            {items.map((item) => (
              <li
                key={item.id}
                onClick={() => {
                  const { id, name, notices } = item;
                  this.setState({
                    defaultValue: { id, name, notices },
                    showDropdown: false,
                    notices: notices,
                  });
                  onSelect(item);
                }}
              >
                {item.name}
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  }
}
