import React, { Component, useContext } from "react";
import "./WorkPlanSiteDropdown.css";
import { ReactComponent as WorkFromOfficeLogo } from "../../../assets/svg/marker.svg";
import { ReactComponent as InfoLogo } from "../../../assets/svg/info.svg";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import {
  Modal,
  DefaultButton,
  HoverCard,
  HoverCardType,
  Checkbox,
  Shimmer,
} from "@fluentui/react";
import ReactMarkdown from "react-markdown";
import { NoticeClient } from "../../../infrastructure/api/NoticeClient";
import _ from "lodash";

export class OtherNoticeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notices: [],
      currentNoticeIdx: 0,
      loading: true,
      acknowledgedNotices: [],
      acknowledgedIndexes: [],
    };
  }

  componentDidMount() {
    const { notices } = this.props;
    this.setState({ loading: true, notices: notices }, () => this.setup());
  }

  async setup() {
    await new Promise((resolve) => setTimeout(resolve, 1200));
    this.setState({ currentNoticeIdx: 0, loading: false });
  }

  render() {
    const { notices, showModal, onClose, viewOnly } = this.props;
    const { currentNoticeIdx } = this.state;

    const userId = localStorage.getItem("userId");

    return (
      <>
        {notices && notices.length ? (
          <Modal
            isOpen={showModal}
            onDismiss={() => {
              this.setState({ currentNoticeIdx: 0 });
              onClose();
            }}
            isBlocking={false}
            styles={{
              main: {
                width: "65%",
                padding: 0,
                overflow: "none",
              },
              scrollableContent: {
                overflow: "none",
              },
            }}
          >
            <div style={{ height: "550px" }}>
              {this.state.loading ? (
                <div style={{ width: "100%", display: "flex" }}>
                  <div style={{ width: "100%", margin: "20px 0px" }}>
                    <Shimmer style={{ padding: "5px" }} />
                    <Shimmer style={{ padding: "5px" }} width="90%" />
                    <Shimmer style={{ padding: "5px" }} width="80%" />
                    <Shimmer style={{ padding: "5px" }} width="70%" />
                    <Shimmer style={{ padding: "5px" }} width="60%" />
                    <Shimmer style={{ padding: "5px" }} width="50%" />
                    <Shimmer style={{ padding: "5px" }} width="40%" />
                  </div>
                </div>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        background:
                          notices && notices.length
                            ? notices[currentNoticeIdx]?.noticeType?.color
                            : "",
                        width: "7px",
                        height: "80px",
                      }}
                    ></div>
                    <div style={{ fontSize: "30px", fontWeight: "bold" }}>
                      {notices[currentNoticeIdx]?.name}
                    </div>{" "}
                    <div
                      style={{
                        fontSize: "11px",
                        color: "grey",
                        marginRight: "20px",
                      }}
                    >
                      {currentNoticeIdx + 1} of {notices.length}
                    </div>
                  </div>
                  <div
                    style={{
                      padding: "0px 20px",
                      maxHeight: "400px",
                      overflow: "scroll",
                    }}
                  >
                    {notices && notices.length > 0 && (
                      <div style={{ textAlign: "center" }}>
                        <ReactMarkdown>
                          {notices[currentNoticeIdx]?.body}
                        </ReactMarkdown>
                        {notices[currentNoticeIdx]?.warning && (
                          <span style={{ color: "red" }}>
                            <h3>IMPORTANT</h3>
                            <ReactMarkdown>
                              {notices[currentNoticeIdx]?.warning}
                            </ReactMarkdown>
                          </span>
                        )}
                        {notices[currentNoticeIdx].attachments &&
                          notices[currentNoticeIdx].attachments.length > 0 &&
                          notices[currentNoticeIdx].attachments.map(
                            (attachment) => {
                              const onRenderPlainCard = (item) => {
                                return (
                                  <img
                                    src={item.uri}
                                    alt="Image"
                                    style={{
                                      maxHeight: "300px",
                                      maxWidth: "300px",
                                    }}
                                  />
                                );
                              };
                              return (
                                <>
                                  <div
                                    key={attachment.id}
                                    style={{ width: "100%" }}
                                  >
                                    <img
                                      src={`${
                                        attachment.uri
                                      }?${new Date().toISOString()}`}
                                      alt="Image"
                                      style={{
                                        maxHeight: "300px",
                                        maxWidth: "300px",
                                      }}
                                    />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                    )}
                  </div>
                  {viewOnly ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        margin: "20px",
                        position: "absolute",
                        bottom: 0,
                        width: "97%",
                      }}
                    >
                      <DefaultButton
                        text="Close"
                        onClick={async () => {
                          onClose();
                        }}
                        style={{ alignSelf: "end" }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "20px",
                        position: "absolute",
                        bottom: 0,
                        width: "97%",
                      }}
                    >
                      {this.state.currentNoticeIdx > 0 ? (
                        <DefaultButton
                          text="Back"
                          onClick={async () => {
                            this.setState({
                              currentNoticeIdx: this.state.currentNoticeIdx - 1,
                            });
                          }}
                        />
                      ) : (
                        <div style={{ width: "80px" }}></div>
                      )}
                      {notices[currentNoticeIdx]?.acknowledge &&
                        !_.find(
                          notices[currentNoticeIdx].acknowledgedUsers,
                          (o) => {
                            return o.userId == userId;
                          }
                        ) &&
                        !_.filter(this.state.acknowledgedIndexes, (o) => {
                          return o == currentNoticeIdx;
                        }).length && (
                          <DefaultButton
                            text="Acknowledge"
                            onClick={async () => {
                              this.setState({
                                acknowledgedIndexes: [
                                  ...this.state.acknowledgedIndexes,
                                  currentNoticeIdx,
                                ],
                                acknowledgedNotices: [
                                  ...this.state.acknowledgedNotices,
                                  notices[currentNoticeIdx],
                                ],
                              });
                              await NoticeClient.AddUser(
                                notices[currentNoticeIdx].id,
                                userId
                              );
                              await NoticeClient.ViewNotice(
                                notices[currentNoticeIdx].id,
                                userId
                              );
                            }}
                          />
                        )}
                      {this.state.currentNoticeIdx + 1 === notices.length ? (
                        <DefaultButton
                          text="Close"
                          onClick={async () => {
                            await NoticeClient.ViewNotice(
                              notices[currentNoticeIdx].id,
                              userId
                            );

                            this.setState({
                              currentNoticeIdx: 0,
                            });
                            onClose();
                          }}
                          disabled={
                            notices[currentNoticeIdx]?.acknowledge &&
                            !_.find(
                              notices[currentNoticeIdx].acknowledgedUsers,
                              (o) => {
                                return o.userId == userId;
                              }
                            ) &&
                            !_.filter(this.state.acknowledgedIndexes, (o) => {
                              return o == currentNoticeIdx;
                            }).length
                          }
                          style={{ alignSelf: "end" }}
                        />
                      ) : (
                        <DefaultButton
                          text="Next"
                          onClick={async () => {
                            NoticeClient.ViewNotice(
                              notices[currentNoticeIdx].id,
                              userId
                            );

                            this.setState({
                              currentNoticeIdx: this.state.currentNoticeIdx + 1,
                            });
                          }}
                          disabled={
                            notices[currentNoticeIdx]?.acknowledge &&
                            !_.find(
                              notices[currentNoticeIdx].acknowledgedUsers,
                              (o) => {
                                return o.userId == userId;
                              }
                            ) &&
                            !_.filter(this.state.acknowledgedIndexes, (o) => {
                              return o == currentNoticeIdx;
                            }).length
                          }
                          style={{ alignSelf: "end" }}
                        />
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </Modal>
        ) : (
          ""
        )}
      </>
    );
  }
}
