import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import {
  EventsClient,
  EventsQueryParams,
} from "../../infrastructure/api/EventsClient";
import {
  FocusZone,
  List,
  DefaultButton,
  Shimmer,
  Modal,
} from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";
import Moment from "react-moment";
import { EventModal } from "../Modals/EventModal";
import { EventsList } from "./EventsList";
import { Badge } from "@fluentui/react-components";

export class EventsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      total: 0,
      search: "",
      sort: "companyName",
      direction: "asc",
      showSuspended: false,
      status: "",
      eventModalVisible: false,
      selectedData: null,
    };
    this.onRenderCell = this.onRenderCell.bind(this);
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
  }

  modalStyles = mergeStyleSets({
    root: {
      width: "600px",
      height: "600px",
    },
    container: {},
  });

  classNames = mergeStyleSets({
    container: {
      overflow: "auto",
      maxHeight: 1000,
    },
    itemCell: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: "border-box",
        borderBottom: `1px solid ${getTheme().semanticColors.bodyDivider}`,
        display: "flex",
        selectors: {
          "&:hover": { background: getTheme().palette.neutralLight },
        },
      },
    ],
    itemImage: {
      flexShrink: 0,
    },
    itemContent: {
      marginLeft: 10,
      overflow: "hidden",
      flexGrow: 1,
    },
    itemName: [
      getTheme().fonts.xLarge,
      {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    ],
    itemIndex: {
      fontSize: getTheme().fonts.small.fontSize,
      color: getTheme().palette.neutralTertiary,
      marginBottom: 0,
    },
    chevron: {
      alignSelf: "center",
      marginLeft: 10,
      color: getTheme().palette.neutralTertiary,
      fontSize: getTheme().fonts.large.fontSize,
      flexShrink: 0,
    },
    contentClass: {
      backgroundColor: getTheme().palette.neutralLight,
      color: getTheme().palette.neutralDark,
      lineHeight: "50px",
      padding: "0 20px",
    },
  });

  render() {
    const { selectedData } = this.state;
    return (
      // <FocusZone direction={FocusZoneDirection.vertical}>
      <div
        className={this.classNames.container}
        data-is-scrollable
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {this.state.data && !this.state.loading && (
          <EventsList
            events={this.props.events}
            userId={this.props.userId}
            refreshEvents={() => {
              this.props.refreshEvents();
            }}
          />
        )}
        {this.state.loading && (
          <div>
            <Shimmer style={{ padding: "5px 20px" }} />
            <Shimmer style={{ padding: "5px 20px" }} width="75%" />
            <Shimmer style={{ padding: "5px 20px" }} width="50%" />
          </div>
        )}
      </div>
      //</FocusZone>
    );
  }

  onRenderCell(item, index, isScrolling) {
    return (
      <div
        style={{ padding: "20px" }}
        onMouseOver={() => {
          this.setState({ selectedData: item });
        }}
      >
        <div
          className="card"
          onClick={() => {
            this.setState({ eventModalVisible: true });
          }}
          style={{ cursor: "pointer" }}
        >
          {item?.eventImages && item?.eventImages.length > 0 && (
            <img
              src={item?.eventImages[0]?.url}
              style={{
                width: "100%",
                borderRadius: "10px 10px 0px 0px",
                objectFit: "cover",
                height: "200px",
              }}
            ></img>
          )}
          <div className="container">
            <h4>
              <Moment utc local format="ddd DD MMM, YYYY">
                {item.startDate}
              </Moment>{" "}
              -{" "}
              <Moment utc local format="ddd DD MMM, YYYY">
                {item.endDate}
              </Moment>
            </h4>
            <h4>
              <b>{item.name}</b>
              {item.isAttendee && (
                <div
                  style={{
                    marginRight: "5px",
                    background: "#5B5FC7",
                    fontSize: "12px",
                    fontWeight: 600,
                    color: "white",
                    borderRadius: "50px",
                    padding: "4px",
                  }}
                >
                  Attending
                </div>
              )}
            </h4>
            <p>{item.description}</p>
          </div>
        </div>
      </div>
    );
  }

  async populateData(skip, take) {
    const { selectedDate, teamId, date, siteId, userId } = this.props;

    this.setState({
      loading: true,
    });

    // var response = await HomeClient.DayView(
    //   new HomeQueryParams()
    //     .WithSiteId(siteId)
    //     .WithTeamId(teamId)
    //     .WithUserId(userId)
    //     .WithNumberOfDays(7)
    //     .WithParam("startDate", date)
    // );

    // if (response.data) {
    //   this.setState({
    //     data: response.data,
    //   });
    // }
    this.setState({
      data: [{ label: "1" }],
    });
    this.setState({
      loading: false,
    });
  }
}
