import { ApiResponse, ListQueryParams } from "../ApiClient";
import {
  GetAPIDomain,
  GetAPIHeaders,
  GetAPIHeadersPatch,
  MyDayAPIHelper,
} from "../MyDayAPIHelper";

export class NoticeQueryParams extends ListQueryParams {
  WithStatus(value: string) {
    this.WithParam("status", value);
    return this;
  }
}

export class NoticeClient {
  static async AddUser(id: string, userId: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();

    return await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + `/notices/${id}/users?userId=${userId}`, {
        method: "post",
        headers: headers,
      })
    );
  }

  static async GetUnviewedNotices(userId: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();

    return await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + `/notices/unviewed-notices?userId=${userId}`, {
        method: "get",
        headers: headers,
      })
    );
  }

  static async ViewNotice(id: string, userId: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();

    return await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + `/notices/${id}/viewed-users?userId=${userId}`, {
        method: "post",
        headers: headers,
      })
    );
  }

  static async UserViewedNoticesList(userId: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();

    return await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + `/notices/viewed-notices?userId=${userId}`, {
        method: "get",
        headers: headers,
      })
    );
  }

  static async GetUnviewedNoticeForUserOnSite(userId: string, siteId: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();

    return await ApiResponse.CreateFromResponse(
      await fetch(
        apiDomain +
          `/notices/unviewed-notices-on-site?userId=${userId}&siteId=${siteId}`,
        {
          method: "get",
          headers: headers,
        }
      )
    );
  }

  static async AddNoticeViewed(id: string, userId: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();

    // headers.append("x-account-discovery", "");

    var response = await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + `/notices/${id}/viewed-users?userId=${userId}`, {
        method: "post",
        headers: headers,
        body: JSON.stringify({}),
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();

        return await ApiResponse.CreateFromResponse(
          await fetch(
            apiDomain + `/notices/${id}/viewed-users?userId=${userId}`,
            {
              method: "post",
              headers: headers,
              body: JSON.stringify({}),
            }
          )
        );
      }
    }

    return response;
  }
}
