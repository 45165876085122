import { ApiResponse, ListQueryParams } from "../ApiClient";
import {
  GetAPIDomain,
  GetAPIHeaders,
  GetApiHeadersFormData,
  GetAPIHeadersPatch,
  MyDayAPIHelper,
} from "../MyDayAPIHelper";

export class VehiclesQueryParams extends ListQueryParams {
  WithUserId(value: any) {
    this.WithParam("userId", value);
    return this;
  }
}

export class VehiclesClient {
  static async List(params: VehiclesQueryParams) {
    var query =
      (await GetAPIDomain()) + "/vehicles" + params.GenerateQueryString();
    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();

        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async Create(
    name: any,
    make: any,
    model: any,
    color: any,
    rego: any,
    note: any,
    ownedById: any,
    typeId: any
  ) {
    var query = (await GetAPIDomain()) + "/vehicles";
    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: await GetAPIHeaders(),
        method: "post",
        body: JSON.stringify({
          name,
          make,
          model,
          color,
          rego,
          note,
          ownedById,
          typeId,
        }),
      })
    );
    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();

        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
            method: "post",
            body: JSON.stringify({
              name,
              make,
              model,
              color,
              rego,
              note,
              ownedById,
              typeId,
            }),
          })
        );
      }
    }

    return response;
  }

  static async Delete(id) {
    var query = (await GetAPIDomain()) + `/vehicles/${id} `;
    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: await GetAPIHeaders(),
        method: "delete",
      })
    );
    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
            method: "delete",
          })
        );
      }
    }

    return response;
  }

  static async Get(id: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    var query = apiDomain + "/vehicles/" + id;

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async Update(
    id: string,
    name: any,
    make: any,
    model: any,
    color: any,
    rego: any,
    note: any,
    typeId: any
  ) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeadersPatch();

    return await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + "/vehicles/" + id, {
        method: "patch",
        headers: headers,
        body: JSON.stringify([
          {
            op: "replace",
            path: "name",
            value: name,
          },
          {
            op: "replace",
            path: "make",
            value: make,
          },
          {
            op: "replace",
            path: "model",
            value: model,
          },
          {
            op: "replace",
            path: "color",
            value: color,
          },
          {
            op: "replace",
            path: "rego",
            value: rego,
          },
          {
            op: "replace",
            path: "note",
            value: note,
          },
          {
            op: "replace",
            path: "typeId",
            value: typeId,
          },
        ]),
      })
    );
  }
}
