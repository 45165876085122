import React, { Component } from "react";
import {
  Stack,
  DefaultButton,
  DatePicker,
  defaultDatePickerStrings,
  Dialog,
  DialogFooter,
  DialogType,
  DayOfWeek,
  Checkbox,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import { CardDoughnut } from "../../Charts/CardDoughnut";
import { ReactComponent as CheckInLogo } from "../../../assets/svg/ticket.svg";
import { ReactComponent as PulseLogo } from "../../../assets/svg/heart-rate.svg";
import { PlannerActionSelector } from "../PlannerActionSelector/PlannerActionSelector";
import { WhatsOnButton } from "../WhatsOnButton/WhatsOnButton";
import { BookAssetButton } from "../BookAssetButton/BookAssetButton";
import {
  HomeClient,
  HomeQueryParams,
} from "../../../infrastructure/api/HomeClient";
import { WorkDaysClient } from "../../../infrastructure/api/WorkDaysClient";
import { SitesClient } from "../../../infrastructure/api/SitesClient";
import { NoticeModal } from "../WorkPlanSiteDropdown/NoticeModal";
import _ from "lodash";
import Moment from "react-moment";
import moment from "moment";

export class PulseCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentDate: new Date(new Date().toDateString()),
      myDay: null,
      pulseLoading: false,
      user: {},
      unacknowledgedNotices: [],
      showModal: false,
      selectedTeam: null,
      refreshPulseCard: true,
    };
    this.goToAssetsHandler = this.goToAssetsHandler.bind(this);
  }

  goToAssetsHandler() {
    this.props.goToAssets();
  }

  componentDidMount() {
    const { day, currentDate, user, selectedTeam, refreshPulseCard } =
      this.props;

    this.setState({ myDay: day, currentDate, user, selectedTeam }, this.update);
  }

  async update() {
    await this.populateData();
  }

  componentWillReceiveProps(props) {
    if (this.state.refreshPulseCard !== props.refreshPulseCard) {
      this.setState(
        { refreshPulseCard: props.refreshPulseCard },
        this.populateData
      );
    }

    if (this.state.currentDate !== props.currentDate) {
      this.setState({ currentDate: props.currentDate }, this.populateData);
    }

    if (this.state.user?.id !== props.user?.id) {
      this.setState({ user: props.user }, this.populateData);
    }

    if (this.state.selectedTeam?.id !== props.selectedTeam?.id) {
      this.setState({ selectedTeam: props.selectedTeam }, this.populateData);
    }
  }

  classNames = mergeStyleSets({
    cardShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)" },
        },
      },
    ],
    buttonShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)" },
        },
      },
    ],
  });

  render() {
    const {
      // day,
      selectedTeam,
      sites,
      user,
      notWorkingReasons,
      cardsLoading,
      selectMode,
      todayOnwardDates,
      onCheck,
      currentWeekStartDate,
      allDates,
      refreshPlanner,
      onChangeSite,
    } = this.props;

    const { myDay: day } = this.state;

    var onSiteColor = localStorage.getItem("theme_OnSite");
    var homeColor = localStorage.getItem("theme_Home");
    var notWorkingColor = localStorage.getItem("theme_NotWorking");
    var noResponseColor = localStorage.getItem("theme_NoResponse");

    var today = new Date(new Date().toDateString());

    const isToday =
      moment(day?.date).format("YYYY-MM-DD") ==
      moment(today).format("YYYY-MM-DD");

    var onSiteColor = localStorage.getItem("theme_OnSite");

    var cardHeight = parseInt(450);

    if (day?.users[0]?.myDay && day?.users[0]?.myDay?.status === "confirmed")
      cardHeight += 30;

    if (
      day?.users &&
      day?.users[0]?.myDay &&
      day?.users[0]?.myDay?.parts[0]?.state === "workFromOffice"
    )
      cardHeight += 30;
    else if (
      day?.users &&
      day?.users[0]?.myDay &&
      day?.users[0]?.myDay?.parts[0]?.state === "wotWorking"
    )
      cardHeight += 10;
    else if (
      day?.users &&
      day?.users[0]?.myDay &&
      day?.users[0]?.myDay?.parts[0]?.state === "workFromHome"
    )
      cardHeight = 450;

    return (
      <div>
        <NoticeModal
          notices={this.state.unacknowledgedNotices}
          showModal={this.state.showModal}
          onClose={(reload) => {
            this.populateData();
            this.setState({ showModal: false });
          }}
        />

        {this.state.myDay && (
          <Stack.Item
            style={{
              height: cardHeight + "px",
              backgroundColor: "white",
              borderRadius: "5px",
              flex: 1,
              maxWidth: "230px",
              minWidth: "230px",
              // margin: "0px 20px 20px 0px",
              textAlign: "center",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              opacity:
                this.state.loading || cardsLoading
                  ? 0.4
                  : new Date(day.date) < today.getTime()
                  ? 0.8
                  : 1,
              pointerEvents:
                this.state.loading ||
                cardsLoading ||
                new Date(day.date) < today.getTime()
                  ? "none"
                  : "all",
            }}
            className={this.classNames.cardShadow}
          >
            <div style={{ padding: "5px 10px" }}>
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      fontWeight: 600,
                      fontSize: 13,
                    }}
                  >
                    Pulse
                  </p>
                  <PulseLogo
                    height="18"
                    width="18"
                    style={{ overflow: "visible", marginRight: "5px" }}
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex" }}>
                    {selectMode && (
                      <Checkbox
                        label=""
                        onChange={() => {
                          onCheck(day.date);
                        }}
                        checked={todayOnwardDates.includes(day.date)}
                      />
                    )}
                    <Moment utc local format="DD MMM">
                      {day.date}
                    </Moment>
                  </div>
                  <div>{this.state.percentWorking}%</div>
                </div>
              </div>
              <CardDoughnut
                key={new Date(day.date).getTime()}
                isSelected={
                  this.state.currentDate &&
                  new Date(day.date).getTime() ==
                    this.state.currentDate.getTime()
                }
                date={new Date(day.date)}
                pieData={day.pieChart}
                myDay={this.state.myDay}
              />
            </div>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <PlannerActionSelector
                dayData={day}
                currentDate={new Date(day.date)}
                currentUser={user}
                notWorkingReasons={notWorkingReasons}
                sites={sites}
                updatePie={async (reloadData) => {
                  if (reloadData) {
                    this.setState({ loading: true }, async () => {
                      this.populateData();
                      refreshPlanner();
                    });
                  } else {
                    this.setState({ loading: true });
                  }
                }}
                onChangeSite={async (id) => {
                  this.setState({ loading: true }, async () => {
                    await this.populateData();
                    if (onChangeSite) onChangeSite(id);
                  });
                }}
                showNoticeModal={(unacknowledgedNotices) => {
                  this.setState({
                    unacknowledgedNotices: unacknowledgedNotices,
                    showModal: true,
                  });
                }}
              />

              {day?.users && day?.users[0]?.checkIn && (
                <BookAssetButton
                  site={day?.users[0]?.myDay?.parts[0]?.site}
                  // date={day?.users[0]?.myDay.date}
                  date={day.date}
                  currentWeekStartDate={currentWeekStartDate}
                  allDates={allDates}
                  refreshPlanner={() => {
                    refreshPlanner();
                  }}
                  refreshDay={() => {
                    this.setState({ loading: true }, () => {
                      this.populateData();
                      refreshPlanner();
                    });
                  }}
                  label={day?.users[0]?.checkIn?.asset?.name}
                  checkIns={day?.users[0]?.userCheckIns}
                  softReload={() => {
                    this.populateData(true);
                  }}
                  fromPulseCard
                />
              )}

              {day?.users &&
                !day?.users[0]?.checkIn &&
                day?.users[0]?.myDay &&
                day?.users[0]?.myDay?.parts[0]?.state === "workFromOffice" && (
                  <BookAssetButton
                    site={day?.users[0]?.myDay?.parts[0]?.site}
                    // date={day?.users[0]?.myDay.date}
                    date={day.date}
                    currentWeekStartDate={currentWeekStartDate}
                    allDates={allDates}
                    refreshPlanner={() => {
                      refreshPlanner();
                    }}
                    refreshDay={() => {
                      this.setState({ loading: true }, () => {
                        this.populateData();
                        refreshPlanner();
                      });
                    }}
                    label="Book Asset"
                  />
                )}

              {day?.users[0]?.myDay &&
                day?.users[0]?.myDay?.status !== "confirmed" && (
                  <div
                    style={{
                      borderRadius: "5px",
                      minWidth: "150px",
                      textAlign: "left",
                      padding: "10px",
                      display: "flex",
                      cursor: isToday ? "pointer" : "default",
                      marginBottom: "15px",
                      justifyContent: "center",
                      color: isToday ? `${onSiteColor}` : "",
                      background: this.state.pulseLoading ? "#ffff" : "",
                    }}
                    className={this.classNames.buttonShadow}
                    onClick={async () => {
                      if (!isToday) return;

                      if (
                        this.state.unacknowledgedNotices &&
                        this.state.unacknowledgedNotices.length > 0
                      ) {
                        this.setState({ showModal: true });
                      } else {
                        var id = day?.users[0]?.myDay.id;
                        var updatedMyDay = this.state.myDay;
                        updatedMyDay.users[0].myDay.status = "confirmed";
                        this.setState({ pulseLoading: true });
                        await WorkDaysClient.ConfirmMyDay(id);
                        this.setState({
                          myDay: updatedMyDay,
                          pulseLoading: false,
                        });
                      }
                    }}
                  >
                    <b style={isToday ? {} : { color: "grey" }}>Check In</b>
                    {this.state.pulseLoading && (
                      <Spinner size={SpinnerSize.medium} />
                    )}
                  </div>
                )}
              {day?.users[0]?.myDay &&
                day?.users[0]?.myDay?.status === "confirmed" && (
                  <div
                    style={{
                      borderRadius: "5px",
                      minWidth: "150px",
                      textAlign: "left",
                      padding: "10px",
                      display: "flex",
                      marginBottom: "15px",
                      justifyContent: "center",
                      background: "#ffffff",
                      cursor: "default",
                    }}
                    className={this.classNames.buttonShadow}
                    onClick={() => {}}
                  >
                    <b style={{ color: "grey" }}>Checked In</b>
                  </div>
                )}

              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  width: "-webkit-fill-available",
                  marginRight: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    margin: "10px 0",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginRight: "5px",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        alignSelf: "center",
                        borderRadius: "10px",
                        backgroundColor: `${onSiteColor}`,
                        marginRight: "5px",
                      }}
                    ></div>
                    <div>{day.pieChart.workingOnSite}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "5px",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        alignSelf: "center",
                        borderRadius: "10px",
                        backgroundColor: `${homeColor}`,
                        marginRight: "5px",
                      }}
                    ></div>
                    <div>{day.pieChart.workingFromHome}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "5px",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        alignSelf: "center",
                        borderRadius: "10px",
                        backgroundColor: `${notWorkingColor}`,
                        marginRight: "5px",
                      }}
                    ></div>
                    <div>{day.pieChart.notWorking}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "5px",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        alignSelf: "center",
                        borderRadius: "10px",
                        backgroundColor: `${noResponseColor}`,
                        marginRight: "5px",
                      }}
                    ></div>
                    <div>{day.pieChart.noResponse}</div>
                  </div>
                </div>

                {day?.teamEvent && (
                  <div
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                      height: "20px",
                      backgroundColor: `${day?.teamEvent?.color}`,
                      marginTop: "auto",
                      marginBottom: "10px",
                    }}
                    className={this.classNames.buttonShadow}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        color: "white",
                        marginTop: "auto",
                      }}
                    >
                      Collaboration Day
                    </p>
                  </div>
                )}
              </div>
            </div>
          </Stack.Item>
        )}
      </div>
    );
  }

  renderDatePicker() {
    return (
      <Dialog
        type={DialogType.normal}
        title="Choose date"
        hidden={!this.state.isDatePickerOpen}
        onDismiss={() => this.setState({ isDatePickerOpen: false })}
      >
        <DatePicker
          firstDayOfWeek={DayOfWeek.Monday}
          id="currentDatePicker2"
          minDate={new Date()}
          value={this.state.currentDate}
          onSelectDate={(date) =>
            this.setState(
              {
                currentDate: date,
                currentWeekStartDate: this.getMonday(date),
                isDatePickerOpen: false,
              },
              this.populateData
            )
          }
          placeholder="Select a date..."
          ariaLabel="Select a date"
          // DatePicker uses English strings by default. For localized apps, you must override this prop.
          strings={defaultDatePickerStrings}
        />

        <DialogFooter>
          <DefaultButton
            onClick={(ev) => {
              this.setState({ isDatePickerOpen: false });
              ev.preventDefault();
            }}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
    );
  }

  async populateData(softLoad = false) {
    this.setState({ loading: true });

    const { user, currentDate, selectedTeam, refreshPlanner } = this.props;

    if (!selectedTeam?.id) return;

    var siteId = user.site.id;
    var userSummary = await HomeClient.DayView(
      new HomeQueryParams()
        .WithSiteId(siteId)
        .WithTeamId(selectedTeam.id)
        .WithUserId(user.id)
        .WithNumberOfDays(1)
        .WithParam("startDate", currentDate)
    );

    this.setState(
      {
        myDay: userSummary.data[0],
        user: user,
      },
      async () => {
        const { myDay } = this.state;

        if (myDay.pieChart) {
          var percentWorking =
            myDay.pieChart.workingOnSite > 0
              ? Math.round(
                  (myDay.pieChart.workingOnSite / myDay.pieChart.total) * 100
                )
              : 0;

          this.setState({ percentWorking: percentWorking });
        }

        if (
          myDay &&
          myDay?.parts &&
          myDay?.parts[0]?.siteId &&
          myDay?.parts[0]?.state == "workFromOffice"
        ) {
          var siteData = await SitesClient.Get(myDay?.parts[0]?.siteId);

          const userId = localStorage.getItem("userId");
          if (siteData.data) {
            const site = siteData.data;
            var notices = site?.notices;

            var unacknowledgedNotices = _.filter(notices, (notice) => {
              return !_.find(notice.acknowledgedUsers, (o) => {
                return o.userId == userId;
              });
            });

            if (unacknowledgedNotices && unacknowledgedNotices.length > 0) {
              this.setState({
                unacknowledgedNotices: unacknowledgedNotices,
                loading: false,
              });
            } else {
              this.setState({ unacknowledgedNotices: [], loading: false });
            }
          }
        } else {
          this.setState({ loading: false });
        }
      }
    );
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }
}
