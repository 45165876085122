import { ApiResponse, ListQueryParams } from "../ApiClient";
import {
  GetAPIDomain,
  GetAPIHeaders,
  GetApiHeadersFormData,
  GetAPIHeadersPatch,
  MyDayAPIHelper,
} from "../MyDayAPIHelper";

export class TeamQueryParams extends ListQueryParams {
  WithUserId(value: string) {
    this.WithParam("userId", value);
    return this;
  }
  WithExpand(value: boolean) {
    this.WithParam("expand", value);
    return this;
  }
}

export class TeamsClient {
  static async List(params: TeamQueryParams) {
    var query =
      (await GetAPIDomain()) + "/teams" + params.GenerateQueryString();
    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async Get(id: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    var query = apiDomain + "/teams/" + id;

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async GetExpectedSiteDays(id: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    var query = apiDomain + "/teams/" + id + "/expected-site-days";

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async SetExpectedSiteDays(id: string, validDays: string[]) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    var query = apiDomain + "/teams/" + id + "/expected-site-days";

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
        method: "post",
        body: JSON.stringify({ validDays }),
      })
    );

    return response;
  }

  static async Create(
    name: string,
    description: string,
    status: string,
    isprivate: boolean,
    color: string,
    addCurrentUserAsTeamLead: boolean
  ) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();

    return await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + "/teams/", {
        method: "post",
        headers: headers,
        body: JSON.stringify({
          name: name,
          description: description,
          status: status,
          private: isprivate,
          color,
          addCurrentUserAsTeamLead,
        }),
      })
    );
  }

  static async Delete(id: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    return await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + "/teams/" + id, {
        method: "delete",
        headers: headers,
      })
    );
  }

  static async AddUsers(id: string, userIds: string[]) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    return await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + "/teams/" + id + "/add-users", {
        method: "post",
        headers: headers,
        body: JSON.stringify(userIds),
      })
    );
  }

  static async RemoveUsers(id: string, userIds: string[]) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    return await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + "/teams/" + id + "/remove-users", {
        method: "delete",
        headers: headers,
        body: JSON.stringify(userIds),
      })
    );
  }
}
