import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  HomeClient,
  HomeQueryParams,
} from "../../infrastructure/api/HomeClient";
import Moment from "react-moment";
import { TooltipHost, ITooltipHostStyles } from "@fluentui/react/lib/Tooltip";
import _ from "lodash";

export class MobileCardDoughnut extends Component {
  constructor(props) {
    Chart.register(ArcElement, Tooltip, Legend);
    super(props);
    this.state = {
      data: [0, 0, 0, 0],
      loading: false,
      total: 0,
      search: "",
      sort: "companyName",
      direction: "asc",
      showSuspended: false,
      status: "",
      percentWorking: 0,
      labelColor: localStorage.getItem("theme_NoResponse"),
      myDay: {},
    };
  }

  componentDidMount() {
    const { pieData, myDay } = this.props;
    var percentWorking =
      pieData.workingOnSite > 0
        ? Math.round((pieData.workingOnSite / pieData.total) * 100)
        : 0;

    this.setState({ percentWorking: percentWorking });

    var onSiteColor = localStorage.getItem("theme_OnSite");
    var homeColor = localStorage.getItem("theme_Home");
    var noResponseColor = localStorage.getItem("theme_NoResponse");
    var notWorkingColor = localStorage.getItem("theme_NotWorking");
    if (myDay) {
      if (myDay?.users[0]?.myDay?.parts[0]?.state === "workFromHome") {
        this.setState({ labelColor: homeColor, myDay });
      } else if (myDay?.users[0]?.myDay?.parts[0]?.state === "workFromOffice") {
        this.setState({ labelColor: onSiteColor, myDay });
      } else if (myDay?.users[0]?.myDay?.parts[0]?.state === "notWorking") {
        this.setState({ labelColor: notWorkingColor, myDay: myDay });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { pieData, myDay } = this.props;
    if (myDay) {
      var onSiteColor = localStorage.getItem("theme_OnSite");
      var homeColor = localStorage.getItem("theme_Home");
      var noResponseColor = localStorage.getItem("theme_NoResponse");
      var notWorkingColor = localStorage.getItem("theme_NotWorking");
      if (
        this.props.myDay?.users[0]?.myDay?.parts[0]?.id !==
        prevProps.myDay?.users[0]?.myDay?.parts[0]?.id
      ) {
        if (myDay?.users[0]?.myDay?.parts[0]?.state === "workFromHome") {
          this.setState({ labelColor: homeColor, myDay });
        } else if (
          myDay?.users[0]?.myDay?.parts[0]?.state === "workFromOffice"
        ) {
          this.setState({ labelColor: onSiteColor, myDay });
        } else if (myDay?.users[0]?.myDay?.parts[0]?.state === "notWorking") {
          this.setState({ labelColor: notWorkingColor, myDay });
        }
      }
    }
  }

  render() {
    var onSiteLabel = localStorage.getItem("localization_OnSite");
    var noResponseLabel = localStorage.getItem("localization_NoResponse");

    const { pieData, onClick, percentWorking, siteLimit } = this.props;

    var onSiteColor = localStorage.getItem("theme_OnSite");
    var noResponseColor = localStorage.getItem("theme_NoResponse");
    var total = !siteLimit ? siteLimit : siteLimit - pieData.workingOnSite;
    var data = {
      labels: [onSiteLabel, noResponseLabel],
      datasets: [
        {
          data: [pieData.workingOnSite, total],
          backgroundColor: [onSiteColor, noResponseColor],
        },
      ],
    };

    var options = {
      showTooltips: false,
      cutout: 12,
      events: [],
      animation: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltips: { enabled: false },
        hover: { mode: null }, // animation duration after a resize
      },
    };

    return (
      <div onClick={onClick}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "40px" }}>
            <Doughnut data={data} id="homePieChart" options={options} />
          </div>
          <div
            style={{
              width: "25px",
              height: "25px",
              textAlign: "center",
              borderRadius: "100px",
              backgroundColor: "transparent",
              color: "black",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                position: "absolute",
                width: "100%",
                fontWeight: "bolder",
                fontSize: "9px",
              }}
            >
              {percentWorking ?? 0}%
            </span>
          </div>
        </div>
      </div>
    );
  }
}
