import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import {
  FocusZone,
  List,
  DefaultButton,
  Shimmer,
  PrimaryButton,
  Modal,
  Spinner,
  SpinnerSize,
  Label,
} from "@fluentui/react";
import Moment from "react-moment";
import { HourSelector } from "../Selectors/HourSelector";
import { DurationSelector } from "../Selectors/DurationSelector";
import { WorkDaysClient } from "../../infrastructure/api/WorkDaysClient";
import moment from "moment-timezone";

const momentTz = require("moment-timezone");

export class BookAssetModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonLoading: false,
      hour: null,
      duration: null,
      errorMessage: "",
    };
  }

  componentDidMount() {}

  attendUser() {
    const { userId } = this.props;
  }

  modalStyles = mergeStyleSets({
    content: { borderRadius: "10px" },
    root: {
      width: "500px",
      height: "300px",
      backgroundColor: "#F0EEEE",
    },
    itemCell: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: "border-box",
        borderBottom: `1px solid ${getTheme().semanticColors.bodyDivider}`,
        display: "flex",
        transition: "0.3s",
        margin: "10px 20px",
        selectors: {
          "&:hover": {
            background: getTheme().palette.neutralLight,
          },
        },
      },
    ],
    itemImage: {
      flexShrink: 0,
    },
    itemContent: {
      marginLeft: 10,
      overflow: "hidden",
      flexGrow: 1,
    },
    itemName: [
      getTheme().fonts.xLarge,
      {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    ],
    itemIndex: {
      fontSize: getTheme().fonts.small.fontSize,
      color: getTheme().palette.neutralTertiary,
      marginBottom: 10,
    },
  });

  render() {
    const { modalVisible, selectedData, onCancel, selectedDate, onOk, userId } =
      this.props;
    const { buttonLoading } = this.state;

    const filteredAllocations =
      selectedData.allocations && selectedData.allocations.length > 0
        ? selectedData.allocations.filter((i) => i.description !== "Reserved")
        : [];

    var dateUpdated = moment(selectedDate).format("DD-MM-YYYY");
    var todayFormatted = moment().format("DD-MM-YYYY");
    return (
      <Modal
        isOpen={modalVisible}
        onDismiss={() => {
          this.setState({ buttonLoading: false, errorMessage: "" }, () =>
            onCancel()
          );
        }}
        isBlocking={false}
        styles={{ borderRadius: "10px" }}
        animated="fade-in"
      >
        {selectedData && (
          <div className={this.modalStyles.root}>
            <Label style={{ padding: "10px 20px" }}>Book Asset</Label>
            <div
              className={this.modalStyles.itemCell + " hover"}
              style={{
                backgroundColor: "white",
              }}
            >
              <div className={this.modalStyles.itemContent}>
                <div className={this.modalStyles.itemName}>
                  {selectedData?.name}
                </div>
                <div className={this.modalStyles.itemIndex}>
                  {selectedData.floor?.name}
                </div>
              </div>
            </div>
            <div className="container" style={{ padding: "10px 20px" }}>
              <Label>
                <Moment utc local format="ddd DD MMM, YYYY">
                  {selectedDate}
                </Moment>{" "}
                {this.state.errorMessage && (
                  <span style={{ color: "red", marginLeft: "10px" }}>
                    {this.state.errorMessage}
                  </span>
                )}
              </Label>

              <div
                style={{
                  padding: "10px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "49%" }}>
                  <HourSelector
                    onSelect={({ id, name }) => {
                      this.setState({ hour: id });
                    }}
                    checkIns={selectedData?.checkIns}
                    isToday={dateUpdated === todayFormatted ? true : false}
                  />
                </div>
                <div style={{ width: "49%" }}>
                  <DurationSelector
                    onSelect={({ id, name }) => {
                      this.setState({ duration: id });
                    }}
                  />
                </div>
              </div>

              <PrimaryButton
                text="Book"
                onClick={async () => {
                  this.setState({ buttonLoading: true });
                  const { duration, hour } = this.state;

                  const hr = hour ? hour : moment().format("HH:mm");

                  var tz = moment.tz.guess();
                  const start = moment.tz(selectedDate, tz); // original timezone

                  const local = start
                    .tz(tz)
                    .format("YYYY-MM-DD[T]HH:mm:ss.SSS");

                  var withTimeUtc = moment(local)
                    .utc()
                    .add(hr, "h")
                    .format("YYYY-MM-DD[T]HH:mm:ss.SSS");

                  var response = await WorkDaysClient.CheckIn(
                    selectedData.id,
                    duration,
                    [withTimeUtc]
                  );
                  if (response?.data && response?.data[0]?.errorType) {
                    this.setState({ errorMessage: response?.data[0]?.reason });
                  } else {
                    this.setState(
                      { buttonLoading: false, errorMessage: "" },
                      () => onOk()
                    );
                  }
                }}
                disabled={
                  (!this.state.duration && !this.state.hour) ||
                  this.state.buttonLoading
                }
                style={{ width: "100%", marginTop: "20px" }}
              />
            </div>
            {filteredAllocations &&
              filteredAllocations.length &&
              filteredAllocations.map((allocation) => (
                <div
                  style={{
                    fontSize: "10px",
                    color: "white",
                    borderRadius: "5px",
                    background: allocation.color,
                    padding: "3px",
                    marginBottom: "5px",
                  }}
                >
                  {allocation.name}
                </div>
              ))}
          </div>
        )}
      </Modal>
    );
  }
}
