import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  HomeClient,
  HomeQueryParams,
} from "../../infrastructure/api/HomeClient";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";

export class DaySelectChart extends Component {
  constructor(props) {
    Chart.register(ArcElement, Tooltip, Legend);
    super(props);
    this.state = {
      data: [0, 0, 0, 0],
      loading: false,
      total: 0,
      search: "",
      sort: "companyName",
      direction: "asc",
      showSuspended: false,
      status: "",
    };
  }

  renderTable(data) {}

  render() {
    var days = ["S", "M", "T", "W", "T", "F", "S"];

    const { teamEvent, pieData, onClick, date, isSelected } = this.props;

    var data = {
      labels: ["Home", "On Site", "Not Working", "No Response"],
      datasets: [
        {
          data: [
            pieData.workingFromHome,
            pieData.workingOnSite,
            pieData.notWorking,
            pieData.noResponse,
          ],
          backgroundColor: ["#00838f", "#fcc250", "#EF6E6C", "#dcf0f2"],
        },
      ],
    };

    var textColor = ["white", "white", "white", "black"];

    var max = Math.max(...data.datasets[0].data);
    var selectedColor =
      data.datasets[0].backgroundColor[data.datasets[0].data.indexOf(max)];
    var selectedTextColor = textColor[data.datasets[0].data.indexOf(max)];

    var options = {
      showTooltips: false,
      cutout: 15,
      events: [],
      animation: false,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltips: { enabled: false },
        hover: { mode: null }, // animation duration after a resize
      },
    };

    return (
      <div className="hover" style={{ position: "relative" }} onClick={onClick}>
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "50px",
            fontSize: "15px",
            textAlign: "center",
            borderRadius: "25px",
            backgroundColor: isSelected ? selectedColor : "transparent",
            color: isSelected ? selectedTextColor : "black",
          }}
        >
          <span
            style={
              teamEvent
                ? {
                    fontWeight: "bolder",
                    fontSize: "15px",
                    color: teamEvent?.color,
                  }
                : { fontWeight: "bolder", fontSize: "15px" }
            }
          >
            {days[date.getDay()]}
          </span>
        </div>
        <div style={{ position: "absolute", width: "50px" }}>
          <Doughnut data={data} id="homePieChart" options={options} />
        </div>
      </div>
    );
  }
}
