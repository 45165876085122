import React, { Component, useContext } from "react";
import { ReactComponent as OnSiteLogo } from "../../../assets/svg/building.svg";
import { ReactComponent as NotWorkingLogo } from "../../../assets/svg/brightness.svg";
import { ReactComponent as HomeLogo } from "../../../assets/svg/house-chimney.svg";
import { ReactComponent as WorkFromOfficeLogo } from "../../../assets/svg/home-location-filled.svg";
import { WorkDaysClient } from "../../../infrastructure/api/WorkDaysClient";
import moment from "moment";
import { WorkPlanSiteDropdown } from "../WorkPlanSiteDropdown/WorkPlanSiteDropdown";
import { WorkPlanNotWorkingDropdown } from "../WorkPlanNotWorkingDropdown/WorkPlanNotWorkingDropdown";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import _ from "lodash";
import Popup from "reactjs-popup";
import "./PlannerActionSelector.css";
import { generateGUID } from "../../../infrastructure/helpers/stringHelper";
import { NoticeClient } from "../../../infrastructure/api/NoticeClient";

export class PlannerActionSelector extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
      myDay: null,
      sites: [],
      notWorkingReasons: [],
      defaultSite: null,
      defaultReason: null,
      showDropdown: false,
      oldState: null,
      key: generateGUID(),
    };
  }

  classNames = mergeStyleSets({
    buttonShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)" },
        },
      },
    ],
  });

  componentDidMount() {
    this._isMounted = true;
    this.populateData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps(props) {
    const { dayData, currentUser, reloadWhenPropsChanged } = props;

    if (dayData?.users && dayData?.users?.length > 0) {
      var currentUserData = _.filter(dayData?.users, function (o) {
        return o.user.id === currentUser.id;
      });
      if (currentUserData?.length > 0 && this.state.myDay) {
        if (
          currentUserData[0]?.myDay?.parts[0]?.site &&
          currentUserData[0]?.myDay?.parts[0]?.site?.id !==
            this.state.myDay.parts[0]?.site?.id
        ) {
          this.setState({
            defaultSite: currentUserData[0]?.myDay?.parts[0]?.site,
            myDay: currentUserData[0]?.myDay,
          });
        }
      }
    }
  }

  async populateData() {
    const { dayData, currentUser, sites, notWorkingReasons } = this.props;

    var mappedReasons =
      notWorkingReasons && notWorkingReasons.length > 0
        ? notWorkingReasons.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        : [];
    this.setState({
      sites: sites,
      notWorkingReasons: mappedReasons,
    });

    if (dayData?.users && dayData?.users?.length > 0) {
      var currentUserData = _.filter(dayData?.users, function (o) {
        return o.user.id === currentUser.id;
      });

      if (currentUserData?.length > 0) {
        if (currentUserData[0]?.myDay?.parts[0]?.site) {
          var site = sites.filter(
            (site) => site.id === currentUserData[0]?.myDay?.parts[0]?.site?.id
          )[0];
          var defaultSite = site;
          this.setState({
            defaultSite: defaultSite,
            myDay: currentUserData[0]?.myDay,
          });
        } else if (currentUserData[0]?.myDay?.parts[0]?.notWorkingReason) {
          var defaultReason = {
            value: currentUserData[0]?.myDay?.parts[0]?.notWorkingReason?.id,
            label: currentUserData[0]?.myDay?.parts[0]?.notWorkingReason?.name,
          };
          this.setState({
            defaultReason: defaultReason,
            myDay: currentUserData[0]?.myDay,
          });
        } else {
          this.setState({
            myDay: currentUserData[0]?.myDay,
          });
        }
      }
    }
  }

  render() {
    const {
      currentDate,
      currentUser,
      notWorkingReasons,
      updatePie,
      onChangeSite,
    } = this.props;

    var onSiteColor = localStorage.getItem("theme_OnSite");
    var homeColor = localStorage.getItem("theme_Home");
    var noResponseColor = localStorage.getItem("theme_NoResponse");
    var notWorkingColor = localStorage.getItem("theme_NotWorking");
    var whatsOnHolidayColor = localStorage.getItem("theme_WhatsOnHoliday");
    var whatsOnEventColor = localStorage.getItem("theme_WhatsOnEvent");

    var action =
      this.state.myDay?.status &&
      (this.state.myDay?.status === "confirmed" ||
        this.state.myDay?.status === "planned")
        ? this.state.myDay?.parts[0]?.state
        : null;

    const onClick = async (status) => {
      var today = new Date(new Date().toDateString());
      var convertedDate = moment.tz(
        new Date(this.props.currentDate),
        "YYYY-MM-DD HH:mm:ss",
        "Pacific/Auckland"
      );

      if (this.props.currentDate.getTime() < today.getTime()) {
        console.log("Past date - ignore");
        return;
      }

      var oldState = !this.state.myDay ? null : this.state.myDay.parts[0].state;

      var myDay = !this.state.myDay
        ? {
            date: new Date(this.props.currentDate).toDateString(),
            status: "planned",
            parts: [
              {
                state: "workFromOffice",
              },
            ],
          }
        : this.state.myDay;

      if (status === "workFromOffice") {
        action = "workFromOffice";
        myDay.parts[0].siteId = currentUser?.site?.id;
        myDay.parts[0].state = "workFromOffice";
        myDay.parts[0].notWorkingReasonId = null;
        this.setState({
          defaultSite: {
            id: currentUser?.site?.id,
            name: currentUser?.site?.name,
            notices: currentUser?.site?.notices,
          },
        });
      } else if (status === "notWorking") {
        action = "notWorking";
        // myDay.parts[0].notWorkingReasonId = notWorkingReasons[0]?.id;
        myDay.parts[0].state = "notWorking";
        myDay.parts[0].siteId = null;
        this.setState({ oldState: oldState });
      } else if (status === "workFromHome") {
        action = "workFromHome";
        myDay.parts[0].state = "workFromHome";
        myDay.parts[0].notWorkingReasonId = null;
        myDay.parts[0].siteId = null;
      }

      if (this.props.currentDate.getTime() == today.getTime()) {
        myDay.status = "confirmed";
        myDay.confirmed = new Date();
        myDay.dates = [this.props.currentDate];
      } else {
        myDay.status = "planned";
        myDay.dates = [this.props.currentDate];
      }

      myDay.date = this.props.currentDate;

      this.setState(
        {
          myDay: myDay,
        },
        () => {
          if (status !== "notWorking")
            this.setState({
              defaultReason: null,
            });
        }
      );
      //only create notworking after creating a reason
      if (status === "notWorking") {
        this.setState({
          showDropdown: !this.state.showDropdown,
        });
        return;
      }

      await WorkDaysClient.Create(myDay);
      updatePie(true);

      if (status === "workFromOffice") {
        const { showNoticeModal } = this.props;
        if (showNoticeModal) {
          var noticeResult = await NoticeClient.GetUnviewedNoticeForUserOnSite(
            currentUser?.id,
            myDay.parts[0].siteId
          );

          if (
            noticeResult.data?.hasNoticesToView &&
            noticeResult.data?.notices
          ) {
            showNoticeModal(noticeResult.data?.notices);
          }
        }
      }
    };

    return (
      <div key={this.props.key}>
        <div
          style={{
            borderRadius: "5px",
            minWidth: "150px",
            textAlign: "left",
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "15px",
          }}
          className={this.classNames.buttonShadow}
        >
          <span
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              onClick("workFromOffice");
            }}
          >
            <OnSiteLogo
              fill={action === "workFromOffice" ? onSiteColor : noResponseColor}
              stroke={
                action === "workFromOffice" ? onSiteColor : noResponseColor
              }
              height="20"
              width="20"
            />
          </span>

          <span
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              onClick("workFromHome");
            }}
          >
            <HomeLogo
              fill={action === "workFromHome" ? homeColor : noResponseColor}
              stroke={action === "workFromHome" ? homeColor : noResponseColor}
              height="20"
              width="20"
            />
          </span>
          <span
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              onClick("notWorking");
            }}
          >
            <NotWorkingLogo
              fill={action === "notWorking" ? notWorkingColor : noResponseColor}
              stroke={
                action === "notWorking" ? notWorkingColor : noResponseColor
              }
              height="20"
              width="20"
            />
          </span>
        </div>
        {action === "workFromOffice" && (
          <WorkPlanSiteDropdown
            items={this.state.sites}
            defaultValue={this.state.defaultSite}
            onSelect={async ({ id }) => {
              var myDay = this.state.myDay;
              myDay.parts[0].notWorkingReasonId = null;
              myDay.parts[0].siteId = id;
              myDay.dates = [this.props.currentDate];

              await WorkDaysClient.Create(myDay);

              if (onChangeSite) {
                onChangeSite(id);
              }
            }}
          />
          // <div
          //   style={{
          //     borderRadius: "5px",
          //     minWidth: "150px",
          //     height: "40px",
          //     textAlign: "left",
          //     padding: "10px",
          //     display: "flex",
          //     cursor: "pointer",
          //     marginBottom: "15px",
          //   }}
          //   className={this.classNames.buttonShadow}
          // >
          //   <OnSiteLogo
          //     fill="#38818D"
          //     stroke="#38818D"
          //     height="20"
          //     width="20"
          //   />
          //   <div style={{ paddingLeft: "10px" }}>
          //     {currentUser?.site?.name}{" "}
          //   </div>
          // </div>
        )}
        {action === "notWorking" && (
          <div className="not-working-dropdown-wrapper">
            <ul className={this.state.showDropdown ? "active" : ""}>
              {this.state.notWorkingReasons.map((item) => (
                <li
                  key={item.value}
                  onClick={async () => {
                    var myDay = this.state.myDay;
                    myDay.parts[0].notWorkingReasonId = item.value;
                    myDay.parts[0].siteId = null;

                    this.setState({
                      showDropdown: false,
                      defaultReason: {
                        label: item.label,
                        value: item.value,
                      },
                    });

                    await WorkDaysClient.Create(myDay);
                    updatePie(true);
                  }}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          </div>
        )}
        {action == "notWorking" && this.state.defaultReason?.label && (
          <>
            <WorkPlanNotWorkingDropdown
              items={this.state.notWorkingReasons}
              defaultValue={this.state.defaultReason}
              onSelect={({ value }) => {
                var myDay = this.state.myDay;
                myDay.parts[0].notWorkingReasonId = value;
                myDay.parts[0].siteId = null;
                myDay.dates = [this.props.currentDate];

                WorkDaysClient.Create(myDay);
              }}
            />
          </>
        )}
      </div>
    );
  }
}
