import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  ImageFit,
  Stack,
  Image,
  Dropdown,
  DefaultButton,
  ChoiceGroup,
  DatePicker,
  defaultDatePickerStrings,
  addDays,
  Panel,
  Dialog,
  DialogFooter,
  PrimaryButton,
  DialogType,
  DayOfWeek,
  Shimmer,
  ShimmerElementType,
  ShimmerElementsGroup,
  Label,
  Checkbox,
} from "@fluentui/react";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import { Icon, FontIcon } from "@fluentui/react/lib/Icon";
import { CardDoughnut } from "../../../components/Charts/CardDoughnut";
import { ReactComponent as CheckInLogo } from "../../../assets/svg/ticket.svg";
import { PlannerActionSelector } from "../PlannerActionSelector/PlannerActionSelector";
import { WhatsOnButton } from "../../Common/WhatsOnButton/WhatsOnButton";
import { BookAssetButton } from "../../Common/BookAssetButton/BookAssetButton";
import {
  HomeClient,
  HomeQueryParams,
} from "../../../infrastructure/api/HomeClient";
import {
  SitesClient,
  SiteQueryParams,
} from "../../../infrastructure/api/SitesClient";
import _ from "lodash";
import Moment from "react-moment";
import moment from "moment";
import { NoticeModal } from "../WorkPlanSiteDropdown/NoticeModal";

export class DayCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      currentDate: new Date(new Date().toDateString()),
      myDay: null,
      showModal: false,
      unacknowledgedNotices: [],
    };
    this.goToAssetsHandler = this.goToAssetsHandler.bind(this);
  }

  goToAssetsHandler() {
    this.props.goToAssets();
  }

  componentDidMount() {
    const { day } = this.props;

    this.setState({ myDay: day });
  }

  async update() {
    await this.populateData();
  }

  componentWillReceiveProps(props) {}

  classNames = mergeStyleSets({
    cardShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)" },
        },
      },
    ],
    buttonShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)" },
        },
      },
    ],
  });

  render() {
    const {
      // day,
      selectedTeam,
      sites,
      user,
      notWorkingReasons,
      cardsLoading,
      selectMode,
      todayOnwardDates,
      onCheck,
      currentWeekStartDate,
      allDates,
      refreshPlanner,
      refreshTeamMembers,
    } = this.props;

    const { myDay: day } = this.state;
    var percentWorking =
      day?.pieChart?.workingOnSite > 0
        ? Math.round((day.pieChart.workingOnSite / day.pieChart.total) * 100)
        : 0;
    var today = new Date(new Date().toDateString());

    var whatsOnHolidayColor = localStorage.getItem("theme_WhatsOnHoliday");

    return (
      <>
        <NoticeModal
          notices={this.state.unacknowledgedNotices}
          showModal={this.state.showModal}
          onClose={() => {
            this.setState({ showModal: false });
          }}
          site={day?.users[0]?.myDay?.parts[0]?.site}
          siteId={day?.users[0]?.myDay?.parts[0]?.site?.id}
        />
        {/* {this.state.loading && (
          <Stack.Item
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              flex: 1,
              margin: "0px 20px 20px 0px",
              textAlign: "center",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              // opacity: this.state.loading ? 0.4 : 1,
              pointerEvents: this.state.loading ? "none" : "all",
            }}
            className={this.classNames.cardShadow}
          >
            <Shimmer style={{ padding: "5px 20px" }} />
            <Shimmer style={{ padding: "5px 20px" }} width="90%" />
            <Shimmer style={{ padding: "5px 20px" }} width="80%" />
            <Shimmer style={{ padding: "5px 20px" }} width="70%" />
            <Shimmer style={{ padding: "5px 20px" }} width="60%" />
          </Stack.Item>
        )} */}
        {this.state.myDay && (
          <Stack.Item
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              flex: 1,
              maxWidth: "190px",
              minWidth: "190px",
              margin: "0px 20px 20px 0px",
              textAlign: "center",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              opacity:
                this.state.loading || cardsLoading
                  ? 0.4
                  : new Date(day.date) < today.getTime()
                  ? 0.8
                  : 1,
              pointerEvents:
                this.state.loading ||
                cardsLoading ||
                new Date(day.date) < today.getTime()
                  ? "none"
                  : "all",
              // minHeight: "400px",
            }}
            className={this.classNames.cardShadow}
          >
            <div style={{ padding: "10px" }}>
              <div style={{ width: "100%" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex" }}>
                    {selectMode && (
                      <Checkbox
                        label=""
                        onChange={() => {
                          onCheck(day.date);
                        }}
                        checked={todayOnwardDates.includes(day.date)}
                      />
                    )}
                    <Moment utc local format="DD MMM">
                      {day.date}
                    </Moment>
                  </div>
                  <div>{percentWorking}%</div>
                </div>
              </div>
              <CardDoughnut
                key={new Date(day.date).getTime()}
                isSelected={
                  this.state.currentDate &&
                  new Date(day.date).getTime() ==
                    this.state.currentDate.getTime()
                }
                date={new Date(day.date)}
                pieData={day.pieChart}
                myDay={this.state.myDay}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <PlannerActionSelector
                dayData={day}
                currentDate={new Date(day.date)}
                currentUser={user}
                notWorkingReasons={notWorkingReasons}
                sites={sites}
                updatePie={(reloadData) => {
                  if (reloadData) {
                    this.setState({ loading: true }, () => {
                      this.populateData();
                      refreshTeamMembers();

                      var dateUpdated = moment(day.date).format("DD-MM-YYYY");
                      var todayFormatted = moment(today).format("DD-MM-YYYY");
                      if (dateUpdated === todayFormatted) refreshPlanner();
                    });
                  } else {
                    this.setState({ loading: true });
                  }
                }}
                showNoticeModal={(unacknowledgedNotices) => {
                  this.setState({
                    unacknowledgedNotices: unacknowledgedNotices,
                    showModal: true,
                  });
                }}
              />

              {day?.users && day?.users[0]?.checkIn && (
                <BookAssetButton
                  site={day?.users[0]?.myDay?.parts[0]?.site}
                  // date={day?.users[0]?.myDay.date}
                  date={day.date}
                  currentWeekStartDate={currentWeekStartDate}
                  allDates={allDates}
                  refreshPlanner={() => {
                    refreshPlanner();
                  }}
                  refreshDay={() => {
                    this.setState({ loading: true }, () => {
                      this.populateData();

                      refreshTeamMembers();
                    });
                  }}
                  label={day?.users[0]?.checkIn?.asset?.name}
                  checkIns={day?.users[0]?.userCheckIns}
                  softReload={() => {
                    this.populateData(true);
                  }}
                />
              )}

              {day?.users &&
                !day?.users[0]?.checkIn &&
                day?.users[0]?.myDay &&
                day?.users[0]?.myDay?.parts[0]?.state === "workFromOffice" && (
                  <BookAssetButton
                    site={day?.users[0]?.myDay?.parts[0]?.site}
                    // date={day?.users[0]?.myDay.date}
                    date={day.date}
                    currentWeekStartDate={currentWeekStartDate}
                    allDates={allDates}
                    refreshPlanner={() => {
                      refreshPlanner();
                    }}
                    refreshDay={() => {
                      this.setState({ loading: true }, () => {
                        this.populateData();
                        refreshTeamMembers();
                      });
                    }}
                    label="Book Asset"
                  />
                )}

              <WhatsOnButton
                selectedTeam={selectedTeam}
                date={day.date}
                userId={this.props.user?.id}
              />

              {day?.holiday && (
                <div
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    height: "20px",
                    backgroundColor: `${whatsOnHolidayColor}`,
                    marginTop: "auto",
                    marginBottom: "5px",
                  }}
                  className={this.classNames.buttonShadow}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "white",
                      marginTop: "auto",
                    }}
                  >
                    {day?.holiday?.description}
                  </p>
                </div>
              )}

              {day?.teamEvent && (
                <div
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                    height: "20px",
                    backgroundColor: `${day?.teamEvent?.color}`,
                    marginTop: "auto",
                    marginBottom: "5px",
                  }}
                  className={this.classNames.buttonShadow}
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "white",
                      marginTop: "auto",
                    }}
                  >
                    Collaboration Day
                  </p>
                </div>
              )}
            </div>
          </Stack.Item>
        )}
      </>
    );
  }

  renderDatePicker() {
    return (
      <Dialog
        type={DialogType.normal}
        title="Choose date"
        hidden={!this.state.isDatePickerOpen}
        onDismiss={() => this.setState({ isDatePickerOpen: false })}
      >
        <DatePicker
          firstDayOfWeek={DayOfWeek.Monday}
          id="currentDatePicker2"
          minDate={new Date()}
          value={this.state.currentDate}
          onSelectDate={(date) =>
            this.setState(
              {
                currentDate: date,
                currentWeekStartDate: this.getMonday(date),
                isDatePickerOpen: false,
              },
              this.populateData
            )
          }
          placeholder="Select a date..."
          ariaLabel="Select a date"
          // DatePicker uses English strings by default. For localized apps, you must override this prop.
          strings={defaultDatePickerStrings}
        />

        <DialogFooter>
          <DefaultButton
            onClick={(ev) => {
              this.setState({ isDatePickerOpen: false });
              ev.preventDefault();
            }}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
    );
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  async populateData(softLoad = false) {
    if (!softLoad) this.setState({ loading: true });
    const { user, selectedTeam } = this.props;

    if (!selectedTeam?.id) return;

    var siteId = user.site.id;
    var userSummary = await HomeClient.DayView(
      new HomeQueryParams()
        .WithSiteId(siteId)
        .WithTeamId(selectedTeam.id)
        .WithUserId(user.id)
        .WithNumberOfDays(1)
        .WithParam("startDate", this.state.myDay.date)
    );

    var day = userSummary.data[0];

    // var sites = await SitesClient.List(
    //   new SiteQueryParams().WithStatus("active")
    // );
    // var items = sites.data.data;

    this.setState({ loading: false, myDay: day });
  }
}
