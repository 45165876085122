import { ApiResponse, ListQueryParams } from "../ApiClient";
import {
  GetAPIDomain,
  GetAPIHeaders,
  GetApiHeadersFormData,
  GetAPIHeadersPatch,
  MyDayAPIHelper,
} from "../MyDayAPIHelper";

export class MessagesQueryParams extends ListQueryParams {
  WithIsMenu(value: any) {
    this.WithParam("isMenu", value);
    return this;
  }
}

export class MessagesClient {
  static async GetUserMessages(id: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    var query = apiDomain + `/messages/user/${id}/for-teams`;

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async SetMessageSeen(id: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    var query = apiDomain + `/messages/recipient/${id}/seen`;

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, { method: "post", headers: headers })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, { method: "post", headers: headers })
        );
      }
    }

    return response;
  }

  static async ClearSeenMessages(id: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    var query = apiDomain + `/messages/user/${id}/clear-seen`;

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, { method: "post", headers: headers })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, { method: "post", headers: headers })
        );
      }
    }

    return response;
  }

  static async Update(id: string, activityStatus: any, seen: any) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeadersPatch();

    return await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + "/messages/user/" + id, {
        method: "patch",
        headers: headers,
        body: JSON.stringify([
          {
            op: "replace",
            path: "activityStatus",
            value: activityStatus,
          },
          {
            op: "replace",
            path: "seen",
            value: seen,
          },
        ]),
      })
    );
  }
}
