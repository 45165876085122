import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Shimmer,
  PanelType,
  Panel,
  Image,
  ImageFit,
  Toggle,
  TextField,
  SearchBox,
  TooltipHost,
  DefaultButton,
} from "@fluentui/react";
import {
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import _ from "lodash";
import { ReactComponent as TeamsLogo } from "../../../../assets/svg/users-alt-solid.svg";
import { ReactComponent as RemoveLogo } from "../../../../assets/svg/cross-circle.svg";
import { ReactComponent as AddLogo } from "../../../../assets/svg/add.svg";
import { TeamsClient } from "../../../../infrastructure/api/TeamsClient";
import {
  UserQueryParams,
  UsersClient,
} from "../../../../infrastructure/api/UsersClient";

export class TeamItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentDate: new Date(new Date().toDateString()),
      currentUser: null,
      team: {},
      teamCount: 0,
      showSidePanel: false,
      selectedData: null,
      addMode: false,
      searchUsers: [],
      searchValue: "",
      weekdates: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      expectedOnSiteDays: [],
    };

    this.inputElementRef = React.createRef();
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
  }

  componentWillReceiveProps(props) {}

  classNames = mergeStyleSets({
    container: {
      width: "100%",
      overflow: "hidden",
    },
    cardShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)" },
        },
      },
    ],
    buttonShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        borderRadius: "15px",
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)" },
        },
      },
    ],
    centerItem: {
      display: "flex",
      justifyContent: "center",
    },
    itemImage: {
      alignSelf: "center",
      flexShrink: 0,
      borderRadius: "50%",
    },
    itemContent: {
      marginLeft: 10,
      overflow: "hidden",
      flexGrow: 1,
    },
    itemName: [
      getTheme().fonts.xLarge,
      {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    ],
    itemIndex: {
      fontSize: getTheme().fonts.small.fontSize,
      color: getTheme().palette.neutralTertiary,
      color: "#cac6c6",
      fontWeight: "bold",
    },
    itemCell: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: "border-box",
        display: "flex",
        margin: "5px",
        maxWidth: 450,
      },
    ],
    removeTeam: {
      margin: "auto",
      cursor: "pointer",
    },
    italicLabel: {
      color: "#cac6c6",
      fontSize: "13px",
    },
    textCenterEllipsis: {
      color: "white",
      fontSize: "12px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: "110px",
      textAlign: "center",
    },
  });

  renderPanel() {
    const { team, weekdates } = this.state;

    const { reloadTeams, currentUser } = this.props;
    var onSiteColor = localStorage.getItem("theme_OnSite");

    const { teamMembers } = team;
    return (
      <Panel
        isLightDismiss
        onOuterClick={() => {}}
        isOpen={this.state.showSidePanel}
        onDismiss={() => {
          this.setState({
            showSidePanel: false,
            addMode: false,
            expectedOnSiteDays: [],
          });
          if (reloadTeams) reloadTeams(true);
        }}
        onOpen={async () => {
          var expectedOnSiteDaysReq = await TeamsClient.GetExpectedSiteDays(
            team?.id
          );
          var expectedOnSiteDays =
            expectedOnSiteDaysReq.data.expectedOnSiteDays;

          this.setState({ expectedOnSiteDays });
        }}
        closeButtonAriaLabel="Close"
        headerText={
          <div>
            <span style={{ fontSize: "18px" }}>{team?.name}</span>
          </div>
        }
        type={PanelType.smallFixedFar}
        styles={{
          content: {
            background: "white",
          },
          contentInner: {
            background: "white",
          },
          header: {
            background: "white",
          },
          navigation: {
            background: "white",
          },
          commands: {
            background: "white",
          },
        }}
      >
        <div style={{ marginTop: "20px" }}></div>

        <div style={{ color: "black" }}>Collaboration Days</div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            margin: "10px 0",
          }}
        >
          {weekdates.map((day) => {
            var loweredCaseDay = day.toLowerCase();
            var isSelected =
              this.state.expectedOnSiteDays.includes(loweredCaseDay);

            return (
              <div
                style={{
                  width: "30px",
                  height: "30px",
                  border: `3px solid ${isSelected ? onSiteColor : "#b2b2b2"}`,
                  borderRadius: "50%",
                  textAlign: "center",
                  margin: "0px 1px",
                  fontSize: "12px",
                  background: isSelected ? onSiteColor : "#b2b2b2",
                  color: isSelected ? "white" : "black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={async () => {
                  if (isSelected) {
                    //remove
                    var filtered = this.state.expectedOnSiteDays.filter(
                      (x) => x !== loweredCaseDay
                    );
                    this.setState({
                      expectedOnSiteDays: filtered,
                    });
                    await TeamsClient.SetExpectedSiteDays(team.id, filtered);
                  } else {
                    //add
                    this.setState({
                      expectedOnSiteDays: [
                        ...this.state.expectedOnSiteDays,
                        loweredCaseDay,
                      ],
                    });
                    await TeamsClient.SetExpectedSiteDays(team.id, [
                      ...this.state.expectedOnSiteDays,
                      loweredCaseDay,
                    ]);
                  }
                }}
              >
                {day.charAt(0)}
              </div>
            );
          })}
        </div>
        <div style={{ marginTop: "20px" }}></div>
        <Toggle
          label="Add User"
          inlineLabel
          defaultChecked={false}
          onChange={(e, checked) => {
            this.setState({ addMode: checked }, () => {
              if (!checked) {
                this.setState({ searchValue: "", searchUsers: [] });
              }
            });
          }}
        />
        {this.state.addMode && (
          <>
            <i className={this.classNames.italicLabel}>
              Users will receive an email confirming team access
            </i>
            <div style={{ marginTop: "10px" }}></div>
            <SearchBox
              placeholder="Search by username or email..."
              onSearch={async (newValue) => {
                this.setState({ searchValue: newValue }, async () => {
                  await this.searchAddUsers();
                });
              }}
              onClear={() => {
                this.setState({ searchUsers: [], searchValue: "" });
              }}
            />
            {this.state.searchUsers.length > 0 &&
              this.state.searchUsers.map((user) => (
                <div
                  style={{ cursor: "default", marginBottom: "10px" }}
                  className={this.classNames.buttonShadow}
                >
                  <div
                    className={this.classNames.itemCell + " hover"}
                    style={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                      flex: "50%",
                      cursor: "default",
                    }}
                    onClick={() => {}}
                  >
                    <Image
                      className={this.classNames.itemImage}
                      src={user?.profilePhoto ?? "/assets/images/blank.png"}
                      alt=""
                      width={40}
                      height={40}
                      imageFit={ImageFit.cover}
                    />

                    <div className={this.classNames.itemContent}>
                      <div
                        className={this.classNames.itemName}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ fontSize: "15px" }}>
                          {user.name} {user.surname}
                        </div>
                      </div>
                      <div
                        className={this.classNames.itemIndex}
                        style={{ display: "flex" }}
                      >
                        {user.email}
                      </div>
                    </div>
                    <div className={this.classNames.removeTeam}>
                      <AddLogo
                        height="20"
                        width="20"
                        style={{ overflow: "visible", padding: "10px 0" }}
                        stroke={"#00838f"}
                        fill={"#00838f"}
                        onClick={async () => {
                          await TeamsClient.AddUsers(team.id, [user.id]);
                          await this.searchAddUsers();
                          this.populateData();
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </>
        )}
        {!this.state.addMode && (
          <>
            {teamMembers &&
              teamMembers &&
              teamMembers.map((teamMember) => (
                <div
                  style={{ cursor: "default", marginBottom: "10px" }}
                  className={this.classNames.buttonShadow}
                >
                  <div
                    className={this.classNames.itemCell + " hover"}
                    style={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                      flex: "50%",
                      cursor: "default",
                    }}
                    onClick={() => {}}
                  >
                    <Image
                      className={this.classNames.itemImage}
                      src={
                        teamMember?.profilePhoto ?? "/assets/images/blank.png"
                      }
                      alt=""
                      width={40}
                      height={40}
                      imageFit={ImageFit.cover}
                    />

                    <div className={this.classNames.itemContent}>
                      <div
                        className={this.classNames.itemName}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ fontSize: "15px" }}>
                          {teamMember.name} {teamMember.surname}
                        </div>
                      </div>
                      <div
                        className={this.classNames.itemIndex}
                        style={{ display: "flex" }}
                      >
                        {teamMember.email}
                      </div>
                    </div>
                    <div className={this.classNames.removeTeam}>
                      <RemoveLogo
                        height="20"
                        width="20"
                        style={{ overflow: "visible", padding: "10px 0" }}
                        stroke={"#EF6D6C"}
                        fill={"#EF6D6C"}
                        onClick={async () => {
                          await TeamsClient.RemoveUsers(team.id, [
                            teamMember.id,
                          ]);
                          this.populateData();
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </>
        )}

        {!this.state.addMode &&
          team?.createdById === currentUser?.id &&
          teamMembers &&
          (teamMembers?.length === 0 ||
            (teamMembers?.length === 1 &&
              teamMembers[0]?.id === currentUser?.id)) && (
            <div
              style={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <DefaultButton
                text="Delete Team"
                styles={{ root: { backgroundColor: "#EF6D6C", color: "#fff" } }}
                onClick={async () => {
                  await TeamsClient.Delete(team.id);
                  this.setState({
                    showSidePanel: false,
                    addMode: false,
                  });
                  if (reloadTeams) reloadTeams(true);
                }}
              />
            </div>
          )}
      </Panel>
    );
  }

  render() {
    const { teamMember, currentUser, reloadTeams } = this.props;

    const { team, teamMembers, isTeamLeader } = teamMember;

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TooltipHost content="Click to set as default">
          <div
            style={{
              borderRadius: "10px",
              height: "120px",
              width: "120px",
              background: team?.color ? team?.color : "#fcc250",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              cursor: "pointer",
            }}
            onClick={async () => {
              await UsersClient.SetTeam(
                currentUser.id,
                team.id,
                true,
                isTeamLeader
              );

              if (reloadTeams) reloadTeams();
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
              }}
            >
              <b
                style={{
                  color: "white",
                  fontSize: "large",
                  marginLeft: "15px",
                  position: "absolute",
                  top: "-5px",
                  fontSize: "13px",
                }}
              >
                {teamMembers.length}
              </b>
            </div>
            <TeamsLogo
              height="60"
              width="60"
              style={{ overflow: "visible", padding: "10px 0" }}
              stroke={"white"}
              fill={"white"}
            />
            <b className={this.classNames.textCenterEllipsis}>{team?.name}</b>
          </div>
        </TooltipHost>{" "}
        {isTeamLeader ? (
          <div
            style={{
              fontSize: "10px",
              color: "white",
              borderRadius: "5px",
              background: "white",
              padding: "0 3px",
              color: "black",
              cursor: "pointer",
              width: "50px",
              marginTop: "10px",
              textAlign: "center",
              alignSelf: "center",
            }}
            onClick={() => {
              this.setState({
                showSidePanel: true,
                selectedData: teamMember,
              });
            }}
          >
            Manage
          </div>
        ) : (
          <div></div>
        )}
        {this.renderPanel()}
      </div>
    );
  }

  async searchAddUsers() {
    const { team } = this.state;
    var response = await UsersClient.LookUp(
      new UserQueryParams()
        .Search(this.state.searchValue)
        .Sort("name", "asc")
        .Paginate(0, 999)
        .WithExcludeFromTeamId(team.id)
        .WithGetPhoto(true)
        .WithSearchableOnly(true)
    );

    this.setState({ searchUsers: response.data });
  }

  async populateData() {
    const { teamMember, reloadUser } = this.props;

    const { team } = teamMember;
    var response = await TeamsClient.Get(team.id);

    if (response.data) this.setState({ team: response.data });

    if (reloadUser) reloadUser();
  }
}
