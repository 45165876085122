import React, { Component } from "react";
import { Row } from "reactstrap";
import { Route } from "react-router";
import { Redirect, Link } from "react-router-dom";
import { AuthContext } from "../../infrastructure/AuthContext";
import { getTheme } from "@fluentui/react";

export class Layout extends Component {
  static displayName = Layout.name;

  componentDidMount() {}

  render() {
    return (
      <AuthContext.Consumer>
        {({ isAuthenticated, tenantId }) => {
          if (isAuthenticated && tenantId) {
            return (
              <div className="ms-Grid" dir="ltr" style={{ scroll: "overflow" }}>
                <div className="ms-Grid-row" style={{ marginRight: 0 }}>
                  <div className="ms-Grid-col">
                    <div>{this.props.children}</div>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div style={{ paddingTop: "10%" }}>
                <div
                  className="auth-wrapper"
                  style={{
                    boxShadow: getTheme().effects.elevation8,
                    maxWidth: "600px",
                    margin: "auto",
                    width: "100%",
                  }}
                >
                  {this.props.children}
                </div>
              </div>
            );
          }
        }}
      </AuthContext.Consumer>
    );
  }
}
