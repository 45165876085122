import { ApiResponse, ListQueryParams } from "../ApiClient";
import {
  GetAPIDomain,
  GetAPIHeaders,
  GetAPIHeadersPatch,
  MyDayAPIHelper,
} from "../MyDayAPIHelper";

export class PermissionsQueryParams extends ListQueryParams {}

export class PermissionsClient {
  static async ForCurrentUser(id: string, userId: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();

    return await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + `/permissions/for/current-user`, {
        headers: headers,
      })
    );
  }
}
