import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Panel,
  PanelType,
  Image,
  DefaultButton,
  TooltipHost,
  DirectionalHint,
  TooltipDelay,
  Dialog,
  DialogContent,
  PrimaryButton,
  DialogType,
  DialogFooter,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import { Icon, FontIcon } from "@fluentui/react/lib/Icon";
import { WorkDaysClient } from "../../../infrastructure/api/WorkDaysClient";
import { CheckInsClient } from "../../../infrastructure/api/CheckInsClient";
import { ReactComponent as CheckInLogo } from "../../../assets/svg/grid-2.svg";
import { ReactComponent as CheckInLogoOutline } from "../../../assets/svg/grid.svg";
import { ReactComponent as FlagLogo } from "../../../assets/svg/flag-alt.svg";
import { EventsTable } from "../../Charts/EventsTable";
import moment from "moment-timezone";
import { AssetTypeSelector } from "../../Selectors/AssetTypeSelector";
import { FloorSelector } from "../../Selectors/FloorSelector";
import { DurationSelector } from "../../Selectors/DurationSelector";
import { HourSelector } from "../../Selectors/HourSelector";
import { getMonday } from "../../../infrastructure/helpers/datesHelper";
import _ from "lodash";
import Moment from "react-moment";
import "./BookAssetItem.css";
import { getDayLocalizationLabel } from "../../../helpers/localizationHelper";
import { BookingFooterPanel } from "../BookingFooterPanel/BookingFooterPanel";

export class BookAssetItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showBookingDetails: false,
      duration: null,
      days: ["S", "M", "T", "W", "T", "F", "S"],
      selectedDays: [],
      errorMessage: "",
      currentDate: new Date(new Date().toDateString()),
      isBlockedDate: false,
      initialized: false,
      releaseLoadingIndex: null,
      defaultRealeaseId: null,
      selectedRealeaseId: null,
      openCheckinId: null,
    };
  }

  componentDidMount() {
    const { date, index, item, releaseReasons } = this.props;
    if (this.state.selectedDays.length <= 0) {
      this.setState({
        selectedDays: [date],
      });
    }

    if (releaseReasons && releaseReasons.length > 0) {
      const defaultReason = releaseReasons.find(
        (reason) => reason.default == true
      );
      if (defaultReason)
        this.setState({
          defaultRealeaseId: defaultReason.id,
          selectedRealeaseId: defaultReason.id,
        });
    }
  }

  componentDidUpdate() {
    const { indexOpened, index, item, date } = this.props;
    if (
      indexOpened !== null &&
      indexOpened !== index &&
      this.state.showBookingDetails === true
    ) {
      this.setState({
        showBookingDetails: false,
      });
    }

    if (item.blockStartDate && item.blockEndDate && !this.state.initialized) {
      var compareDate = moment(date).format("YYYY-MM-DD");
      var startDate = moment(item.blockStartDate).format("YYYY-MM-DD");
      var endDate = moment(item.blockEndDate).format("YYYY-MM-DD");

      var isBetween =
        moment(compareDate).isBetween(startDate, endDate, "day") ||
        moment(compareDate).isSame(startDate, "day") ||
        moment(compareDate).isSame(endDate, "day");
      if (isBetween)
        this.setState({
          isBlockedDate: true,
          initialized: true,
        });
    }
  }

  classNames = mergeStyleSets({
    itemCell: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: "border-box",
        borderBottom: `1px solid ${getTheme().semanticColors.bodyDivider}`,
        transition: "0.3s",
        margin: "10px 0px",
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        selectors: {
          "&:hover": {
            background: getTheme().palette.neutralLight,
            boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)",
          },
        },
      },
    ],

    dialogAssetContainer: {
      minHeight: 54,
      padding: 10,
      boxSizing: "border-box",
      borderBottom: `1px solid ${getTheme().semanticColors.bodyDivider}`,
      transition: "0.3s",
      margin: "10px 0px",
    },
    itemImage: {
      width: 40,
      height: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    itemContent: {
      marginLeft: 10,
      overflow: "hidden",
      flexGrow: 1,
    },
    itemName: [
      getTheme().fonts.xLarge,
      {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    ],
    itemIndex: {
      fontSize: getTheme().fonts.small.fontSize,
      color: getTheme().palette.neutralTertiary,
      textAlign: "left",
    },
    bookingDetails: {
      transition: "height 200ms",
    },
  });

  render() {
    const {
      item,
      weekDates,
      onDismiss,
      date,
      onDismissRefresh,
      index,
      onOpenAsset,
      indexOpened,
      singleDateMode,
      highLightTeam,
      fromMobileView,
      releaseReasons,
    } = this.props;

    var onSiteColor = localStorage.getItem("theme_OnSite");
    var teamHighlightColor = localStorage.getItem("theme_HighlightTeam");
    var reservedColor = localStorage.getItem("theme_Reserved");

    const currentUserId = localStorage.getItem("userId");

    const filteredAllocations =
      item.allocations && item.allocations.length > 0
        ? item.allocations.filter((i) => i.description !== "Reserved")
        : [];

    const reservedAllocation = item.allocations.filter(
      (i) => i.description === "Reserved"
    )[0];

    var dateUpdated = moment(date).format("DD-MM-YYYY");
    var todayFormatted = moment().format("DD-MM-YYYY");

    const tryOpenAsset = (item) => {
      if (
        item.reservedUserId &&
        item.reservedUserId !== currentUserId &&
        reservedAllocation
      )
        return;

      if (this.state.isBlockedDate) return;

      if (item.assetType && !item.assetType.isBookable) return;

      this.setState({
        showBookingDetails: !this.state.showBookingDetails,
      });
      onOpenAsset(index);
    };

    if (fromMobileView)
      return (
        <>
          <div
            className={this.classNames.itemCell + " hover"}
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              border:
                item.checkIns.length > 0
                  ? `2px solid ${
                      highLightTeam ? teamHighlightColor : onSiteColor
                    }`
                  : "",
            }}
          >
            <div
              style={{ display: "flex" }}
              onClick={() => {
                if (
                  item.reservedUserId &&
                  item.reservedUserId !== currentUserId &&
                  reservedAllocation
                )
                  return;

                if (this.state.isBlockedDate) return;

                if (item.assetType && !item.assetType.isBookable) return;

                this.setState({
                  showBookingDetails: !this.state.showBookingDetails,
                });
                onOpenAsset(index);
              }}
            >
              <div className={this.classNames.itemImage}>
                {item.checkIns.length > 0 ? (
                  <CheckInLogo
                    fill={highLightTeam ? teamHighlightColor : onSiteColor}
                    stroke={highLightTeam ? teamHighlightColor : onSiteColor}
                    height="20"
                    width="20"
                  />
                ) : item.reservedUserId ? (
                  <CheckInLogo
                    fill="#BFBFBF"
                    stroke="#BFBFBF"
                    height="20"
                    width="20"
                  />
                ) : (
                  <CheckInLogoOutline
                    fill="#BFBFBF"
                    stroke="#BFBFBF"
                    height="20"
                    width="20"
                  />
                )}
              </div>
              <div className={this.classNames.itemContent}>
                <div
                  className={this.classNames.itemName}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ fontSize: "14px", display: "flex" }}>
                    {item.name.length > 20 ? (
                      <TooltipHost content={item.name}>
                        <span>{item.name.slice(0, 20)}...</span>
                      </TooltipHost>
                    ) : (
                      <span>{item.name}</span>
                    )}
                  </div>{" "}
                  {this.state.isBlockedDate && (
                    <div
                      style={{
                        fontSize: "10px",
                        color: "white",
                        borderRadius: "5px",
                        background: "#EF6D6C",
                        padding: "3px",
                      }}
                    >
                      Blocked
                    </div>
                  )}
                  {!this.state.isBlockedDate && (
                    <>
                      {" "}
                      {item.checkIns.length === 0 &&
                        item.reservedUserId &&
                        reservedAllocation && (
                          <div
                            style={{
                              fontSize: "10px",
                              color: "white",
                              borderRadius: "5px",
                              background: reservedColor,
                              padding: "3px",
                            }}
                          >
                            Reserved
                          </div>
                        )}
                      {item.checkIns.length > 0 && (
                        <div
                          style={{
                            fontSize: "10px",
                            color: "white",
                            borderRadius: "5px",
                            background: `${onSiteColor}`,
                            padding: "3px",
                          }}
                        >
                          Booked
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className={this.classNames.itemIndex}>
                  {item.checkIns.length === 0 &&
                    item.reservedUserId &&
                    reservedAllocation && (
                      <div
                        style={{
                          fontSize: "13px",
                          marginTop: "3px",
                        }}
                      >
                        Reserved {item.reservedUser?.name}{" "}
                        {item.reservedUser?.surname}
                      </div>
                    )}

                  {item.checkIns.length > 0 &&
                    item.checkIns.map((checkin) => (
                      <div
                        style={{
                          fontSize: "13px",
                          marginTop: "3px",
                        }}
                      >
                        {checkin.user?.name} {checkin.user?.surname}{" "}
                        <Moment utc local format="h:mm A">
                          {moment(checkin.checkInDate)}
                        </Moment>{" "}
                        -{" "}
                        <Moment utc local format="h:mm A">
                          {moment(checkin.checkOutDue)}
                        </Moment>
                        {/* {checkin.userId === currentUserId && (
                          <span
                            style={{
                              marginLeft: "5px",
                              color: "red",
                              cursor: "pointer",
                            }}
                            onClick={async () => {
                              await CheckInsClient.ReleaseCheckIn(checkin.id);
                              onDismissRefresh(true);
                            }}
                          >
                            X
                          </span>
                        )} */}
                      </div>
                    ))}
                </div>

                {filteredAllocations && filteredAllocations.length > 0 && (
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    {filteredAllocations.map((allocation) => (
                      <div
                        style={{
                          fontSize: "10px",
                          color: "white",
                          borderRadius: "5px",
                          background: allocation.color,
                          padding: "3px",
                          marginLeft: "3px",
                        }}
                      >
                        {allocation.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {this.bookingFooterPanel()}
          </div>
        </>
      );
    else
      return (
        <>
          <div
            className={this.classNames.itemCell + " hover"}
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              border:
                item.checkIns.length > 0
                  ? `2px solid ${
                      highLightTeam ? teamHighlightColor : onSiteColor
                    }`
                  : "",
            }}
          >
            <div
              style={{ display: "flex", flexWrap: "wrap" }}
              onClick={() => {
                tryOpenAsset(item);
              }}
            >
              <div className={this.classNames.itemImage}>
                {item.checkIns.length > 0 ? (
                  <CheckInLogo
                    fill={highLightTeam ? teamHighlightColor : onSiteColor}
                    stroke={highLightTeam ? teamHighlightColor : onSiteColor}
                    height="20"
                    width="20"
                  />
                ) : item.reservedUserId ? (
                  <CheckInLogo
                    fill="#BFBFBF"
                    stroke="#BFBFBF"
                    height="20"
                    width="20"
                  />
                ) : (
                  <CheckInLogoOutline
                    fill="#BFBFBF"
                    stroke="#BFBFBF"
                    height="20"
                    width="20"
                  />
                )}
              </div>
              <div className={this.classNames.itemContent}>
                <div
                  className={this.classNames.itemName}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ fontSize: "14px" }}>
                    {item.name.length > 20 ? (
                      <TooltipHost content={item.name}>
                        <span>{item.name.slice(0, 20)}...</span>
                      </TooltipHost>
                    ) : (
                      <span>{item.name}</span>
                    )}

                    {filteredAllocations.length > 0 && (
                      <TooltipHost
                        content={
                          <>
                            {filteredAllocations &&
                              filteredAllocations.map((allocation) => (
                                <div
                                  style={{
                                    fontSize: "10px",
                                    color: "white",
                                    borderRadius: "5px",
                                    background: allocation.color,
                                    padding: "3px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  {allocation.name}
                                </div>
                              ))}
                          </>
                        }
                      >
                        <FlagLogo
                          fill="#FFF2C2"
                          stroke="#FFF2C2"
                          height="12"
                          width="12"
                        />
                      </TooltipHost>
                    )}
                  </div>{" "}
                  {this.state.isBlockedDate && (
                    <div
                      style={{
                        fontSize: "10px",
                        color: "white",
                        borderRadius: "5px",
                        background: "#EF6D6C",
                        padding: "3px",
                      }}
                    >
                      Blocked
                    </div>
                  )}
                  {!this.state.isBlockedDate && (
                    <>
                      {" "}
                      {item.checkIns.length === 0 &&
                        item.reservedUserId &&
                        reservedAllocation && (
                          <div
                            style={{
                              fontSize: "10px",
                              color: "white",
                              borderRadius: "5px",
                              background: reservedColor,
                              padding: "3px",
                            }}
                          >
                            Reserved
                          </div>
                        )}
                      {item.checkIns.length > 0 && (
                        <div
                          style={{
                            fontSize: "10px",
                            color: "white",
                            borderRadius: "5px",
                            background: `${onSiteColor}`,
                            padding: "3px",
                          }}
                        >
                          Booked
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className={this.classNames.itemIndex}>
                  {item.checkIns.length === 0 &&
                    item.reservedUserId &&
                    reservedAllocation && (
                      <div
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Reserved {item.reservedUser?.name}{" "}
                        {item.reservedUser?.surname}
                      </div>
                    )}

                  {item.checkIns.length > 0 &&
                    item.checkIns.map((checkin) => (
                      <div
                        style={{
                          fontSize: "12px",
                          display: "flex",
                        }}
                      >
                        <span style={{ display: "flex", gap: "3px" }}>
                          {" "}
                          {checkin.user?.name} {checkin.user?.surname}{" "}
                          <Moment utc local format="h:mm A">
                            {moment(checkin.checkInDate)}
                          </Moment>{" "}
                          -{" "}
                          <Moment utc local format="h:mm A">
                            {moment(checkin.checkOutDue)}
                          </Moment>
                        </span>

                        {checkin.userId === currentUserId && (
                          <span
                            style={{
                              marginLeft: "5px",
                              color: "red",
                              cursor: "pointer",
                            }}
                            onClick={async (e) => {
                              e.preventDefault();

                              const isToday = moment(date).isSame(
                                moment(),
                                "day"
                              );

                              if (isToday) {
                                if (this.state.showReleaseIndex === index)
                                  this.setState({
                                    showReleaseIndex: null,
                                    openCheckinId: null,
                                  });
                                else
                                  this.setState({
                                    showReleaseIndex: index,
                                    openCheckinId: checkin.id,
                                  });
                              } else {
                                await CheckInsClient.ReleaseCheckIn(
                                  checkin.id,
                                  null,
                                  true
                                );
                                onDismissRefresh(true);
                              }
                            }}
                          >
                            X
                          </span>
                        )}
                      </div>
                    ))}
                </div>
              </div>
              {this.state.showReleaseIndex === index && (
                <div style={{ flex: "1 1 100%", marginTop: "3px" }}>
                  {releaseReasons.map((reason, reasonIndex) => {
                    const releaseCheckIn = async () => {
                      this.setState({
                        releaseLoadingIndex: reasonIndex,
                        selectedRealeaseId: reason.id,
                      });
                      await CheckInsClient.ReleaseCheckIn(
                        this.state.openCheckinId,
                        reason.id
                      );
                      await new Promise((resolve) => setTimeout(resolve, 1000));
                      this.setState({
                        releaseLoadingIndex: null,
                        showReleaseIndex: null,
                        selectedRealeaseId: this.state.defaultRealeaseId,
                        showBookingDetails: false,
                      });
                      onDismissRefresh(true);
                    };
                    return (
                      <div style={{ width: "100%", marginBottom: "5px" }}>
                        <div
                          style={{
                            borderRadius: "5px",
                            cursor: "pointer",
                            padding: "3px 5px",
                            fontSize: "12px",
                            height: "20px",
                            textAlign: "center",
                            display:
                              this.state.releaseLoadingIndex === reasonIndex
                                ? "flex"
                                : "block",
                            justifyContent: "center",
                            color:
                              reason.id === this.state.selectedRealeaseId
                                ? "white"
                                : "gray",
                            backgroundColor:
                              reason.id === this.state.selectedRealeaseId
                                ? "#3E97FF"
                                : "#F1F1F2",
                          }}
                          className="releaseBtn"
                          onClick={async () => {
                            releaseCheckIn();
                          }}
                        >
                          {this.state.releaseLoadingIndex === reasonIndex && (
                            <Spinner size={SpinnerSize.small} />
                          )}
                          {reason.name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            {this.state.showBookingDetails && (
              <div className={this.classNames.bookingDetails}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <p style={{ fontSize: "10px" }}>Book from:</p>
                  <HourSelector
                    onSelect={({ id, name }) => {
                      this.setState({ hour: id, hourValue: { id, name } });
                    }}
                    checkIns={item?.checkIns}
                    defaultValue={this.state.hourValue}
                    isToday={dateUpdated === todayFormatted ? true : false}
                  />
                  <DurationSelector
                    onSelect={({ id, name }) => {
                      this.setState({ duration: id });
                    }}
                  />{" "}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    margin: "10px 0",
                  }}
                >
                  {!singleDateMode &&
                    weekDates &&
                    weekDates.length > 0 &&
                    weekDates.map((day) => {
                      var selected =
                        new Date(day).getDay() ==
                        new Date(new Date().toDateString()).getDay();

                      var isBeforeDate = moment(day).isBefore(
                        moment(this.state.currentDate)
                      );

                      var isBlocked = false;

                      if (item.blockStartDate && item.blockEndDate) {
                        var compareDate = moment(day).format("YYYY-MM-DD");
                        var startDate = moment(item.blockStartDate).format(
                          "YYYY-MM-DD"
                        );
                        var endDate = moment(item.blockEndDate).format(
                          "YYYY-MM-DD"
                        );

                        var isBetween =
                          moment(compareDate).isBetween(
                            startDate,
                            endDate,
                            "day"
                          ) ||
                          moment(compareDate).isSame(startDate, "day") ||
                          moment(compareDate).isSame(endDate, "day");
                        if (isBetween) isBlocked = true;
                      }

                      var disabled = false;

                      if (isBeforeDate || isBlocked) disabled = true;
                      return (
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            border: `3px solid ${
                              disabled ? "rgb(144 144 144)" : onSiteColor
                            }`,
                            borderRadius: "50%",
                            textAlign: "center",
                            margin: "0px 1px",
                            fontSize: "12px",
                            background: disabled
                              ? "#b2b2b2"
                              : this.state.selectedDays.includes(day)
                              ? onSiteColor
                              : "",
                            color: this.state.selectedDays.includes(day)
                              ? "white"
                              : "black",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: disabled ? "default" : "cursor",
                          }}
                          onClick={() => {
                            if (disabled) {
                              return;
                            }
                            if (this.state.selectedDays.includes(day)) {
                              var updated = _.remove(
                                this.state.selectedDays,
                                function (n) {
                                  return n !== day;
                                }
                              );
                              this.setState({
                                selectedDays: updated,
                              });
                            } else {
                              this.setState({
                                selectedDays: _.union([
                                  ...this.state.selectedDays,
                                  day,
                                ]),
                              });
                            }
                          }}
                        >
                          {this.state.days[new Date(day).getDay()]}
                        </div>
                      );
                    })}
                </div>
                {this.state.errorMessage && (
                  <p
                    style={{ margin: "10px 0", color: "red", fontSize: "10px" }}
                  >
                    {this.state.errorMessage}
                  </p>
                )}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div></div>
                  {!this.state.isBlockedDate && (
                    <DefaultButton
                      text="Book"
                      onClick={async () => {
                        this.setState({ loading: true });
                        const { duration, hour } = this.state;
                        const { date, singleDateMode } = this.props;

                        if (singleDateMode) {
                          var hr = parseInt(hour.split(":")[0]);
                          var mins = hour.split(":")[1];

                          var tz = moment.tz.guess();
                          const start = moment.tz(date, tz); // original timezone

                          const local = start
                            .tz(tz)
                            .format("YYYY-MM-DD[T]HH:mm:ss.SSS");

                          var withTimeUtc = moment(local)
                            .utc()
                            .add(hr, "h")
                            .add(mins, "minutes")
                            .format("YYYY-MM-DD[T]HH:mm:ss.SSS");

                          var response = await WorkDaysClient.CheckIn(
                            item.id,
                            duration,
                            [withTimeUtc]
                          );

                          if (response.data.length > 0) {
                            var failed = _.filter(response.data, [
                              "success",
                              false,
                            ]);

                            if (failed.length > 0) {
                              this.setState({ errorMessage: failed[0].reason });
                            } else {
                              this.setState(
                                { errorMessage: "", showBookingDetails: false },
                                () => {
                                  onDismissRefresh();
                                }
                              );
                            }
                          }

                          this.setState({ loading: false });
                        } else {
                          const hr = hour ? hour : moment().format("HH:mm");

                          var formatDate = moment(date).format("YYYY-MM-DD");

                          var dates = this.state.selectedDays.map((day) => {
                            var hr = parseInt(hour.split(":")[0]);
                            var mins = hour.split(":")[1];

                            var tz = moment.tz.guess();
                            const start = moment.tz(day, tz); // original timezone

                            const local = start
                              .tz(tz)
                              .format("YYYY-MM-DD[T]HH:mm:ss.SSS");

                            var withTimeUtc = moment(local)
                              .utc()
                              .add(hr, "h")
                              .add(mins, "minutes")
                              .format("YYYY-MM-DD[T]HH:mm:ss.SSS");
                            return withTimeUtc;
                          });

                          var working8AM = "2023-01-23T00:00:00.000";

                          var uniqueDates = _.uniq(dates);

                          var response = await WorkDaysClient.CheckIn(
                            item.id,
                            duration,
                            uniqueDates
                          );

                          if (response.data.length > 0) {
                            var failed = _.filter(response.data, [
                              "success",
                              false,
                            ]);

                            if (failed.length > 0) {
                              this.setState({ errorMessage: failed[0].reason });
                            } else {
                              this.setState(
                                { errorMessage: "", showBookingDetails: false },
                                () => {
                                  var refreshDay =
                                    uniqueDates.length === 1 ? true : false;
                                  onDismissRefresh(refreshDay);
                                }
                              );
                            }
                          }

                          this.setState({ loading: false });
                        }
                      }}
                      allowDisabledFocus
                      style={{
                        borderRadius: "5px",
                        fontSize: "12px",
                        height: "25px",
                      }}
                      disabled={
                        !this.state.hour ||
                        !this.state.duration ||
                        !this.state.selectedDays ||
                        this.state.loading
                      }
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      );
  }

  bookingFooterPanel = () => {
    const {
      item,
      weekDates,
      onDismiss,
      date,
      onDismissRefresh,
      index,
      onOpenAsset,
      indexOpened,
      singleDateMode,
      highLightTeam,
      fromMobileView,
    } = this.props;

    return (
      <BookingFooterPanel
        item={item}
        date={date}
        onDismissRefresh={onDismissRefresh}
        onDismiss={() => {
          this.setState({
            showBookingDetails: false,
          });
        }}
        show={this.state.showBookingDetails}
        selectedDays={this.state.selectedDays}
        loading={this.state.loading}
      />
    );
  };
}
