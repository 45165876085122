import { ApiResponse, ListQueryParams } from "../ApiClient";
import { GetAPIDomain, GetAPIHeaders, MyDayAPIHelper } from "../MyDayAPIHelper";

export class HomeQueryParams extends ListQueryParams {
  WithTeamIds(value: any) {
    this.WithParam("teamIds", value);
    return this;
  }
  WithTeamId(value: any) {
    this.WithParam("teamId", value);
    return this;
  }
  WithDate(value: any) {
    this.WithParam("date", value);
    return this;
  }
  WithIncludeWeek(value: boolean) {
    this.WithParam("includeWeek", value);
    return this;
  }
  WithPieChartStatus(value: any) {
    this.WithParam("pieChartStatus", value);
    return this;
  }
  WithSiteId(value: any) {
    this.WithParam("siteId", value);
    return this;
  }
  WithUserId(value: any) {
    this.WithParam("userId", value);
    return this;
  }
  WithNumberOfDays(value: any) {
    this.WithParam("numberOfDays", value);
    return this;
  }
  WithSearch(value: any) {
    this.WithParam("search", value);
    return this;
  }
}

export class HomeClient {
  static async PieChart(params: HomeQueryParams) {
    var query =
      (await GetAPIDomain()) +
      "/work-days/pie-chart" +
      params.GenerateQueryString();
    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async PieChartUsers(params: HomeQueryParams) {
    var query =
      (await GetAPIDomain()) +
      "/work-days/pie-chart/users" +
      params.GenerateQueryString();
    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async DayView(params: HomeQueryParams) {
    var query =
      (await GetAPIDomain()) +
      "/work-days/day-view-v2" +
      params.GenerateQueryString();
    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async SearchUsersPlans(params: HomeQueryParams) {
    var query =
      (await GetAPIDomain()) +
      "/work-days/search-users-plan" +
      params.GenerateQueryString();
    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async TeamMemberView(params: HomeQueryParams) {
    var query =
      (await GetAPIDomain()) + "/work-days/team" + params.GenerateQueryString();
    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async SitePieChart(params: HomeQueryParams) {
    var query =
      (await GetAPIDomain()) +
      "/work-days/pie-chart/sites" +
      params.GenerateQueryString();
    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }
}
