import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import { Dropdown, Label } from "@fluentui/react";
import {
  SiteQueryParams,
  SitesClient,
} from "../../infrastructure/api/SitesClient";

export class SiteSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeStatus: "",
      loading: false,
      selectedSite: null,
      sites: [],
    };
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
  }

  classNames = mergeStyleSets({
    dropdown: { borderRadius: "5px" },
  });

  render() {
    const { onSelect, siteId, hideLabel } = this.props;
    return (
      <>
        {!hideLabel && <Label>Site</Label>}
        <Dropdown
          placeholder="Select a site"
          selectedKey={
            this.state.selectedSite
              ? this.state.selectedSite.id
              : siteId
              ? siteId
              : null
          }
          options={this.state.sites.map((item) => {
            return {
              key: item.id,
              text: item.name,
            };
          })}
          onChanged={(item) => {
            this.setState({
              selectedSite: {
                id: item.key,
                name: item.text,
              },
            });

            onSelect({
              id: item.key,
              name: item.text,
            });
          }}
          styles={{ title: { borderRadius: "5px" } }}
        />
      </>
    );
  }

  async populateData() {
    const { setOptions, siteId, onSelect } = this.props;
    var sites = await SitesClient.List(new SiteQueryParams().WithExpand(false));
    this.setState({
      sites: sites.data.data,
    });

    if (setOptions) {
      setOptions(sites.data.data);
    }

    if (siteId) {
      var site = sites.data.data.filter((site) => site.id === siteId)[0];

      if (site) {
        onSelect({
          id: site.id,
          name: site.name,
        });
      }
    } else if (!siteId && this.state.selectedSite === null) {
      var site = sites.data.data[0];

      if (site) {
        onSelect({
          id: site.id,
          name: site.name,
        });
      }
    }
  }
}
