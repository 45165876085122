import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import { Dropdown, Label } from "@fluentui/react";
import moment from "moment";
import _ from "lodash";

export class DurationSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedData: null,
      data: [],
      initialized: false,
    };
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate() {
    if (
      !_.isEmpty(this.props.defaultValue) &&
      this.state.selectedData?.id != this.props.defaultValue?.id
    )
      this.setState({ selectedData: this.props.defaultValue });
  }

  async update() {
    if (!this.state.initialized) await this.populateData();
  }

  classNames = mergeStyleSets({
    dropdown: { borderRadius: "10px" },
  });

  render() {
    const { onSelect, disabled } = this.props;
    return (
      <>
        <Dropdown
          disabled={disabled}
          placeholder="Select duration"
          selectedKey={
            this.state.selectedData ? this.state.selectedData.id : null
          }
          options={this.state.data.map((item) => {
            return {
              key: item.id,
              text: item.name,
            };
          })}
          onChanged={(item) => {
            this.setState({
              selectedData: {
                id: item.key,
                name: item.text,
              },
            });

            onSelect({
              id: item.key,
              name: item.text,
            });
          }}
          className={this.classNames.dropdown}
          styles={{
            dropdownItem: {
              fontSize: "12px",
            },
            label: {
              fontSize: "12px",
            },
            dropdown: {
              fontSize: "12px",
              minWidth: 80,
            },
            title: {
              borderRadius: "5px",
            },
            dropdownItemDisabled: {
              color: "#e0e0e0",
            },
          }}
        />
      </>
    );
  }

  async populateData() {
    const { onSelect } = this.props;
    const defaultData = { id: 480, name: "8 hours" };
    var data = [
      { id: 60, name: "1 hour" },
      { id: 240, name: "4 hours" },
      { id: 480, name: "8 hours" },
    ];

    if (!this.props.defaultValue?.id) {
      onSelect(defaultData);
      this.setState({
        data: data,
        selectedData: defaultData,
        initialized: true,
      });
    } else {
      this.setState({
        data: data,
        initialized: true,
      });
    }
  }
}
