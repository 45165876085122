import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import * as microsoftTeams from "@microsoft/teams-js";
import "react-tooltip/dist/react-tooltip.css";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

/** initialize throws if called more than once and hence is wrapped in a try-catch to perform a safe initialization. */

try {
  microsoftTeams.initialize(() => {
    microsoftTeams.getContext((c) => {
      if (c.entityId.indexOf("|") != -1) {
        localStorage.setItem("tenant-id", c.entityId.split("|")[1]);
      }

      if (c.theme == "dark") {
        localStorage.setItem("header-color", "black");
      } else {
        localStorage.setItem("header-color", " #464EB8");
      }
    });
  });
} catch (e) {
  console.log("Teams app not available");
}

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement
);

registerServiceWorker();
