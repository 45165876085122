import { ApiResponse, ListQueryParams } from "../ApiClient";
import {
  GetAPIDomain,
  GetAPIHeaders,
  GetAPIHeadersPatch,
  MyDayAPIHelper,
} from "../MyDayAPIHelper";

export class AccountSettingsQueryParams extends ListQueryParams {
  WithStatus(value: string) {
    this.WithParam("status", value);
    return this;
  }
  WithCurrentUser(value: boolean) {
    this.WithParam("currentUser", value);
    return this;
  }
}

export class AccountSettingsClient {
  static async GetCurrent() {
    var query = (await GetAPIDomain()) + "/account-settings/current";
    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }
}
