import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  HomeClient,
  HomeQueryParams,
} from "../../infrastructure/api/HomeClient";
import Moment from "react-moment";
import { TooltipHost, ITooltipHostStyles } from "@fluentui/react/lib/Tooltip";
import _ from "lodash";

export class CardDoughnut extends Component {
  constructor(props) {
    Chart.register(ArcElement, Tooltip, Legend);
    super(props);
    this.state = {
      data: [0, 0, 0, 0],
      loading: false,
      total: 0,
      search: "",
      sort: "companyName",
      direction: "asc",
      showSuspended: false,
      status: "",
      percentWorking: 0,
      labelColor: localStorage.getItem("theme_NoResponse"),
      myDay: {},
    };
  }

  componentDidMount() {
    const { pieData, myDay } = this.props;
    var percentWorking =
      pieData.workingOnSite > 0
        ? Math.round((pieData.workingOnSite / pieData.total) * 100)
        : 0;

    this.setState({ percentWorking: percentWorking });

    var onSiteColor = localStorage.getItem("theme_OnSite");
    var homeColor = localStorage.getItem("theme_Home");
    var noResponseColor = localStorage.getItem("theme_NoResponse");
    var notWorkingColor = localStorage.getItem("theme_NotWorking");
    if (myDay) {
      if (myDay?.users[0]?.myDay?.parts[0]?.state === "workFromHome") {
        this.setState({ labelColor: homeColor, myDay });
      } else if (myDay?.users[0]?.myDay?.parts[0]?.state === "workFromOffice") {
        this.setState({ labelColor: onSiteColor, myDay });
      } else if (myDay?.users[0]?.myDay?.parts[0]?.state === "notWorking") {
        this.setState({ labelColor: notWorkingColor, myDay: myDay });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { pieData, myDay } = this.props;
    if (myDay) {
      var onSiteColor = localStorage.getItem("theme_OnSite");
      var homeColor = localStorage.getItem("theme_Home");
      var noResponseColor = localStorage.getItem("theme_NoResponse");
      var notWorkingColor = localStorage.getItem("theme_NotWorking");
      if (
        this.props.myDay?.users[0]?.myDay?.parts[0]?.id !==
        prevProps.myDay?.users[0]?.myDay?.parts[0]?.id
      ) {
        if (myDay?.users[0]?.myDay?.parts[0]?.state === "workFromHome") {
          this.setState({ labelColor: homeColor, myDay });
        } else if (
          myDay?.users[0]?.myDay?.parts[0]?.state === "workFromOffice"
        ) {
          this.setState({ labelColor: onSiteColor, myDay });
        } else if (myDay?.users[0]?.myDay?.parts[0]?.state === "notWorking") {
          this.setState({ labelColor: notWorkingColor, myDay });
        }
      }
    }
  }

  render() {
    var onSiteLabel = localStorage.getItem("localization_OnSite");
    var homeLabel = localStorage.getItem("localization_Home");
    var noResponseLabel = localStorage.getItem("localization_NoResponse");
    var notWorkingLabel = localStorage.getItem("localization_NotWorking");
    var mondayLabel = localStorage.getItem("localization_Monday");
    var tuesdayLabel = localStorage.getItem("localization_Tuesday");
    var wednesdayLabel = localStorage.getItem("localization_Wednesday");
    var thursdayLabel = localStorage.getItem("localization_Thursday");
    var fridayLabel = localStorage.getItem("localization_Friday");
    var saturdayLabel = localStorage.getItem("localization_Saturday");
    var sundayLabel = localStorage.getItem("localization_Sunday");

    var days = [
      sundayLabel,
      mondayLabel,
      tuesdayLabel,
      wednesdayLabel,
      thursdayLabel,
      fridayLabel,
      saturdayLabel,
    ];

    const { teamEvent, pieData, onClick, date, isSelected } = this.props;

    var onSiteColor = localStorage.getItem("theme_OnSite");
    var homeColor = localStorage.getItem("theme_Home");
    var noResponseColor = localStorage.getItem("theme_NoResponse");
    var notWorkingColor = localStorage.getItem("theme_NotWorking");

    var data = {
      labels: [homeLabel, onSiteLabel, notWorkingLabel, noResponseLabel],
      datasets: [
        {
          data: [
            pieData.workingOnSite,
            pieData.workingFromHome,
            pieData.notWorking,
            pieData.noResponse,
          ],
          backgroundColor: [
            onSiteColor,
            homeColor,
            notWorkingColor,
            noResponseColor,
          ],
        },
      ],
    };

    var textColor = ["white", "white", "white", "black"];

    var max = Math.max(...data.datasets[0].data);
    var selectedColor =
      data.datasets[0].backgroundColor[data.datasets[0].data.indexOf(max)];
    var selectedTextColor = textColor[data.datasets[0].data.indexOf(max)];

    var options = {
      showTooltips: false,
      cutout: 40,
      events: [],
      animation: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltips: { enabled: false },
        hover: { mode: null }, // animation duration after a resize
      },
    };

    return (
      <div style={{ position: "absolut", width: "100%" }} onClick={onClick}>
        {/* <div style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Moment utc local format="DD MMM">
                {date}
              </Moment>
            </div>
            <div>{this.state.percentWorking}%</div>
          </div>
        </div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100px" }}>
            <Doughnut data={data} id="homePieChart" options={options} />
          </div>
          <div
            style={{
              // top: "13px",
              // left: "57px",
              width: "75px",
              height: "75px",
              textAlign: "center",
              borderRadius: "100px",
              backgroundColor: isSelected ? selectedColor : "transparent",
              color: isSelected ? selectedTextColor : "black",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TooltipHost
              // content="test"
              content={days[date.getDay()]}
              // This id is used on the tooltip itself, not the host
              // (so an element with this id only exists when the tooltip is shown)
              // id={tooltipId}
              // calloutProps={calloutProps}
              // styles={hostStyles}
            >
              <span
                style={
                  teamEvent
                    ? {
                        position: "absolute",
                        top: "25px",
                        left:
                          days[date.getDay()].charAt(0) === "M" ||
                          days[date.getDay()].charAt(0) === "W"
                            ? "25px"
                            : "30px",

                        fontWeight: "bolder",
                        fontSize: "27px",
                        color: teamEvent?.color,
                      }
                    : {
                        position: "absolute",
                        top: "25px",
                        left:
                          days[date.getDay()].charAt(0) === "M" ||
                          days[date.getDay()].charAt(0) === "W"
                            ? "25px"
                            : "30px",
                        fontWeight: "bolder",
                        fontSize: "27px",
                      }
                }
              >
                {days[date.getDay()].charAt(0)}
              </span>
            </TooltipHost>
          </div>
        </div>

        <div
          style={{
            marginTop: "10px",
            color: this.state.labelColor,
            fontWeight: 600,
          }}
        >
          {days[date.getDay()]}
        </div>
      </div>
    );
  }
}
