import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import {
  FocusZone,
  List,
  DefaultButton,
  Shimmer,
  PrimaryButton,
  Modal,
  Spinner,
  SpinnerSize,
  Label,
} from "@fluentui/react";
import Moment from "react-moment";
import { DurationSelector } from "../Selectors/DurationSelector";
import { WorkDaysClient } from "../../infrastructure/api/WorkDaysClient";
import moment from "moment";
import {
  CheckInsClient,
  CheckInsQueryParams,
} from "../../infrastructure/api/CheckInsClient";

export class ReleaseBookAssetModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonLoading: false,
      hour: null,
      duration: null,
    };
  }

  componentDidMount() {}

  attendUser() {
    const { userId } = this.props;
  }

  modalStyles = mergeStyleSets({
    content: { borderRadius: "10px" },
    root: {
      width: "500px",
      height: "200px",
    },
    itemCell: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: "border-box",
        borderBottom: `1px solid ${getTheme().semanticColors.bodyDivider}`,
        display: "flex",
        transition: "0.3s",
        margin: "10px 20px",
        selectors: {
          "&:hover": {
            background: getTheme().palette.neutralLight,
          },
        },
      },
    ],
    itemImage: {
      flexShrink: 0,
    },
    itemContent: {
      marginLeft: 10,
      overflow: "hidden",
      flexGrow: 1,
    },
    itemName: [
      getTheme().fonts.xLarge,
      {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    ],
    itemIndex: {
      fontSize: getTheme().fonts.small.fontSize,
      color: getTheme().palette.neutralTertiary,
      marginBottom: 10,
    },
  });

  render() {
    const { modalVisible, selectedData, onCancel, onOk, userId } = this.props;
    const { buttonLoading } = this.state;
    return (
      <Modal
        isOpen={modalVisible}
        onDismiss={() => {
          onCancel();
        }}
        isBlocking={false}
        styles={{ borderRadius: "10px" }}
        animated="fade-in"
      >
        <div className={this.modalStyles.root}>
          <div
            className={this.modalStyles.itemCell + " hover"}
            style={{
              backgroundColor: "white",
            }}
          >
            <div className={this.modalStyles.itemContent}>
              <div className={this.modalStyles.itemName}>
                {selectedData?.asset?.name}
              </div>
              <div className={this.modalStyles.itemIndex}>
                In use until{" "}
                <Moment format="HH:mm">{selectedData?.checkOutDue}</Moment>
              </div>
            </div>
          </div>
          <div
            className="container"
            style={{
              padding: "10px 20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <DefaultButton
              text="Extend"
              disabled={this.state.buttonLoading}
              onClick={async () => {}}
              style={{ width: "100%", marginTop: "20px", marginRight: "10px" }}
            />
            <PrimaryButton
              onClick={async () => {
                this.setState({ buttonLoading: true });
                await CheckInsClient.ReleaseCheckIn(selectedData?.id);
                this.setState({ buttonLoading: false });
                onOk();
              }}
              disabled={this.state.buttonLoading}
              style={{ width: "100%", marginTop: "20px", marginLeft: "10px" }}
            >
              Release
              {buttonLoading && (
                <Spinner
                  style={{ marginLeft: "5px" }}
                  size={SpinnerSize.medium}
                />
              )}
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    );
  }
}
