import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import {
  HomeClient,
  HomeQueryParams,
} from "../../infrastructure/api/HomeClient";
import { FocusZone, Spinner, List, Image, ImageFit } from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { ReactComponent as OnSiteLogo } from "../../assets/svg/building.svg";
import { ReactComponent as NotWorkingLogo } from "../../assets/svg/brightness.svg";
import { ReactComponent as HomeLogo } from "../../assets/svg/house-chimney.svg";
import { TooltipHost, ITooltipHostStyles } from "@fluentui/react/lib/Tooltip";

export class TeamMember extends Component {
  constructor(props) {
    Chart.register(ArcElement, Tooltip, Legend);
    super(props);
    this.state = {
      data: [],
      loading: true,
      total: 0,
      search: "",
      sort: "companyName",
      direction: "asc",
      showSuspended: false,
      status: "",
      currentDateData: {},
    };
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
  }

  getColorByState(state) {
    var onSiteColor = localStorage.getItem("theme_OnSite");
    var homeColor = localStorage.getItem("theme_Home");
    var noResponseColor = localStorage.getItem("theme_NoResponse");
    var notWorkingColor = localStorage.getItem("theme_NotWorking");

    switch (state) {
      case "notWorking":
        return notWorkingColor;
      case "workFromHome":
        return homeColor;
      case "workFromOffice":
        return onSiteColor;

      default:
        return noResponseColor;
    }
  }

  getMyDayForDate(data) {
    const { teamDayViewDates } = data;
    var currentDate = new Date(new Date().toDateString());
    var myDay = {
      date: currentDate,
    };

    if (teamDayViewDates && teamDayViewDates.length > 0) {
      for (var i = 0; i < teamDayViewDates.length; i++) {
        if (
          new Date(teamDayViewDates[i].date).getTime() == currentDate.getTime()
        ) {
          myDay = teamDayViewDates[i];
        }
      }
    }

    return myDay;
  }

  classNames = mergeStyleSets({
    container: {
      overflow: "auto",
      maxHeight: 1000,
      borderRadius: "10px",
    },
    itemCell: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: "border-box",
        borderBottom: `1px solid ${getTheme().semanticColors.bodyDivider}`,
        display: "flex",
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        transition: "0.3s",
        margin: "5px",
        maxWidth: 450,
        selectors: {
          "&:hover": {
            background: getTheme().palette.neutralLight,
            boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)",
          },
        },
      },
    ],
    itemImage: {
      alignSelf: "center",
      flexShrink: 0,
      borderRadius: "50%",
    },
    itemContent: {
      marginLeft: 10,
      overflow: "hidden",
      flexGrow: 1,
    },
    itemName: [
      getTheme().fonts.xLarge,
      {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    ],
    itemIndex: {
      fontSize: getTheme().fonts.small.fontSize,
      color: getTheme().palette.neutralTertiary,
      marginTop: 5,
    },
    chevron: {
      alignSelf: "center",
      marginLeft: 10,
      color: getTheme().palette.neutralTertiary,
      fontSize: getTheme().fonts.large.fontSize,
      flexShrink: 0,
    },
    contentClass: {
      backgroundColor: getTheme().palette.neutralLight,
      color: getTheme().palette.neutralDark,
      lineHeight: "50px",
      padding: "0 20px",
    },
  });

  render() {
    const { item } = this.props;
    const { user, teamDayViewDates } = item;
    var mondayLabel = localStorage.getItem("localization_Monday");
    var tuesdayLabel = localStorage.getItem("localization_Tuesday");
    var wednesdayLabel = localStorage.getItem("localization_Wednesday");
    var thursdayLabel = localStorage.getItem("localization_Thursday");
    var fridayLabel = localStorage.getItem("localization_Friday");
    var saturdayLabel = localStorage.getItem("localization_Saturday");
    var sundayLabel = localStorage.getItem("localization_Sunday");

    var days = [
      sundayLabel.charAt(0),
      mondayLabel.charAt(0),
      tuesdayLabel.charAt(0),
      wednesdayLabel.charAt(0),
      thursdayLabel.charAt(0),
      fridayLabel.charAt(0),
      saturdayLabel.charAt(0),
    ];

    var onSiteColor = localStorage.getItem("theme_OnSite");
    var homeColor = localStorage.getItem("theme_Home");
    var noResponseColor = localStorage.getItem("theme_NoResponse");
    var notWorkingColor = localStorage.getItem("theme_NotWorking");

    return (
      <div
        className={this.classNames.itemCell + " hover"}
        style={{
          backgroundColor: "white",
          borderRadius: "15px",
          flex: "50%",
        }}
        onClick={() => {}}
      >
        <Image
          className={this.classNames.itemImage}
          src={user?.profilePhoto ?? "/assets/images/blank.png"}
          alt=""
          width={60}
          height={60}
          imageFit={ImageFit.cover}
        />

        <div className={this.classNames.itemContent}>
          <div
            className={this.classNames.itemName}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ fontSize: "14px" }}>
              {user?.name} {user?.surname}
            </div>{" "}
            {this.state.currentDateData?.state === "workFromOffice" && (
              <div style={{ display: "flex" }}>
                <span
                  style={{
                    color: onSiteColor,
                    fontSize: "14px",
                    marginRight: "10px",
                  }}
                >
                  {this.state.currentDateData?.site?.name}
                </span>
                <OnSiteLogo
                  fill={onSiteColor}
                  stroke={onSiteColor}
                  height="20"
                  width="20"
                />
              </div>
            )}
            {this.state.currentDateData?.state === "workFromHome" && (
              <div style={{ display: "flex" }}>
                <span
                  style={{
                    color: homeColor,
                    fontSize: "14px",
                    marginRight: "10px",
                  }}
                >
                  Home
                </span>
                <HomeLogo
                  fill={homeColor}
                  stroke={homeColor}
                  height="20"
                  width="20"
                />
              </div>
            )}
            {this.state.currentDateData?.state === "notWorking" && (
              <div style={{ display: "flex" }}>
                <span
                  style={{
                    color: notWorkingColor,
                    fontSize: "14px",
                    marginRight: "10px",
                  }}
                >
                  {this.state.currentDateData?.notWorkingReason?.name}
                </span>
                <NotWorkingLogo
                  fill={notWorkingColor}
                  stroke={notWorkingColor}
                  height="20"
                  width="20"
                />
              </div>
            )}
          </div>
          <div
            className={this.classNames.itemIndex}
            style={{ display: "flex" }}
          >
            {teamDayViewDates &&
              teamDayViewDates.length > 0 &&
              teamDayViewDates.map((item) => {
                return (
                  <TooltipHost
                    // content="test"
                    content={
                      item.state === "workFromOffice"
                        ? item.site?.name
                        : item.state === "workFromHome"
                        ? "Home"
                        : item.state === "notWorking"
                        ? item.notWorkingReason?.name
                        : "No Response"
                    }
                    // This id is used on the tooltip itself, not the host
                    // (so an element with this id only exists when the tooltip is shown)
                    // id={tooltipId}
                    // calloutProps={calloutProps}
                    // styles={hostStyles}
                  >
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        border: `3px solid ${this.getColorByState(item.state)}`,
                        borderRadius: "50%",
                        textAlign: "center",
                        margin: "0px 1px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {days[new Date(item.date).getDay()]}
                    </div>
                  </TooltipHost>
                );
              })}
          </div>
        </div>
      </div>
    );
  }

  async populateData(skip, take) {
    const { item } = this.props;
    var currentDateData = this.getMyDayForDate(item);

    this.setState({ currentDateData: currentDateData });
  }
}
