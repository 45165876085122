import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  DatePicker,
  DayOfWeek,
  defaultDatePickerStrings,
} from "@fluentui/react";
import {
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import _ from "lodash";
import { ReactComponent as CheckLogo } from "../../../../assets/svg/check-circle.svg";
import { ReactComponent as AddLogo } from "../../../../assets/svg/add.svg";
import { UserAttributesClient } from "../../../../infrastructure/api/UserAttributesClient";
import moment from "moment";

export class SkillWithDateItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      expiryDate: null,
      isAdded: false,
    };
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
  }

  classNames = mergeStyleSets({
    container: {
      width: "100%",
      overflow: "hidden",
    },
  });

  render() {
    const { skill, currentUser } = this.props;
    const { expiryDate, isAdded } = this.state;
    return (
      <div>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "15px",
            cursor: "default",
            display: "flex",
            alignItems: "center",
          }}
        >
          {" "}
          <div
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              background: skill?.color ? skill?.color : "#00838f",
            }}
          ></div>
          <div
            style={{
              flexGrow: 1,
              marginLeft: "10px",
            }}
          >
            <div style={{ fontSize: "15px" }}>{skill.name}</div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <DatePicker
            firstDayOfWeek={DayOfWeek.Monday}
            minDate={new Date()}
            onSelectDate={(date) => this.setState({ expiryDate: date })}
            placeholder="Expiry Date"
            // DatePicker uses English strings by default. For localized apps, you must override this prop.
            strings={defaultDatePickerStrings}
            style={{
              flexGrow: 1,
              marginRight: "10px",
              marginLeft: "35px",
              alignSelf: "end",
            }}
            disabled={isAdded}
          />
          {isAdded ? (
            <CheckLogo
              height="20"
              width="20"
              style={{
                overflow: "visible",
                padding: "10px 0",
                cursor: "default",
              }}
              stroke={"#00838f"}
              fill={"#00838f"}
            />
          ) : (
            <AddLogo
              height="20"
              width="20"
              style={{
                overflow: "visible",
                padding: "10px 0",
                cursor: expiryDate ? "pointer" : "default",
              }}
              stroke={expiryDate ? "#00838f" : "#8f8f8f"}
              fill={expiryDate ? "#00838f" : "#8f8f8f"}
              onClick={async () => {
                if (!expiryDate) return;

                this.setState({ isAdded: true });
                await UserAttributesClient.AddLinkedUsers(
                  skill.id,
                  [currentUser.id],
                  moment(expiryDate).format("YYYY-MM-DD")
                );
              }}
            />
          )}
        </div>
      </div>
    );
  }

  async populateData() {}
}
