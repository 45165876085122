import { ApiResponse, ListQueryParams } from "../ApiClient";
import {
  GetAPIDomain,
  GetAPIHeaders,
  GetAPIHeadersPatch,
  MyDayAPIHelper,
} from "../MyDayAPIHelper";

export class NotWorkingReasonQueryParams extends ListQueryParams {
  WithStatus(value: string) {
    this.WithParam("status", value);
    return this;
  }
}

export class NotWorkingReasonClient {
  static async List(params: NotWorkingReasonQueryParams) {
    var query =
      (await GetAPIDomain()) +
      "/not-working-reasons" +
      params.GenerateQueryString();
    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async Delete(id: string) {
    var query = (await GetAPIDomain()) + "/not-working-reasons/" + id;
    var headers = await GetAPIHeaders();

    return await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
        method: "delete",
      })
    );
  }

  static async Get(id: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    var query = apiDomain + "/not-working-reasons/" + id;

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async Create(name: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();

    return await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + "/not-working-reasons/", {
        method: "post",
        headers: headers,
        body: JSON.stringify({
          name: name,
        }),
      })
    );
  }

  static async Update(id: string, name: string, status: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeadersPatch();

    return await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + "/not-working-reasons/" + id, {
        method: "patch",
        headers: headers,
        body: JSON.stringify([
          {
            op: "replace",
            path: "name",
            value: name,
          },
          {
            op: "replace",
            path: "status",
            value: status,
          },
        ]),
      })
    );
  }
}
