import React, { Component, useContext } from 'react';
import { Redirect, Link } from 'react-router-dom';


export class NoTenant extends Component {

    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: false };
    }

    render() {

        return (
            <div style={{ maxWidth: "600px", width: "100%", padding: "30px", textAlign: "center", backgroundColor:"rgb(255 255 255 / 10%)" }}>
                <img src="/assets/images/daysie_logo.png" style={{ height: "50px", position: "relative", top: "15px" }} />
                <h3 className="mb-4">
                    No tenant available</h3>
                <hr />
                <p>Your organisation settings cannot be found, please reload the tab.</p>
            </div>
        );

    }
}