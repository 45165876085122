import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import { Dropdown, Label } from "@fluentui/react";
import moment from "moment";

export class HourSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedData: null,
      data: [],
      takenTimeFramesWithDuration: [],
      initialized: false,
    };
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate() {
    if (this.state.selectedData?.id != this.props.defaultValue?.id)
      this.setState({ selectedData: this.props.defaultValue });
  }

  async update() {
    if (!this.state.initialized) await this.populateData();
  }

  classNames = mergeStyleSets({
    dropdown: { borderRadius: "10px" },
  });

  render() {
    const { onSelect, defaultValue, disabled } = this.props;
    return (
      <>
        <Dropdown
          disabled={disabled}
          placeholder="Select time"
          selectedKey={
            this.state.selectedData ? this.state.selectedData.id : null
          }
          options={this.state.data.map((item) => {
            return {
              key: item.id,
              text: item.name,
              disabled: item.disabled,
            };
          })}
          onChanged={(item) => {
            this.setState({
              selectedData: {
                id: item.key,
                name: item.text,
              },
            });

            onSelect({
              id: item.key,
              name: item.text,
            });
          }}
          className={this.classNames.dropdown}
          styles={{
            dropdownItem: {
              fontSize: "12px",
            },
            label: {
              fontSize: "12px",
            },
            dropdown: {
              fontSize: "12px",
              minWidth: 80,
            },
            title: {
              borderRadius: "5px",
            },
            dropdownItemDisabled: {
              color: "#e0e0e0",
            },
          }}
          defaultSelectedKey={defaultValue}
          calloutProps={{ calloutMaxHeight: 270 }}
        />
      </>
    );
  }

  async populateData() {
    if (!this.state.initialized) this.updateTakenTimeFrames();
  }

  getTimeSlots() {
    const { isToday, checkIns, onSelect, removeDisabledOptions } = this.props;
    var startTime = moment("00:00", "HH:mm");
    var endTime = moment("23:59", "HH:mm");

    if (endTime.isBefore(startTime)) {
      endTime.add(1, "day");
    }

    var timeSlots = [];

    // const isSameDay = moment(this.state.checkInDate).isSame(
    //   moment().format("YYYY-MM-DD")
    // );

    while (startTime <= endTime) {
      var label = moment(startTime).format("h:mm A");
      var value = moment(startTime).format("HH:mm");
      var checkTimeTaken = false;
      var isBeforeCurrentTime = false;

      var currentTime = moment(moment(), "h:mm A");

      if (isToday && moment(startTime, "h:mm A").isBefore(currentTime)) {
        isBeforeCurrentTime = true; // return true if time is before
      } else if (checkIns && checkIns.length > 0) {
        checkTimeTaken =
          this.state.takenTimeFramesWithDuration?.length > 0
            ? this.isTimeSlotTaken(startTime)
            : false;
      }

      if (isToday && isBeforeCurrentTime) {
        //do nothing
      } else if (isToday && removeDisabledOptions && checkTimeTaken) {
        timeSlots.push({ value, label, disabled: true });
      } else {
        timeSlots.push({ value, label, disabled: checkTimeTaken });
      }

      startTime.add(15, "minutes");
    }

    if (!this.props.defaultValue?.id) {
      //check if 8:30AM is available
      var eightThirty = timeSlots.filter(
        (x) => x.value === "08:30" && x.disabled == false
      )[0];

      if (eightThirty) {
        onSelect({
          id: eightThirty.value,
          name: eightThirty.label,
        });
        this.setState({
          selectedData: {
            id: eightThirty.value,
            name: eightThirty.label,
          },
        });
      }
      // if 8:30AM is not available, use the next available time
      else {
        var firstEnabled = timeSlots.filter((x) => x.disabled == false)[0];

        if (firstEnabled) {
          onSelect({
            id: firstEnabled.value,
            name: firstEnabled.label,
          });
          this.setState({
            selectedData: {
              id: firstEnabled.value,
              name: firstEnabled.label,
            },
          });
        }
      }
    }
    this.setState({
      data: timeSlots.map(function (item) {
        return {
          id: item.value.trim(),
          name: item.label.trim(),
          disabled: item.disabled,
        };
      }),
      initialized: true,
    });
  }

  updateTakenTimeFrames() {
    //get all future bookings and set taken time frames state to compare when getting available timeslots
    const takenTimeFramesWithDuration = [];
    const takenTimeFrames = [];
    const uncheckedoutBookings = this.props.checkIns;

    const { selectedDuration } = this.state;

    for (const booking of uncheckedoutBookings) {
      takenTimeFramesWithDuration.push({
        from: selectedDuration
          ? moment(booking.checkInDate)
              .subtract(parseInt(selectedDuration.value) - 15, "minutes")
              .format("HH:mm a")
          : moment(booking.checkInDate).format("HH:mm a"),
        to: moment(booking.checkOutDue).format("HH:mm a"),
      });
      takenTimeFrames.push({
        from: moment(booking.checkInDate).format("HH:mm a"),
        to: moment(booking.checkOutDue).format("HH:mm a"),
      });
    }
    this.setState(
      {
        takenTimeFramesWithDuration: takenTimeFramesWithDuration,
        takenTimeFrames: takenTimeFrames,
      },
      () => this.getTimeSlots()
    );
  }

  //checker if a specific time slot is taken
  isTimeSlotTaken = (time) => {
    var isTaken = false;
    const { takenTimeFramesWithDuration } = this.state;

    for (let i = 0; i < takenTimeFramesWithDuration.length; i++) {
      var toComparePlus15 = moment(time, "h:mm A").add(15, "minutes");
      var toCompare = moment(time, "h:mm A");

      var from = moment(takenTimeFramesWithDuration[i].from, "h:mm A");
      var to =
        takenTimeFramesWithDuration[i].to === "00:00 am"
          ? moment("23:59 am", "h:mm A")
          : moment(takenTimeFramesWithDuration[i].to, "h:mm A");

      isTaken =
        toComparePlus15.isBetween(from, to) || toCompare.isBetween(from, to);
      if (isTaken) break;
    }

    return isTaken;
  };
}
