import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import {
  EventsClient,
  EventsQueryParams,
} from "../../infrastructure/api/EventsClient";
import {
  FocusZone,
  List,
  DefaultButton,
  Shimmer,
  Modal,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { FontIcon } from "@fluentui/react/lib/Icon";
import { Icon } from "@fluentui/react/lib/Icon";
import Moment from "react-moment";
import moment from "moment";
import { Badge } from "@fluentui/react-components";
import { EventModal } from "../Modals/EventModal";
import { getRandomEventImage } from "../../helpers/imageHelper";

export class EventsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      eventModalVisible: false,
      selectedData: null,
      unattendIndexLoading: null,
      randomImageForIndexes: [],
      selectedIndex: 0,
    };
    this.onRenderCell = this.onRenderCell.bind(this);
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
  }

  modalStyles = mergeStyleSets({
    root: {
      width: "600px",
      height: "600px",
    },
    container: {},
  });

  iconClass = mergeStyles({
    fontSize: 12,
    height: 12,
    width: 12,
    cursor: "pointer",
    color: "red",
  });

  render() {
    const { selectedData } = this.state;
    const { refreshEvents } = this.props;
    return (
      <div style={{ width: "100%" }}>
        <List items={this.props.events} onRenderCell={this.onRenderCell} />
        <EventModal
          eventModalVisible={this.state.eventModalVisible}
          selectedData={this.state.selectedData}
          onCancel={() => {
            this.setState({ eventModalVisible: false });
            if (refreshEvents) refreshEvents();
          }}
          userId={this.props.userId}
          refreshEvents={() => {
            this.props.refreshEvents();
          }}
          randomImg={this.state.randomImageForIndexes[this.state.selectedIndex]}
        />
      </div>
    );
  }

  onRenderCell(item, index, isScrolling) {
    const { selectedData } = this.state;

    return (
      <div
        style={{ padding: "5px" }}
        onMouseOver={() => {
          this.setState({ selectedData: item, selectedIndex: index });
        }}
      >
        <div className="card-item">
          {item?.eventImages && item?.eventImages.length > 0 ? (
            <img
              src={item?.eventImages[0]?.url}
              style={{
                width: "100%",
                borderRadius: "10px 10px 0px 0px",
                objectFit: "cover",
                height: "200px",
                cursor: "pointer",
              }}
              onClick={() => {
                this.setState({ eventModalVisible: true });
              }}
            ></img>
          ) : (
            <img
              src={this.state.randomImageForIndexes[index]}
              style={{
                width: "100%",
                borderRadius: "10px 10px 0px 0px",
                objectFit: "cover",
                height: "200px",
                cursor: "pointer",
              }}
              onClick={() => {
                this.setState({ eventModalVisible: true });
              }}
            ></img>
          )}

          <div
            className="container"
            onClick={() => {
              this.setState({ eventModalVisible: true });
            }}
          >
            <b style={{ cursor: "pointer" }}>{item.name}</b>
            <h4>
              {moment(item.startDate).isSame(item.endDate) ? (
                <>
                  <Moment utc local format="ddd DD MMM, YYYY">
                    {item.startDate}
                  </Moment>{" "}
                </>
              ) : (
                <>
                  <Moment utc local format="ddd DD MMM, YYYY">
                    {item.startDate}
                  </Moment>{" "}
                  -{" "}
                  <Moment utc local format="ddd DD MMM, YYYY">
                    {item.endDate}
                  </Moment>
                </>
              )}
            </h4>
            {item.startTime && item.endTime && (
              <i style={{ color: "rgb(99 99 99)" }}>
                {moment(item.startTime, ["HH:mm"]).format("hh:mm A")} -{" "}
                {moment(item.endTime, ["HH:mm"]).format("hh:mm A")}
              </i>
            )}

            <h4 style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                {item.isAttendee && (
                  <>
                    <div
                      style={{
                        marginRight: "5px",
                        background: "#5B5FC7",
                        fontSize: "12px",
                        fontWeight: 600,
                        color: "white",
                        borderRadius: "50px",
                        padding: "4px",
                      }}
                    >
                      Attending
                    </div>
                    <span
                      onClick={async () => {
                        this.setState({ unattendIndexLoading: index });
                        await EventsClient.RemoveAttendeeToEvent(
                          this.state.selectedData?.id,
                          this.props.userId
                        );
                        this.props.refreshEvents();
                        this.setState({ unattendIndexLoading: null });
                      }}
                    >
                      {this.state.unattendIndexLoading === null && (
                        <FontIcon
                          aria-label="Cancel"
                          iconName="Cancel"
                          className={this.iconClass}
                        />
                      )}
                      {this.state.unattendIndexLoading === index && (
                        <FontIcon
                          aria-label="Refresh"
                          iconName="Refresh"
                          className={`${this.iconClass} spin-icon`}
                        />
                      )}
                    </span>{" "}
                  </>
                )}
              </div>
            </h4>
            <p>{item.description}</p>
          </div>
        </div>
      </div>
    );
  }

  async populateData(skip, take) {
    const { events } = this.props;

    this.setState({
      loading: true,
    });

    if (events && events.length > 0) {
      var images = [];

      events.forEach((element, index) => {
        images.push(getRandomEventImage());
      });

      this.setState({ randomImageForIndexes: images });
    }

    this.setState({
      loading: false,
    });
  }
}
