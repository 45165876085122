import React, { Component, useContext } from "react";
import "./WorkPlanNotWorkingDropdown.css";
import { ReactComponent as Logo } from "../../../assets/svg/brightness.svg";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";

export class WorkPlanNotWorkingDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
      defaultValue: {},
    };
  }

  componentDidMount() {
    const { defaultValue } = this.props;

    if (defaultValue?.label) this.setState({ defaultValue: defaultValue });
  }

  componentWillReceiveProps(props) {
    const { defaultValue } = this.props;
    console.log(defaultValue);
    if (defaultValue && defaultValue?.label !== this.state.defaultValue?.label)
      this.setState({ defaultValue: defaultValue });
  }

  classNames = mergeStyleSets({
    buttonShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)" },
        },
      },
    ],
  });

  render() {
    const { showDropdown, setShowDropdown, items, onSelect } = this.props;

    var notWorkingColor = localStorage.getItem("theme_NotWorking");
    return (
      <div className="dropdown-wrapper">
        <div
          onClick={() => {
            this.setState({ showDropdown: !this.state.showDropdown });
          }}
          style={{
            borderRadius: "5px",
            minWidth: "150px",
            textAlign: "left",
            padding: "10px",
            display: "flex",
            cursor: "pointer",
            marginBottom: "15px",
            background: "white",
            width: "auto",
          }}
          className="trigger-button"
        >
          <Logo
            fill={notWorkingColor}
            stroke={notWorkingColor}
            height="20"
            width="20"
          />
          <div style={{ paddingLeft: "10px" }}>
            {this.state.defaultValue?.label
              ? this.state.defaultValue?.label
              : "Select a reason..."}
          </div>
        </div>
        <ul className={this.state.showDropdown ? "active" : ""}>
          {items.map((item) => (
            <li
              key={item.value}
              onClick={() => {
                const { value, label } = item;
                onSelect(item);
                this.setState({
                  defaultValue: { value, label },
                  showDropdown: false,
                });
              }}
            >
              {item.label}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
