import { ApiResponse, ListQueryParams } from "../ApiClient";
import {
  GetAPIDomain,
  GetAPIHeaders,
  GetAPIHeadersPatch,
  MyDayAPIHelper,
} from "../MyDayAPIHelper";

export class AssetsQueryParams extends ListQueryParams {
  WithAssetTypeId(value: string) {
    this.WithParam("assetTypeId", value);
    return this;
  }
  WithSiteId(value: string) {
    this.WithParam("siteId", value);
    return this;
  }
  WithAssetId(value: string) {
    this.WithParam("assetId", value);
    return this;
  }
  WithAllocationId(value: string) {
    this.WithParam("allocationId", value);
    return this;
  }
  WithCheckInStartDate(value: string) {
    this.WithParam("CheckinStartDate", value);
    return this;
  }
  WithCheckInEndDate(value: string) {
    this.WithParam("CheckinEndDate", value);
    return this;
  }
  WithStatus(value: string) {
    this.WithParam("status", value);
    return this;
  }

  WithDesk(value: boolean) {
    this.WithParam("isDesk", value);
    return this;
  }
  WithFloorId(value: boolean) {
    this.WithParam("floorId", value);
    return this;
  }
  WithNeighborhoodId(value: boolean) {
    this.WithParam("neighborhoodId", value);
    return this;
  }
  WithIsReserved(value: boolean) {
    this.WithParam("isReserved", value);
    return this;
  }
  WithAttributeIds(value: any[]) {
    this.WithParam("attributeIds", value);
    return this;
  }
}

export class AssetsClient {
  static async List(params: AssetsQueryParams) {
    var query =
      (await GetAPIDomain()) + "/assets" + params.GenerateQueryString();
    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }
}
