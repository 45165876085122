import { ApiResponse, ListQueryParams } from "../ApiClient";
import {
  GetAPIDomain,
  GetAPIHeaders,
  GetApiHeadersFormData,
  GetAPIHeadersPatch,
  MyDayAPIHelper,
} from "../MyDayAPIHelper";

export class VehicleTypesQueryParams extends ListQueryParams {
  WithUserId(value: any) {
    this.WithParam("userId", value);
    return this;
  }
}

export class VehicleTypesClient {
  static async List(params: VehicleTypesQueryParams) {
    var query =
      (await GetAPIDomain()) + "/vehicle-types" + params.GenerateQueryString();
    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();

        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }
}
