import React, { Component, useContext } from "react";
import {
  Dialog,
  DialogFooter,
  DialogType,
  TextField,
  Calendar,
  defaultCalendarStrings,
  Modal,
  DialogContent,
  PrimaryButton,
} from "@fluentui/react";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import { Icon, FontIcon } from "@fluentui/react/lib/Icon";
import moment from "moment";
import Moment from "react-moment";
import { DialogTitle } from "@fluentui/react-components";
import { getDayLocalizationLabel } from "../../../helpers/localizationHelper";
import { HourSelector } from "../../Selectors/HourSelector";
import { DurationSelector } from "../../Selectors/DurationSelector";
import { WorkDaysClient } from "../../../infrastructure/api/WorkDaysClient";
import { ReactComponent as CheckInLogo } from "../../../assets/svg/grid-2.svg";
import _ from "lodash";

export class BookingFooterPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      duration: null,
      errorMessage: "",
    };
  }

  componentDidMount() {}

  classNames = mergeStyleSets({
    itemCell: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: "border-box",
        borderBottom: `1px solid ${getTheme().semanticColors.bodyDivider}`,
        transition: "0.3s",
        margin: "10px 0px",
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        selectors: {
          "&:hover": {
            background: getTheme().palette.neutralLight,
            boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)",
          },
        },
      },
    ],

    dialogAssetContainer: {
      minHeight: 54,
      padding: 10,
      boxSizing: "border-box",
      borderBottom: `1px solid ${getTheme().semanticColors.bodyDivider}`,
      transition: "0.3s",
      margin: "10px 0px",
    },
    itemImage: {
      width: 40,
      height: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    itemContent: {
      marginLeft: 10,
      overflow: "hidden",
      flexGrow: 1,
    },
    itemName: [
      getTheme().fonts.xLarge,
      {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    ],
    itemIndex: {
      fontSize: getTheme().fonts.small.fontSize,
      color: getTheme().palette.neutralTertiary,
    },
    bookingDetails: {
      transition: "height 200ms",
    },
  });

  render() {
    const {
      item,
      date,
      onDismissRefresh,
      onDismiss,
      show,
      selectedDays,
      loading,
      isReserved,
      isNotBookable,
      isBlocked,
    } = this.props;

    var onSiteColor = localStorage.getItem("theme_OnSite");
    var teamHighlightColor = localStorage.getItem("theme_HighlightTeam");
    var availableColor = localStorage.getItem("theme_Available");
    var reservedColor = localStorage.getItem("theme_Reserved");
    var inUseColor = localStorage.getItem("theme_InUse");
    var bookedColor = localStorage.getItem("theme_Booked");
    var itemColor = localStorage.getItem("theme_Item");

    var dateUpdated = moment(date).format("DD-MM-YYYY");
    var todayFormatted = moment().format("DD-MM-YYYY");

    return (
      <Dialog
        hidden={!show}
        onDismiss={() => {
          this.setState({ errorMessage: "" });
          onDismiss();
        }}
        dialogContentProps={{
          type: DialogType.normal,
        }}
        styles={{
          main: {
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            maxWidth: "100%",
            height: "80%",
            borderRadius: "15px 15px 0px 0px",
            background: "#F3F4F3",
          },
          root: {},
        }}
        isDarkOverlay={false}
        className=""
      >
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "5px",
            }}
          >
            <div
              style={{
                borderRadius: "10px",
                height: "5px",
                width: "25px",
                backgroundColor: "#b5b5b5",
              }}
            ></div>
          </div>
          <div style={{ fontSize: "14px", marginTop: "30px" }}>
            <b>Book Asset</b>
          </div>
          <div
            className={this.classNames.dialogAssetContainer}
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            <div style={{ display: "flex" }}>
              <div className={this.classNames.itemImage}>
                <CheckInLogo
                  fill="#BFBFBF"
                  stroke="#BFBFBF"
                  height="20"
                  width="20"
                />
              </div>
              <div className={this.classNames.itemContent}>
                <div
                  className={this.classNames.itemName}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "13px",
                  }}
                >
                  {item?.name}
                </div>
              </div>
            </div>
          </div>
          <div style={{ fontSize: "14px", marginTop: "20px" }}>
            <b>
              {getDayLocalizationLabel(moment(date).format("dddd"))}
              <Moment utc local format=", DD MMMM">
                {date}
              </Moment>
            </b>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div style={{ width: "100%", marginRight: "5px" }}>
              <HourSelector
                onSelect={({ id, name }) => {
                  this.setState({ hour: id, hourValue: { id, name } });
                }}
                checkIns={item?.checkIns}
                defaultValue={this.state.hourValue}
                isToday={dateUpdated === todayFormatted ? true : false}
                removeDisabledOptions
                disabled={isReserved || isNotBookable || isBlocked}
              />
            </div>

            <div style={{ width: "100%", marginLeft: "5px" }}>
              <DurationSelector
                onSelect={({ id, name }) => {
                  this.setState({ duration: id });
                }}
                disabled={isReserved || isNotBookable || isBlocked}
              />
            </div>
          </div>
          {this.state.errorMessage && (
            <p style={{ margin: "10px 0", color: "red", fontSize: "14px" }}>
              {this.state.errorMessage}
            </p>
          )}
          <div style={{ marginTop: "30px", width: "100%" }}>
            <PrimaryButton
              onClick={async () => {
                this.setState({ bookLoading: true });
                const { duration, hour } = this.state;

                var hr = parseInt(hour.split(":")[0]);
                var mins = hour.split(":")[1];

                var tz = moment.tz.guess();
                const start = moment.tz(date, tz); // original timezone

                const local = start.tz(tz).format("YYYY-MM-DD[T]HH:mm:ss.SSS");

                var withTimeUtc = moment(local)
                  .utc()
                  .add(hr, "h")
                  .add(mins, "minutes")
                  .format("YYYY-MM-DD[T]HH:mm:ss.SSS");

                var response = await WorkDaysClient.CheckIn(item.id, duration, [
                  withTimeUtc,
                ]);

                if (response.data.length > 0) {
                  var failed = _.filter(response.data, ["success", false]);

                  if (failed.length > 0) {
                    this.setState({ errorMessage: failed[0].reason });
                  } else {
                    this.setState({ errorMessage: "" }, () => {
                      onDismissRefresh();
                    });
                  }
                }

                this.setState({ bookLoading: false });
              }}
              text={`Book ${item?.assetType?.name}`}
              allowDisabledFocus
              style={{
                borderRadius: "5px",
                fontSize: "14px",
                height: "40px",
                background: onSiteColor,
                width: "100%",
              }}
              disabled={
                !this.state.hour ||
                !this.state.duration ||
                !selectedDays ||
                loading ||
                isReserved ||
                isNotBookable ||
                isBlocked
              }
            />
          </div>
          {item.checkIns && item.checkIns.length > 0 && (
            <div style={{ marginTop: "25px", width: "100%" }}>
              {item.checkIns.map((checkin) => (
                <div
                  className={this.classNames.itemIndex}
                  style={{ marginBottom: "3px", fontSize: "15px" }}
                >
                  {checkin.user?.name} {checkin.user?.surname}{" "}
                  <Moment utc local format="h:mm A">
                    {moment(checkin.checkInDate)}
                  </Moment>{" "}
                  -{" "}
                  <Moment utc local format="h:mm A">
                    {moment(checkin.checkOutDue)}
                  </Moment>
                </div>
              ))}
            </div>
          )}

          {isNotBookable && (
            <div
              style={{
                fontSize: "13px",
                color: "white",
                borderRadius: "5px",
                background: "grey", //item.allocations[0].color,
                padding: "5px",
                marginTop: "20px",
                width: "fit-content",
              }}
            >
              Asset Not Bookable
            </div>
          )}

          {isBlocked && (
            <div
              style={{
                fontSize: "13px",
                color: "white",
                borderRadius: "5px",
                background: "#EF6D6C", //item.allocations[0].color,
                padding: "5px",
                marginTop: "20px",
                width: "fit-content",
              }}
            >
              Blocked
            </div>
          )}
          {isReserved && (
            <div
              style={{
                fontSize: "13px",
                color: "white",
                borderRadius: "5px",
                background: reservedColor, //item.allocations[0].color,
                padding: "5px",
                marginTop: "20px",
                width: "fit-content",
              }}
            >
              Reserved {item.reservedUser?.name} {item.reservedUser?.surname}
            </div>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}
