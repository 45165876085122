import React, { Component, useContext, useRef } from "react";

import { ReactComponent as WorkFromOfficeLogo } from "../../../assets/svg/marker.svg";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import { FloorsClient } from "../../../infrastructure/api/FloorsClient";
import {
  getOverlayCenterLatLng,
  getMapBounds,
  CustomPoint,
} from "../../../helpers/imageHelper";
import {
  Shimmer,
  DefaultButton,
  Modal,
  SpinnerSize,
  Spinner,
} from "@fluentui/react";
import {
  MapContainer,
  TileLayer,
  useMapEvents,
  MapConsumer,
  Marker,
  ImageOverlay,
  SVGOverlay,
  CircleMarker,
  Circle,
  Popup,
  Tooltip,
} from "react-leaflet";
import * as L from "leaflet";
import moment from "moment-timezone";
import Moment from "react-moment";
import { WorkDaysClient } from "../../../infrastructure/api/WorkDaysClient";
import { CheckInsClient } from "../../../infrastructure/api/CheckInsClient";
import { DurationSelector } from "../../Selectors/DurationSelector";
import { HourSelector } from "../../Selectors/HourSelector";
import _ from "lodash";
import "./FloorPlan.css";
// import Legend from "./Legend.jsx";

export class FloorPlan extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      photoUrl: "",
      floor: {},
      bounds: null,
      initialPosition: null,
      isModalOpen: false,
      selectedData: {},
      map: null,
      hourValue: {},
      hour: "",
      duration: 480,
      durationValue: { id: 480, name: "8 hours" },
      zoom: 4,
      circleWeight: 1,
      circleFontSize: "10px",
      releaseLoadingIndex: null,
      defaultRealeaseId: null,
      selectedRealeaseId: null,
      openCheckinId: null,
    };
  }

  componentDidMount() {
    const { releaseReasons } = this.props;

    this.attachMapEventListener();
    this.update();

    if (releaseReasons && releaseReasons.length > 0) {
      const defaultReason = releaseReasons.find(
        (reason) => reason.default == true
      );
      if (defaultReason)
        this.setState({
          defaultRealeaseId: defaultReason.id,
          selectedRealeaseId: defaultReason.id,
        });
    }
  }

  async update() {
    const { floorId } = this.props;
    // this.setState({ loading: true });

    var response = await FloorsClient.Get(floorId);

    if (response.successful) {
      var height = 0;
      var width = 0;
      if (response.data.blobPhotoDetails) {
        height = 432;
        width = response.data?.width > response.data?.height ? 576 : 250;
      }
      // setBounds([height, width])
      var overlayCenterLatlng = getOverlayCenterLatLng(
        response.data?.width,
        response.data?.height
      );
      var bounds = getMapBounds(response.data?.width, response.data?.height);

      this.setState({
        photoUrl: `${response.data.blobPhoto}?${new Date().getTime()}`,
        floor: response.data,
        bounds: bounds,
        initialPosition: overlayCenterLatlng,
        loading: false,
      });
    }
  }

  attachMapEventListener() {
    // Check if the map instance is available
    if (this.mapRef.current) {
      const map = this.mapRef.current;

      // Attach the zoomend event listener
      map.on("zoomend", () => {
        const zoomLevel = map.getZoom();

        // Adjust the font size based on the zoom level
        var fontSize = "6px";
        // Adjust the weight based on the zoom level
        const newWeight = zoomLevel * 0.5; // Adjust this multiplier as needed

        if (zoomLevel <= 5) fontSize = "6px";
        if (zoomLevel == 6) fontSize = "10px";
        else if (zoomLevel >= 7 && zoomLevel <= 8)
          fontSize = `${zoomLevel * 3}px`;
        if (zoomLevel >= 9) fontSize = `${zoomLevel * 5}px`;

        this.setState({
          zoom: zoomLevel,
          circleFontSize: fontSize,
          circleWeight: newWeight,
        });
      });
    }
  }

  handleMapCreated = (mapInstance) => {
    this.mapRef.current = mapInstance;
    this.attachMapEventListener(); // Attach the event listener after map is created
  };

  render() {
    const { bounds, initialPosition, photoUrl, loading } = this.state;
    const { assets, teamMemberIds, showTeamMembers, releaseReasons } =
      this.props;

    var onSiteColor = localStorage.getItem("theme_OnSite");
    var homeColor = localStorage.getItem("theme_Home");
    var noResponseColor = localStorage.getItem("theme_NoResponse");
    var notWorkingColor = localStorage.getItem("theme_NotWorking");
    var whatsOnHolidayColor = localStorage.getItem("theme_WhatsOnHoliday");
    var whatsOnEventColor = localStorage.getItem("theme_WhatsOnEvent");
    var teamHighlightColor = localStorage.getItem("theme_HighlightTeam");
    var availableColor = localStorage.getItem("theme_Available");
    var reservedColor = localStorage.getItem("theme_Reserved");
    var inUseColor = localStorage.getItem("theme_InUse");
    var bookedColor = localStorage.getItem("theme_Booked");
    var itemColor = localStorage.getItem("theme_Item");

    var userId = localStorage.getItem("userId");
    // const Markers = () => {
    //   const map = useMapEvents({
    //     click(e) {
    //       console.log(e);
    //       setSelectedPosition([e.latlng.lat, e.latlng.lng]);
    //       var point = CustomPoint(e.latlng, 5);
    //       setSelectedXPoint(point.x);
    //       setSelectedYPoint(point.y);
    //       setSelectedLat(e.latlng.lat);
    //       setSelectedLng(e.latlng.lng);
    //     },
    //   });

    //   return selectedPosition ? (
    //     <Circle
    //       center={selectedPosition}
    //       key={selectedPosition[0]}
    //       interactive={false}
    //       pathOptions={{ color: "#FF0000", weight: 0 }}
    //       fillOpacity={1}
    //       radius={0.3}
    //     ></Circle>
    //   ) : // <Marker position={selectedPosition} icon={icon}></Marker>
    //   // <Marker position={selectedPosition}></Marker>
    //   null;
    // };

    const PopOverDetails = ({ item, hideLabel }) => {
      const { refreshAssets, date } = this.props;
      const currentUserId = localStorage.getItem("userId");
      const filteredAllocations =
        item.allocations && item.allocations.length > 0
          ? item.allocations.filter((i) => i.description !== "Reserved")
          : [];

      return (
        <>
          {!hideLabel && (
            <span style={{ fontSize: "14px" }}>{item.assetMapLabel}</span>
          )}
          {item.checkIns.length > 0 &&
            item.checkIns.map((checkin, index) => (
              <div
                style={{
                  fontSize: "12px",
                }}
              >
                {checkin.user?.name} {checkin.user?.surname}{" "}
                <Moment utc local format="h:mm A">
                  {moment(checkin.checkInDate)}
                  {/* {moment(checkin.checkInDate).add(5, "h")} */}
                </Moment>{" "}
                -{" "}
                <Moment utc local format="h:mm A">
                  {moment(checkin.checkOutDue)}
                  {/* {moment(checkin.checkOutDue).add(5, "h")} */}
                </Moment>
                {checkin.userId === currentUserId && (
                  <span
                    style={{
                      marginLeft: "5px",
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={async (e) => {
                      const isToday = moment(date).isSame(moment(), "day");
                      if (isToday) {
                        if (this.state.showReleaseIndex === index)
                          this.setState({ showReleaseIndex: null });
                        else this.setState({ showReleaseIndex: index });
                      } else {
                        await CheckInsClient.ReleaseCheckIn(
                          checkin.id,
                          null,
                          true
                        );
                        refreshAssets();
                      }
                    }}
                  >
                    X
                  </span>
                )}
                {this.state.showReleaseIndex === index &&
                  releaseReasons.map((reason, reasonIndex) => {
                    const releaseCheckIn = async () => {
                      this.setState({
                        releaseLoadingIndex: reasonIndex,
                        selectedRealeaseId: reason.id,
                      });
                      await CheckInsClient.ReleaseCheckIn(
                        checkin.id,
                        reason.id
                      );
                      await new Promise((resolve) => setTimeout(resolve, 1000));
                      this.setState({
                        releaseLoadingIndex: null,
                        showReleaseIndex: null,
                        selectedRealeaseId: this.state.defaultRealeaseId,
                        showBookingDetails: false,
                      });
                      refreshAssets();
                    };
                    return (
                      <div style={{ width: "100%", marginBottom: "5px" }}>
                        <div
                          style={{
                            borderRadius: "5px",
                            cursor: "pointer",
                            padding: "3px 5px",
                            fontSize: "12px",
                            height: "20px",
                            textAlign: "center",
                            display:
                              this.state.releaseLoadingIndex === reasonIndex
                                ? "flex"
                                : "block",
                            justifyContent: "center",
                            color:
                              reason.id === this.state.selectedRealeaseId
                                ? "white"
                                : "gray",
                            backgroundColor:
                              reason.id === this.state.selectedRealeaseId
                                ? "#3E97FF"
                                : "#F1F1F2",
                          }}
                          className="releaseBtn"
                          onClick={async () => {
                            releaseCheckIn();
                          }}
                        >
                          {this.state.releaseLoadingIndex === reasonIndex && (
                            <Spinner size={SpinnerSize.small} />
                          )}
                          {reason.name}
                        </div>
                      </div>
                    );
                  })}
              </div>
            ))}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <div></div>
            <DefaultButton
              text="Book"
              allowDisabledFocus
              style={{
                borderRadius: "5px",
                fontSize: "12px",
                height: "25px",
              }}
              onClick={() => {
                this.setState({ selectedData: item }, () =>
                  this.setState({ isModalOpen: true })
                );
              }}
            ></DefaultButton>
          </div>
          {filteredAllocations && filteredAllocations.length > 0 && (
            <div style={{ display: "flex", marginTop: "10px" }}>
              {filteredAllocations.map((allocation) => (
                <div
                  style={{
                    fontSize: "10px",
                    color: "white",
                    borderRadius: "5px",
                    background: allocation.color,
                    padding: "3px",
                    marginBottom: "5px",
                    marginRight: "3px",
                  }}
                >
                  {allocation.name}
                </div>
              ))}
            </div>
          )}
        </>
      );
    };

    const BookingModal = () => {
      const item = this.state.selectedData;
      const { refreshAssets } = this.props;
      const filteredAllocations =
        item.allocations && item.allocations.length > 0
          ? item.allocations.filter((i) => i.description !== "Reserved")
          : [];

      const { date } = this.props;

      var dateUpdated = moment(date).format("DD-MM-YYYY");
      var todayFormatted = moment().format("DD-MM-YYYY");

      return (
        <Modal
          // titleAriaId={titleId}
          isOpen={this.state.isModalOpen}
          onDismiss={() => {
            this.setState({ isModalOpen: false });
          }}
          isBlocking={false}
          styles={{
            main: {
              borderRadius: "5px",
              minHeight: "100px",
            },
          }}
          // containerClassName={contentStyles.container}
          // dragOptions={isDraggable ? dragOptions : undefined}
        >
          <div style={{ padding: "10px" }}>
            <span style={{ fontSize: "14px" }}>{item?.assetMapLabel}</span>
            {item &&
              item?.checkIns?.length > 0 &&
              item?.checkIns?.map((checkin) => (
                <div
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {checkin.user?.name} {checkin.user?.surname}{" "}
                  <Moment utc local format="h:mm">
                    {moment(checkin.checkInDate)}
                    {/* {moment(checkin.checkInDate).add(5, "h")} */}
                  </Moment>{" "}
                  -{" "}
                  <Moment utc local format="h:mm A">
                    {moment(checkin.checkOutDue)}
                    {/* {moment(checkin.checkOutDue).add(5, "h")} */}
                  </Moment>
                </div>
              ))}
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <p style={{ fontSize: "10px", marginRight: "10px" }}>
                  Book from:
                </p>
                <HourSelector
                  onSelect={({ id, name }) => {
                    if (this.state.hour != id)
                      this.setState({ hour: id, hourValue: { id, name } });
                  }}
                  checkIns={item?.checkIns}
                  defaultValue={this.state.hourValue}
                  isToday={dateUpdated === todayFormatted ? true : false}
                />
                <span style={{ marginRight: "10px" }}></span>
                <DurationSelector
                  onSelect={({ id, name }) => {
                    if (this.state.duration != id)
                      this.setState({
                        duration: id,
                        durationValue: { id, name },
                      });
                  }}
                  defaultValue={this.state.durationValue}
                />{" "}
              </div>

              {this.state.errorMessage && (
                <p style={{ margin: "10px 0", color: "red", fontSize: "10px" }}>
                  {this.state.errorMessage}
                </p>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <div></div>
                <DefaultButton
                  text="Book"
                  onClick={async () => {
                    const { duration, hour } = this.state;
                    const { date } = this.props;

                    var hr = parseInt(hour.split(":")[0]);
                    var mins = hour.split(":")[1];

                    var tz = moment.tz.guess();
                    const start = moment.tz(date, tz); // original timezone

                    const local = start
                      .tz(tz)
                      .format("YYYY-MM-DD[T]HH:mm:ss.SSS");

                    var withTimeUtc = moment(local)
                      .utc()
                      .add(hr, "h")
                      .add(mins, "minutes")
                      .format("YYYY-MM-DD[T]HH:mm:ss.SSS");

                    var response = await WorkDaysClient.CheckIn(
                      item.id,
                      duration,
                      [withTimeUtc]
                    );

                    if (response.data.length > 0) {
                      var failed = _.filter(response.data, ["success", false]);

                      if (failed.length > 0) {
                        this.setState({ errorMessage: failed[0].reason });
                      } else {
                        // this.setState({ loading: true });
                        this.setState(
                          { errorMessage: "", isModalOpen: false },
                          () => {
                            refreshAssets();
                          }
                        );
                      }
                    }
                  }}
                  allowDisabledFocus
                  style={{
                    borderRadius: "5px",
                    fontSize: "12px",
                    height: "25px",
                  }}
                />
              </div>
              {filteredAllocations && filteredAllocations.length > 0 && (
                <div style={{ display: "flex" }}>
                  {filteredAllocations.map((allocation) => (
                    <div
                      style={{
                        fontSize: "10px",
                        color: "white",
                        borderRadius: "5px",
                        background: allocation.color,
                        padding: "3px",
                        marginBottom: "5px",
                        marginRight: "3px",
                      }}
                    >
                      {allocation.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </Modal>
      );
    };

    return (
      <>
        <BookingModal />
        {loading && (
          <div style={{ width: "100%", margin: "20px 0px" }}>
            <Shimmer style={{ padding: "5px" }} />
            <Shimmer style={{ padding: "5px" }} width="90%" />
            <Shimmer style={{ padding: "5px" }} width="80%" />
            <Shimmer style={{ padding: "5px" }} width="70%" />
            <Shimmer style={{ padding: "5px" }} width="60%" />
            <Shimmer style={{ padding: "5px" }} width="50%" />
            <Shimmer style={{ padding: "5px" }} width="40%" />
          </div>
        )}

        {!loading && bounds && (
          <div
            style={{
              height: "calc(100% - 35px)",
              margin: "20px",
              background: "white",
              position: "relative",
            }}
          >
            <MapContainer
              center={initialPosition}
              zoom={this.state.zoom}
              // maxBoundsViscosity={1}
              crs={L.CRS.Simple}
              doubleClickZoom
              style={{
                height: "100%",
                cursor: "pointer",
                background: "white",
              }}
              // bounds={bounds}
              scrollWheelZoom
              attributionControl={false}
              whenCreated={(map) => {
                this.handleMapCreated(map);

                this.setState({ map }, () => {
                  const legend = L.control({ position: "topright" });

                  legend.onAdd = () => {
                    const div = L.DomUtil.create("div", "info legend");
                    div.innerHTML =
                      `<div style="display: flex; margin-right: 20px; align-items: center;">
                        <div
                          style="width: 15px; height: 15px; align-self: center; border-radius:10px; background-color:` +
                      availableColor +
                      `; margin-right:10px"></div>
                        <div>Available</div>
                      </div>` +
                      `<div style="display: flex; margin-right: 20px; align-items: center;">
                        <div
                          style="width: 15px; height: 15px; align-self: center; border-radius:10px; background-color:` +
                      reservedColor +
                      `; margin-right:10px"></div>
                        <div>Reserved</div>
                      </div>` +
                      `<div style="display: flex; margin-right: 20px; align-items: center;">
                        <div
                          style="width: 15px; height: 15px; align-self: center; border-radius:10px; background-color:` +
                      inUseColor +
                      `; margin-right:10px"></div>
                        <div>In Use</div>
                      </div>` +
                      `<div style="display: flex; margin-right: 20px; align-items: center;">
                        <div
                          style="width: 15px; height: 15px; align-self: center; border-radius:10px; background-color:` +
                      bookedColor +
                      `; margin-right:10px"></div>
                        <div>Booked</div>
                      </div>` +
                      `<div style="display: flex; margin-right: 20px; align-items: center;">
                        <div
                          style="width: 15px; height: 15px; align-self: center; border-radius:10px; background-color:` +
                      itemColor +
                      `; margin-right:10px"></div>
                        <div>Item</div>
                      </div>`;
                    return div;
                  };

                  legend.addTo(this.state.map);
                });
              }}
            >
              <ImageOverlay url={photoUrl} bounds={bounds} />
              {assets &&
                assets.map((item) => {
                  const { date } = this.props;
                  const { zoom, circleWeight, circleFontSize } = this.state;
                  var isCurrent = false;
                  var isReserved = false;
                  var isTeamMember = false;
                  var isBlocked = false;
                  if (item.blockStartDate && item.blockEndDate) {
                    var compareDate = moment(date).format("YYYY-MM-DD");
                    var startDate = moment(item.blockStartDate).format(
                      "YYYY-MM-DD"
                    );
                    var endDate = moment(item.blockEndDate).format(
                      "YYYY-MM-DD"
                    );

                    var isBetween =
                      moment(compareDate).isBetween(
                        startDate,
                        endDate,
                        "day"
                      ) ||
                      moment(compareDate).isSame(startDate, "day") ||
                      moment(compareDate).isSame(endDate, "day");
                    if (isBetween) isBlocked = true;
                  }

                  const filteredAllocations =
                    item.allocations && item.allocations.length > 0
                      ? item.allocations.filter(
                          (i) => i.description !== "Reserved"
                        )
                      : [];

                  if (item.checkIns.length > 0) {
                    var start = moment(item.checkIns[0].checkInDate).format(
                      "hh:mm A"
                    );
                    var end = moment(item.checkIns[0].checkOutDue).format(
                      "hh:mm A"
                    );

                    if (moment().isBetween(start, end)) {
                      isCurrent = true;
                    }

                    if (showTeamMembers) {
                      var checkInUserIds = item.checkIns.map((x) => x.userId);
                      var match = _.intersection(teamMemberIds, checkInUserIds);

                      if (match && match.length > 0) {
                        isTeamMember = true;
                      }
                    }
                  }

                  if (item.reservedUserId) {
                    isReserved = true;
                  }

                  const reservedAllocation = item.allocations.filter(
                    (i) => i.description === "Reserved"
                  )[0];

                  if (!reservedAllocation) isReserved = false;

                  if (isBlocked) {
                    return (
                      <Circle
                        center={[item.latitude, item.longitude]}
                        radius={0.3}
                        pathOptions={{
                          color: "#EF6D6C", // Border color
                          weight: circleWeight, // Border thickness
                          fillColor: "white", // Fill color
                          fillOpacity: 1, // Fill opacity
                        }}
                      >
                        {zoom >= 5 && (
                          <Tooltip
                            direction="center"
                            offset={[0, 0]}
                            opacity={1}
                            permanent
                            className="tooltip-circle-label"
                          >
                            <span style={{ fontSize: circleFontSize }}>
                              {item.assetMapLabel}
                            </span>
                          </Tooltip>
                        )}
                        <Popup>
                          <div style={{ fontSize: "14px", color: "black" }}>
                            {item.assetMapLabel}
                          </div>
                          <div
                            style={{
                              fontSize: "10px",
                              color: "white",
                              borderRadius: "5px",
                              background: "#EF6D6C",
                              padding: "3px",
                              marginBottom: "5px",
                              marginRight: "3px",
                            }}
                          >
                            Blocked
                          </div>
                        </Popup>
                      </Circle>
                    );
                  }

                  if (item.latitude && item.longitude) {
                    if (isTeamMember) {
                      return (
                        <Circle
                          center={[item.latitude, item.longitude]}
                          radius={0.3}
                          pathOptions={{
                            color: teamHighlightColor, // Border color
                            weight: circleWeight, // Border thickness
                            fillColor: "white", // Fill color
                            fillOpacity: 1, // Fill opacity
                          }}
                          fillOpacity={1}
                        >
                          {zoom >= 5 && (
                            <Tooltip
                              direction="center"
                              offset={[0, 0]}
                              opacity={1}
                              permanent
                              className="tooltip-circle-label"
                            >
                              <span style={{ fontSize: circleFontSize }}>
                                {item.assetMapLabel}
                              </span>
                            </Tooltip>
                          )}
                          <Popup>
                            <PopOverDetails item={item} />
                          </Popup>
                        </Circle>
                      );
                    }

                    if (isReserved && item.checkIns.length <= 0) {
                      return (
                        <Circle
                          center={[item.latitude, item.longitude]}
                          radius={0.3}
                          pathOptions={{
                            color: reservedColor, // Border color
                            weight: circleWeight, // Border thickness
                            fillColor: "white", // Fill color
                            fillOpacity: 1, // Fill opacity
                          }}
                          fillOpacity={1}
                        >
                          {zoom >= 5 && (
                            <Tooltip
                              direction="center"
                              offset={[0, 0]}
                              opacity={1}
                              permanent
                              className="tooltip-circle-label"
                            >
                              <span style={{ fontSize: circleFontSize }}>
                                {item.assetMapLabel}
                              </span>
                            </Tooltip>
                          )}
                          <Popup>
                            <div style={{ fontSize: "14px", color: "black" }}>
                              {item.assetMapLabel}
                            </div>
                            <div
                              style={{
                                fontSize: "10px",
                                color: "black",
                                borderRadius: "5px",
                                background: reservedColor, //item.allocations[0].color,
                                padding: "3px",
                                marginTop: "10px",
                              }}
                            >
                              Reserved {item.reservedUser?.name}{" "}
                              {item.reservedUser?.surname}
                              {/* {item.allocations[0].name} */}
                            </div>
                            {item.reservedUserId == userId && (
                              <PopOverDetails item={item} hideLabel />
                            )}
                            {item.reservedUserId != userId &&
                              filteredAllocations &&
                              filteredAllocations.length > 0 && (
                                <div
                                  style={{ display: "flex", marginTop: "3px" }}
                                >
                                  {filteredAllocations.map((allocation) => (
                                    <div
                                      style={{
                                        fontSize: "10px",
                                        color: "white",
                                        borderRadius: "5px",
                                        background: allocation.color,
                                        padding: "3px",
                                        marginBottom: "5px",
                                        marginRight: "3px",
                                      }}
                                    >
                                      {allocation.name}
                                    </div>
                                  ))}
                                </div>
                              )}
                          </Popup>
                        </Circle>
                      );
                    }

                    if (
                      item.assetType?.isBookable &&
                      item.checkIns.length <= 0
                    ) {
                      return (
                        <Circle
                          center={[item.latitude, item.longitude]}
                          radius={0.3}
                          pathOptions={{
                            color: availableColor, // Border color
                            weight: circleWeight, // Border thickness
                            fillColor: "white", // Fill color
                            fillOpacity: 1, // Fill opacity
                          }}
                          eventHandlers={{
                            click: () => {
                              this.setState({ selectedData: item }, () =>
                                this.setState({ isModalOpen: true })
                              );
                            },
                          }}
                        >
                          {zoom >= 5 && (
                            <Tooltip
                              direction="center"
                              offset={[0, 0]}
                              opacity={1}
                              permanent
                              className="tooltip-circle-label"
                            >
                              <span style={{ fontSize: circleFontSize }}>
                                {item.assetMapLabel}
                              </span>
                            </Tooltip>
                          )}
                        </Circle>
                      );
                    }

                    if (
                      item.assetType?.isBookable &&
                      item.checkIns.length > 0
                    ) {
                      return (
                        <Circle
                          center={[item.latitude, item.longitude]}
                          radius={0.3}
                          pathOptions={{
                            color: isCurrent ? inUseColor : bookedColor, // Border color
                            weight: circleWeight, // Border thickness
                            fillColor: "white", // Fill color
                            fillOpacity: 1, // Fill opacity
                          }}
                          eventHandlers={{
                            click: () => {
                              this.mapRef.current._popup._closeButton.addEventListener(
                                "click",
                                (event) => {
                                  event.preventDefault();
                                }
                              );
                            },
                          }}
                        >
                          {zoom >= 5 && (
                            <Tooltip
                              direction="center"
                              offset={[0, 0]}
                              opacity={1}
                              permanent
                              className="tooltip-circle-label"
                            >
                              <span style={{ fontSize: circleFontSize }}>
                                {item.assetMapLabel}
                              </span>
                            </Tooltip>
                          )}
                          <Popup closeOnClick={false}>
                            <PopOverDetails item={item} />
                          </Popup>
                        </Circle>
                      );
                    }

                    if (!item.assetType?.isBookable) {
                      return (
                        <Circle
                          center={[item.latitude, item.longitude]}
                          radius={0.3}
                          pathOptions={{
                            color: itemColor, // Border color
                            weight: circleWeight, // Border thickness
                            fillColor: "white", // Fill color
                            fillOpacity: 1, // Fill opacity
                          }}
                        >
                          {" "}
                          {zoom >= 5 && (
                            <Tooltip
                              direction="center"
                              offset={[0, 0]}
                              opacity={1}
                              permanent
                              className="tooltip-circle-label"
                            >
                              <span style={{ fontSize: circleFontSize }}>
                                {item.assetMapLabel}
                              </span>
                            </Tooltip>
                          )}
                          <Popup>
                            <span style={{ fontSize: "14px" }}>
                              {item.assetMapLabel}
                            </span>
                          </Popup>
                        </Circle>
                      );
                    }
                  } else {
                    return;
                  }
                })}
              {/* <Markers /> */}
              {/* <Legend map={this.state.map} /> */}
            </MapContainer>
          </div>
        )}
      </>
    );
  }
}
