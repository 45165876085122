import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import { Dropdown, Label, DefaultButton, PrimaryButton } from "@fluentui/react";
import {
  AssetTypesQueryParams,
  AssetTypesClient,
} from "../../infrastructure/api/AssetTypesClient";
import { ReactComponent as CheckInLogo } from "../../assets/svg/grid.svg";

export class AssetTypeColumnSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeStatus: "",
      loading: false,
      selectedData: null,
      data: [],
      siteId: null,
    };
  }

  componentDidMount() {}

  async update() {
    await this.populateData();
  }

  componentDidUpdate() {
    const { siteId, selectedData } = this.props;
    if (siteId !== this.state.siteId) {
      this.populateData();
    }

    if (this.state.selectedData?.id !== selectedData?.id) {
      this.setState({ selectedData: selectedData });
    }
  }

  classNames = mergeStyleSets({
    dropdown: { borderRadius: "10px" },
  });

  render() {
    const { onSelect, enabledValues } = this.props;
    const { selectedData } = this.state;
    return (
      <>
        {this.state.data &&
          this.state.data.map((item) => {
            const disabled =
              !enabledValues ||
              enabledValues.length === 0 ||
              !enabledValues.includes(item.id)
                ? true
                : false;
            return selectedData && selectedData?.id === item.id ? (
              <div
                style={{
                  marginBottom: "5px",
                  // marginRight: "20px",
                  // flexGrow: 1,
                  width: "290px",
                }}
              >
                <PrimaryButton
                  text={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* <CheckInLogo
                        fill={disabled ? "#c2c2c2" : "#0f0f0f"}
                        stroke={disabled ? "#c2c2c2" : "#0f0f0f"}
                        height="20"
                        width="20"
                      />{" "} */}
                      <p style={{ fontWeight: "500" }}>{item.name}</p>
                    </div>
                  }
                  onClick={() => {
                    this.setState({ selectedData: item });
                    onSelect(item);
                  }}
                  allowDisabledFocus
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                  }}
                  disabled={disabled}
                />
              </div>
            ) : (
              <div
                style={{
                  marginBottom: "5px",
                  // marginRight: "20px",
                  // flexGrow: 1,
                  width: "290px",
                }}
              >
                <DefaultButton
                  text={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* <CheckInLogo
                        fill={disabled ? "#c2c2c2" : "#0f0f0f"}
                        stroke={disabled ? "#c2c2c2" : "#0f0f0f"}
                        height="20"
                        width="20"
                      />{" "} */}
                      <p style={{ fontWeight: "500" }}>{item.name}</p>
                    </div>
                  }
                  onClick={() => {
                    this.setState({ selectedData: item });
                    onSelect(item);
                  }}
                  allowDisabledFocus
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                  }}
                  disabled={disabled}
                />
              </div>
            );
          })}
        {/* <Dropdown
          placeholder="Select an asset type"
          selectedKey={
            this.state.selectedData ? this.state.selectedData.id : null
          }
          options={this.state.data.map((item) => {
            return {
              key: item.id,
              text: item.name,
            };
          })}
          onChanged={(item) =>
            this.setState({
              selectedData: {
                id: item.key,
                name: item.text,
              },
            })
          }
          className={this.classNames.dropdown}
        /> */}
      </>
    );
  }

  async populateData() {
    const {
      onSelect,
      isBookableOnly,
      siteId,
      ignoreUserType,
      populateDefaultAttributes,
    } = this.props;
    const { selectedData } = this.state;

    var query = new AssetTypesQueryParams();

    query.Sort("name", "asc");

    query.WithParam("ignoreUserType", true);

    if (siteId) {
      query.WithParam("siteId", siteId);
    }

    if (ignoreUserType) query.WithParam("ignoreUserType", true);

    var result = await AssetTypesClient.List(query);

    var data = result?.data?.data;

    // if (isBookableOnly && data && data.length > 0) {
    //   data = data.filter((x) => x.isBookable === true);
    // }
    this.setState({
      data: data,
      siteId: siteId,
    });

    if (data && data.length === 1) {
      this.setState({
        selectedData: data[0],
      });

      onSelect({
        id: data[0]?.id,
        name: data[0]?.name,
      });
    }

    if (populateDefaultAttributes && selectedData?.id) {
      var selected = data.filter((x) => x.id == selectedData?.id)[0];

      if (selected) {
        populateDefaultAttributes(selected?.attributes);
      }
    }
  }
}
