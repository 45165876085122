import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  ImageFit,
  Stack,
  Image,
  Dropdown,
  DefaultButton,
  ChoiceGroup,
  DatePicker,
  defaultDatePickerStrings,
  addDays,
  Panel,
  Dialog,
  DialogFooter,
  PrimaryButton,
  DialogType,
  DayOfWeek,
  Shimmer,
  ShimmerElementType,
  ShimmerElementsGroup,
  Label,
  Toggle,
} from "@fluentui/react";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import { Icon, FontIcon } from "@fluentui/react/lib/Icon";

import { UsersClient } from "../../infrastructure/api/UsersClient";
import {
  HomeClient,
  HomeQueryParams,
} from "../../infrastructure/api/HomeClient";
import {
  TeamsClient,
  TeamQueryParams,
} from "../../infrastructure/api/TeamsClient";
import { CardDoughnut } from "../../components/Charts/CardDoughnut";
import { ReactComponent as HomeLogo } from "../../assets/svg/home-location-filled.svg";
import { ReactComponent as WhatsOnLogo } from "../../assets/svg/calendar-clock-filled.svg";
import { ReactComponent as CheckInLogo } from "../../assets/svg/ticket.svg";
import { WeeklyPlannerActionSelector } from "../Common/WeeklyPlannerActionSelector/WeeklyPlannerActionSelector";
import {
  NotWorkingReasonClient,
  NotWorkingReasonQueryParams,
} from "../../infrastructure/api/NotWorkingReasonClient";
import {
  SiteQueryParams,
  SitesClient,
} from "../../infrastructure/api/SitesClient";
import { EventsTable } from "../Charts/EventsTable";
import { DayCard } from "../Common/DayCard/DayCard";
import _ from "lodash";
import { TeamMember } from "../Charts/TeamMember";
import moment from "moment";
import { getDayLocalizationLabel } from "../../helpers/localizationHelper";

export class Planner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeStatus: "",
      loading: true,
      currentDate: new Date(new Date().toDateString()),
      currentWeekStartDate: this.getMonday(new Date(new Date().toDateString())),
      isDatePickerOpen: false,
      teams: [],
      selectedTeam: null,
      userSummary: [],
      userSummaryWeekends: [],
      showWeekends: false,
      refreshPlanner: false,
      initialized: false,
      myDay: {
        date: new Date(new Date().toDateString()),
        parts: [
          {
            state: "workFromOffice",
          },
        ],
      },
      sites: [],
      showEvents: false,
      events: [],
      userId: null,
      indexRefreshing: null,
      percentWorking: 0,
      todayOnwardDates: [],
      cardsLoading: false,
      selectMode: false,
      teamMembers: [],
      teamMembersLoading: true,
    };
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
    await this.populateTeamMembers();
  }

  componentWillReceiveProps(props) {
    const { currentDate, currentWeekStartDate, selectedTeam, currentUser } =
      props;
    if (
      moment(this.state.currentDate).format("YYYY-MM-DD") !==
        moment(currentDate).format("YYYY-MM-DD") ||
      moment(this.state.currentWeekStartDate).format("YYYY-MM-DD") !==
        moment(currentWeekStartDate).format("YYYY-MM-DD")
    ) {
      this.setState(
        {
          currentDate: currentDate,
          currentWeekStartDate: currentWeekStartDate,
        },
        () => {
          this.populateData();
          this.populateTeamMembers();
        }
      );
    }

    if (selectedTeam?.id !== this.state.selectedTeam?.id) {
      this.setState(
        {
          selectedTeam: selectedTeam,
        },
        () => {
          this.populateData();
          this.populateTeamMembers();
        }
      );
    }
  }

  classNames = mergeStyleSets({
    container: {
      width: "100%",
      // background:
      //   "linear-gradient(170deg, rgba(0,0,0,0) 0%, rgba(214,237,241,1) 85%, rgba(221,244,248,1) 100%)",
      overflow: "hidden",
    },
    cardShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)" },
        },
      },
    ],
    buttonShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)" },
        },
      },
    ],
  });

  render() {
    var stackTokens = {
      childrenGap: 20,
      padding: 20,
    };
    const iconClass = mergeStyles({
      fontSize: 50,
      height: 50,
      width: 50,
      margin: "0 25px",
    });
    var onSiteColor = localStorage.getItem("theme_OnSite");
    var homeColor = localStorage.getItem("theme_Home");
    var noResponseColor = localStorage.getItem("theme_NoResponse");
    var notWorkingColor = localStorage.getItem("theme_NotWorking");
    var whatsOnHolidayColor = localStorage.getItem("theme_WhatsOnHoliday");
    var whatsOnEventColor = localStorage.getItem("theme_WhatsOnEvent");

    var onSiteLabel = localStorage.getItem("localization_OnSite");
    var notWorkingLabel = localStorage.getItem("localization_NotWorking");
    var homeLabel = localStorage.getItem("localization_Home");

    const { goToAssets } = this.props;
    return (
      <>
        <div className={this.classNames.container}>
          <>
            <div
              style={{
                margin: "50px 20px 20px 20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {!this.props.isMobile && (
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "17px",
                  }}
                >
                  {getDayLocalizationLabel(
                    moment(this.state.currentDate).format("dddd")
                  )}
                  , {this.state.percentWorking}% of your team are planning to be
                  on site!
                </div>
              )}
              <div>
                <Toggle
                  label="Weekend"
                  checked={this.state.showWeekends}
                  onText="Hide"
                  offText="Show"
                  onChange={(ev, checked) => {
                    var dates = checked
                      ? this.getTodayOnwardsDates(
                          this.state.userSummaryWeekends
                        )
                      : this.getTodayOnwardsDates(this.state.userSummary);
                    this.setState({
                      showWeekends: !this.state.showWeekends,
                      todayOnwardDates: dates,
                    });
                  }}
                />
              </div>
            </div>
            <Stack
              horizontal
              tokens={{
                padding: 0,
              }}
              horizontalAlign={this.state.showWeekends ? "start" : "start"}
              style={{ marginLeft: "20px" }}
            >
              <WeeklyPlannerActionSelector
                currentUser={this.state.user}
                sites={this.state.sites}
                notWorkingReasons={this.state.notWorkingReasons}
                showWeekends={this.state.showWeekends}
                todayOnwardDates={this.state.todayOnwardDates}
                setCardsLoading={(loading) => {
                  if (loading) this.setState({ cardsLoading: loading });
                }}
                refreshPlanner={() => {
                  this.populateData(true);
                }}
                selectMode={(isSelectMode) => {
                  this.setState({ selectMode: isSelectMode });
                }}
              />
            </Stack>
            {this.state.cardsLoading || this.state.loading ? (
              <Stack style={{ width: "100%", margin: "20px 0px" }}>
                <Shimmer style={{ padding: "5px 20px" }} />
                <Shimmer style={{ padding: "5px 20px" }} width="90%" />
                <Shimmer style={{ padding: "5px 20px" }} width="80%" />
                <Shimmer style={{ padding: "5px 20px" }} width="70%" />
                <Shimmer style={{ padding: "5px 20px" }} width="60%" />
                <Shimmer style={{ padding: "5px 20px" }} width="50%" />
                <Shimmer style={{ padding: "5px 20px" }} width="40%" />
              </Stack>
            ) : (
              <div style={{ margin: "10px" }}>
                <Stack
                  horizontal
                  tokens={stackTokens}
                  horizontalAlign={"start"}
                  // horizontal
                  // tokens={stackTokens}
                  // horizontalAlign={"start"}
                  // verticalFill
                  // verticalAlign={"start"}
                  wrap
                >
                  {!this.state.showWeekends &&
                    this.state.userSummary &&
                    this.state.userSummary.map((day, index) => {
                      return (
                        <DayCard
                          day={day}
                          selectedTeam={this.state.selectedTeam}
                          sites={this.state.sites}
                          user={this.state.user}
                          notWorkingReasons={this.state.notWorkingReasons}
                          goToAssets={() => {
                            goToAssets();
                          }}
                          index={index}
                          selectMode={this.state.selectMode}
                          onCheck={(date) => {
                            var findDate = _.find(
                              this.state.todayOnwardDates,
                              function (o) {
                                return o === date;
                              }
                            );

                            if (findDate) {
                              var updatedDates = _.remove(
                                this.state.todayOnwardDates,
                                function (n) {
                                  return n !== date;
                                }
                              );

                              this.setState({
                                todayOnwardDates: updatedDates,
                              });
                            } else {
                              this.setState({
                                todayOnwardDates: [
                                  ...this.state.todayOnwardDates,
                                  date,
                                ],
                              });
                            }
                          }}
                          todayOnwardDates={this.state.todayOnwardDates}
                          currentWeekStartDate={this.getMonday(
                            this.state.currentDate
                          )}
                          allDates={this.state.allDates}
                          refreshPlanner={() => {
                            this.populateData(true);
                          }}
                          refreshTeamMembers={() => {
                            this.populateTeamMembers();
                          }}
                        />
                      );
                    })}
                  {this.state.showWeekends &&
                    this.state.userSummaryWeekends &&
                    this.state.userSummaryWeekends.map((day, index) => {
                      return (
                        <DayCard
                          day={day}
                          selectedTeam={this.state.selectedTeam}
                          sites={this.state.sites}
                          user={this.state.user}
                          notWorkingReasons={this.state.notWorkingReasons}
                          goToAssets={() => {
                            goToAssets();
                          }}
                          index={index}
                          selectMode={this.state.selectMode}
                          onCheck={(date) => {
                            var findDate = _.find(
                              this.state.todayOnwardDates,
                              function (o) {
                                return o === date;
                              }
                            );

                            if (findDate) {
                              var updatedDates = _.remove(
                                this.state.todayOnwardDates,
                                function (n) {
                                  return n !== date;
                                }
                              );

                              this.setState({
                                todayOnwardDates: updatedDates,
                              });
                            } else {
                              this.setState({
                                todayOnwardDates: [
                                  ...this.state.todayOnwardDates,
                                  date,
                                ],
                              });
                            }
                          }}
                          todayOnwardDates={this.state.todayOnwardDates}
                          currentWeekStartDate={this.getMonday(
                            this.state.currentDate
                          )}
                          allDates={this.state.allDates}
                          refreshPlanner={() => {
                            this.populateData(true);
                          }}
                          refreshTeamMembers={() => {
                            this.populateTeamMembers();
                          }}
                        />
                      );
                    })}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                      width: "100%",
                    }}
                  >
                    <div style={{ display: "flex", marginRight: "20px" }}>
                      <div
                        style={{
                          width: "15px",
                          height: "15px",
                          alignSelf: "center",
                          borderRadius: "10px",
                          backgroundColor: `${onSiteColor}`,
                          marginRight: "20px",
                        }}
                      ></div>
                      <div>{onSiteLabel}</div>
                    </div>
                    <div style={{ display: "flex", marginRight: "20px" }}>
                      <div
                        style={{
                          width: "15px",
                          height: "15px",
                          alignSelf: "center",
                          borderRadius: "10px",
                          backgroundColor: `${homeColor}`,
                          marginRight: "20px",
                        }}
                      ></div>
                      <div>{homeLabel}</div>
                    </div>
                    <div style={{ display: "flex", marginRight: "20px" }}>
                      <div
                        style={{
                          width: "15px",
                          height: "15px",
                          alignSelf: "center",
                          borderRadius: "10px",
                          backgroundColor: `${notWorkingColor}`,
                          marginRight: "20px",
                        }}
                      ></div>
                      <div>{notWorkingLabel}</div>
                    </div>
                  </div>
                </Stack>
              </div>
            )}{" "}
          </>
          <div
            style={{
              border: "1px #c2c2c2 solid",
              borderRadius: "5px",
              margin: "20px",
            }}
          >
            <div
              style={{
                margin: "10px 20px",
                fontWeight: "bold",
                fontSize: "17px",
              }}
            >
              Team Members
            </div>
            {this.state.teamMembersLoading ? (
              <div style={{ width: "100%" }}>
                <Shimmer style={{ padding: "5px 20px" }} />
                <Shimmer style={{ padding: "5px 20px" }} width="90%" />
                <Shimmer style={{ padding: "5px 20px" }} width="80%" />
                <Shimmer style={{ padding: "5px 20px" }} width="70%" />
                <Shimmer style={{ padding: "5px 20px" }} width="60%" />
              </div>
            ) : (
              <Stack
                horizontal
                tokens={{
                  childrenGap: 5,
                  padding: 10,
                }}
                verticalFill
                horizontalAlign="start"
                verticalAlign={"start"}
                wrap
                style={{ overflow: "scroll" }}
              >
                {this.state.teamMembers.map((item) => {
                  return (
                    <div style={{ minWidth: "380px" }}>
                      <TeamMember
                        item={item}
                        currentWeekStartDate={this.state.currentWeekStartDate}
                      />
                    </div>
                  );
                })}
              </Stack>
            )}
          </div>
        </div>

        {this.renderDatePicker()}
        {this.renderWhatsOnPanel()}
      </>
    );
  }

  renderDatePicker() {
    return (
      <Dialog
        type={DialogType.normal}
        title="Choose date"
        hidden={!this.state.isDatePickerOpen}
        onDismiss={() => this.setState({ isDatePickerOpen: false })}
      >
        <DatePicker
          firstDayOfWeek={DayOfWeek.Monday}
          id="currentDatePicker2"
          minDate={new Date()}
          value={this.state.currentDate}
          onSelectDate={(date) =>
            this.setState(
              {
                currentDate: date,
                currentWeekStartDate: this.getMonday(date),
                isDatePickerOpen: false,
              },
              () => {
                this.populateData();
                this.populateTeamMembers();
              }
            )
          }
          placeholder="Select a date..."
          ariaLabel="Select a date"
          // DatePicker uses English strings by default. For localized apps, you must override this prop.
          strings={defaultDatePickerStrings}
        />

        <DialogFooter>
          <DefaultButton
            onClick={(ev) => {
              this.setState({ isDatePickerOpen: false });
              ev.preventDefault();
            }}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
    );
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  async populateTeamMembers() {
    const { currentWeekStartDate } = this.props;
    this.setState({ teamMembersLoading: true }, async () => {
      if (this.state.selectedTeam?.id) {
        var teamSummary = await HomeClient.TeamMemberView(
          new HomeQueryParams()
            .WithTeamId(this.state.selectedTeam.id)
            .WithNumberOfDays(7)
            .WithParam("startDate", currentWeekStartDate)
        );

        this.setState({
          teamMembersLoading: false,
          teamMembers: teamSummary.data,
        });
      } else
        this.setState({
          teamMembersLoading: false,
        });
    });
    // var currentDateData = this.getMyDayForDate(teamSummary.data);
  }

  async populateData(softLoad = false, cardsLoading = false) {
    const { currentWeekStartDate } = this.props;
    if (!softLoad) this.setState({ loading: true });
    if (cardsLoading) this.setState({ cardsLoading: true });
    await this.populateStaticData();
    const { currentUser } = this.props;

    if (currentUser && currentUser.site) {
      var siteId = currentUser.site.id;

      this.setState({
        siteId: siteId,
        userId: currentUser.id,
        user: currentUser,
        selectedSite: currentUser?.site,
      });

      if (currentUser.teams && currentUser.teams.length > 0) {
        var teamId = currentUser.teams[0].id;
        const { selectedTeam: propsTeam } = this.props;

        if (!this.state.selectedTeam) {
          this.setState(
            {
              teams: currentUser.teams,
              selectedTeam: {
                id: !_.isEmpty(propsTeam)
                  ? propsTeam.id
                  : currentUser.teams[0].id,
                name: !_.isEmpty(propsTeam)
                  ? propsTeam.name
                  : currentUser.teams[0].name,
              },
            },
            () => this.populateTeamMembers()
          );
        } else {
          this.populateTeamMembers();
        }

        if (!_.isEmpty(propsTeam)) teamId = propsTeam.id;

        //Get currentUser MyDay
        var userSummary = await HomeClient.DayView(
          new HomeQueryParams()
            .WithSiteId(siteId)
            .WithTeamId(teamId)
            .WithUserId(currentUser.id)
            .WithNumberOfDays(7)
            .WithParam("startDate", currentWeekStartDate)
        );

        var slicedDate = userSummary.data.slice(0, 5);
        var slicedDateWeekends = userSummary.data.slice(5, 7);

        var dates = this.state.showWeekends
          ? this.getTodayOnwardsDates(slicedDateWeekends)
          : this.getTodayOnwardsDates(slicedDate);

        var allDates = this.state.showWeekends
          ? slicedDateWeekends.map((x) => x.date)
          : slicedDate.map((x) => x.date);

        this.setState(
          {
            allDates: allDates,
            todayOnwardDates: dates,
            userSummary: slicedDate,
            userSummaryWeekends: slicedDateWeekends,
            myDay: this.getMyDayForDate(
              userSummary.data,
              this.state.currentDate
            ),
            todaysChart: this.getChartForDate(
              userSummary.data,
              this.state.currentDate
            ),
            initComplete: true,
            loading: false,
            indexRefreshing: null,
            cardsLoading: false,
          },
          () => {
            const { todaysChart } = this.state;

            if (todaysChart) {
              var percentWorking =
                todaysChart.workingOnSite > 0
                  ? Math.round(
                      (todaysChart.workingOnSite / todaysChart.total) * 100
                    )
                  : 0;

              this.setState({ percentWorking: percentWorking });
            }
          }
        );
      } else {
        this.setState({
          loading: false,
          initialized: true,
          indexRefreshing: null,
          selectMode: false,
        });
      }
    } else {
      this.setState({
        loading: false,
        initialized: true,
        indexRefreshing: null,
        selectMode: false,
      });
    }
  }

  async populateStaticData() {
    var notWorkingReasons = await NotWorkingReasonClient.List(
      new NotWorkingReasonQueryParams()
    );
    this.setState({ notWorkingReasons: notWorkingReasons.data.data });

    var sites = await SitesClient.List(
      new SiteQueryParams().WithStatus("active")
    );

    this.setState({ sites: sites.data.data });
  }
  forwardOneWeek() {
    var newDate = addDays(this.state.currentDate, 7);
    var newWeekStartDate = this.getMonday(newDate);

    if (
      newWeekStartDate.getTime() < new Date().getTime() &&
      this.state.currentWeekStartDate.getTime() > new Date().getTime()
    ) {
      this.setState(
        {
          currentDate: new Date(new Date().toDateString()),
          currentWeekStartDate: newWeekStartDate,
        },
        () => {
          this.populateData();
          this.populateTeamMembers();
        }
      ); //If current week, default to current
    } else {
      this.setState(
        {
          currentDate: newWeekStartDate,
          currentWeekStartDate: newWeekStartDate,
        },
        () => {
          this.populateData();
          this.populateTeamMembers();
        }
      ); //If other week, default to Monday
    }
  }

  backwardOneWeek() {
    var newDate = addDays(this.state.currentDate, -7);
    var newWeekStartDate = this.getMonday(newDate);

    if (
      newWeekStartDate.getTime() < new Date().getTime() &&
      this.state.currentWeekStartDate.getTime() > new Date().getTime()
    ) {
      this.setState(
        {
          currentDate: new Date(new Date().toDateString()),
          currentWeekStartDate: newWeekStartDate,
        },
        () => {
          this.populateData();
          this.populateTeamMembers();
        }
      ); //If current week, default to current
    } else {
      this.setState(
        {
          currentDate: newWeekStartDate,
          currentWeekStartDate: newWeekStartDate,
        },
        () => {
          this.populateData();
          this.populateTeamMembers();
        }
      ); //If other week, default to Monday
    }
  }

  getMyDayForDate(data, date) {
    var myDay = {
      date: this.state.currentDate,
      status: "planned",
      parts: [
        {
          state: "workFromOffice",
        },
      ],
    };

    if (data && data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        if (new Date(data[i].date).getTime() == date.getTime()) {
          if (data[i].users.length > 0 && data[i].users[0].myDay) {
            myDay = data[i].users[0].myDay;
          }
        }
      }
    }

    return myDay;
  }

  getChartForDate(data, date) {
    var chart = {};

    if (data && data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        if (new Date(data[i].date).getTime() == date.getTime()) {
          if (data[i].users.length > 0 && data[i].users[0].myDay) {
            chart = data[i].pieChart;
          }
        }
      }
    }

    return chart;
  }

  getTodayOnwardsDates(data) {
    var dates = [];
    var today = new Date(new Date().toDateString());

    if (data && data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        if (new Date(data[i].date).getTime() >= today.getTime()) {
          dates.push(data[i].date);
        }
      }
    }

    return dates;
  }

  renderWhatsOnPanel() {
    return (
      <Panel
        isLightDismiss
        onOuterClick={() => {}}
        isOpen={this.state.showEvents}
        onDismiss={() => this.setState({ showEvents: false })}
        closeButtonAriaLabel="Close"
        headerText="Events"
        type={3}
      >
        <EventsTable
          events={this.state.events}
          userId={this.state.userId}
          refreshEvents={() => {
            this.populateEvents();
          }}
        />
      </Panel>
    );
  }
}
