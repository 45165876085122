import React, { Component, useContext } from "react";
import { ReactComponent as DashboardLogo } from "../../assets/svg/apps.svg";
import { ReactComponent as PlannerLogo } from "../../assets/svg/calendar.svg";
import { ReactComponent as AssetLogo } from "../../assets/svg/grid.svg";
import { ReactComponent as WhatsOnLogo } from "../../assets/svg/calendar-clock.svg";
import { ReactComponent as UserProfileLogo } from "../../assets/svg/circle-user-alt.svg";
import MenuItemTypes from "../Enums/MenuItemTypes";

export class NavItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.update();
  }

  async update() {}

  render() {
    const { itemType, label, isSelected, onClick, isMobile } = this.props;

    var onSiteColor = localStorage.getItem("theme_OnSite");
    var homeColor = localStorage.getItem("theme_Home");
    var noResponseColor = localStorage.getItem("theme_NoResponse");
    var notWorkingColor = localStorage.getItem("theme_NotWorking");
    var whatsOnHolidayColor = localStorage.getItem("theme_WhatsOnHoliday");
    var whatsOnEventColor = localStorage.getItem("theme_WhatsOnEvent");

    const renderIcon = () => {
      switch (itemType) {
        case MenuItemTypes.Dashboard:
          return (
            <DashboardLogo
              fill={isSelected ? onSiteColor : "#BFBFBF"}
              stroke={isSelected ? onSiteColor : "#BFBFBF"}
              height="20"
              width="20"
            />
          );
        case MenuItemTypes.Planner:
          return (
            <PlannerLogo
              fill={isSelected ? onSiteColor : "#BFBFBF"}
              stroke={isSelected ? onSiteColor : "#BFBFBF"}
              height="20"
              width="20"
            />
          );
        case MenuItemTypes.Assets:
          return (
            <AssetLogo
              fill={isSelected ? onSiteColor : "#BFBFBF"}
              stroke={isSelected ? onSiteColor : "#BFBFBF"}
              height="20"
              width="20"
            />
          );
        case MenuItemTypes.WhatsOn:
          return (
            <WhatsOnLogo
              fill={isSelected ? onSiteColor : "#BFBFBF"}
              stroke={isSelected ? onSiteColor : "#BFBFBF"}
              height="20"
              width="20"
            />
          );
        case MenuItemTypes.UserProfile:
          return (
            <UserProfileLogo
              fill={isSelected ? onSiteColor : "#BFBFBF"}
              stroke={isSelected ? onSiteColor : "#BFBFBF"}
              height="20"
              width="20"
            />
          );
      }
    };

    return (
      <>
        <div
          style={{
            padding: "10px 0px",
            backgroundColor: "#FFFFFF",
          }}
          onClick={() => {
            onClick();
          }}
        >
          <div
            style={
              isSelected
                ? {
                    backgroundColor: "#FFFFFF",
                    borderLeft: `5px solid ${onSiteColor}`,
                    display: "flex",
                    padding: "10px 15px",
                    cursor: "pointer",
                  }
                : {
                    backgroundColor: "#FFFFFF",
                    display: "flex",
                    padding: "10px 20px",
                    cursor: "pointer",
                  }
            }
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {renderIcon()}
            </div>
            <div
              style={{
                paddingLeft: "20px",
              }}
            >
              <span
                style={
                  isSelected ? { color: onSiteColor } : { color: "#BFBFBF" }
                }
              >
                {!isMobile ? label : ""}
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}
