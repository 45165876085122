import React, { Component } from "react";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import { Icon } from "@fluentui/react/lib/Icon";
import {
  CheckInsClient,
  CheckInsQueryParams,
} from "../../infrastructure/api/CheckInsClient";
import moment from "moment";
import { Label, ActivityItem, List, Shimmer } from "@fluentui/react";
import Moment from "react-moment";
import { ReleaseBookAssetModal } from "../Modals/ReleaseBookAssetModal";

export class BookedAssetsLight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      total: 0,
      search: "",
      sort: "companyName",
      direction: "asc",
      showSuspended: false,
      status: "",
      refresh: false,
      modalVisible: false,
      selectedData: null,
    };
    this.onRenderCell = this.onRenderCell.bind(this);
  }

  componentDidMount() {
    this.update();
  }
  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.refresh !== this.state.refresh) {
      this.update();
    }
  }

  async update() {
    await this.populateData();
  }

  classNames = mergeStyleSets({
    container: {
      overflow: "auto",
      maxHeight: 1000,
      borderRadius: "10px",
    },
    itemCell: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: "border-box",
        borderBottom: `1px solid ${getTheme().semanticColors.bodyDivider}`,
        display: "flex",
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        transition: "0.3s",
        margin: 10,
        selectors: {
          "&:hover": {
            background: getTheme().palette.neutralLight,
            boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)",
          },
        },
      },
    ],
    itemImage: {
      flexShrink: 0,
    },
    itemContent: {
      marginLeft: 10,
      overflow: "hidden",
      flexGrow: 1,
    },
    itemName: [
      getTheme().fonts.xLarge,
      {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    ],
    itemIndex: {
      fontSize: getTheme().fonts.small.fontSize,
      color: getTheme().palette.neutralTertiary,
      marginBottom: 10,
    },
    chevron: {
      alignSelf: "center",
      marginLeft: 10,
      color: getTheme().palette.neutralTertiary,
      fontSize: getTheme().fonts.large.fontSize,
      flexShrink: 0,
    },
    contentClass: {
      backgroundColor: getTheme().palette.neutralLight,
      color: getTheme().palette.neutralDark,
      lineHeight: "50px",
      padding: "0 20px",
    },
  });

  render() {
    return (
      <>
        <ReleaseBookAssetModal
          modalVisible={this.state.modalVisible}
          selectedData={this.state.selectedData}
          onCancel={() => {
            this.setState({ modalVisible: false });
          }}
          onOk={() => {
            this.setState({ modalVisible: false });
            this.populateData();
          }}
        />
        <Label>Booked Assets</Label>
        {this.state.loading && (
          <div>
            <Shimmer style={{ padding: "5px 20px" }} />
            <Shimmer style={{ padding: "5px 20px" }} width="75%" />
            <Shimmer style={{ padding: "5px 20px" }} width="50%" />
          </div>
        )}
        {!this.state.loading &&
          this.state.data &&
          this.state.data.length > 0 && (
            <List items={this.state.data} onRenderCell={this.onRenderCell} />
          )}

        {!this.state.loading &&
          this.state.data &&
          this.state.data.length <= 0 && <p>No booked assets</p>}
      </>
    );
  }

  onRenderCell(item, index, isScrolling) {
    return (
      <div
        className={this.classNames.itemCell + " hover"}
        style={{
          backgroundColor: "white",
          borderRadius: "15px",
        }}
        onClick={() => {
          this.setState({ selectedData: item }, () => {
            this.setState({ modalVisible: true });
          });
        }}
      >
        <div className={this.classNames.itemContent}>
          <div className={this.classNames.itemName}>{item.asset?.name}</div>
          <div className={this.classNames.itemIndex}>
            <Moment utc local format="HH:mm">
              {item.checkInDate}
            </Moment>{" "}
            -
            <Moment utc local format="HH:mm">
              {item.checkOutDue}
            </Moment>
          </div>
        </div>
      </div>
    );
  }

  async populateData(skip, take) {
    const { selectedDate, userId } = this.props;

    this.setState({
      loading: true,
    });

    var response = await CheckInsClient.List(
      new CheckInsQueryParams()
        .WithStartDate(selectedDate)
        .WithEndDate(moment(selectedDate).add(1, "d").toISOString())
        .WithUserId(userId)
    );

    if (response.data) {
      const { data } = response.data;
      this.setState({
        data: data,
      });
    }

    this.setState({
      loading: false,
    });
  }
}
