import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  TooltipHost,
  Panel,
  PanelType,
  DefaultButton,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import {
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import _ from "lodash";
import { ReactComponent as Logo } from "../../../../assets/svg/building.svg";
import "./SiteOverview.css";
import { SiteSelector } from "../../../Selectors/SiteSelector";
import { UsersClient } from "../../../../infrastructure/api/UsersClient";
import {
  SiteQueryParams,
  SitesClient,
} from "../../../../infrastructure/api/SitesClient";
import { ReactComponent as AddLogo } from "../../../../assets/svg/add.svg";
import { ReactComponent as CheckLogo } from "../../../../assets/svg/check-circle.svg";

export class SiteOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      siteBtnLoading: false,
      siteBtnIndexLoading: null,
      currentDate: new Date(new Date().toDateString()),
      currentUser: null,
      site: {},
      showSidePanel: false,
      sites: [],
    };

    this.inputElementRef = React.createRef();
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
  }

  componentWillReceiveProps(props) {}

  classNames = mergeStyleSets({
    container: {
      width: "100%",
      overflow: "hidden",
    },
    cardShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)" },
        },
      },
    ],
    buttonShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)" },
        },
      },
    ],
    centerItem: {
      display: "flex",
      justifyContent: "center",
    },
  });

  renderPanel() {
    const { currentUser, reloadUser } = this.props;
    return (
      <Panel
        isLightDismiss={false}
        onOuterClick={() => {}}
        isOpen={this.state.showSidePanel}
        onDismiss={() => {
          this.setState({
            showSidePanel: false,
          });
        }}
        onOpen={() => {}}
        closeButtonAriaLabel="Close"
        headerText={
          <div>
            <span style={{ fontSize: "18px" }}>Set Default Site</span>
          </div>
        }
        type={PanelType.smallFixedFar}
        styles={{
          content: {
            background: "white",
          },
          contentInner: {
            background: "white",
          },
          header: {
            background: "white",
          },
          navigation: {
            background: "white",
          },
          commands: {
            background: "white",
          },
        }}
      >
        <div style={{ marginTop: "20px" }}></div>
        <div style={{ minWidth: "200px" }}>
          <div style={{ fontWeight: "bold", marginBottom: "10px" }}>Sites</div>{" "}
          {this.state.sites &&
            this.state.sites.length > 0 &&
            this.state.sites.map((site, index) => {
              return (
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "15px",
                    cursor: "default",
                    margin: "10px 0",
                  }}
                  onClick={() => {}}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ fontSize: "15px" }}>{site.name}</div>{" "}
                    {this.props.currentUser?.site?.id !== site?.id ? (
                      <div>
                        {this.state.siteBtnIndexLoading === index ? (
                          <Spinner size={SpinnerSize.medium} />
                        ) : (
                          <CheckLogo
                            height="20"
                            width="20"
                            style={{
                              overflow: "visible",
                              cursor: "pointer",
                            }}
                            stroke={"#8f8f8f"}
                            fill={"#8f8f8f"}
                            onClick={async () => {
                              this.setState({ siteBtnIndexLoading: index });
                              await UsersClient.UpdateDefaultSite(
                                currentUser.id,
                                site?.id
                              );

                              this.setState({
                                showSidePanel: false,
                                siteBtnIndexLoading: false,
                              });
                              reloadUser();
                            }}
                          />
                        )}
                      </div>
                    ) : (
                      <div>
                        <CheckLogo
                          height="20"
                          width="20"
                          style={{
                            overflow: "visible",
                            cursor: "default",
                          }}
                          stroke={"#00838f"}
                          fill={"#00838f"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </Panel>
    );
  }

  render() {
    return (
      <>
        {this.renderPanel()}
        <TooltipHost content="Click to set a default site">
          {this.props.currentUser?.site ? (
            <div
              style={{
                borderRadius: "10px",
                height: "120px",
                width: "120px",
                background: this.props.currentUser?.site?.color
                  ? this.props.currentUser?.site?.color
                  : "#00838f",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                cursor: "pointer",
              }}
              onClick={() => {
                this.setState({ showSidePanel: true });
              }}
            >
              <Logo
                height="60"
                width="60"
                style={{ overflow: "visible", padding: "10px 0" }}
                stroke={"white"}
                fill={"white"}
              />
              <b style={{ color: "white", fontSize: "12px" }}>
                {this.props.currentUser?.site?.name}
              </b>
            </div>
          ) : (
            <div
              style={{
                borderRadius: "10px",
                height: "120px",
                width: "120px",
                background: "#e2e2e2",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                cursor: "pointer",
              }}
              onClick={() => {
                this.setState({ showSidePanel: true });
              }}
            >
              <Logo
                height="60"
                width="60"
                style={{ overflow: "visible", padding: "10px 0" }}
                stroke={"white"}
                fill={"white"}
              />
              <b style={{ color: "white", fontSize: "12px" }}>No default set</b>
            </div>
          )}
        </TooltipHost>
      </>
    );
  }

  async populateData() {
    this.setState({ loading: true });
    const { currentUser, reloadPhoto } = this.props;

    var sites = await SitesClient.List(new SiteQueryParams().WithExpand(false));
    this.setState({
      sites: sites.data.data,
    });

    if (currentUser?.site) {
      this.setState({
        loading: false,
        site: currentUser?.site,
      });
    }

    this.setState({
      loading: false,
    });
  }
}
