import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import {
  EventsClient,
  EventsQueryParams,
} from "../../infrastructure/api/EventsClient";
import {
  FocusZone,
  List,
  DefaultButton,
  Shimmer,
  Modal,
  Spinner,
  SpinnerSize,
  Panel,
} from "@fluentui/react";
import { FontIcon } from "@fluentui/react/lib/Icon";
import { Icon } from "@fluentui/react/lib/Icon";
import Moment from "react-moment";
import { EventModal } from "../Modals/EventModal";
import { EventsTable } from "./EventsTable";
import { Badge } from "@fluentui/react-components";

export class EventsListLight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      eventModalVisible: false,
      selectedData: null,
      unattendIndexLoading: null,
      showEvents: false,
    };
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
  }

  modalStyles = mergeStyleSets({
    root: {
      width: "600px",
      height: "600px",
    },
    container: {},
  });

  iconClass = mergeStyles({
    fontSize: 12,
    height: 12,
    width: 12,
    cursor: "pointer",
    color: "red",
  });

  render() {
    const { selectedData, data } = this.state;
    const { events } = this.props;
    return (
      <div>
        {events && events.length > 0 && (
          <div
            style={{
              // border: "1px solid #5b5b5b",
              boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.05)",
              borderRadius: "10px",
              cursor: "pointer",
              margin: "10px",
              width: "auto",
            }}
            onMouseOver={() => {
              this.setState({ selectedData: events[0] });
            }}
            className="card-item"
            onClick={() => {
              this.setState({ showEvents: true });
            }}
          >
            <div>
              <div className="container">
                <h4>
                  <Moment utc local format="ddd DD MMM, YYYY">
                    {events[0].startDate}
                  </Moment>{" "}
                  -{" "}
                  <Moment utc local format="ddd DD MMM, YYYY">
                    {events[0].endDate}
                  </Moment>
                </h4>
                <h4>
                  <b style={{ cursor: "pointer" }}>{events[0].name}</b>
                  {events[0].isAttendee && (
                    <div
                      style={{
                        marginRight: "5px",
                        background: "#5B5FC7",
                        fontSize: "12px",
                        fontWeight: 600,
                        color: "white",
                        borderRadius: "50px",
                        padding: "4px",
                      }}
                    >
                      Attending
                    </div>
                  )}
                  {events[0].isAttendee && (
                    <>
                      <span
                        onClick={async () => {
                          this.setState({ unattendIndexLoading: 1 });
                          await EventsClient.RemoveAttendeeToEvent(
                            this.state.selectedData?.id,
                            this.props.userId
                          );
                          this.props.refreshEvents();
                          this.setState({ unattendIndexLoading: null });
                        }}
                      >
                        <FontIcon
                          aria-label="Cancel"
                          iconName="Cancel"
                          className={this.iconClass}
                        />
                      </span>{" "}
                    </>
                  )}
                </h4>
                <p>{events[0].description}</p>
              </div>
            </div>
          </div>
        )}

        <EventModal
          eventModalVisible={this.state.eventModalVisible}
          selectedData={this.state.selectedData}
          onCancel={() => {
            this.setState({ eventModalVisible: false });
          }}
          userId={this.props.userId}
          refreshEvents={() => {
            this.props.refreshEvents();
          }}
        />
        <Panel
          isLightDismiss
          onOuterClick={() => {}}
          isOpen={this.state.showEvents}
          onDismiss={() => this.setState({ showEvents: false })}
          closeButtonAriaLabel="Close"
          headerText="Events"
          type={3}
        >
          <EventsTable
            events={this.props.events}
            userId={this.props.userId}
            refreshEvents={() => {
              this.props.refreshEvents();
            }}
          />
        </Panel>
      </div>
    );
  }

  async populateData(skip, take) {
    const { events } = this.props;
    if (events && events.length > 0)
      this.setState({
        data: events,
      });

    this.setState({
      loading: true,
    });

    this.setState({
      data: [{ label: "1" }],
    });
    this.setState({
      loading: false,
    });
  }
}
