import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import { Shimmer } from "@fluentui/react";
import {
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import _ from "lodash";
import { ReactComponent as Logo } from "../../../../assets/svg/user-check.svg";
import { ReactComponent as RemoveLogo } from "../../../../assets/svg/cross-circle.svg";
import { UserAttributesClient } from "../../../../infrastructure/api/UserAttributesClient";
import moment from "moment";

export class SkillItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
  }

  classNames = mergeStyleSets({
    container: {
      width: "100%",
      overflow: "hidden",
    },
  });

  render() {
    const { userSkill, currentUser, refreshSkills } = this.props;
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            borderRadius: "10px",
            height: "120px",
            width: "120px",
            background: userSkill?.userSkill?.color
              ? userSkill?.userSkill?.color
              : "#00838f",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Logo
            height="60"
            width="60"
            style={{
              overflow: "visible",
              padding: "10px 0",
              marginLeft: "10px",
            }}
            stroke={"white"}
            fill={"white"}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <b style={{ color: "white", marginRight: "5px", fontSize: "13px" }}>
              {userSkill?.userSkill?.name}
            </b>
          </div>

          <div
            style={{
              position: "absolute",
              top: "-15px",
              right: "-5px",
              cursor: "pointer",
            }}
          >
            <RemoveLogo
              height="25"
              width="25"
              style={{ overflow: "visible", padding: "10px 0" }}
              stroke={"#EF6D6C"}
              fill={"#EF6D6C"}
              onClick={async () => {
                await UserAttributesClient.RemoveLinkedUser(
                  userSkill?.userSkill.id,
                  currentUser.id
                );

                if (refreshSkills) refreshSkills();
              }}
            />
          </div>
        </div>
        {userSkill?.expiryDate && (
          <div
            style={{
              fontSize: "10px",
              color: "white",
              borderRadius: "5px",
              background: "white",
              padding: "0 3px",
              color: "black",
              width: "60px",
              marginTop: "10px",
              textAlign: "center",
              alignSelf: "center",
            }}
          >
            {moment(userSkill?.expiryDate).format("YYYY-MM-DD")}
          </div>
        )}
      </div>
    );
  }

  async populateData() {}
}
