import { ApiResponse, ListQueryParams } from "../ApiClient";
import {
  GetAPIDomain,
  GetAPIHeaders,
  GetApiHeadersFormData,
  GetAPIHeadersPatch,
  MyDayAPIHelper,
} from "../MyDayAPIHelper";

export class UserAttributesQueryParams extends ListQueryParams {
  WithIsMenu(value: any) {
    this.WithParam("isMenu", value);
    return this;
  }
  WithExcludeFromUserId(value: any) {
    this.WithParam("excludeFromUserId", value);
    return this;
  }
  WithGetUsers(value: any) {
    this.WithParam("getUsers", value);
    return this;
  }
}

export class UserAttributesClient {
  static async List(params: UserAttributesQueryParams) {
    var query =
      (await GetAPIDomain()) + "/user-skills" + params.GenerateQueryString();
    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async Get(id: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    var query = apiDomain + "/user-skills/" + id;

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async AddLinkedUsers(id: string, userIds: string[], expiryDate?: any) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();

    return await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + "/user-skills/" + id + "/users", {
        method: "post",
        headers: headers,
        body: JSON.stringify({
          userIds: userIds,
          expiryDate,
        }),
      })
    );
  }

  static async RemoveLinkedUser(id: string, userId: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();

    return await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + "/user-skills/" + id + "/users", {
        method: "delete",
        headers: headers,
        body: JSON.stringify({
          userId: userId,
        }),
      })
    );
  }
}
