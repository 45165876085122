import { ApiResponse, ListQueryParams } from "../ApiClient";
import {
  GetAPIDomain,
  GetAPIHeaders,
  GetApiHeadersFormData,
  GetAPIHeadersPatch,
  MyDayAPIHelper,
} from "../MyDayAPIHelper";

export class SiteQueryParams extends ListQueryParams {
  WithUserId(value: string) {
    this.WithParam("userId", value);
    return this;
  }
  WithSiteTypeId(value: string) {
    this.WithParam("siteTypeId", value);
    return this;
  }
  WithStatus(value: string) {
    this.WithParam("status", value);
    return this;
  }
  WithDate(value: boolean) {
    this.WithParam("date", value);
    return this;
  }
  WithExpand(value: boolean) {
    this.WithParam("expand", value);
    return this;
  }
}

export class SitesClient {
  static async List(params: SiteQueryParams) {
    var query =
      (await GetAPIDomain()) + "/sites" + params.GenerateQueryString();
    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async GetDailySummary(id: string, date: any) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    var query = apiDomain + "/sites/daily-summary" + `?id=${id}&date=${date}`;

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async Delete(id: string) {
    var query = (await GetAPIDomain()) + "/sites/" + id;
    var headers = await GetAPIHeaders();

    return await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
        method: "delete",
      })
    );
  }

  static async Get(id: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    var query = apiDomain + "/sites/" + id;

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async GetAssetTypesFromSite(id: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    var query = apiDomain + "/sites/" + id + "/asset-types";

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async Create(
    name: string,
    latitude: string,
    longitude: string,
    address1: string,
    address2: string,
    address3: string,
    city: string,
    state: string,
    country: string,
    postcode: string,
    status: string,
    siteTypeId?: any,
    maxLimit?: number
  ) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();

    return await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + "/sites/", {
        method: "post",
        headers: headers,
        body: JSON.stringify({
          name: name,
          latitude: latitude,
          longitude: longitude,
          address1: address1,
          address2: address2,
          address3: address3,
          city: city,
          state: state,
          country: country,
          postcode: postcode,
          status: status,
          siteTypeId: siteTypeId,
          maxLimit,
        }),
      })
    );
  }

  static async Update(
    id: string,
    name: string,
    latitude: string,
    longitude: string,
    address1: string,
    address2: string,
    address3: string,
    city: string,
    state: string,
    country: string,
    postcode: string,
    status: string,
    siteTypeId?: any,
    maxLimit?: number
  ) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeadersPatch();

    return await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + "/sites/" + id, {
        method: "patch",
        headers: headers,
        body: JSON.stringify([
          {
            op: "replace",
            path: "name",
            value: name,
          },
          {
            op: "replace",
            path: "latitude",
            value: latitude,
          },
          {
            op: "replace",
            path: "longitude",
            value: longitude,
          },
          {
            op: "replace",
            path: "address1",
            value: address1,
          },
          {
            op: "replace",
            path: "address2",
            value: address2,
          },
          {
            op: "replace",
            path: "address3",
            value: address3,
          },
          {
            op: "replace",
            path: "city",
            value: city,
          },
          {
            op: "replace",
            path: "state",
            value: state,
          },
          {
            op: "replace",
            path: "country",
            value: country,
          },
          {
            op: "replace",
            path: "postcode",
            value: postcode,
          },
          {
            op: "replace",
            path: "status",
            value: status,
          },
          {
            op: "replace",
            path: "siteTypeId",
            value: siteTypeId,
          },
          {
            op: "replace",
            path: "maxLimit",
            value: maxLimit,
          },
        ]),
      })
    );
  }

  static async UploadSiteImage(siteId: string, file: any) {
    let formData = new FormData();
    formData.append("file", file);

    var accessToken = localStorage.getItem("acess-token");

    const response = await fetch(
      (await GetAPIDomain()) + "/sites/upload-site-image/" + siteId,
      {
        method: "post",
        headers: new Headers({ Authorization: "Bearer " + accessToken }),
        body: formData,
      }
    );

    if (!response.ok) {
      return false;
    }

    const data = await response.json();
  }

  static async BulkUpload(siteTypeId: string, file: any) {
    let formData = new FormData();
    formData.append("siteTypeId", siteTypeId);
    formData.append("file", file);
    var apiDomain = await GetAPIDomain();
    var headers = await GetApiHeadersFormData();

    return await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + "/sites/bulk", {
        method: "post",
        headers: headers,
        body: formData,
      })
    );
  }
}
