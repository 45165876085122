import React, { Component } from "react";
import {
    GetAPIDomain,
    GetMSALConfiguration,
    GetPermissions,
    GetTeamsPermissions,
    MyDayAPIHelper,
} from "../infrastructure/MyDayAPIHelper";
import * as msal from "@azure/msal-browser";
import { authentication } from "@microsoft/teams-js";
import { AuthContext } from "../infrastructure/AuthContext";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { MessageBar } from "@fluentui/react";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { AccountSettingsClient } from "../infrastructure/api/AccountSettingsClient";
import { ChartColors } from "../constants/chartColors";
import { setDefaults, setAccountTheme } from "../helpers/accountSettingsHelper";

export class AuthStart extends Component {
    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: false };
    }

    async componentDidMount() {

        var config = await GetMSALConfiguration();
        var msalInstance = new msal.PublicClientApplication(config);
        var authResult = await msalInstance.handleRedirectPromise();

        if (authResult) {

            MyDayAPIHelper.SetAccessToken(
                authResult.accessToken,
                authResult.expiresOn
            );

            authentication.notifySuccess("success");

        } else {
            var silentRequest = {
                scopes: (await GetPermissions()).split(" "),
            };

            try {

                msalInstance.loginRedirect(silentRequest);

            } catch (err) {

                this.setState({
                    loading: false,
                    error: "Sorry, something went wrong: " + err.message,
                });

            }
        }

    }

    render() {
        return (
            <div
                style={{
                    maxWidth: "600px",
                    padding: "30px",
                    textAlign: "center",
                    backgroundColor: "rgb(255 255 255 / 10%)",
                }}
            >
                <img
                    src="/assets/images/daysie_logo.png"
                    style={{ height: "50px", position: "relative", top: "15px" }}
                />
                <h3 className="mb-4">Welcome to Daysie</h3>

                {!this.state.error && (
                    <p>Redirecting to authentication provider</p>
                )}
                {this.state.error  && <p>{this.state.error}</p>}
            </div>
        );
    }
}
