import React, { Component } from "react";
import { authentication, app } from "@microsoft/teams-js";
import { setDefaults } from "../helpers/accountSettingsHelper";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { MyDayAPIHelper, GetPermissions, GetMSALConfiguration } from "../infrastructure/MyDayAPIHelper";
import * as msal from "@azure/msal-browser";

export class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: false, showLogIn: false };
    }

    async componentDidMount() {
        setDefaults();

        try {
            await app.initialize();
        } catch (err) {
            //Not in teams
        }

        if (app.isInitialized()) {
            try {

                var accessToken = await authentication.getAuthToken({});

                var date = new Date();
                date.setTime(date.getTime() + (1 * 60 * 60 * 1000));

                MyDayAPIHelper.SetAccessToken(
                    accessToken,
                    date
                );

                window.location.reload();

            } catch (err) {

                this.setState({
                    loading: false,
                    error: "Sorry, something went wrong: " + err.message,
                });

            }
        } else {
            this.setState({
                showLogIn: true
            });
        }
    }

    render() {
        return (
            <div
                style={{
                    maxWidth: "600px",
                    padding: "30px",
                    textAlign: "center",
                    backgroundColor: "rgb(255 255 255 / 10%)",
                }}
            >
                <img
                    src="/assets/images/daysie_logo.png"
                    style={{ height: "50px", position: "relative", top: "15px" }}
                />
                <h3 className="mb-4">Welcome to Daysie</h3>

                {!this.state.error && !this.state.showLogIn && (
                    <p>We are signing you in</p>
                )}

                {this.state.showLogIn && <PrimaryButton
                    disabled={this.state.loading}
                    style={{ width: "100%", marginTop: "20px" }}
                    onClick={async (e) => {

                        this.setState({
                            loading: true
                        });

                        //Log in via. msal
                        var silentRequest = {
                            scopes: (await GetPermissions()).split(" "),
                        };

                        var config = await GetMSALConfiguration();
                        var msalInstance = new msal.PublicClientApplication(config);
                        const loginResponse = await msalInstance.acquireTokenPopup(silentRequest);

                        MyDayAPIHelper.SetAccessToken(
                            loginResponse.accessToken,
                            loginResponse.expiresOn
                        );

                        window.location.reload();

                        this.setState({
                            loading: false
                        });

                    }
                    }
                >
                    {!this.state.loading && "SIGN IN"}
                    {this.state.loading && <Spinner animation="border" />}
                </PrimaryButton>}

                {this.state.error && <p>{this.state.error}</p>}
            </div>
        );
    }
}
