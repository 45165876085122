import React from 'react';
import { Route } from 'react-router-dom';
import { SignIn } from '../components/SignIn';
import { NoTenant } from '../components/NoTenant';
import { MyDayAPIHelper } from './MyDayAPIHelper';

export const PrivateRoute = ({ component: Component, ...rest }) => {

    if (MyDayAPIHelper.IsAuthenticated() && MyDayAPIHelper.TennatId()) {

        return (
            <Route {...rest} render={props => (
                <Component {...props} />
            )} />);
    } else if (!MyDayAPIHelper.TennatId()) {

        return (
            <Route {...rest} render={() => (
                <NoTenant />
            )} />);
    } else if (!MyDayAPIHelper.IsAuthenticated()) {

        return (
            <Route {...rest} render={() => (
                <SignIn />
            )} />);
    }
};