export enum ChartColors {
  Home = "#fcc250",
  OnSite = "#00838f",
  NoResponse = "#dcf0f2",
  NotWorking = "#EF6E6C",
  Holiday = "#F15050",
  Event = "#8F6593",
  HighlightTeam = "#f571c7",
  Available = "#00838f",
  Reserved = "#dcf0f1",
  InUse = "#EF6E6C",
  Booked = "#fcc250",
  Item = "#8F6593",
}

export enum ChartLabels {
  Home = "Home",
  OnSite = "On Site",
  NoResponse = "No Response",
  NotWorking = "Not Working",
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday",
}
