import { authentication, app } from "@microsoft/teams-js";

export async function GetAPIDomain() {
  var domain = localStorage.getItem("api-domain");

  if (!domain) {
    const response = await fetch("app-settings/domains");
    const data = await response.json();

    domain = data.domainApi;
    localStorage.setItem("api-domain", domain);
  }

  return domain;
}

export const addDefaultSrc = (ev: any) => {
  console.log("setting image");
  ev.target.src = "/assets/images/blank.png";
};

export async function GetMSALConfiguration() {
  var clientId = localStorage.getItem("sso-client-id");
  var authority = localStorage.getItem("sso-authority");

  if (!clientId) {
    if (!MyDayAPIHelper.TennatId()) return null;

    var url = "app-settings/account/" + MyDayAPIHelper.GetCurrentTenantId();
    const response = await fetch(url);
    const data = await response.json();

    clientId = data.clientId;
    authority = "https://login.microsoftonline.com/" + data.tenantId;
    localStorage.setItem("sso-client-id", clientId);
    localStorage.setItem("sso-authority", authority);
  }

  return {
    auth: {
        clientId: clientId,
          authority: authority,
          redirectUri: window.location.href,
        navigateToLoginRequestUrl: false
    },
  };
}

export async function GetTeamsPermissions() {
  var permissions = localStorage.getItem("sso-teams-permissions");

  if (!permissions) {
    if (!MyDayAPIHelper.TennatId()) return null;

    var url = "app-settings/account/" + MyDayAPIHelper.GetCurrentTenantId();
    const response = await fetch(url);
    const data = await response.json();

    permissions = data.teamsPermissions;
    localStorage.setItem("sso-teams-permissions", permissions);
  }

  return permissions;
}

export async function GetPermissions() {
  var permissions = localStorage.getItem("sso-permissions");

  if (!permissions) {
    if (!MyDayAPIHelper.TennatId()) return null;

    var url = "app-settings/account/" + MyDayAPIHelper.GetCurrentTenantId();
    const response = await fetch(url);
    const data = await response.json();

    permissions = data.permissions;
    localStorage.setItem("sso-permissions", permissions);
  }

  return permissions;
}

export async function GetAPIHeaders() {
  var expiry = new Date(Date.parse(localStorage.getItem("expires")));

  if (expiry < new Date()) {
    await MyDayAPIHelper.RefreshToken();
  }

  var accessToken = localStorage.getItem("access-token");
  var headers = new Headers({
    "content-type": "application/json",
    Authorization: "Bearer " + accessToken,
  });

  return headers;
}

export async function GetAPIHeadersPatch() {
  var expiry = new Date(Date.parse(localStorage.getItem("expires")));

  if (expiry < new Date()) {
    await MyDayAPIHelper.RefreshToken();
  }

  var accessToken = localStorage.getItem("access-token");
  var headers = new Headers({
    "content-type": "application/json-patch+json",
    Authorization: "Bearer " + accessToken,
  });

  return headers;
}

export async function GetApiHeadersFormData() {
  var accessToken = localStorage.getItem("access-token");
  return new Headers({ Authorization: "Bearer " + accessToken });
}

export class MyDayAPIHelper {
  //******************************
  // AUTHENTICATION
  //******************************

  static refresh_lock = false;

  //SIGN OUT
  static LogOut() {
    localStorage.removeItem("access-token");
    localStorage.removeItem("expiry");
    localStorage.removeItem("teams-access-token");
    localStorage.removeItem("teams-expiry");
  }

  //SET ACCESS TOKEN
  static async SetAccessToken(token: string, expiry: Date) {
    localStorage.setItem("access-token", token);
    localStorage.setItem("expiry", expiry.toISOString());
  }

  static async SetTeamsAccessToken(token: string, expiry: Date) {
    localStorage.setItem("teams-access-token", token);
    localStorage.setItem("teams-expiry", expiry.toISOString());
  }

  static async ClearAccessToken() {
    localStorage.removeItem("access-token");
    localStorage.removeItem("expiry");
    localStorage.removeItem("teams-access-token");
    localStorage.removeItem("teams-expiry");
  }

    static AccessToken() {
        return localStorage.getItem("access-token");
    }

  //REFRESH TOKEN
    static async RefreshToken() {

        var accessToken = await authentication.getAuthToken({});

        var date = new Date();
        date.setTime(date.getTime() + (1 * 60 * 60 * 1000));

        MyDayAPIHelper.SetAccessToken(
            accessToken,
            date
        );

        return true;
    //var config = await GetMSALConfiguration();

    //const msalInstance = new msal.PublicClientApplication(config);

    //var silentRequest = <any>{
    //  scopes: (await GetPermissions()).split(" "),
    //  loginHint: localStorage.getItem("login-hint"),
    //};
    //var teamsRequest = <any>{
    //  scopes: (await GetTeamsPermissions()).split(" "),
    //};

    //try {
    //  const loginResponse = await msalInstance.ssoSilent(silentRequest);

    //  console.log(loginResponse);

    //  teamsRequest.loginHint = loginResponse.account.username;
    //  const teamsResponse = await msalInstance.ssoSilent(teamsRequest);
    //  console.log(teamsResponse);

    //  MyDayAPIHelper.SetAccessToken(
    //    loginResponse.accessToken,
    //    loginResponse.expiresOn
    //  );
    //  MyDayAPIHelper.SetTeamsAccessToken(
    //    teamsResponse.accessToken,
    //    teamsResponse.expiresOn
    //  );

    //  return true;
    //} catch (err) {
    //  console.log("silent refresh failed");
    //  console.log(err);
    //  MyDayAPIHelper.ClearAccessToken();

    //  return false;
    //}
  }

  //IS AUTHENTICATED
  static IsAuthenticated() {
    if (localStorage == null) return false;

    if (localStorage.getItem("access-token") == null) return false;

    var token = localStorage.getItem("access-token");
    if (token != null && token.length === 0) return false;

    return true;
  }

  //Current tenant available
  static TennatId() {
    return MyDayAPIHelper.GetCurrentTenantId() != null;
  }

  static GetCurrentTenantId() {
    if (localStorage == null) return null;

      if (localStorage.getItem("tenant-id") == null) {

          const params = new URLSearchParams(window.location.search);
          if (params.get("tenantId")) {
        localStorage.setItem(
          "tenant-id",
            params.get("tenantId")
        );
      } else {
        return null;
      }
    }

    return localStorage.getItem("tenant-id");
  }
}

export class ApiResponse {
  constructor() {
    this.successful = false;
    this.authenticated = false;
  }

  static async Create(response: Response) {
    var result = new ApiResponse();

    result.authenticated = true;

    if (!response.ok) {
      result.successful = false;

      if (response.status === 401) {
        await MyDayAPIHelper.RefreshToken();
        window.location.reload();
      } else {
        try {
          var data = await response.json();
          result.data = data;
          result.validationErrors = data.validationErrors;
        } catch (ex) {
          console.log("No validate errors for this request");
        }
      }
    } else {
      result.successful = true;
      try {
        result.data = await response.json();
      } catch (ex) {
        console.log(ex);
        console.log("No content for this request");
      }
    }

    return result;
  }

  successful: Boolean;
  validationErrors: any;
  authenticated: Boolean;
  data: any;
}

export class ListQueryParams {
  constructor() {
    this.skip = 0;
    this.take = 50;
    this.direction = "Ascending";
    this.params = Array<QueryParameter>();
  }

  Sort(sort: string, direction?: string) {
    this.sort = sort;

    if (direction && direction === "desc") return this.Descending();
    if (direction && direction !== "desc") return this.Ascending();

    return this;
  }

  Descending() {
    this.direction = "Descending";
    return this;
  }

  Ascending() {
    this.direction = "Ascending";
    return this;
  }

  Paginate(skip: number, take: number) {
    this.skip = skip;
    this.take = take;
    return this;
  }

  Search(value: string) {
    this.WithParam("q", value);
    return this;
  }

  WithStart(value: any) {
    this.WithParam("start", value);
    return this;
  }

  WithEnd(value: any) {
    this.WithParam("end", value);
    return this;
  }

  WithParam(param: string, value: any) {
    this.params.push({
      key: param,
      value: value,
    });

    return this;
  }

  skip: number;
  take: number;
  direction: string;
  params: QueryParameter[];
  sort?: string;

  GenerateQueryString() {
    var query = "?skip=" + this.skip + "&take=" + this.take;
    if (this.sort)
      query = query + "&sort=" + this.sort + "&direction=" + this.direction;

    if (this.params) {
      this.params.map((param) => {
        if (param.value && param.key) {
          if (param.value instanceof Date) {
            query =
              query + "&" + param.key + "=" + (<Date>param.value).toISOString();
          } else {
            query = query + "&" + param.key + "=" + param.value;
          }
        }
      });
    }

    return query;
  }
}

export class QueryParameter {
  key?: string;
  value?: any;
}
