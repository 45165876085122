import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import {
  HomeClient,
  HomeQueryParams,
} from "../../infrastructure/api/HomeClient";
import { FocusZone, Spinner, List, Image, ImageFit } from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";

export class WeekSelector extends Component {
  constructor(props) {
    super(props);

    const { selectedDate } = props;

    this.state = { selectedDates: [new Date(selectedDate)] };
  }

  render() {
    const { dates, selectedDate, onSelectedDatesUpdate } = this.props;

    var days = ["S", "M", "T", "W", "T", "F", "S"];

    return (
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        {dates.map((d) => {
          var isSelected = false;
          var isDisabled = false;

          if (
            new Date(new Date(d).toDateString()).getTime() <
            new Date(new Date().toDateString()).getTime()
          )
            isDisabled = true;

          for (var i = 0; i < this.state.selectedDates.length; i++) {
            if (
              new Date(d.toDateString()).getTime() ==
              new Date(this.state.selectedDates[i].toDateString()).getTime()
            )
              isSelected = true;
          }

          return (
            <div
              className="hover"
              style={{
                height: "35px",
                width: "35px",
                borderRadius: "50%",
                backgroundColor: isDisabled
                  ? "grey"
                  : isSelected
                  ? "#00838f"
                  : "#dcf0f2",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={(e) => {
                if (isDisabled) return;

                if (isSelected) {
                  if (this.state.selectedDates.length > 1) {
                    var selectedDates = [];
                    for (var i = 0; i < this.state.selectedDates.length; i++) {
                      if (
                        new Date(d.toDateString()).getTime() !=
                        new Date(
                          this.state.selectedDates[i].toDateString()
                        ).getTime()
                      ) {
                        selectedDates.push(this.state.selectedDates[i]);
                      }
                    }

                    this.setState({
                      selectedDates: selectedDates,
                    });
                  }
                } else {
                  var selectedDates = this.state.selectedDates;
                  selectedDates.push(d);

                  this.setState({
                    selectedDates: selectedDates,
                  });
                }

                if (onSelectedDatesUpdate) {
                  onSelectedDatesUpdate(this.state.selectedDates);
                }
              }}
            >
              <strong style={{ color: isSelected ? "white" : "black" }}>
                {days[d.getDay()]}
              </strong>
            </div>
          );
        })}
      </div>
    );
  }
}
