import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import {
  Dropdown,
  Label,
  IconButton,
  Stack,
  ChoiceGroup,
  Panel,
  PanelType,
} from "@fluentui/react";

import {
  NeighborhoodsQueryParams,
  NeighborhoodsClient,
} from "../../infrastructure/api/NeighborhoodsClient";
import { ReactComponent as Logo } from "../../assets/svg/house-chimney.svg";
import { ReactComponent as AngleLogo } from "../../assets/svg/angle-right.svg";

export class NeighborhoodSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeStatus: "",
      loading: false,
      selectedData: null,
      data: [],
      siteId: null,
      isPanelOpen: false,
    };
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps, prevState) {
    const { siteId, selectedData, changefromParent } = this.props;
    if (siteId && siteId != this.state.siteId) this.update();
    if (changefromParent && this.state.selectedData?.id !== selectedData?.id) {
      this.setState({
        selectedData: selectedData,
      });
    }
  }

  async update() {
    await this.populateData();
  }
  classNames = mergeStyleSets({
    dropdown: { borderRadius: "10px" },
  });

  render() {
    const {
      onSelect,
      hideLabel,
      hideWhenNoData,
      onDeselect,
      neighborhoodId,
      isMobileView,
      hideDeselectBtn,
    } = this.props;
    const { data, selectedData } = this.state;

    if (isMobileView) {
      return (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={() => {
              this.setState({ isPanelOpen: true });
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <Logo
                fill={"#000"}
                stroke={"white"}
                height="25"
                width="25"
                style={{ overflow: "visible" }}
              /> */}
              <div
                style={{
                  marginLeft: "5px",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                Neighborhood
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {this.state.selectedData && (
                <div
                  style={{
                    color: "grey",
                    fontWeight: 300,
                    marginRight: "5px",
                  }}
                >
                  {this.state.selectedData?.name}
                </div>
              )}
              <AngleLogo
                fill={"grey"}
                stroke={"grey"}
                height="12"
                width="12"
                style={{ overflow: "visible" }}
              />
            </div>
          </div>

          {this.renderPanel()}
        </>
      );
    } else {
      return (
        ((hideWhenNoData && data.length > 0) || !hideWhenNoData) && (
          <Stack horizontal={hideLabel ? true : false} verticalAlign="center">
            {!hideLabel && <Label>Neighborhood</Label>}
            <div style={{ display: "flex", width: "100%" }}>
              <Dropdown
                placeholder="Select a Neighborhood"
                selectedKey={
                  this.state.selectedData
                    ? this.state.selectedData.id
                    : neighborhoodId
                    ? neighborhoodId
                    : null
                }
                options={this.state.data.map((item) => {
                  return {
                    key: item.id,
                    text: item.name,
                  };
                })}
                onChanged={(item) => {
                  this.setState({
                    selectedData: {
                      id: item.key,
                      name: item.text,
                    },
                  });

                  onSelect({
                    key: item.key,
                    id: item.key,
                    name: item.text,
                  });
                }}
                styles={{
                  title: { borderRadius: "5px" },
                  root: { width: "100%" },
                }}
              />{" "}
              {!hideDeselectBtn && (
                <IconButton
                  iconProps={{ iconName: "Blocked" }}
                  title="Deselect"
                  ariaLabel="Deselect"
                  styles={{ root: { color: "red" } }}
                  onClick={() => {
                    if (onDeselect) onDeselect();

                    this.setState({ selectedData: null });
                  }}
                />
              )}
            </div>
          </Stack>
        )
      );
    }
  }

  renderPanel = () => {
    const { isPanelOpen, mobileData, selectedData } = this.state;
    return (
      <Panel
        type={PanelType.smallFluid}
        headerText="Choose Neighborhood"
        isOpen={isPanelOpen}
        onDismiss={() => {
          this.setState({ isPanelOpen: false });
        }}
        // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
        closeButtonAriaLabel="Close"
      >
        <ChoiceGroup
          defaultSelectedKey={selectedData?.id}
          options={mobileData}
          onChange={(event, option) => {
            var selectedData = {
              id: option.key,
              name: option.text,
              plottable: option.plottable,
            };

            this.setState({ isPanelOpen: false, selectedData });
          }}
        />
      </Panel>
    );
  };

  async populateData() {
    const { siteId, neighborhoodId, onSelect } = this.props;
    var query = new NeighborhoodsQueryParams();

    if (siteId) query.WithSiteId(siteId);

    query.Sort("name", "asc");

    var result = await NeighborhoodsClient.List(query);

    var mobileData = result?.data?.data.map((item) => ({
      key: item.id,
      text: item.name,
    }));

    this.setState({
      data: result?.data?.data,
      siteId: siteId,
      mobileData,
    });

    // if (neighborhoodId) {
    //   var neighborhood = result?.data?.data.filter(
    //     (neighborhood) => neighborhood.id === neighborhoodId
    //   )[0];

    //   if (neighborhood) {
    //     onSelect({
    //       id: neighborhood.id,
    //       name: neighborhood.name,
    //     });
    //   }
    // } else if (!neighborhoodId && this.state.selectedData === null) {
    //   var neighborhood = result.data.data[0];

    //   if (neighborhood) {
    //     onSelect({
    //       id: neighborhood.id,
    //       name: neighborhood.name,
    //     });
    //   }
    // }
  }
}
