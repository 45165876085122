import { ListQueryParams, ApiResponse } from "../ApiClient";
import {
  GetAPIDomain,
  GetAPIHeaders,
  GetApiHeadersFormData,
  GetAPIHeadersPatch,
  MyDayAPIHelper,
} from "../MyDayAPIHelper";

export class UserQueryParams extends ListQueryParams {
  WithShowSuspended(value: any) {
    if (!value) this.WithParam("status", "active");
    return this;
  }
  WithSearchableOnly(value: boolean) {
    if (!value) this.WithParam("searchableOnly", value);
    return this;
  }
  WithRoleId(value: any) {
    this.WithParam("roleId", value);
    return this;
  }

  WithStatus(value: any) {
    this.WithParam("status", value);
    return this;
  }

  WithTeamId(value: any) {
    this.WithParam("teamId", value);
    return this;
  }

  WithSiteId(value: any) {
    this.WithParam("siteId", value);
    return this;
  }

  WithReportsToId(value: any) {
    this.WithParam("reportsToId", value);
    return this;
  }

  WithExcludeFromTeamId(value: any) {
    this.WithParam("excludeFromTeamId", value);
    return this;
  }
  WithGetPhoto(value: boolean) {
    this.WithParam("getPhoto", value);
    return this;
  }
  WithExpand(value: boolean) {
    this.WithParam("expand", value);
    return this;
  }
}

var accessToken = localStorage.getItem("acess-token");
export class UsersClient {
  static async List(params: UserQueryParams) {
    var query =
      (await GetAPIDomain()) + "/users" + params.GenerateQueryString();
    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async LookUp(params: UserQueryParams) {
    var query =
      (await GetAPIDomain()) + "/users/lookup" + params.GenerateQueryString();
    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async Get(id: string, excludeFavorites = false) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    var query =
      apiDomain + "/users/" + id + `?excludeFavorites=${excludeFavorites}`;

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async GetCurrentUser(email: string) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    var query = apiDomain + "/users/current/" + email;

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async GetCurrentUserByToken() {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    var query = apiDomain + "/users/by-token";

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async AddReportsToUsers(id: string, userIds: string[]) {
    var apiDomain = await GetAPIDomain();

    var headers = await GetAPIHeaders();
    return await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + "/users/" + id + "/reports-to-users", {
        method: "post",
        headers: headers,
        body: JSON.stringify({
          userIds: userIds,
        }),
      })
    );
  }

  static async SetRole(id: string, roleId: any) {
    var query = (await GetAPIDomain()) + "/users/" + id + "/set-roles";

    var headers = await GetAPIHeaders();

    return await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
        method: "post",
        body: JSON.stringify({
          roleIds: roleId,
        }),
      })
    );
  }

  static async SetTeam(
    userId: string,
    teamId: string,
    isPrimary: boolean = false,
    isTeamLeader: boolean = false
  ) {
    var query = (await GetAPIDomain()) + "/users/" + userId + "/set-team";

    var headers = await GetApiHeadersFormData();

    let formData = new FormData();
    formData.append("userId", userId);
    formData.append("teamId", teamId);
    formData.append("isPrimary", isPrimary ? "true" : "false");
    formData.append("isTeamLeader", isTeamLeader ? "true" : "false");

    return await ApiResponse.CreateFromResponse(
      await fetch(query, {
        method: "post",
        headers: headers,
        body: formData,
      })
    );
  }

  static async RemoveTeam(userId: string, teamId: string) {
    var query = (await GetAPIDomain()) + "/users/" + userId + "/remove-team";

    var headers = await GetApiHeadersFormData();

    let formData = new FormData();
    formData.append("userId", userId);
    formData.append("teamId", teamId);

    return await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
        method: "delete",
        body: formData,
      })
    );
  }

  static async Suspend(id: string) {
    var query = (await GetAPIDomain()) + "/users/" + id + "/suspend";

    var headers = await GetAPIHeaders();

    return await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
        method: "post",
      })
    );
  }

  static async Restore(id: string) {
    var query = (await GetAPIDomain()) + "/users/" + id + "/restore";

    var headers = await GetAPIHeaders();

    return await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
        method: "post",
      })
    );
  }

  static async Delete(id: string) {
    var query = (await GetAPIDomain()) + "/users/" + id;

    var headers = await GetAPIHeaders();

    return await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
        method: "delete",
      })
    );
  }

  static async Update(
    id: string,
    name: string,
    surname: string,
    searchable: boolean
  ) {
    var apiDomain = await GetAPIDomain();

    var headers = await GetAPIHeadersPatch();
    return await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + "/users/" + id, {
        method: "patch",
        headers: headers,
        body: JSON.stringify([
          {
            op: "replace",
            path: "name",
            value: name,
          },
          {
            op: "replace",
            path: "surname",
            value: surname,
          },
          {
            op: "replace",
            path: "searchable",
            value: searchable,
          },
        ]),
      })
    );
  }

  static async UpdateDefaultSite(id: string, siteId: string) {
    var apiDomain = await GetAPIDomain();

    var headers = await GetAPIHeadersPatch();
    return await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + "/users/" + id, {
        method: "patch",
        headers: headers,
        body: JSON.stringify([
          {
            op: "replace",
            path: "siteId",
            value: siteId,
          },
        ]),
      })
    );
  }

  static async UploadPhoto(userId: string, file: any) {
    let formData = new FormData();
    formData.append("userId", userId);
    formData.append("file", file);

    var accessToken = localStorage.getItem("access-token");

    const response = await fetch(
      (await GetAPIDomain()) + "/users/upload-photo",
      {
        method: "post",
        headers: new Headers({ Authorization: "Bearer " + accessToken }),
        body: formData,
      }
    );

    if (!response.ok) {
      return false;
    }

    const data = await response.json();
  }

  static async DeletePhoto(id: string) {
    var query = (await GetAPIDomain()) + "/users/" + id + "/remove-photo";

    var headers = await GetAPIHeaders();

    return await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
        method: "delete",
      })
    );
  }

  static async GetSummary(
    id: string,
    month: number,
    year: number,
    ignoreTeamDates: boolean = false
  ) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    var query =
      apiDomain +
      "/users/monthly-summary" +
      `?id=${id}&month=${month}&year=${year}&ignoreTeamDates=${ignoreTeamDates}`;

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }
}
