import React, { Component, useContext } from 'react';
import { Redirect, Link } from 'react-router-dom';


export class NoTeam extends Component {

    constructor(props) {
        super(props);
        this.state = { codeStatus: "", loading: false };
    }

    render() {

        return (
            <div style={{ paddingTop: "10%" }}>
                <div className="auth-wrapper" style={{ boxShadow: "0 0 15px #888888", maxWidth: "600px", margin: "auto", width: "100%" }}>
            <div style={{ maxWidth: "600px", width: "100%", padding: "30px", textAlign: "center", backgroundColor:"rgb(255 255 255 / 10%)" }}>
                <img src="/assets/images/daysie_logo.png" style={{ height: "50px", position: "relative", top: "15px" }} />
                <h3 className="mb-4">
                    No teams available</h3>
                <hr />
                <p>When you are added to a team, please refresh this page.</p>
                    </div>
                </div>
            </div>
        );

    }
}