import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import { Dropdown, Label } from "@fluentui/react";
import {
  UserAttributesQueryParams,
  UserAttributesClient,
} from "../../infrastructure/api/UserAttributesClient";

export class UserAttributeSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeStatus: "",
      loading: false,
      selectedData: null,
      data: [],
      siteId: null,
    };
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps, prevState) {
    const { siteId, selectedAttribute } = this.props;
    if (siteId !== this.state.siteId)
      this.setState({ siteId: siteId }, () => this.update());

    if (prevProps.selectedAttribute?.id !== selectedAttribute?.id) {
      this.setState({ selectedData: selectedAttribute });
    }
  }

  async update() {
    await this.populateData();
  }
  classNames = mergeStyleSets({
    dropdown: { borderRadius: "10px" },
  });

  render() {
    const { onSelect, hideLabel, siteId } = this.props;
    return (
      <>
        {!hideLabel && <Label>User Attributes</Label>}
        <Dropdown
          placeholder="Select"
          selectedKey={
            this.state.selectedData ? this.state.selectedData.id : null
          }
          options={this.state.data.map((item) => {
            return {
              key: item.id,
              text: item.name,
              users: item.users,
              color: item.color,
            };
          })}
          onChanged={(item) => {
            this.setState({
              selectedData: {
                id: item.key,
                name: item.text,
                users: item.users,
                color: item.color,
              },
            });

            onSelect({
              id: item.key,
              name: item.text,
              users: item.users,
              color: item.color,
            });
          }}
          styles={{ title: { borderRadius: "5px" } }}
        />
      </>
    );
  }

  sortUsers(item) {
    const { siteId } = this.props;
    if (item?.users && siteId) {
      var sorted = item?.users.sort((user) =>
        (user?.myDay && user?.myDay?.parts[0]?.siteId === siteId) ||
        user.site?.id === siteId
          ? -1
          : 1
      );

      item.users = sorted;
      return item;
    } else {
      return item;
    }
  }

  async populateData() {
    const { selectedAttribute, onSelect, siteId, getUsers } = this.props;

    var query = new UserAttributesQueryParams().WithIsMenu(true);

    if (getUsers) query.WithGetUsers(true);

    var result = await UserAttributesClient.List(query);

    var data = result?.data?.data;

    if (data && data.length > 0) {
      var mapped = data.map((item) => {
        return this.sortUsers(item);
      });
      this.setState({
        data: mapped,
      });
    }

    if (selectedAttribute) {
      this.setState({
        selectedData: {
          id: selectedAttribute.id,
          name: selectedAttribute.name,
          users: selectedAttribute.users,
          color: selectedAttribute.color,
        },
      });

      onSelect({
        id: selectedAttribute.id,
        name: selectedAttribute.name,
        users: selectedAttribute.users,
        color: selectedAttribute.color,
      });
    } else {
      this.setState({
        selectedData: null,
      });
    }
  }
}
