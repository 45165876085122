import React, { Component, useContext } from "react";
import { ReactComponent as WorkFromOfficeLogo } from "../../../assets/svg/marker.svg";
import { ReactComponent as InfoLogo } from "../../../assets/svg/info.svg";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import {
  Modal,
  DefaultButton,
  HoverCard,
  HoverCardType,
  Checkbox,
  Panel,
  Shimmer,
} from "@fluentui/react";
import ReactMarkdown from "react-markdown";
import { NoticeClient } from "../../../infrastructure/api/NoticeClient";
import _ from "lodash";

export class MobileNoticePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notices: [],
      acknowledgedIndexes: [],
      acknowledgedNotices: [],
      currentNoticeIdx: 0,
      loading: true,
    };
  }

  componentDidMount() {
    const { notices, siteId } = this.props;
    this.setState({ loading: true, notices: notices }, () => this.setup());
  }

  async addNoticeViewed() {
    const { notices, show } = this.props;
    const { currentNoticeIdx } = this.state;

    const userId = localStorage.getItem("userId");
    if (
      notices?.length > 0 &&
      currentNoticeIdx < notices.length &&
      show === true
    ) {
      NoticeClient.AddNoticeViewed(notices[currentNoticeIdx].id, userId);
    }
  }

  async setup() {
    await new Promise((resolve) => setTimeout(resolve, 1200));
    this.setState({ currentNoticeIdx: 0, loading: false });
  }

  render() {
    const { notices, show, onClose } = this.props;
    const { currentNoticeIdx } = this.state;

    const userId = localStorage.getItem("userId");

    var showAcknowledgeButton =
      notices[currentNoticeIdx]?.acknowledge &&
      !_.find(notices[currentNoticeIdx].acknowledgedUsers, (o) => {
        return o.userId == userId;
      }) &&
      !_.filter(this.state.acknowledgedIndexes, (o) => {
        return o == currentNoticeIdx;
      }).length;

    return (
      <>
        {notices && notices.length ? (
          <Panel
            isLightDismiss={false}
            onOuterClick={() => {}}
            isOpen={show}
            onOpen={() => {
              this.addNoticeViewed();
              this.setState({
                acknowledgedNotices: [],
                acknowledgedIndexes: [],
                currentNoticeIdx: 0,
              });
            }}
            onDismiss={() => {
              this.setState({
                acknowledgedNotices: [],
                acknowledgedIndexes: [],
                currentNoticeIdx: 0,
              });
              if (this.state.acknowledgedNotices.length > 0) {
                onClose(true);
              } else {
                onClose();
              }
            }}
            closeButtonAriaLabel="Close"
            styles={{
              header: {
                paddingRight: "0px",
              },
            }}
            headerText={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ fontSize: "30px", fontWeight: "bold" }}>
                  {notices[currentNoticeIdx]?.name}
                </div>{" "}
                <div
                  style={{
                    fontSize: "11px",
                    color: "grey",
                    marginRight: "20px",
                  }}
                >
                  {currentNoticeIdx + 1} of {notices.length}
                </div>
              </div>
            }
            type={1}
          >
            <div>
              {this.state.loading ? (
                <div style={{ width: "100%", display: "flex" }}>
                  <div style={{ width: "100%", margin: "20px 0px" }}>
                    <Shimmer style={{ padding: "5px" }} />
                    <Shimmer style={{ padding: "5px" }} width="90%" />
                    <Shimmer style={{ padding: "5px" }} width="80%" />
                    <Shimmer style={{ padding: "5px" }} width="70%" />
                    <Shimmer style={{ padding: "5px" }} width="60%" />
                    <Shimmer style={{ padding: "5px" }} width="50%" />
                    <Shimmer style={{ padding: "5px" }} width="40%" />
                  </div>
                </div>
              ) : (
                <>
                  <div
                    style={{
                      padding: "0px 20px",
                      maxHeight: "calc(100vh - 200px)",
                      marginBottom: "50px",
                      // overflow: "scroll", --this breaks the scrolling on mobile
                    }}
                  >
                    {notices && notices.length > 0 && (
                      <div style={{ textAlign: "center" }}>
                        <ReactMarkdown>
                          {notices[currentNoticeIdx]?.body}
                        </ReactMarkdown>
                        {notices[currentNoticeIdx]?.warning && (
                          <span style={{ color: "red" }}>
                            <h3>IMPORTANT</h3>
                            <ReactMarkdown>
                              {notices[currentNoticeIdx]?.warning}
                            </ReactMarkdown>
                          </span>
                        )}
                        {notices[currentNoticeIdx] &&
                          notices[currentNoticeIdx].attachments &&
                          notices[currentNoticeIdx].attachments.length > 0 &&
                          notices[currentNoticeIdx].attachments.map(
                            (attachment) => {
                              const onRenderPlainCard = (item) => {
                                return (
                                  <img
                                    src={item.uri}
                                    alt="Image"
                                    style={{
                                      maxHeight: "300px",
                                      maxWidth: "300px",
                                    }}
                                  />
                                );
                              };
                              return (
                                <>
                                  <div
                                    key={attachment.id}
                                    style={{ width: "100%" }}
                                  >
                                    <img
                                      src={`${
                                        attachment.uri
                                      }?${new Date().toISOString()}`}
                                      alt="Image"
                                      style={{
                                        maxHeight: "300px",
                                        maxWidth: "300px",
                                      }}
                                    />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "10px",
                      position: "absolute",
                      bottom: 10,
                      left: 0,
                      width: "-webkit-fill-available",
                    }}
                  >
                    {this.state.currentNoticeIdx > 0 ? (
                      <DefaultButton
                        text="Back"
                        onClick={async () => {
                          this.setState({
                            currentNoticeIdx: this.state.currentNoticeIdx - 1,
                          });
                        }}
                      />
                    ) : (
                      <div style={{ width: "80px" }}></div>
                    )}

                    {showAcknowledgeButton && (
                      <DefaultButton
                        text="Acknowledge"
                        style={{ margin: "0 15px" }}
                        onClick={async () => {
                          this.setState({
                            acknowledgedIndexes: [
                              ...this.state.acknowledgedIndexes,
                              currentNoticeIdx,
                            ],
                            acknowledgedNotices: [
                              ...this.state.acknowledgedNotices,
                              notices[currentNoticeIdx],
                            ],
                          });
                          await NoticeClient.AddUser(
                            notices[currentNoticeIdx].id,
                            userId
                          );
                        }}
                      />
                    )}
                    {this.state.currentNoticeIdx + 1 === notices.length ? (
                      <DefaultButton
                        text="Close"
                        onClick={async () => {
                          this.setState({
                            currentNoticeIdx: 0,
                            acknowledgedNotices: [],
                          });

                          if (this.state.acknowledgedNotices.length > 0) {
                            onClose(true);
                          } else {
                            onClose();
                          }
                        }}
                        style={{ alignSelf: "end" }}
                      />
                    ) : (
                      <DefaultButton
                        text="Next"
                        onClick={async () => {
                          this.setState(
                            {
                              currentNoticeIdx: this.state.currentNoticeIdx + 1,
                            },
                            () => {
                              this.addNoticeViewed();
                            }
                          );
                        }}
                        style={{ alignSelf: "end" }}
                        // allowDisabledFocus
                        disabled={
                          notices[currentNoticeIdx]?.acknowledge &&
                          !_.find(
                            notices[currentNoticeIdx].acknowledgedUsers,
                            (o) => {
                              return o.userId == userId;
                            }
                          ) &&
                          !_.filter(this.state.acknowledgedIndexes, (o) => {
                            return o == currentNoticeIdx;
                          }).length
                        }
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </Panel>
        ) : (
          ""
        )}
      </>
    );
  }
}
