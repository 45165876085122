import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  ImageFit,
  Stack,
  Image,
  Dropdown,
  DefaultButton,
  ChoiceGroup,
  DatePicker,
  defaultDatePickerStrings,
  addDays,
  Panel,
  Dialog,
  DialogFooter,
  PrimaryButton,
  DialogType,
  DayOfWeek,
  Shimmer,
  ShimmerElementType,
  ShimmerElementsGroup,
  Label,
  Toggle,
} from "@fluentui/react";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import _ from "lodash";
import moment from "moment";
import { UsersClient } from "../../../../infrastructure/api/UsersClient";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import {
  IPersonaSharedProps,
  Persona,
  PersonaSize,
  PersonaPresence,
} from "@fluentui/react/lib/Persona";
import { SiteSelector } from "../../../Selectors/SiteSelector";
import { Doughnut } from "react-chartjs-2";

import { ReactComponent as AngleLeftLogo } from "../../../../assets/svg/angle-small-left.svg";
import { ReactComponent as AngleRightLogo } from "../../../../assets/svg/angle-small-right.svg";

export class MonthlyOverview extends Component {
  constructor(props) {
    Chart.register(ArcElement, Tooltip, Legend);
    super(props);
    this.state = {
      loading: true,
      currentDate: new Date(new Date().toDateString()),
      date: moment().format(),
      currentUser: null,
      data: {},
      reRender: false,
    };

    this.inputElementRef = React.createRef();
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    const { currentUser, reloadPhoto } = this.props;
    await this.populateData();
  }

  componentWillReceiveProps(props) {
    if (this.state.reRender !== this.props.reRender) {
      this.setState({ reRender: this.props.reRender }, () =>
        this.populateData()
      );
    }
  }

  classNames = mergeStyleSets({
    centerItem: {
      display: "flex",
      justifyContent: "center",
    },
  });

  render() {
    const { fromMobile } = this.props;

    var onSiteColor = localStorage.getItem("theme_OnSite");
    var homeColor = localStorage.getItem("theme_Home");
    var noResponseColor = localStorage.getItem("theme_NoResponse");
    var notWorkingColor = localStorage.getItem("theme_NotWorking");

    var data = {
      labels: ["Home", "On Site", "Not Working", "No Response"],
      datasets: [
        {
          data: [
            this.state.data?.workingOnSite,
            this.state.data?.workingFromHome,
            this.state.data?.notWorking,
            this.state.data?.noResponse,
          ],
          backgroundColor: [
            onSiteColor,
            homeColor,
            notWorkingColor,
            noResponseColor,
          ],
        },
      ],
    };

    var options = {
      showTooltips: false,
      cutout: 50,
      events: [],
      animation: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltips: { enabled: false },
        hover: { mode: null }, // animation duration after a resize
      },
    };

    var onSiteColor = localStorage.getItem("theme_OnSite");
    var homeColor = localStorage.getItem("theme_Home");
    var noResponseColor = localStorage.getItem("theme_NoResponse");
    var notWorkingColor = localStorage.getItem("theme_NotWorking");

    return (
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "50%",
          height: "120px",
          overflow: "none",
          width: "120px",
          marginLeft: fromMobile ? 0 : "40px",
          position: "relative",
        }}
      >
        <div className={this.classNames.centerItem}>
          {" "}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              zIndex: fromMobile ? 0 : 1,
            }}
          >
            <span
              onClick={() => {
                this.setState(
                  {
                    date: moment(this.state.date).subtract(1, "M"),
                  },
                  () => this.populateData()
                );
              }}
            >
              <AngleLeftLogo
                height="30"
                width="30"
                style={{ overflow: "visible", cursor: "pointer" }}
                fill={"#BFBFBF"}
                stroke={"#BFBFBF"}
              />
            </span>
            <div style={{ width: "120px" }}>
              <Doughnut
                data={data}
                id="monthlyPanelPieChart"
                options={options}
              />
            </div>
            <span
              onClick={() => {
                if (moment(this.state.date).isSameOrAfter(new Date(), "month"))
                  return;

                this.setState(
                  {
                    date: moment(this.state.date).add(1, "M"),
                  },
                  () => this.populateData()
                );
              }}
            >
              <AngleRightLogo
                height="30"
                width="30"
                style={{ overflow: "visible", cursor: "pointer" }}
                fill={
                  moment(this.state.date).isSameOrAfter(new Date(), "month")
                    ? "#dedede"
                    : "#BFBFBF"
                }
                stroke={
                  moment(this.state.date).isSameOrAfter(new Date(), "month")
                    ? "#dedede"
                    : "#BFBFBF"
                }
              />
            </span>
          </div>
          <div
            style={{
              width: "120px",
              height: "120px",
              textAlign: "center",
              borderRadius: "100px",
              backgroundColor: "transparent",
              color: "black",
              position: "absolute",
            }}
          >
            <div
              style={{
                fontWeight: "bolder",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                margin: "0 auto",
                position: "relative",
                width: "100%",
              }}
            >
              <div>
                <div style={{ fontSize: "16px" }}>
                  {moment(this.state.date).format("MMMM")}
                </div>
                <div style={{ marginTop: "5px", fontSize: "12px" }}>
                  {moment(this.state.date).format("YYYY")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  async populateData() {
    const { currentUser, reloadPhoto } = this.props;
    this.setState({ loading: true });

    if (!currentUser) return;

    var response = await UsersClient.GetSummary(
      currentUser.id,
      moment(this.state.date).month() + 1,
      moment(this.state.date).year(),
      true
    );

    if (!response.authenticated) {
    } else {
      this.setState({
        data: response.data,
        loading: false,
      });
    }
  }
}
