import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import {
  FocusZone,
  List,
  DefaultButton,
  Shimmer,
  Modal,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import {
  EventsQueryParams,
  EventsClient,
} from "../../infrastructure/api/EventsClient";
import Moment from "react-moment";
import moment from "moment";
import { FontIcon } from "@fluentui/react/lib/Icon";
import { Badge } from "@fluentui/react-components";

export class EventModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonLoading: false,
      unattendIndexLoading: null,
    };
  }

  componentDidMount() {}

  attendUser() {
    const { userId } = this.props;
  }

  unattendUser() {}

  modalStyles = mergeStyleSets({
    root: {
      width: "650px",
      height: "650px",
    },
    container: {},
  });

  iconClass = mergeStyles({
    fontSize: 12,
    height: 12,
    width: 12,
    cursor: "pointer",
    color: "red",
  });
  render() {
    const {
      eventModalVisible,
      selectedData,
      onCancel,
      refreshEvents,
      userId,
      randomImg,
    } = this.props;
    const { buttonLoading } = this.state;
    return (
      <Modal
        isOpen={eventModalVisible}
        onDismiss={() => {
          onCancel();
        }}
        isBlocking={false}
        className={this.modalStyles.container}
        animated="fade-in"
      >
        {selectedData && (
          <div className={this.modalStyles.root}>
            {selectedData?.eventImages &&
            selectedData?.eventImages.length > 0 ? (
              <img
                src={selectedData?.eventImages[0]?.url}
                style={{
                  width: "100%",
                  objectFit: "cover",
                  height: "400px",
                }}
              ></img>
            ) : (
              <img
                src={randomImg}
                style={{
                  width: "100%",
                  objectFit: "cover",
                  height: "400px",
                }}
              ></img>
            )}
            <div className="container">
              <h4>
                <b>{selectedData.name}</b>
              </h4>
              <h4>
                {moment(selectedData.startDate).isSame(selectedData.endDate) ? (
                  <>
                    <Moment utc local format="ddd DD MMM, YYYY">
                      {selectedData.startDate}
                    </Moment>{" "}
                  </>
                ) : (
                  <>
                    <Moment utc local format="ddd DD MMM, YYYY">
                      {selectedData.startDate}
                    </Moment>{" "}
                    -{" "}
                    <Moment utc local format="ddd DD MMM, YYYY">
                      {selectedData.endDate}
                    </Moment>
                  </>
                )}
              </h4>
              {selectedData.startTime && selectedData.endTime && (
                <i style={{ color: "rgb(99 99 99)" }}>
                  {moment(selectedData.startTime, ["HH:mm"]).format("hh:mm A")}{" "}
                  - {moment(selectedData.endTime, ["HH:mm"]).format("hh:mm A")}
                </i>
              )}

              <p>{selectedData.description}</p>
              {selectedData.isBookable && !selectedData.isAttendee && (
                <div
                  style={{
                    position: "absolute",
                    bottom: "0",
                    marginBottom: "20px",
                    marginRight: "20px",
                    width: "-webkit-fill-available",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <DefaultButton
                      style={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                      onClick={async () => {
                        this.setState({ buttonLoading: true });
                        await EventsClient.LinkAttendeeToEvent(
                          selectedData.id,
                          [userId]
                        );
                        this.setState({ buttonLoading: false });
                        refreshEvents();
                        onCancel();
                      }}
                      disabled={buttonLoading || !userId}
                    >
                      Attend{" "}
                      {buttonLoading && <Spinner size={SpinnerSize.medium} />}
                    </DefaultButton>
                  </div>
                </div>
              )}

              {selectedData.isBookable && selectedData.isAttendee && (
                <div
                  style={{
                    position: "absolute",
                    bottom: "0",
                    marginBottom: "20px",
                    marginRight: "20px",
                    width: "-webkit-fill-available",
                  }}
                >
                  <h4 style={{ display: "flex", justifyContent: "end" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          marginRight: "5px",
                          background: "#5B5FC7",
                          fontSize: "12px",
                          fontWeight: 600,
                          color: "white",
                          borderRadius: "50px",
                          padding: "4px",
                        }}
                      >
                        Attending
                      </div>
                      <div
                        onClick={async () => {
                          this.setState({ buttonLoading: true });
                          await EventsClient.RemoveAttendeeToEvent(
                            selectedData.id,
                            userId
                          );
                          this.setState({ buttonLoading: false });
                          refreshEvents();
                          onCancel();
                        }}
                      >
                        {!this.state.buttonLoading && (
                          <FontIcon
                            aria-label="Cancel"
                            iconName="Cancel"
                            className={this.iconClass}
                          />
                        )}
                        {this.state.buttonLoading && (
                          <FontIcon
                            aria-label="Refresh"
                            iconName="Refresh"
                            className={`${this.iconClass} spin-icon`}
                          />
                        )}
                      </div>{" "}
                    </div>
                  </h4>
                  {/* <div style={{ display: "flex", justifyContent: "end" }}>
                    <DefaultButton
                      style={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                      onClick={async () => {
                        this.setState({ buttonLoading: true });
                        await EventsClient.RemoveAttendeeToEvent(
                          selectedData.id,
                          userId
                        );
                        this.setState({ buttonLoading: false });
                        refreshEvents();
                        onCancel();
                      }}
                      disabled={buttonLoading || !userId}
                    >
                      Unattend{" "}
                      {buttonLoading && <Spinner size={SpinnerSize.medium} />}
                    </DefaultButton>
                  </div> */}
                </div>
              )}
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
