import React, { Component, useContext } from "react";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import _ from "lodash";
import moment from "moment";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { generateGUID } from "../../../infrastructure/helpers/stringHelper";

export class SiteStatChart extends Component {
  constructor(props) {
    Chart.register(ArcElement, Tooltip, Legend);
    super(props);
    this.state = {
      loading: true,
      currentDate: new Date(new Date().toDateString()),
      date: moment().format(),
      currentUser: null,
      data: {},
      key: generateGUID(),
    };

    this.inputElementRef = React.createRef();
  }

  componentDidMount() {}

  classNames = mergeStyleSets({
    centerItem: {
      display: "flex",
      justifyContent: "center",
    },
    cardShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)" },
        },
      },
    ],
  });

  render() {
    const { data } = this.props;
    var onSiteColor = localStorage.getItem("theme_OnSite");
    var homeColor = localStorage.getItem("theme_Home");
    var noResponseColor = localStorage.getItem("theme_NoResponse");
    var notWorkingColor = localStorage.getItem("theme_NotWorking");

    var chartData = {
      datasets: [
        {
          data: [data?.workingOnSite, data?.siteMaxLimit],
          backgroundColor: [onSiteColor, noResponseColor],
        },
      ],
    };

    var options = {
      showTooltips: false,
      cutout: 35,
      events: [],
      animation: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltips: { enabled: false },
        hover: { mode: null }, // animation duration after a resize
      },
    };

    var onSiteColor = localStorage.getItem("theme_OnSite");
    var homeColor = localStorage.getItem("theme_Home");
    var noResponseColor = localStorage.getItem("theme_NoResponse");
    var notWorkingColor = localStorage.getItem("theme_NotWorking");

    return (
      <div
        key={this.state.key}
        style={{
          backgroundColor: "white",
          borderRadius: "5px",
          padding: "10px",
          height: "85px",
          marginBottom: "10px",
          minWidth: "250px",
          maxWidth: "250px",
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
        }}
        className={this.classNames.cardShadow}
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "50%",
            height: "90px",
            overflow: "none",
            width: "90px",
            position: "relative",
          }}
        >
          <div className={this.classNames.centerItem}>
            {" "}
            <div style={{ display: "flex", alignItems: "center", zIndex: 1 }}>
              <div style={{ width: "90px" }}>
                <Doughnut
                  data={chartData}
                  id="siteStatChart"
                  options={options}
                />
              </div>
            </div>
            <div
              style={{
                width: "90px",
                height: "90px",
                textAlign: "center",
                borderRadius: "100px",
                backgroundColor: "transparent",
                color: "black",
                position: "absolute",
              }}
            >
              <div
                style={{
                  fontWeight: "bolder",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  margin: "0 auto",
                  position: "relative",
                  width: "100%",
                }}
              >
                <div>
                  <div style={{ fontSize: "16px", marginBottom: "5px" }}>
                    {data?.percentage ? `${data?.percentage}%` : "?%"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "15px", alignSelf: "center" }}>
          <b>{data?.siteName}</b>
        </div>
      </div>
    );
  }

  async populateData() {}
}
