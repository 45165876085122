import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import { Shimmer } from "@fluentui/react";
import {
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import _ from "lodash";
import { UsersClient } from "../../../../infrastructure/api/UsersClient";
import { ReactComponent as ProfileLogo } from "../../../../assets/svg/portrait.svg";

export class ProfileImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentDate: new Date(new Date().toDateString()),
      currentUser: null,
    };

    this.inputElementRef = React.createRef();
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
  }

  componentWillReceiveProps(props) {}

  classNames = mergeStyleSets({
    container: {
      width: "100%",
      // background:
      //   "linear-gradient(170deg, rgba(0,0,0,0) 0%, rgba(214,237,241,1) 85%, rgba(221,244,248,1) 100%)",
      overflow: "hidden",
    },
    cardShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)" },
        },
      },
    ],
    buttonShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)" },
        },
      },
    ],
    centerItem: {
      display: "flex",
      justifyContent: "center",
    },
  });

  render() {
    const { currentUser, reloadPhoto, primaryTeam } = this.props;

    return (
      <>
        <div
          style={{
            borderRadius: "10px",
            height: "120px",
            width: "120px",
          }}
        >
          {currentUser?.profilePhoto ? (
            <img
              src={currentUser?.profilePhoto + "?" + new Date().getTime()}
              style={{
                borderRadius: "10px",
                cursor: "pointer",
                width: "120px",
                height: "120px",
              }}
              onClick={() => {
                this.inputElementRef.current.click();
              }}
            ></img>
          ) : (
            <ProfileLogo
              style={{
                borderRadius: "10px",
                cursor: "pointer",
                width: "120px",
                height: "120px",
              }}
              fill="#E2E2E2"
              stroke="#E2E2E2"
              onClick={() => {
                this.inputElementRef.current.click();
              }}
            />
          )}

          <input
            type="file"
            id="selectedFile"
            onChange={async (e) => {
              if (!e.target.files) return;

              await UsersClient.UploadPhoto(currentUser?.id, e.target.files[0]);

              reloadPhoto();
            }}
            accept={"image/*"}
            ref={this.inputElementRef}
            style={{ display: "none" }}
          />
        </div>
      </>
    );
  }

  async populateData() {
    this.setState({ loading: true });

    this.setState({
      loading: false,
    });
  }
}
