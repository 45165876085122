import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import { Dropdown, Label, DefaultButton, PrimaryButton } from "@fluentui/react";
import {
  AssetTypesQueryParams,
  AssetTypesClient,
} from "../../infrastructure/api/AssetTypesClient";

export class AssetTypeDropdownSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeStatus: "",
      loading: false,
      selectedData: null,
      data: [],
      enabledValues: [],
    };
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps, prevState) {
    const { enabledValues, selectedData } = prevProps;
    const { enabledValues: stateEnabledValues } = this.state;

    if (JSON.stringify(stateEnabledValues) !== JSON.stringify(enabledValues)) {
      this.setState({ enabledValues: enabledValues }, () =>
        this.populateData()
      );
    }

    if (this.state.selectedData?.id !== selectedData?.key) {
      this.setState({
        selectedData: { ...selectedData, id: selectedData?.key },
      });
    }
  }

  async update() {
    await this.populateData();
  }
  classNames = mergeStyleSets({
    dropdown: { borderRadius: "10px" },
  });

  render() {
    const { hideLabel, onSelect } = this.props;
    const { selectedData } = this.state;
    return (
      <>
        {!hideLabel && <Label>Asset Type</Label>}
        <Dropdown
          placeholder="Select an asset type"
          selectedKey={
            this.state.selectedData ? this.state.selectedData.id : null
          }
          options={this.state.data.map((item) => {
            return {
              id: item.id,
              key: item.id,
              text: item.name,
              attributes: item.attributes,
            };
          })}
          styles={{
            title: { borderRadius: "5px" },
            root: { width: "100%" },
          }}
          onChanged={(item) => {
            this.setState({
              selectedData: {
                id: item.key,
                name: item.text,
                attributes: item.attributes,
              },
            });
            onSelect({ ...item, id: item.key });
          }}
          className={this.classNames.dropdown}
        />
      </>
    );
  }

  async populateData() {
    const {
      onSelect,
      isBookableOnly,
      populateDefaultAttributes,
      selectedData,
      enabledValues,
    } = this.props;

    var query = new AssetTypesQueryParams();

    query.Sort("name", "asc");

    var result = await AssetTypesClient.List(query);

    var data = result?.data?.data;

    if (isBookableOnly && data && data.length > 0) {
      data = data.filter((x) => x.isBookable === true);
    }

    if (enabledValues && enabledValues.length > 0) {
      data = data.filter((item) => enabledValues.includes(item.id));
    } else {
      data = [];
    }

    this.setState({
      data: data,
    });

    if (data && data.length === 1) {
      this.setState({
        selectedData: data[0],
      });

      onSelect({
        id: data[0]?.id,
        name: data[0]?.name,
        attributes: data[0]?.attributes,
      });
    }

    if (populateDefaultAttributes && selectedData?.id) {
      var selected = data.filter((x) => x.id == selectedData?.id)[0];

      if (selected) {
        populateDefaultAttributes(selected?.attributes);
      }
    }
  }
}
