import { ApiResponse, ListQueryParams } from "../ApiClient";
import {
  GetAPIDomain,
  GetAPIHeaders,
  GetAPIHeadersPatch,
  MyDayAPIHelper,
} from "../MyDayAPIHelper";

export class EventsQueryParams extends ListQueryParams {
  WithEnabled(value: boolean) {
    this.WithParam("enabled", value);
    return this;
  }
  WithStatus(value: string) {
    this.WithParam("status", value);
    return this;
  }
  WithIsPlottable(value: boolean) {
    this.WithParam("isPlottable", value);
    return this;
  }
  WithTeamIds(value: any) {
    this.WithParam("teamIds", value);
    return this;
  }
  WithSiteIds(value: any) {
    this.WithParam("siteIds", value);
    return this;
  }
}

export class EventsClient {
  static async List(params: EventsQueryParams) {
    var query =
      (await GetAPIDomain()) + "/events" + params.GenerateQueryString();
    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async LinkAttendeeToEvent(id: string, userIds: any) {
    var query = (await GetAPIDomain()) + "/events/link-attendee";

    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
        method: "post",
        body: JSON.stringify({
          id,
          userIds,
        }),
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }

  static async RemoveAttendeeToEvent(id: string, userId: string) {
    var query = (await GetAPIDomain()) + "/events/remove-attendee";

    var headers = await GetAPIHeaders();

    var response = await ApiResponse.CreateFromResponse(
      await fetch(query, {
        headers: headers,
        method: "delete",
        body: JSON.stringify({
          id,
          userId,
        }),
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        return await ApiResponse.CreateFromResponse(
          await fetch(query, {
            headers: headers,
          })
        );
      }
    }

    return response;
  }
}
