var mondayLabel = localStorage.getItem("localization_Monday");
var tuesdayLabel = localStorage.getItem("localization_Tuesday");
var wednesdayLabel = localStorage.getItem("localization_Wednesday");
var thursdayLabel = localStorage.getItem("localization_Thursday");
var fridayLabel = localStorage.getItem("localization_Friday");
var saturdayLabel = localStorage.getItem("localization_Saturday");
var sundayLabel = localStorage.getItem("localization_Sunday");

export const getDayLocalizationLabel = (day) => {
  switch (day) {
    case "Monday":
      return mondayLabel;
    case "Tuesday":
      return tuesdayLabel;
    case "Wednesday":
      return wednesdayLabel;
    case "Thursday":
      return thursdayLabel;
    case "Friday":
      return fridayLabel;
    case "Saturday":
      return saturdayLabel;
    case "Sunday":
      return sundayLabel;
  }
};
