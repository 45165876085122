import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import {
  Dropdown,
  Label,
  DefaultButton,
  PrimaryButton,
  Panel,
  PanelType,
  ChoiceGroup,
} from "@fluentui/react";
import {
  AssetTypesQueryParams,
  AssetTypesClient,
} from "../../infrastructure/api/AssetTypesClient";
import { ReactComponent as CheckInLogo } from "../../assets/svg/grid.svg";
import { ReactComponent as Logo } from "../../assets/svg/grid-2.svg";
import { ReactComponent as AngleLogo } from "../../assets/svg/angle-right.svg";

export class AssetTypeSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeStatus: "",
      loading: false,
      selectedData: null,
      data: [],
    };
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate() {
    const { selectedData } = this.props;
    if (this.state.selectedData?.id !== selectedData?.id) {
      this.setState({ selectedData: selectedData });
    }
  }

  async update() {
    await this.populateData();
  }
  classNames = mergeStyleSets({
    dropdown: { borderRadius: "10px" },
  });

  render() {
    const { onSelect, enabledValues, isMobileView } = this.props;
    const { selectedData } = this.state;

    if (isMobileView)
      return (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={() => {
              this.setState({ isPanelOpen: true });
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <Logo
                fill={"#000"}
                stroke={"white"}
                height="25"
                width="25"
                style={{ overflow: "visible" }}
              /> */}
              <div
                style={{
                  marginLeft: "5px",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                Asset Type
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {this.state.selectedData && (
                <div
                  style={{
                    color: "grey",
                    fontWeight: 300,
                    marginRight: "5px",
                  }}
                >
                  {this.state.selectedData?.name}
                </div>
              )}
              <AngleLogo
                fill={"grey"}
                stroke={"grey"}
                height="12"
                width="12"
                style={{ overflow: "visible" }}
              />
            </div>
          </div>

          {this.renderPanel()}
        </>
      );
    else
      return (
        <>
          <Label>Asset Type</Label>

          {this.state.data &&
            this.state.data.map((item) => {
              const disabled =
                !enabledValues ||
                enabledValues.length === 0 ||
                !enabledValues.includes(item.id)
                  ? true
                  : false;
              return selectedData && selectedData?.id === item.id ? (
                <div style={{ margin: "5px 0" }}>
                  <PrimaryButton
                    text={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p style={{ marginLeft: "10px" }}>{item.name}</p>
                      </div>
                    }
                    onClick={() => {
                      this.setState({ selectedData: item });
                      onSelect(item);
                    }}
                    allowDisabledFocus
                    style={{ borderRadius: "5px", width: "100%" }}
                    disabled={disabled}
                  />
                </div>
              ) : (
                <div style={{ margin: "5px 0" }}>
                  <DefaultButton
                    text={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p style={{ marginLeft: "10px" }}>{item.name}</p>
                      </div>
                    }
                    onClick={() => {
                      this.setState({ selectedData: item });
                      onSelect(item);
                    }}
                    allowDisabledFocus
                    style={{ borderRadius: "5px", width: "100%" }}
                    disabled={disabled}
                  />
                </div>
              );
            })}
        </>
      );
  }

  renderPanel = () => {
    const { isPanelOpen, mobileData, selectedData } = this.state;
    const { onSelect } = this.props;
    return (
      <Panel
        type={PanelType.smallFluid}
        headerText="Choose Asset"
        isOpen={isPanelOpen}
        onDismiss={() => {
          this.setState({ isPanelOpen: false });
        }}
        // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
        closeButtonAriaLabel="Close"
      >
        <ChoiceGroup
          defaultSelectedKey={selectedData?.id}
          options={mobileData}
          onChange={(event, option) => {
            var selectedData = {
              id: option.key,
              name: option.text,
              attributes: option.attributes,
            };
            onSelect(selectedData);
            this.setState({ isPanelOpen: false, selectedData });
          }}
        />
      </Panel>
    );
  };

  async populateData() {
    const {
      onSelect,
      isBookableOnly,
      populateDefaultAttributes,
      selectedData,
    } = this.props;

    var query = new AssetTypesQueryParams();

    query.Sort("name", "asc");

    var result = await AssetTypesClient.List(query);

    var data = result?.data?.data;

    if (isBookableOnly && data && data.length > 0) {
      data = data.filter((x) => x.isBookable === true);
    }

    var mobileData = data.map((item) => ({
      key: item.id,
      text: item.name,
      attributes: item.attributes,
    }));

    this.setState({
      data: data,
      mobileData: mobileData,
    });

    if (data && data.length === 1) {
      this.setState({
        selectedData: data[0],
      });

      onSelect({
        id: data[0]?.id,
        name: data[0]?.name,
        attributes: data[0]?.attributes,
      });
    }

    if (populateDefaultAttributes && selectedData?.id) {
      var selected = data.filter((x) => x.id == selectedData?.id)[0];

      if (selected) {
        populateDefaultAttributes(selected?.attributes);
      }
    }
  }
}
