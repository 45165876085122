import React, { Component, useContext } from "react";
import { ReactComponent as OnSiteLogo } from "../../../assets/svg/building.svg";
import { ReactComponent as NotWorkingLogo } from "../../../assets/svg/brightness.svg";
import { ReactComponent as HomeLogo } from "../../../assets/svg/house-chimney.svg";
import { ReactComponent as WorkFromOfficeLogo } from "../../../assets/svg/home-location-filled.svg";
import { WorkDaysClient } from "../../../infrastructure/api/WorkDaysClient";
import moment from "moment";
import { WorkPlanSiteDropdown } from "../WorkPlanSiteDropdown/WorkPlanSiteDropdown";
import { WorkPlanNotWorkingDropdown } from "../WorkPlanNotWorkingDropdown/WorkPlanNotWorkingDropdown";
import {
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import _ from "lodash";
import Popup from "reactjs-popup";
import "./WeeklyPlannerActionSelector.css";
import { DefaultButton } from "@fluentui/react";

export class WeeklyPlannerActionSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
      myDay: null,
      sites: [],
      notWorkingReasons: [],
      defaultSite: null,
      defaultReason: null,
      showDropdown: false,
      oldState: null,
      hasSelected: false,
    };
  }

  classNames = mergeStyleSets({
    buttonShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)" },
        },
      },
    ],
  });

  componentDidMount() {
    this.populateDate();
  }

  componentDidUpdate() {
    const { sites, notWorkingReasons } = this.props;

    if (sites && sites.length !== this.state.sites.length) {
      var mappedSites =
        sites && sites.length > 0
          ? sites.map((item) => ({ name: item.name, id: item.id }))
          : [];

      this.setState({
        sites: mappedSites,
      });
    }

    if (
      notWorkingReasons &&
      notWorkingReasons.length !== this.state.notWorkingReasons.length
    ) {
      var mappedReasons =
        notWorkingReasons && notWorkingReasons.length > 0
          ? notWorkingReasons.map((item) => ({
              label: item.name,
              value: item.id,
            }))
          : [];
      this.setState({
        notWorkingReasons: mappedReasons,
      });
    }
  }

  reset() {
    this.setState({
      showDropdown: false,
      myDay: null,
      defaultSite: null,
      defaultReason: null,
      showDropdown: false,
      oldState: null,
      hasSelected: false,
    });
  }

  populateDate() {
    const { dayData, currentUser, sites, notWorkingReasons } = this.props;

    var mappedSites =
      sites && sites.length > 0
        ? sites.map((item) => ({ name: item.name, id: item.id }))
        : [];
    var mappedReasons =
      notWorkingReasons && notWorkingReasons.length > 0
        ? notWorkingReasons.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        : [];
    this.setState({
      sites: mappedSites,
      notWorkingReasons: mappedReasons,
    });

    if (dayData?.users && dayData?.users?.length > 0) {
      var currentUserData = _.filter(dayData?.users, function (o) {
        return o.user.id === currentUser.id;
      });

      if (currentUserData?.length > 0) {
        if (currentUserData[0]?.myDay?.parts[0]?.site) {
          var defaultSite = {
            id: currentUserData[0]?.myDay?.parts[0]?.site?.id,
            name: currentUserData[0]?.myDay?.parts[0]?.site?.name,
          };
          this.setState({
            defaultSite: defaultSite,
            myDay: currentUserData[0]?.myDay,
          });
        } else if (currentUserData[0]?.myDay?.parts[0]?.notWorkingReason) {
          var defaultReason = {
            value: currentUserData[0]?.myDay?.parts[0]?.notWorkingReason?.id,
            label: currentUserData[0]?.myDay?.parts[0]?.notWorkingReason?.name,
          };
          this.setState({
            defaultReason: defaultReason,
            myDay: currentUserData[0]?.myDay,
          });
        } else {
          this.setState({
            myDay: currentUserData[0]?.myDay,
          });
        }
      }
    }
  }

  render() {
    const { setCardsLoading, currentUser, refreshPlanner, selectMode } =
      this.props;

    var onSiteColor = localStorage.getItem("theme_OnSite");
    var homeColor = localStorage.getItem("theme_Home");
    var notWorkingColor = localStorage.getItem("theme_NotWorking");
    var noResponseColor = localStorage.getItem("localization_NoResponse");

    var action =
      this.state.myDay?.status &&
      (this.state.myDay?.status === "confirmed" ||
        this.state.myDay?.status === "planned")
        ? this.state.myDay?.parts[0]?.state
        : null;

    const onClick = async (status) => {
      const { todayOnwardDates } = this.props;

      var myDay = !this.state.myDay
        ? {
            date: new Date(this.props.currentDate).toDateString(),
            status: "planned",
            parts: [
              {
                state: "workFromOffice",
              },
            ],
          }
        : this.state.myDay;

      if (status === "workFromOffice") {
        myDay.parts[0].siteId = currentUser?.site?.id;
        myDay.parts[0].state = "workFromOffice";
        myDay.parts[0].notWorkingReasonId = null;
        this.setState({
          defaultSite: {
            id: currentUser?.site?.id,
            name: currentUser?.site?.name,
          },
        });
      } else if (status === "notWorking") {
        // myDay.parts[0].notWorkingReasonId = notWorkingReasons[0]?.id;
        myDay.parts[0].state = "notWorking";
        myDay.parts[0].siteId = null;
      } else if (status === "workFromHome") {
        myDay.parts[0].state = "workFromHome";
        myDay.parts[0].notWorkingReasonId = null;
        myDay.parts[0].siteId = null;
      }

      myDay.status = "planned";
      myDay.dates = todayOnwardDates;

      this.setState(
        {
          myDay: myDay,
        },
        () => {
          this.setState({
            defaultReason: null,
            hasSelected: true,
          });
          selectMode(true);
        }
      );
      //only create notworking after creating a reason
      // if (status === "notWorking") {
      //   this.setState({
      //     showDropdown: !this.state.showDropdown,
      //   });
      // }
    };

    return (
      <>
        <div
          style={{
            borderRadius: "5px",
            maxWidth: "190px",
            minWidth: "190px",
            textAlign: "left",
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "white",
            height: "20px",
          }}
          className={this.classNames.buttonShadow}
        >
          <span
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              onClick("workFromOffice");
            }}
          >
            <OnSiteLogo
              fill={action === "workFromOffice" ? onSiteColor : "#BFBFBF"}
              stroke={action === "workFromOffice" ? onSiteColor : "#BFBFBF"}
              height="20"
              width="20"
            />
          </span>

          <span
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              onClick("workFromHome");
            }}
          >
            <HomeLogo
              fill={action === "workFromHome" ? homeColor : "#BFBFBF"}
              stroke={action === "workFromHome" ? homeColor : "#BFBFBF"}
              height="20"
              width="20"
            />
          </span>
          <span
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              onClick("notWorking");
            }}
          >
            <NotWorkingLogo
              fill={action === "notWorking" ? notWorkingColor : "#BFBFBF"}
              stroke={action === "notWorking" ? notWorkingColor : "#BFBFBF"}
              height="20"
              width="20"
            />
          </span>
        </div>
        {action === "workFromOffice" && (
          <div style={{ marginLeft: "20px" }}>
            <WorkPlanSiteDropdown
              items={this.state.sites}
              defaultValue={this.state.defaultSite}
              onSelect={(item) => {
                var myDay = this.state.myDay;
                myDay.parts[0].notWorkingReasonId = null;
                myDay.parts[0].siteId = item.id;
                this.setState({ defaultSite: item, myDay });
              }}
            />
          </div>
          // <div
          //   style={{
          //     borderRadius: "5px",
          //     minWidth: "150px",
          //     height: "40px",
          //     textAlign: "left",
          //     padding: "10px",
          //     display: "flex",
          //     cursor: "pointer",
          //     marginBottom: "15px",
          //   }}
          //   className={this.classNames.buttonShadow}
          // >
          //   <OnSiteLogo
          //     fill="#38818D"
          //     stroke="#38818D"
          //     height="20"
          //     width="20"
          //   />
          //   <div style={{ paddingLeft: "10px" }}>
          //     {currentUser?.site?.name}{" "}
          //   </div>
          // </div>
        )}
        {/* {action === "notWorking" && (
          <div className="weekly-not-working-dropdown-wrapper">
            <ul className={action === "notWorking" ? "active" : ""}>
              {this.state.notWorkingReasons.map((item) => (
                <li
                  key={item.value}
                  onClick={async () => {
                    var myDay = this.state.myDay;
                    myDay.parts[0].notWorkingReasonId = item.value;

                    this.setState({
                      showDropdown: false,
                      hasSelected: true,
                      defaultReason: {
                        label: item.label,
                        value: item.value,
                      },
                      myDay: myDay,
                    });

                    // var newState = "notWorking";
                    // updatePie(false);
                    // await WorkDaysClient.Create(myDay);
                    // updatePie(true);
                  }}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          </div>
        )} */}
        {action === "notWorking" && (
          <div style={{ marginLeft: "20px" }}>
            <WorkPlanNotWorkingDropdown
              items={this.state.notWorkingReasons}
              defaultValue={this.state.defaultReason}
              onSelect={({ value, label }) => {
                var myDay = this.state.myDay;
                myDay.parts[0].notWorkingReasonId = value;

                this.setState({
                  defaultReason: {
                    label,
                    value,
                  },
                  myDay: myDay,
                });
                // WorkDaysClient.Create(myDay);
              }}
            />
          </div>
        )}

        {(action !== "notWorking" && this.state.hasSelected) ||
        (action === "notWorking" && this.state.defaultReason?.label) ? (
          <div style={{ display: "flex" }}>
            <div
              style={{
                borderRadius: "5px",
                width: "120px",
                height: "20px",
                textAlign: "left",
                padding: "10px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginBottom: "15px",
                background: "white",
                maxWidth: "230px",
                marginLeft: "20px",
                fontWeight: "bold",
              }}
              className="trigger-button"
              onClick={async () => {
                setCardsLoading(true);
                const { todayOnwardDates } = this.props;

                this.state.myDay.dates = todayOnwardDates;
                this.reset();
                action = null;
                await WorkDaysClient.Create(this.state.myDay);

                selectMode(false);
                refreshPlanner();
                setCardsLoading(false);
              }}
            >
              <span
                style={{
                  color:
                    action === "notWorking"
                      ? notWorkingColor
                      : action === "workFromOffice"
                      ? onSiteColor
                      : action === "workFromHome"
                      ? homeColor
                      : "gray",
                }}
              >
                Apply to week
              </span>
            </div>
            <div
              style={{
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                textAlign: "center",
                padding: "5px",
                cursor: "pointer",
                background: "white",
                fontWeight: "bold",
                fontSize: "15px",
                marginLeft: "10px",
                color: "red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                this.reset();
                action = null;
                selectMode(false);
                setCardsLoading(false);
              }}
            >
              X
            </div>
          </div>
        ) : (
          <div
            style={{
              fontWeight: "bold",
              fontSize: "14px",
              marginLeft: "20px",
              marginTop: "10px",
              color: "gray",
            }}
          >
            Apply to week
          </div>
        )}
      </>
    );
  }
}
