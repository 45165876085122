import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  DefaultButton,
  PrimaryButton,
  Stack,
  TextField,
  Panel,
  PanelType,
  Spinner,
  SpinnerSize,
  DatePicker,
  DayOfWeek,
  defaultDatePickerStrings,
} from "@fluentui/react";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import { ReactComponent as UserLogo } from "../../assets/svg/circle-user.svg";
import { ReactComponent as CheckLogo } from "../../assets/svg/check-circle.svg";

import { ReactComponent as TeamLogo } from "../../assets/svg/users-alt-solid.svg";
import { ReactComponent as VehicleLogo } from "../../assets/svg/car.svg";
import { ReactComponent as SkillLogo } from "../../assets/svg/user-check.svg";

import _ from "lodash";
import moment from "moment";
import { UsersClient } from "../../infrastructure/api/UsersClient";
import { ProfileImage } from "../Modules/UserProfile/AccountOverview/ProfileImage";
import { MonthlyOverview } from "../Modules/UserProfile/AccountOverview/MonthlyOverview";
import { TeamOverview } from "../Modules/UserProfile/AccountOverview/TeamOverview";
import { SiteOverview } from "../Modules/UserProfile/AccountOverview/SiteOverview";
import { TeamItem } from "../Modules/UserProfile/Teams/TeamItem";
import { VehicleItem } from "../Modules/UserProfile/Vehicles/VehicleItem";
import {
  VehiclesClient,
  VehiclesQueryParams,
} from "../../infrastructure/api/VehiclesClient";
import { VehicleTypeSelector } from "../Selectors/VehicleTypeSelector";
import { Formik } from "formik";
import * as Yup from "yup";
import { PermissionsClient } from "../../infrastructure/api/PermissionsClient";
import { UserPermissionTypes } from "../../constants/userPermissionTypes";
import { TeamsClient } from "../../infrastructure/api/TeamsClient";
import {
  UserAttributesClient,
  UserAttributesQueryParams,
} from "../../infrastructure/api/UserAttributesClient";
import { MyDayAPIHelper } from "../../infrastructure/MyDayAPIHelper";
import { SkillItem } from "../Modules/UserProfile/Skills/SkillItem";
import { ReactComponent as AddLogo } from "../../assets/svg/add.svg";
import { SkillWithDateItem } from "../Modules/UserProfile/Skills/SkillWithDateItem";
import { NoticeOverview } from "../Modules/UserProfile/AccountOverview/NoticeOverview";

export class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentDate: new Date(new Date().toDateString()),
      currentUser: null,
      searchable: false,
      vehicles: [],
      showVehiclePanel: false,
      initialVehicleValues: {
        name: "",
        make: "",
        model: "",
        typeId: "",
        rego: "",
        color: "",
        note: "",
      },
      reRender: false,
      teamBoxLoading: false,
      initialTeamValues: {
        name: "",
        description: "",
        status: "active",
        isPrivate: false,
        color: "#00838f",
      },
      showTeamPanel: false,
      showSkillsPanel: false,
      availableSkills: [],
      showDatePickerForSkillIndex: null,
      addedSkillIndexes: [],
      skillDatePickerValue: null,
    };

    this.inputElementRef = React.createRef();
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    this.setState({ teamBoxLoading: true });
    await this.populateData();
    this.populateVechicles();
    this.populateAvailableSkills();
  }

  componentWillReceiveProps(props) {}

  classNames = mergeStyleSets({
    container: {
      width: "100%",
      // background:
      //   "linear-gradient(170deg, rgba(0,0,0,0) 0%, rgba(214,237,241,1) 85%, rgba(221,244,248,1) 100%)",
      overflow: "hidden",
      padding: "40px 20px",
    },
    cardShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)" },
        },
      },
    ],
    buttonShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)" },
        },
      },
    ],
    endItem: {
      display: "flex",
      justifyContent: "end",
    },
    topAlign: {
      verticalAlign: "top",
    },
    fixed120: { height: "120px", width: "120px" },
    itemContent: {
      marginLeft: 10,
      overflow: "hidden",
      flexGrow: 1,
    },
    itemName: [
      getTheme().fonts.xLarge,
      {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    ],
    itemIndex: {
      fontSize: getTheme().fonts.small.fontSize,
      color: getTheme().palette.neutralTertiary,
      color: "#cac6c6",
      fontWeight: "bold",
    },
    itemCell: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: "border-box",
        display: "flex",
        margin: "5px",
        maxWidth: 450,
      },
    ],
    removeTeam: {
      margin: "auto",
      cursor: "pointer",
    },
    italicLabel: {
      color: "#cac6c6",
      fontSize: "13px",
    },
    textCenterEllipsis: {
      color: "white",
      fontSize: "12px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: "110px",
      textAlign: "center",
    },
  });

  renderVehiclePanel() {
    const ValidationSchema = Yup.object().shape({
      name: Yup.string().required("This field is required"),
      typeId: Yup.string().required("This field is required"),
    });

    return (
      <Panel
        isLightDismiss
        isHiddenOnDismiss
        onOuterClick={() => {}}
        isOpen={this.state.showVehiclePanel}
        onDismiss={() =>
          this.setState({
            showVehiclePanel: false,
          })
        }
        onOpen={() => {}}
        closeButtonAriaLabel="Close"
        headerText={
          <div>
            <span style={{ fontSize: "18px" }}>Add Vehicle</span>
          </div>
        }
        type={PanelType.smallFixedFar}
        styles={{
          content: {
            background: "white",
          },
          contentInner: {
            background: "white",
          },
          header: {
            background: "white",
          },
          navigation: {
            background: "white",
          },
          commands: {
            background: "white",
          },
        }}
      >
        <div style={{ marginTop: "20px" }}></div>

        <Formik
          initialValues={this.state.initialVehicleValues}
          validationSchema={ValidationSchema}
          onSubmit={async (values) => {
            this.setState({ buttonLoading: true });
            const { name, make, model, color, rego, note, typeId } = values;
            const { currentUser } = this.state;

            await VehiclesClient.Create(
              name,
              make,
              model,
              color,
              rego,
              note,
              currentUser.id,
              typeId
            );
            this.setState(
              {
                buttonLoading: false,
                showVehiclePanel: false,
                initialVehicleValues: {
                  name: "",
                  make: "",
                  model: "",
                  typeId: "",
                  rego: "",
                  color: "",
                  note: "",
                },
              },
              () => {
                this.populateVechicles();
              }
            );
          }}
        >
          {({
            errors,
            touched,
            isSubmitting,
            values,
            setFieldValue,
            resetForm,
            setSubmitting,
            submitForm,
            isValid,
          }) => (
            <>
              <table
                className="scroll-y table table-row-dashed gy-5"
                style={{ width: "100%" }}
              >
                <tbody className="fw-bold text-gray-600 w-100">
                  <tr>
                    <td className={this.classNames.topAlign}>
                      <TextField
                        label="Name"
                        required
                        onChange={(e) => {
                          setFieldValue("name", e.target.value);
                        }}
                        name="name"
                        value={values.name}
                        errorMessage={touched.name && errors.name}
                      />
                    </td>
                  </tr>{" "}
                  <tr>
                    <td className={this.classNames.topAlign}>
                      <VehicleTypeSelector
                        onSelect={async (item) => {
                          setFieldValue("typeId", item.id);
                        }}
                        vehicleTypeId={values.typeId}
                        required
                        disableSelectDefault
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={this.classNames.topAlign}>
                      <TextField
                        label="Make"
                        onChange={(e) => {
                          setFieldValue("make", e.target.value);
                        }}
                        value={values.make}
                        errorMessage={errors.make}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={this.classNames.topAlign}>
                      <TextField
                        label="Color"
                        onChange={(e) => {
                          setFieldValue("color", e.target.value);
                        }}
                        value={values.color}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={this.classNames.topAlign}>
                      <TextField
                        label="Model"
                        onChange={(e) => {
                          setFieldValue("model", e.target.value);
                        }}
                        value={values.model}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={this.classNames.topAlign}>
                      <TextField
                        label="Rego"
                        onChange={(e) => {
                          setFieldValue("rego", e.target.value);
                        }}
                        value={values.rego}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={this.classNames.topAlign}>
                      <TextField
                        label="Note"
                        onChange={(e) => {
                          setFieldValue("note", e.target.value);
                        }}
                        multiline
                        rows={3}
                        value={values.note}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "10px",
                }}
                className={this.classNames.topAlign}
              >
                <PrimaryButton
                  onClick={() => {
                    this.setState(
                      {
                        buttonLoading: true,
                      },
                      () => {
                        setSubmitting(true);
                        submitForm();
                      }
                    );
                  }}
                  disabled={!values.name || !values.typeId}
                >
                  {this.state.buttonLoading && (
                    <Spinner size={SpinnerSize.medium} />
                  )}
                  Add
                </PrimaryButton>
              </div>
            </>
          )}
        </Formik>
      </Panel>
    );
  }

  renderTeamPanel() {
    const colorRegex = /^#[0-9A-F]{6}$/i;
    const ValidationSchema = Yup.object().shape({
      name: Yup.string().required("This field is required"),
      color: Yup.string()
        .required("This field is required")
        .matches(colorRegex, "Invalid hex value."),
    });

    return (
      <Panel
        isLightDismiss
        isHiddenOnDismiss
        onOuterClick={() => {}}
        isOpen={this.state.showTeamPanel}
        onDismiss={() =>
          this.setState({
            showTeamPanel: false,
          })
        }
        onOpen={() => {}}
        closeButtonAriaLabel="Close"
        headerText={
          <div>
            <span style={{ fontSize: "18px" }}>Add Team</span>
          </div>
        }
        type={PanelType.smallFixedFar}
        styles={{
          content: {
            background: "white",
          },
          contentInner: {
            background: "white",
          },
          header: {
            background: "white",
          },
          navigation: {
            background: "white",
          },
          commands: {
            background: "white",
          },
        }}
      >
        <div style={{ marginTop: "20px" }}></div>

        <Formik
          initialValues={this.state.initialTeamValues}
          validationSchema={ValidationSchema}
          onSubmit={async (values) => {
            this.setState({ buttonLoading: true });
            const { name, description, status, isPrivate, color } = values;
            const { currentUser } = this.state;

            await TeamsClient.Create(
              name,
              description,
              status,
              isPrivate,
              color,
              true
            );

            this.setState(
              {
                buttonLoading: false,
                showTeamPanel: false,
                initialTeamValues: {
                  name: "",
                  description: "",
                  status: "active",
                  isPrivate: false,
                  color: "#00838f",
                },
              },
              () => {
                this.populateData();
              }
            );
          }}
        >
          {({
            errors,
            touched,
            isSubmitting,
            values,
            setFieldValue,
            resetForm,
            setSubmitting,
            submitForm,
            isValid,
          }) => (
            <>
              <table
                className="scroll-y table table-row-dashed gy-5"
                style={{ width: "100%" }}
              >
                <tbody className="fw-bold text-gray-600 w-100">
                  <tr>
                    <td className={this.classNames.topAlign}>
                      <TextField
                        label="Name"
                        required
                        onChange={(e) => {
                          setFieldValue("name", e.target.value);
                        }}
                        name="name"
                        value={values.name}
                        errorMessage={errors.name}
                      />
                    </td>
                  </tr>{" "}
                  <tr>
                    <td className={this.classNames.topAlign}>
                      <TextField
                        label="Color"
                        onChange={(e) => {
                          setFieldValue("color", e.target.value);
                        }}
                        value={values.color}
                        errorMessage={errors.color}
                      />
                    </td>
                  </tr>
                  <tr>
                    <TextField
                      type="text"
                      disabled
                      value=""
                      className="form-control"
                      style={{
                        backgroundColor: `${values.color}`,
                        width: "100%",
                      }}
                    />
                  </tr>
                </tbody>
              </table>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "10px",
                }}
                className={this.classNames.topAlign}
              >
                <PrimaryButton
                  onClick={() => {
                    this.setState(
                      {
                        buttonLoading: true,
                      },
                      () => {
                        setSubmitting(true);
                        submitForm();
                      }
                    );
                  }}
                  disabled={errors.name || errors.color}
                >
                  {this.state.buttonLoading && (
                    <Spinner size={SpinnerSize.medium} />
                  )}
                  Add
                </PrimaryButton>
              </div>
            </>
          )}
        </Formik>
      </Panel>
    );
  }

  populateAvailableSkills = async () => {
    const { currentUser } = this.props;
    var response = await UserAttributesClient.List(
      new UserAttributesQueryParams()
        .Paginate(0, 999)
        .Sort("name", "asc")
        .WithExcludeFromUserId(currentUser.id)
    );

    if (response.successful) {
      this.setState({ availableSkills: response.data.data });
    }
  };

  renderSkillsPanel() {
    const { currentUser } = this.props;

    return (
      <Panel
        isLightDismiss
        isHiddenOnDismiss
        onOuterClick={() => {}}
        isOpen={this.state.showSkillsPanel}
        onDismiss={() => {
          this.populateData(true);
          this.setState({
            showSkillsPanel: false,
            showDatePickerForSkillIndex: null,
            skillDatePickerValue: null,
            addedSkillIndexes: [],
          });
        }}
        closeButtonAriaLabel="Close"
        headerText={
          <div>
            <span style={{ fontSize: "18px" }}>Skills</span>
          </div>
        }
        type={PanelType.smallFixedFar}
        styles={{
          content: {
            background: "white",
          },
          contentInner: {
            background: "white",
          },
          header: {
            background: "white",
          },
          navigation: {
            background: "white",
          },
          commands: {
            background: "white",
          },
        }}
      >
        <div style={{ marginTop: "20px" }}></div>

        <div
          className="scroll-y table table-row-dashed gy-5"
          style={{ width: "100%" }}
        >
          {this.state.availableSkills &&
            this.state.availableSkills.length > 0 &&
            this.state.availableSkills.map((skill, index) => {
              return (
                <>
                  <div
                    className={this.classNames.buttonShadow}
                    style={{
                      padding: skill.expiryRequired ? "10px" : "5px 10px",
                      borderRadius: "15px",
                      marginTop: "10px",
                    }}
                  >
                    {!skill.expiryRequired && (
                      <div style={{ cursor: "default" }}>
                        <div
                          style={{
                            backgroundColor: "white",
                            borderRadius: "15px",
                            cursor: "default",
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => {}}
                        >
                          <div
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              background: skill?.color
                                ? skill?.color
                                : "#00838f",
                            }}
                          ></div>
                          <div
                            style={{
                              flexGrow: 1,
                              marginLeft: "10px",
                            }}
                          >
                            <div style={{ fontSize: "15px" }}>{skill.name}</div>
                          </div>

                          <div className={this.classNames.removeTeam}>
                            {this.state.addedSkillIndexes.includes(index) ? (
                              <CheckLogo
                                height="20"
                                width="20"
                                style={{
                                  overflow: "visible",
                                  padding: "10px 0",
                                }}
                                stroke={"#00838f"}
                                fill={"#00838f"}
                              />
                            ) : (
                              <AddLogo
                                height="20"
                                width="20"
                                style={{
                                  overflow: "visible",
                                  padding: "10px 0",
                                }}
                                stroke={"#00838f"}
                                fill={"#00838f"}
                                onClick={async () => {
                                  this.setState({
                                    addedSkillIndexes: [
                                      ...this.state.addedSkillIndexes,
                                      index,
                                    ],
                                  });
                                  await UserAttributesClient.AddLinkedUsers(
                                    skill.id,
                                    [currentUser.id]
                                  );
                                  // populateAvailableSkills();
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {skill.expiryRequired && (
                      <SkillWithDateItem
                        skill={skill}
                        currentUser={currentUser}
                      />
                    )}
                  </div>
                </>
              );
            })}
        </div>
      </Panel>
    );
  }

  render() {
    var stackTokens = {
      childrenGap: 20,
      padding: 20,
    };

    const { currentUser, reloadPhoto, primaryTeam } = this.state;
    const { currentUser: propsUser } = this.props;
    const { vehicles } = this.state;
    return (
      <>
        {this.renderVehiclePanel()}
        {this.renderTeamPanel()}
        {this.renderSkillsPanel()}
        <div className={this.classNames.container}>
          <div
            style={{ fontWeight: "bold", textAlign: "left", fontSize: "15px" }}
          >
            Account Overview
          </div>
          <Stack horizontal tokens={stackTokens} horizontalAlign={"start"}>
            <ProfileImage
              currentUser={currentUser}
              reloadPhoto={() => {
                this.populateData(true);
              }}
            />
            <div className={this.classNames.fixed120}>
              <TeamOverview
                currentUser={currentUser}
                reloadUser={async () => {
                  this.setState({
                    reRender: !this.state.reRender,
                    teamBoxLoading: true,
                  });
                  await this.populateData(true);
                }}
                reRender={this.state.reRender}
                loading={this.state.teamBoxLoading}
              />
            </div>

            <SiteOverview
              currentUser={currentUser}
              reloadUser={() => {
                this.populateData(true);
              }}
            />

            <NoticeOverview currentUser={currentUser} />

            <MonthlyOverview
              currentUser={currentUser}
              reRender={this.state.reRender}
            />
          </Stack>

          <div
            style={{ fontWeight: "bold", textAlign: "left", fontSize: "15px" }}
          >
            Teams
          </div>
          <Stack horizontal tokens={stackTokens} horizontalAlign={"start"}>
            {currentUser &&
              currentUser.teamMembersList &&
              currentUser.teamMembersList.length > 0 &&
              currentUser.teamMembersList.map((teamMember) => {
                return (
                  <TeamItem
                    teamMember={teamMember}
                    reloadTeams={async (softReload = false) => {
                      if (!softReload)
                        this.setState({
                          reRender: !this.state.reRender,
                          teamBoxLoading: true,
                        });

                      await this.populateData(true);

                      this.setState({
                        reRender: !this.state.reRender,
                        teamBoxLoading: false,
                      });
                    }}
                    currentUser={propsUser}
                  />
                );
              })}

            {localStorage.getItem("userPermissions") !== null &&
              localStorage
                .getItem("userPermissions")
                .includes(UserPermissionTypes.MANAGE_TEAMS) && (
                <div className={this.classNames.fixed120}>
                  <div
                    style={{
                      borderRadius: "10px",
                      height: "120px",
                      width: "120px",
                      background: "#E2E2E2",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.setState({ showTeamPanel: true });
                    }}
                  >
                    <TeamLogo
                      height="60"
                      width="60"
                      style={{ overflow: "visible", padding: "10px 0" }}
                      stroke={"white"}
                      fill={"white"}
                    />
                    <b style={{ color: "white", fontSize: "13px" }}>Add</b>
                  </div>
                </div>
              )}
          </Stack>

          <div
            style={{ fontWeight: "bold", textAlign: "left", fontSize: "15px" }}
          >
            Skills
          </div>
          <Stack horizontal tokens={stackTokens} horizontalAlign={"start"}>
            {currentUser &&
              currentUser.userSkillMapped &&
              currentUser.userSkillMapped.length > 0 &&
              currentUser.userSkillMapped.map((userSkill) => {
                return (
                  <SkillItem
                    userSkill={userSkill}
                    currentUser={propsUser}
                    refreshSkills={() => {
                      this.populateData(true);
                    }}
                  />
                );
              })}
            <div className={this.classNames.fixed120}>
              <div
                style={{
                  borderRadius: "10px",
                  height: "120px",
                  width: "120px",
                  background: "#E2E2E2",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.setState({ showSkillsPanel: true });
                }}
              >
                <SkillLogo
                  height="60"
                  width="60"
                  style={{
                    overflow: "visible",
                    padding: "10px 0",
                    marginLeft: "10px",
                  }}
                  stroke={"white"}
                  fill={"white"}
                />
                <b style={{ color: "white", fontSize: "13px" }}>Add</b>
              </div>
            </div>
          </Stack>

          <div
            style={{ fontWeight: "bold", textAlign: "left", fontSize: "15px" }}
          >
            Vehicles
          </div>

          <Stack horizontal tokens={stackTokens} horizontalAlign={"start"}>
            {vehicles &&
              vehicles.length > 0 &&
              vehicles.map((vehicle) => {
                return (
                  <div className={this.classNames.fixed120}>
                    <VehicleItem
                      vehicle={vehicle}
                      refreshVehicles={() => {
                        this.populateVechicles();
                      }}
                      currentUser={currentUser}
                    />
                  </div>
                );
              })}
            <div className={this.classNames.fixed120}>
              <div
                style={{
                  borderRadius: "10px",
                  height: "120px",
                  width: "120px",
                  background: "#E2E2E2",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.setState({ showVehiclePanel: true });
                }}
              >
                <VehicleLogo
                  height="60"
                  width="60"
                  style={{ overflow: "visible", padding: "10px 0" }}
                  stroke={"white"}
                  fill={"white"}
                />
                <b style={{ color: "white", fontSize: "13px" }}>Add</b>
              </div>
            </div>
          </Stack>

          <div
            style={{ fontWeight: "bold", textAlign: "left", fontSize: "15px" }}
          >
            Other
          </div>

          <Stack horizontal tokens={stackTokens} horizontalAlign={"start"}>
            <div className={this.classNames.fixed120}>
              <div
                style={{
                  borderRadius: "10px",
                  height: "120px",
                  width: "120px",
                  background: "#E2E2E2",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  cursor: "pointer",
                }}
                onClick={() => {
                  MyDayAPIHelper.LogOut();
                  window.location.reload();
                }}
              >
                <VehicleLogo
                  height="60"
                  width="60"
                  style={{ overflow: "visible", padding: "10px 0" }}
                  stroke={"white"}
                  fill={"white"}
                />
                <b style={{ color: "white", fontSize: "13px" }}>Sign out</b>
              </div>
            </div>
          </Stack>
        </div>
      </>
    );
  }

  async populateData(softLoad = false) {
    if (!softLoad) this.setState({ loading: true });

    var currentUser = await UsersClient.Get("current", true);

    this.setState({
      currentUser: currentUser.data,
      loading: false,
      teamBoxLoading: false,
      reRender: softLoad ? this.state.reRender : !this.state.reRender,
    });

    var userPermissions = localStorage.getItem("userPermissions");

    if (userPermissions === null) {
      var permissions = await PermissionsClient.ForCurrentUser();

      var mappedPermissions = permissions.data.data.map((p) => p.id);

      localStorage.setItem(
        "userPermissions",
        JSON.stringify(mappedPermissions)
      );
    }
  }

  async populateVechicles() {
    this.setState({
      vehiclesLoading: true,
    });
    const { currentUser } = this.props;

    var query = new VehiclesQueryParams();
    var response = await VehiclesClient.List(
      query.Paginate(0, 9999).Sort("name", "asc").WithUserId(currentUser.id)
    );

    if (!response.authenticated) {
    } else {
      const { data } = response;

      this.setState({
        vehiclesLoading: false,
        vehicles: data?.data,
      });
    }
    this.setState({
      vehiclesLoading: false,
    });
  }
}
