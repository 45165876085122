import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Panel,
  PanelType,
  TextField,
  Shimmer,
  Label,
  Dropdown,
  IconButton,
  Popup,
  DefaultButton,
  Toggle,
  ChoiceGroup,
  Dialog,
  DialogContent,
  PrimaryButton,
  DialogType,
  defaultDatePickerStrings,
  DatePicker,
  DayOfWeek,
  DialogFooter,
  Calendar,
  defaultCalendarStrings,
} from "@fluentui/react";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import { Icon, FontIcon } from "@fluentui/react/lib/Icon";
import {
  AssetsQueryParams,
  AssetsClient,
} from "../../../infrastructure/api/AssetsClient";
import { CheckInsClient } from "../../../infrastructure/api/CheckInsClient";

import { ReactComponent as CheckInLogo } from "../../../assets/svg/grid-2.svg";
import { ReactComponent as AddLogo } from "../../../assets/svg/add.svg";
import { ReactComponent as BookAssetLogo } from "../../../assets/svg/add.svg";
import { ReactComponent as CloseIcon } from "../../../assets/svg/cross.svg";

import { EventsTable } from "../../Charts/EventsTable";
import moment from "moment-timezone";
import { AssetTypeSelector } from "../../Selectors/AssetTypeSelector";
import { FloorSelector } from "../../Selectors/FloorSelector";
import { BookAssetItem } from "../BookAssetItem/BookAssetItem";
import {
  getDates,
  getMonday,
} from "../../../infrastructure/helpers/datesHelper";
import _ from "lodash";
import { SitesClient } from "../../../infrastructure/api/SitesClient";
import Moment from "react-moment";
import { getDayLocalizationLabel } from "../../../helpers/localizationHelper";
import { TooltipHost, TooltipDelay, DirectionalHint } from "@fluentui/react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { NeighborhoodSelector } from "../../Selectors/NeighborhoodSelector";
import { UserAttributeSelector } from "../../Selectors/UserAttributeSelector";
import { ReactComponent as AngleLogo } from "../../../assets/svg/angle-right.svg";
import { ReactComponent as AttributeLogo } from "../../../assets/svg/apps.svg";
import { ReactComponent as FilterLogo } from "../../../assets/svg/settings-sliders.svg";
import { ReactComponent as AngleDownLogo } from "../../../assets/svg/angle-small-down.svg";
import { ReactComponent as MapLogo } from "../../../assets/svg/map.svg";
import { ReactComponent as ListLogo } from "../../../assets/svg/list.svg";
import { ReactComponent as IconLogo } from "../../../assets/svg/info.svg";
import { AssetTypeDropdownSelector } from "../../Selectors/AssetTypeDropdownSelector";
import { MobileCalendarPicker } from "../MobileCalendarPicker/MobileCalendarPicker";
import { FloorPlanMobile } from "../FloorPlanMobile/FloorPlanMobile";
import { SiteSelector } from "../../Selectors/SiteSelector";
import { ReactComponent as TeamLogo } from "../../../assets/svg/users-alt-solid-2.svg";

export class BookAssetButton extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentDate: new Date(new Date().toDateString()),
      showSidePanel: false,
      events: [],
      userId: null,
      floor: {},
      selectedAssetType: {},
      selectedAssetTypes: [],
      assetTypes: [],
      assets: [],
      fullAssetsList: [],
      assetItemIndexOpened: null,
      isTooltipOpen: false,
      site: {},
      showCheckInTooltip: false,
      initialCheckInsLength: 0,
      attributes: [],
      neighborhoodId: null,
      selectedAttribute: null,
      selectedNeighborhood: null,
      attributeId: null,
      showFilters: true,
      isAttributePanelOpen: false,
      showDatePickerDialog: false,
      showFloorPlan: false,
      showMapLegendPanel: false,
      filterSite: {},
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.initializeData();
  }

  componentDidUpdate(props) {
    const { checkIns, date } = props;

    if (checkIns) {
      if (checkIns.length !== this.state.initialCheckInsLength) {
        this.setState({
          showCheckInTooltip: checkIns.length < 1 ? false : true,
          initialCheckInsLength: checkIns.length,
        });
      }
    }

    if (date != this.state.currentDate) {
      this.setState(
        {
          currentDate: date,
        },
        this.populateAssets
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async update() {
    this.populateAssets();
  }

  async initializeData(softLoad = false) {
    if (!softLoad) this.setState({ loading: true });
    const { checkIns, site, currentUser } = this.props;

    var useSite = site;

    if (!useSite) {
      useSite = currentUser?.site;
    }

    if (useSite?.id) {
      var assetTypesResponse = await SitesClient.GetAssetTypesFromSite(
        useSite?.id
      );

      var assetTypes = assetTypesResponse.data;

      var storageSelectedAssetType = localStorage.getItem(
        "planner_selectedAssetType"
      );

      var selectedAssetType =
        storageSelectedAssetType !== null
          ? JSON.parse(storageSelectedAssetType)
          : {};

      var isAssetTypePresent = _.find(assetTypes, (o) => {
        return o.id == selectedAssetType?.id;
      });

      var storageSelectedFloor = localStorage.getItem("assets_selectedFloor");

      this.setState(
        {
          assetTypes: assetTypes,
          floorAssetTypes: assetTypes,
          selectedAssetType: isAssetTypePresent ? selectedAssetType : {},
          floor:
            storageSelectedFloor !== null
              ? JSON.parse(storageSelectedFloor)
              : {},
          site: useSite,
          showCheckInTooltip: checkIns && checkIns.length <= 1 ? false : true,
          initialCheckInsLength:
            checkIns && checkIns.length > 0 ? checkIns.length : 0,
        },
        () => this.update()
      );
    }
  }

  classNames = mergeStyleSets({
    buttonShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)" },
        },
      },
    ],
    container: {
      overflow: "auto",
      maxHeight: 1000,
      borderRadius: "10px",
    },
    itemCell: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: "border-box",
        borderBottom: `1px solid ${getTheme().semanticColors.bodyDivider}`,
        display: "flex",
        transition: "0.3s",
        margin: "10px 0px",
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": {
            background: getTheme().palette.neutralLight,
            boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)",
          },
        },
      },
    ],

    filterContainer: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: "border-box",
        borderBottom: `1px solid ${getTheme().semanticColors.bodyDivider}`,
        display: "flex",
        transition: "0.3s",
        marginTop: "5px",
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": {
            background: getTheme().palette.neutralLight,
            boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)",
          },
        },
      },
    ],
    itemImage: {
      // borderRadius: "50%",
      // background: "#edebed",
      width: 40,
      height: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    filterImage: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    itemContent: {
      marginLeft: 10,
      overflow: "hidden",
      flexGrow: 1,
    },
    itemName: [
      getTheme().fonts.xLarge,
      {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    ],
    itemIndex: {
      fontSize: getTheme().fonts.small.fontSize,
      color: getTheme().palette.neutralTertiary,
    },
    chevron: {
      alignSelf: "center",
      marginLeft: 10,
      color: getTheme().palette.neutralTertiary,
      fontSize: getTheme().fonts.large.fontSize,
      flexShrink: 0,
    },
    contentClass: {
      backgroundColor: getTheme().palette.neutralLight,
      color: getTheme().palette.neutralDark,
      lineHeight: "50px",
      padding: "0 20px",
    },
  });

  render() {
    const {
      label,
      labelComponent,
      checkIns,
      softReload,
      fromPulseCard,
      fromMobileView,
      site,
      loading,
    } = this.props;

    var onSiteColor = localStorage.getItem("theme_OnSite");
    return (
      <>
        {fromMobileView && (
          <>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                  cursor:
                    checkIns && checkIns.length > 0 && !fromPulseCard
                      ? "default"
                      : "pointer",
                }}
              >
                <div
                  style={{
                    backgroundColor: onSiteColor,
                    borderRadius: "20px",
                    padding: "12px",
                    height: "25px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    width: "65px",
                    marginRight: "30px",
                  }}
                  className={this.classNames.cardShadow}
                  onClick={() => {
                    if (!loading) {
                      this.setState(
                        {
                          showSidePanel: true,
                          showFilters: false,
                        },
                        () => this.initializeData(true)
                      );
                    }
                  }}
                >
                  <BookAssetLogo
                    fill={onSiteColor}
                    stroke={"white"}
                    height="25"
                    width="25"
                    style={{ overflow: "visible" }}
                  />
                  <div
                    style={{
                      marginLeft: "5px",
                      color: "white",
                      fontWeight: 600,
                    }}
                  >
                    Book
                  </div>
                </div>
              </div>
            </div>
            {this.renderMobilePanel()}
          </>
        )}
        {!fromMobileView && (
          <>
            <div
              style={
                labelComponent
                  ? {}
                  : {
                      borderRadius: "5px",
                      minWidth: "150px",
                      textAlign: "left",
                      padding: "10px",
                      display: "flex",
                      marginBottom: "15px",
                      justifyContent: "space-between",
                    }
              }
              className={this.classNames.buttonShadow}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  cursor:
                    checkIns && checkIns.length > 0 && !fromPulseCard
                      ? "default"
                      : "pointer",
                }}
                onClick={() => {
                  if (checkIns && checkIns.length > 0 && !fromPulseCard) {
                  } else {
                    this.setState({ showSidePanel: true });
                  }
                }}
              >
                {labelComponent ? (
                  labelComponent
                ) : (
                  <div
                    style={{
                      display: "flex",
                      cursor: "pointer",
                    }}
                    onClick={() => this.setState({ showSidePanel: true })}
                  >
                    <CheckInLogo
                      fill={onSiteColor}
                      stroke={onSiteColor}
                      height="20"
                      width="20"
                    />

                    {label.length > 15 ? (
                      <TooltipHost content={label}>
                        <div style={{ paddingLeft: "10px" }}>
                          {label.slice(0, 15)}...
                        </div>
                      </TooltipHost>
                    ) : (
                      <div style={{ paddingLeft: "10px" }}>{label}</div>
                    )}
                  </div>
                )}

                {fromPulseCard && checkIns && checkIns.length > 0 && (
                  <div>
                    <AddLogo
                      fill={onSiteColor}
                      stroke={onSiteColor}
                      height="20"
                      width="20"
                      style={{ overflow: "visible", cursor: "pointer" }}
                    />
                  </div>
                )}
                {!fromPulseCard &&
                  this.state.showCheckInTooltip &&
                  checkIns &&
                  checkIns.length > 0 && (
                    <div
                      onClick={() =>
                        this.setState({ isTooltipOpen: !this.isTooltipOpen })
                      }
                    >
                      <a
                        id={`tooltip-${checkIns[0].id}`}
                        // onMouseEnter={() => this.setState({ isTooltipOpen: true })}
                      >
                        {" "}
                        <div
                          style={{
                            color: "gray",
                            fontWeight: "bolder",
                            cursor: "pointer",
                          }}
                        >
                          {checkIns.length}
                        </div>
                      </a>
                      <ReactTooltip
                        anchorSelect={`#tooltip-${checkIns[0].id}`}
                        clickable
                        content={
                          <div>
                            {checkIns.map((checkIn) => (
                              <div style={{ display: "flex" }}>
                                <div
                                  onClick={() => {
                                    localStorage.setItem(
                                      "planner_selectedAssetType",
                                      JSON.stringify(checkIn?.asset?.assetType)
                                    );
                                    this.setState({
                                      showSidePanel: true,
                                      selectedAssetType:
                                        checkIn?.asset?.assetType,
                                    });
                                  }}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  {checkIn?.asset?.assetType?.name} -{" "}
                                  {checkIn?.asset?.name} -{" "}
                                  <Moment utc local format="h:mm A">
                                    {moment(checkIn.checkInDate)}
                                    {/* {moment(checkin.checkInDate).add(5, "h")} */}
                                  </Moment>{" "}
                                  -{" "}
                                  <Moment utc local format="h:mm A">
                                    {moment(checkIn.checkOutDue)}
                                    {/* {moment(checkin.checkOutDue).add(5, "h")} */}
                                  </Moment>
                                </div>

                                <span
                                  style={{
                                    marginLeft: "5px",
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                  onClick={async () => {
                                    this.setState({ isTooltipOpen: false });
                                    await CheckInsClient.ReleaseCheckIn(
                                      checkIn.id
                                    );

                                    softReload();
                                  }}
                                >
                                  X
                                </span>
                              </div>
                            ))}
                          </div>
                        }
                        openOnClick
                        // isOpen={this.state.isTooltipOpen}
                        style={{
                          backgroundColor: onSiteColor,
                          zIndex: 3,
                          opacity: 1,
                        }}
                      />
                    </div>
                  )}
              </div>
            </div>
            {this.renderPanel()}
          </>
        )}
      </>
    );
  }

  async populateAssets(forceReload = false) {
    const { site, date, allDates } = this.props;
    const { currentWeekStartDate, neighborhoodId, attributeId } = this.state;
    this.setState({ loading: true });

    this.setState({ weekDates: allDates });

    if (
      (this.state.selectedAssetType?.id && this.state.floor?.id) ||
      forceReload
    ) {
      var tz = moment.tz.guess();
      const start = moment.tz(date, tz); // original timezone

      const local = start.tz(tz).format("YYYY-MM-DD[T]HH:mm:ss.SSS");

      var withTimeUtc = moment(local).utc().format("YYYY-MM-DD[T]HH:mm:ss.SSS");

      var end = moment(withTimeUtc)
        .add(24, "h")
        .subtract(1, "s")
        .format("YYYY-MM-DD[T]HH:mm:ss.SSS");

      var query = new AssetsQueryParams();

      query.WithStatus("active");

      query.WithStart(withTimeUtc);
      query.WithEnd(end);

      query.Paginate(0, 2000);

      if (this.state.selectedAssetType?.id)
        query.WithAssetTypeId(this.state.selectedAssetType?.id);

      if (this.state.floor?.id) query.WithFloorId(this.state.floor?.id);

      if (neighborhoodId) query.WithNeighborhoodId(neighborhoodId);
      if (attributeId) query.WithAttributeIds([attributeId]);

      var response = await AssetsClient.List(query);

      if (response.data) {
        const { data } = response.data;

        this.setState({
          assets: data,
          fullAssetsList: data,
          loading: false,
        });
      }
    } else {
      this.setState({
        assets: [],
        fullAssetsList: [],
        loading: false,
      });
    }

    this.setState({ loading: false });
  }

  renderPanel() {
    const { site, date, refreshPlanner, refreshDay } = this.props;
    return (
      <Panel
        isLightDismiss
        onOuterClick={() => {}}
        isOpen={this.state.showSidePanel}
        onDismiss={() =>
          this.setState({
            showSidePanel: false,
            assets: [],
            floor: {},
            selectedAssetType: {},
            neighborhoodId: null,
            attributeId: null,
          })
        }
        onOpen={() => {
          this.initializeData(true);
        }}
        closeButtonAriaLabel="Close"
        headerText={
          <div>
            <span style={{ fontSize: "18px" }}>{`Assets: ${site?.name}`}</span>
            <br />
            <span style={{ fontSize: "12px" }}>
              {getDayLocalizationLabel(moment(date).format("dddd"))}
              <Moment utc local format=", DD MMMM">
                {date}
              </Moment>
            </span>
          </div>
        }
        type={PanelType.smallFixedFar}
        styles={{
          content: {
            background: "white",
          },
          contentInner: {
            background: "white",
          },
          header: {
            background: "white",
          },
          navigation: {
            background: "white",
          },
          commands: {
            background: "white",
          },
        }}
      >
        <div style={{ marginTop: "20px" }}></div>

        <Toggle
          label=""
          inlineLabel
          onText={<b style={{ fontWeight: 600 }}>filters</b>}
          offText={<b style={{ fontWeight: 600 }}>filters</b>}
          onChange={(e, checked) => {
            this.setState({ showFilters: checked });
          }}
          defaultChecked={this.state.showFilters}
        />

        <div style={{ display: this.state.showFilters ? "contents" : "none" }}>
          <FloorSelector
            siteId={site?.id}
            onSelect={(value) => {
              var floorAssetTypes = this.state.assetTypes.filter(
                (x) => x.floorId === value.id
              );

              this.setState(
                { floor: value, floorAssetTypes: floorAssetTypes },
                () => this.populateAssets()
              );
            }}
          />

          <NeighborhoodSelector
            siteId={site?.id}
            onSelect={(value) => {
              const { id } = value;
              this.setState({ neighborhoodId: id }, () =>
                this.populateAssets()
              );
            }}
            onDeselect={() => {
              this.setState({ neighborhoodId: null }, () =>
                this.populateAssets()
              );
            }}
            hideWhenNoData
          />

          {this.state.attributes && this.state.attributes.length > 0 && (
            <>
              {" "}
              <Label>Attributes</Label>
              <div style={{ display: "flex", width: "100%" }}>
                <Dropdown
                  placeholder="Select Attributes"
                  selectedKey={
                    this.state.attributeId ? this.state.attributeId : null
                  }
                  options={this.state.attributes.map((item) => {
                    return {
                      key: item.id,
                      text: item.name,
                    };
                  })}
                  onChanged={(item) => {
                    this.setState({ attributeId: item.key }, () =>
                      this.populateAssets()
                    );
                  }}
                  styles={{
                    title: { borderRadius: "5px" },
                    root: { width: "100%" },
                  }}
                />
                <IconButton
                  iconProps={{ iconName: "Blocked" }}
                  title="Deselect"
                  ariaLabel="Deselect"
                  styles={{ root: { color: "red" } }}
                  onClick={() => {
                    this.setState({ attributeId: null }, () =>
                      this.populateAssets()
                    );
                  }}
                />
              </div>
            </>
          )}

          <AssetTypeSelector
            onSelect={(value) => {
              if (this.state.floor) {
                var floorAssetTypes = this.state.assetTypes.filter(
                  (x) => x.floorId === this.state.floor.id
                );

                this.setState(
                  {
                    selectedAssetType: value,
                    floorAssetTypes: floorAssetTypes,
                    attributes: value?.attributes || [],
                    attributeId: null,
                  },
                  () => this.populateAssets()
                );
              } else {
                this.setState(
                  {
                    selectedAssetType: value,
                    attributes: value?.attributes || [],
                    attributeId: null,
                  },
                  () => this.populateAssets()
                );
              }

              localStorage.setItem(
                "planner_selectedAssetType",
                JSON.stringify(value)
              );
            }}
            enabledValues={
              this.state.floorAssetTypes &&
              this.state.floorAssetTypes.length > 0
                ? this.state.floorAssetTypes?.map((x) => x.id)
                : []
            }
            isBookableOnly
            selectedData={this.state.selectedAssetType}
            populateDefaultAttributes={(attributes) => {
              this.setState({
                attributes: attributes || [],
                attributeId: null,
              });
            }}
          />
        </div>

        {this.state.fullAssetsList.length > 0 && (
          <div style={{ marginTop: "20px" }}>
            <TextField
              placeholder="Search"
              onChange={(e) => {
                if (e.target.value) {
                  var filtered = _.filter(
                    this.state.fullAssetsList,
                    function (o) {
                      return o.name
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase());
                    }
                  );

                  this.setState({ assets: filtered });
                } else {
                  this.setState({ assets: this.state.fullAssetsList });
                }
              }}
            />
          </div>
        )}

        <div style={{ marginTop: "20px" }}>
          {!this.state.loading &&
            this.state.assets.map((item, index) => {
              return (
                <BookAssetItem
                  item={item}
                  weekDates={this.state.weekDates}
                  onDismiss={() => {
                    this.setState({
                      showSidePanel: false,
                      assets: [],
                      floor: {},
                      selectedAssetType: {},
                    });
                  }}
                  date={date}
                  onDismissRefresh={(refreshDayOnly) => {
                    this.setState(
                      {
                        showSidePanel: false,
                        assets: [],
                        floor: {},
                        selectedAssetType: {},
                      },
                      () => {
                        if (refreshDayOnly) refreshDay();
                        else refreshPlanner();
                      }
                    );
                  }}
                  onOpenAsset={(index) => {
                    this.setState({ assetItemIndexOpened: index });
                  }}
                  indexOpened={this.state.assetItemIndexOpened}
                  index={index}
                />
              );
            })}
          {this.state.loading && (
            <div style={{ width: "100%", margin: "20px 0px" }}>
              <Shimmer style={{ padding: "5px" }} />
              <Shimmer style={{ padding: "5px" }} width="90%" />
              <Shimmer style={{ padding: "5px" }} width="80%" />
              <Shimmer style={{ padding: "5px" }} width="70%" />
              <Shimmer style={{ padding: "5px" }} width="60%" />
              <Shimmer style={{ padding: "5px" }} width="50%" />
              <Shimmer style={{ padding: "5px" }} width="40%" />
            </div>
          )}
        </div>
      </Panel>
    );
  }

  renderAttributesPanel = () => {
    const { isAttributesPanelOpen, attributesMobileData, selectedAttribute } =
      this.state;
    return (
      <Panel
        type={PanelType.smallFluid}
        headerText="Choose Attribute"
        isOpen={isAttributesPanelOpen}
        onDismiss={() => {
          this.setState({ isAttributesPanelOpen: false });
        }}
        // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
        closeButtonAriaLabel="Close"
      >
        <ChoiceGroup
          defaultSelectedKey={selectedAttribute?.id}
          options={attributesMobileData}
          onChange={(event, option) => {
            var selectedAttribute = {
              id: option.key,
              name: option.text,
            };

            this.setState({ isAttributesPanelOpen: false, selectedAttribute });
          }}
        />
      </Panel>
    );
  };

  renderMobilePanel() {
    const {
      site,
      date,
      refreshPlanner,
      refreshDay,
      onClosePanel,
      currentUser,
      teamEvent,
    } = this.props;

    var onSiteColor = localStorage.getItem("theme_OnSite");

    const { loading } = this.state;

    return (
      <>
        {this.filterBottomPanel()}
        <div
          style={{
            background: "#e7f4f6",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1000000,
            height: "100%",
            width: "100%",
            transition: "transform 200ms ease-in-out",
            transform: this.state.showSidePanel
              ? "translateY(0%)"
              : "translateY(100%)",
            opacity: this.state.showSidePanel ? "1" : "0",
            visibility: this.state.showSidePanel ? "visible" : "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "20px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ fontSize: "18px", marginRight: "10px" }}>{`${
                site?.name ?? currentUser?.site?.name
              }`}</span>

              <span style={{ fontSize: "14px", marginRight: "15px" }}>
                <Moment utc local format="MMM DD">
                  {date}
                </Moment>
              </span>

              {teamEvent && (
                <TooltipHost content={<p>Collaboration Day</p>}>
                  <TeamLogo
                    fill={teamEvent?.color}
                    stroke={teamEvent?.color}
                    height="18"
                    width="18"
                    style={{
                      overflow: "visible",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  />
                </TooltipHost>
              )}
            </div>
            <div>
              <CloseIcon
                fill={"grey"}
                stroke={"grey"}
                height="15"
                width="15"
                style={{
                  overflow: "visible",
                  strokeWidth: 0.5,
                }}
                onClick={() => {
                  this.setState({
                    showSidePanel: false,
                    assets: [],
                    floor: {},
                    selectedAssetType: {},
                    neighborhoodId: null,
                    attributeId: null,
                    selectedNeighborhood: null,
                    selectedAttribute: null,
                  });
                  onClosePanel();
                }}
              />
            </div>
          </div>

          <div style={{ marginTop: "10px" }}></div>

          <div
            style={{
              position: "absolute",
              bottom: "1%",
              left: "73%",
              display: "flex",
              justifyContent: "end",
              marginBottom: "20px",
              zIndex: 999,
            }}
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    backgroundColor: onSiteColor,
                    borderRadius: "20px",
                    padding: "12px",
                    height: "25px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    width: "40px",
                    marginRight: "30px",
                  }}
                  className={this.classNames.cardShadow}
                  onClick={() => this.setState({ showFilterBottomPanel: true })}
                >
                  <FilterLogo
                    fill={onSiteColor}
                    stroke={"white"}
                    height="25"
                    width="25"
                    style={{ overflow: "visible" }}
                  />
                </div>
              </div>
            </div>
          </div>

          {this.state.showFloorPlan ? (
            <FloorPlanMobile
              floorId={this.state.floor?.id}
              assets={this.state.assets}
              date={this.state.currentDate}
              refreshAssets={() => {
                this.populateAssets();
              }}
              // teamMemberIds={this.state.teamMemberIds}
              // showTeamMembers={showTeamMembers}
            />
          ) : (
            <div
              style={{
                marginTop: "5px",
                overflowY: "scroll",
                height: "calc(100vh - 130px)",
                padding: "0 20px 20px 20px",
              }}
            >
              {!this.state.loading &&
                this.state.assets &&
                this.state.assets.length > 0 &&
                this.state.assets.map((item, index) => {
                  return (
                    <BookAssetItem
                      fromMobileView
                      item={item}
                      weekDates={this.state.weekDates}
                      onDismiss={() => {
                        this.setState({
                          showSidePanel: false,
                          assets: [],
                          floor: {},
                          selectedAssetType: {},
                        });
                      }}
                      date={date}
                      onDismissRefresh={(refreshDayOnly) => {
                        this.populateAssets();
                        this.setState({
                          showSidePanel: false,
                          assets: [],
                          floor: {},
                          selectedAssetType: {},
                          neighborhoodId: null,
                          attributeId: null,
                          selectedNeighborhood: null,
                          selectedAttribute: null,
                        });
                        onClosePanel();
                      }}
                      onOpenAsset={(index) => {
                        this.setState({ assetItemIndexOpened: index });
                      }}
                      indexOpened={this.state.assetItemIndexOpened}
                      index={index}
                    />
                  );
                })}

              {!this.state.loading && this.state.assets.length == 0 && (
                <div
                  style={{
                    fontWeight: "600",
                    fontSize: "20px",
                    color: "grey",
                    marginTop: "100px",
                  }}
                >
                  No asset to display
                </div>
              )}
              {this.state.loading && (
                <div style={{ width: "100%", margin: "20px 0px" }}>
                  <Shimmer style={{ padding: "5px" }} />
                  <Shimmer style={{ padding: "5px" }} width="90%" />
                  <Shimmer style={{ padding: "5px" }} width="80%" />
                  <Shimmer style={{ padding: "5px" }} width="70%" />
                  <Shimmer style={{ padding: "5px" }} width="60%" />
                  <Shimmer style={{ padding: "5px" }} width="50%" />
                  <Shimmer style={{ padding: "5px" }} width="40%" />
                </div>
              )}
            </div>
          )}
        </div>
      </>
    );
  }

  mapLegendPanel = () => {
    var teamHighlightColor = localStorage.getItem("theme_HighlightTeam");
    var availableColor = localStorage.getItem("theme_Available");
    var reservedColor = localStorage.getItem("theme_Reserved");
    var inUseColor = localStorage.getItem("theme_InUse");
    var bookedColor = localStorage.getItem("theme_Booked");
    var itemColor = localStorage.getItem("theme_Item");
    return (
      <Panel
        isLightDismiss
        isOpen={this.state.showMapLegendPanel}
        onDismiss={() => {
          this.setState({ showMapLegendPanel: false });
        }}
        closeButtonAriaLabel="Close"
        headerText="Map Legend"
        type={PanelType.custom}
        customWidth="200px"
        hasCloseButton={false}
      >
        <div
          style={{
            display: "flex",
            marginRight: "20px",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              width: "15px",
              height: "15px",
              alignSelf: "center",
              borderRadius: "10px",
              backgroundColor: availableColor,
              marginRight: "10px",
            }}
          ></div>
          <div>Available</div>
        </div>
        <div
          style={{
            display: "flex",
            marginRight: "20px",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <div
            style={{
              width: "15px",
              height: "15px",
              alignSelf: "center",
              borderRadius: "10px",
              backgroundColor: reservedColor,
              marginRight: "10px",
            }}
          ></div>
          <div>Reserved</div>
        </div>

        <div
          style={{
            display: "flex",
            marginRight: "20px",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <div
            style={{
              width: "15px",
              height: "15px",
              alignSelf: "center",
              borderRadius: "10px",
              backgroundColor: inUseColor,
              marginRight: "10px",
            }}
          ></div>
          <div>In Use</div>
        </div>

        <div
          style={{
            display: "flex",
            marginRight: "20px",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <div
            style={{
              width: "15px",
              height: "15px",
              alignSelf: "center",
              borderRadius: "10px",
              backgroundColor: bookedColor,
              marginRight: "10px",
            }}
          ></div>
          <div>Booked</div>
        </div>

        <div
          style={{
            display: "flex",
            marginRight: "20px",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <div
            style={{
              width: "15px",
              height: "15px",
              alignSelf: "center",
              borderRadius: "10px",
              backgroundColor: itemColor,
              marginRight: "10px",
            }}
          ></div>
          <div>Item</div>
        </div>
      </Panel>
    );
  };

  filterBottomPanel = () => {
    const { site, date, onChangeDate, currentUser } = this.props;
    const { showFilterBottomPanel, showSidePanel, filterSite } = this.state;
    var onSiteColor = localStorage.getItem("theme_OnSite");
    var selectedSiteId = filterSite?.id ?? site?.id ?? currentUser?.site?.id;
    return (
      <>
        <div
          style={{
            opacity: showFilterBottomPanel ? "1" : "0",
            visibility: showFilterBottomPanel ? "visible" : "hidden",
          }}
        >
          <Dialog
            id="filterBottomPanel"
            hidden={false}
            onDismiss={() =>
              this.setState({
                showFilterBottomPanel: false,
              })
            }
            dialogContentProps={{
              type: DialogType.normal,
            }}
            styles={{
              main: {
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
                maxWidth: "100%",
                height: "97%",
                maxHeight: "97%",
                borderRadius: "15px 15px 0px 0px",
                background: "#F3F4F3",
                zIndex: 100,
              },
              root: showFilterBottomPanel ? {} : { display: "none" },
            }}
            modalProps={{ isModeless: true }}
            isDarkOverlay={false}
          >
            <DialogContent>
              {this.mapLegendPanel()}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <div>
                  <b>Filters</b>
                </div>
                <div
                  onClick={() =>
                    this.setState({
                      showFilterBottomPanel: false,
                    })
                  }
                >
                  <b>X</b>
                </div>
              </div>
              <div style={{ paddingBottom: "0px" }}>
                <div style={{ marginTop: "10px" }}></div>
                <SiteSelector
                  onSelect={async (item) => {
                    this.setState(
                      {
                        filterSite: item,
                        floor: {},
                        showFloorPlan: false,
                        assets: [],
                        fullAssetsList: [],
                      },
                      () => {
                        this.populateAssets();
                      }
                    );
                  }}
                  setOptions={async (sites) => {}}
                  siteId={selectedSiteId}
                />

                <MobileCalendarPicker date={date} onChangeDate={onChangeDate} />
                <FloorSelector
                  siteId={selectedSiteId}
                  onNoFloorData={() => {
                    localStorage.removeItem("assets_selectedFloor");
                    this.setState(
                      {
                        floor: {},
                        floorAssetTypes: [],
                        showFloorPlan: false,
                        assets: [],
                        fullAssetsList: [],
                      },
                      () => {
                        this.populateAssets();
                      }
                    );
                  }}
                  onSelect={(value) => {
                    if (
                      JSON.stringify(this.state.floor) !== JSON.stringify(value)
                    ) {
                      var floorAssetTypes = this.state.assetTypes.filter(
                        (x) => x.floorId === value.id
                      );

                      localStorage.setItem(
                        "assets_selectedFloor",
                        JSON.stringify(value)
                      );
                      this.setState(
                        {
                          floor: value,
                          floorAssetTypes: floorAssetTypes,
                          showFloorPlan: true,
                        },
                        () => {
                          this.populateAssets();
                        }
                      );
                    }
                  }}
                />

                <NeighborhoodSelector
                  siteId={selectedSiteId}
                  onSelect={(value) => {
                    const { id } = value;
                    this.setState(
                      { neighborhoodId: id, selectedNeighborhood: value },
                      () => this.populateAssets()
                    );
                  }}
                  onDeselect={() => {
                    this.setState(
                      { neighborhoodId: null, selectedNeighborhood: null },
                      () => this.populateAssets()
                    );
                  }}
                  hideWhenNoData
                  selectedData={this.state.selectedNeighborhood}
                  hideDeselectBtn
                  changefromParent
                />
                <AssetTypeDropdownSelector
                  onSelect={(value) => {
                    if (this.state.floor) {
                      var floorAssetTypes = this.state.assetTypes.filter(
                        (x) => x.floorId === this.state.floor.id
                      );
                      this.setState(
                        {
                          selectedAssetType: value,
                          floorAssetTypes: floorAssetTypes,
                          attributes: value?.attributes || [],
                          attributeId: null,
                        },
                        () => this.populateAssets()
                      );
                    } else {
                      this.setState(
                        {
                          selectedAssetType: value,
                          attributes: value?.attributes || [],
                          attributeId: null,
                        },
                        () => this.populateAssets()
                      );
                    }

                    localStorage.setItem(
                      "planner_selectedAssetType",
                      JSON.stringify(value)
                    );
                  }}
                  enabledValues={
                    this.state.floorAssetTypes &&
                    this.state.floorAssetTypes.length > 0
                      ? this.state.floorAssetTypes?.map((x) => x.id)
                      : []
                  }
                  isBookableOnly
                  selectedData={this.state.selectedAssetType}
                  populateDefaultAttributes={(attributes) => {
                    this.setState({
                      attributes: attributes || [],
                      attributeId: null,
                    });
                  }}
                />

                {this.state.attributes && this.state.attributes.length > 0 && (
                  <>
                    {" "}
                    <Label>Attributes</Label>
                    <div style={{ display: "flex", width: "100%" }}>
                      <Dropdown
                        placeholder="Select Attributes"
                        selectedKey={
                          this.state.attributeId ? this.state.attributeId : null
                        }
                        options={this.state.attributes.map((item) => {
                          return {
                            key: item.id,
                            text: item.name,
                          };
                        })}
                        onChanged={(item) => {
                          this.setState(
                            { attributeId: item.key, selectedAttribute: item },
                            () => this.populateAssets()
                          );
                        }}
                        styles={{
                          title: { borderRadius: "5px" },
                          root: { width: "100%" },
                        }}
                      />
                    </div>
                  </>
                )}

                <div
                  style={{
                    height: "30px",
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  {this.state.showFloorPlan ? (
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "end",
                        width: "100%",
                      }}
                    >
                      <IconLogo
                        height="30"
                        width="30"
                        style={{
                          overflow: "visible",
                          strokeWidth: 0.5,
                          marginRight: "20px",
                        }}
                        stroke="gray"
                        fill="gray"
                        onClick={() => {
                          this.setState({ showMapLegendPanel: true });
                        }}
                      />
                      <ListLogo
                        height="30"
                        width="30"
                        style={{ overflow: "visible", strokeWidth: 0.5 }}
                        stroke="gray"
                        fill="gray"
                        onClick={() => {
                          this.setState({
                            showFloorPlan: false,
                          });
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <MapLogo
                        height="30"
                        width="30"
                        style={{ overflow: "visible", strokeWidth: 0.5 }}
                        stroke="gray"
                        fill="gray"
                        onClick={() => {
                          this.setState({
                            showFloorPlan: true,
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{ color: onSiteColor, fontWeight: 500 }}
                  onClick={() => {
                    this.setState(
                      {
                        neighborhoodId: null,
                        selectedNeighborhood: null,
                        attributeId: null,
                        selectedAttribute: null,
                      },
                      () => this.populateAssets()
                    );
                  }}
                >
                  Reset
                </p>
              </div>

              <div style={{ marginTop: "10px", width: "100%" }}>
                <PrimaryButton
                  onClick={() => {
                    this.setState({
                      showFilterBottomPanel: false,
                    });
                  }}
                  text="OK"
                  allowDisabledFocus
                  style={{
                    borderRadius: "5px",
                    fontSize: "14px",
                    height: "40px",
                    background: onSiteColor,
                    width: "100%",
                  }}
                  disabled={this.state.loading}
                />
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </>
    );
  };
}
