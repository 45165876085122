import {
  ImageFit,
  Stack,
  Image,
  Dropdown,
  DefaultButton,
  ChoiceGroup,
  DatePicker,
  defaultDatePickerStrings,
  addDays,
  MessageBar,
  MessageBarType,
  Panel,
  Dialog,
  DialogFooter,
  PrimaryButton,
  DialogType,
  DayOfWeek,
  Shimmer,
  ShimmerElementType,
  ShimmerElementsGroup,
  Label,
  Link,
} from "@fluentui/react";

import React, { Component } from "react";
import {
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import { UsersClient } from "../infrastructure/api/UsersClient";
import { HomeClient, HomeQueryParams } from "../infrastructure/api/HomeClient";
import {
  EventsQueryParams,
  EventsClient,
} from "../infrastructure/api/EventsClient";
import { Icon } from "@fluentui/react/lib/Icon";
import { NoTeam } from "./NoTeam";
import { TeamMyDayChart } from "./Charts/TeamMyDayChart";
import { DaySelectChart } from "./Charts/DaySelectChart";
import { EventsTable } from "./Charts/EventsTable";
import {
  NotWorkingReasonClient,
  NotWorkingReasonQueryParams,
} from "../infrastructure/api/NotWorkingReasonClient";
import {
  SiteQueryParams,
  SitesClient,
} from "../infrastructure/api/SitesClient";
import { WorkDaysClient } from "../infrastructure/api/WorkDaysClient";
import { TeamTable } from "./Charts/TeamTable";
import { PlanTable } from "./Charts/PlanTable";
import { WeekSelector } from "./Charts/WeekSelector";
import { ActivityFeeds } from "./Charts/ActivityFeeds";
import { BookedAssetsLight } from "./Charts/BookedAssetsLight";
import { EventsList } from "./Charts/EventsList";

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      selectedDatesForUpdate: [],
      loading: false,
      eventsLoading: false,
      total: 0,
      skip: 0,
      take: 50,
      search: "",
      sort: "created",
      direction: "desc",
      selectedTeam: null,
      initComplete: false,
      notWorkingReasons: [],
      sites: [],
      currentDate: new Date(new Date().toDateString()),
      isOpen: false,
      currentWeekStartDate: this.getMonday(new Date(new Date().toDateString())),
      refreshWidgets: 1,
      isDatePickerOpen: false,
      isCheckInOpen: false,
      selectedMyDay: {
        date: new Date(new Date().toDateString()),
        status: "planned",
        parts: [
          {
            state: "workFromOffice",
          },
        ],
      },
      showEvents: false,
      eventModalVisible: false,
      events: [],
      assetRefresh: false,
    };
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  classNames = mergeStyleSets({
    container: {
      overflow: "auto",
      maxHeight: 450,
    },
    itemHeader: {
      backgroundColor: "#4052AB",
      color: "white",
      borderRadius: "5px 5px 0 0",
    },
    itemCell: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: "border-box",
        borderBottom: `1px solid ${getTheme().semanticColors.bodyDivider}`,
        display: "flex",
        selectors: {
          "&:hover": { background: getTheme().palette.neutralLight },
        },
      },
    ],
    itemImage: {
      flexShrink: 0,
    },
    itemContent: {
      marginLeft: 10,
      overflow: "hidden",
      flexGrow: 1,
    },
    itemName: [
      getTheme().fonts.xLarge,
      {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    ],
    panelItemName: [
      getTheme().fonts.large,
      {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    ],
    itemIndex: {
      fontSize: getTheme().fonts.small.fontSize,
      color: getTheme().palette.neutralTertiary,
      marginBottom: 10,
    },
    chevron: {
      alignSelf: "center",
      marginLeft: 10,
      color: getTheme().palette.neutralTertiary,
      fontSize: getTheme().fonts.large.fontSize,
      flexShrink: 0,
    },
    contentClass: {
      backgroundColor: getTheme().palette.neutralLight,
      color: getTheme().palette.neutralDark,
      lineHeight: "50px",
      padding: "0 20px",
      background:
        "linear-gradient(141deg, rgba(252,194,80,0.5) 0%, rgba(240,110,108,0.5) 50%, rgba(12,133,144,0.5) 100%)",
    },
    dropdown: { width: 300, borderRadius: "10px" },
    exampleRoot: {
      marginTop: "20px",
    },
    nameText: {
      fontWeight: "bold",
    },
    stackRoot: {
      width: "100%",
      display: "flex",
    },
    firstStack: {
      width: "66%",
    },
    secondStack: {
      width: "33%",
    },
  });

  modalStyles = mergeStyleSets({
    root: {
      width: "600px",
      height: "600px",
    },
    container: {},
  });

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
  }

  render() {
    var stackTokens = {
      childrenGap: 20,
      padding: 20,
    };

    const { events, userId } = this.state;

    return (
      <>
        <div className="deskContent">
          {this.state.selectedTeam != null && (
            <>
              <div className={this.classNames.contentClass}>
                <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                  <Stack
                    horizontal
                    tokens={{ childrenGap: 20 }}
                    horizontalAlign="space-between"
                  >
                    <Stack
                      horizontal
                      tokens={{ childrenGap: 20 }}
                      verticalAlign="center"
                    >
                      {this.state.teams.length == 1 && (
                        <>
                          <b style={{ lineHeight: "30px" }}>
                            {this.state.selectedTeam.name}
                          </b>
                          <Icon
                            iconName="Group"
                            style={{
                              position: "relative",
                              top: "1px",
                              lineHeight: "30px",
                              marginLeft: "10px",
                            }}
                          />{" "}
                        </>
                      )}
                      {this.state.teams.length > 1 && (
                        <>
                          <Dropdown
                            placeholder="Select a team"
                            selectedKey={
                              this.state.selectedTeam
                                ? this.state.selectedTeam.id
                                : null
                            }
                            options={this.state.teams.map((item) => {
                              return {
                                key: item.id,
                                text: item.name,
                              };
                            })}
                            onChanged={(item) =>
                              this.setState(
                                {
                                  selectedTeam: {
                                    id: item.key,
                                    name: item.text,
                                  },
                                },
                                this.update
                              )
                            }
                            className={this.classNames.dropdown}
                          />

                          <DefaultButton
                            style={{ display: "none" }}
                            text="Next team"
                            onClick={() => {
                              var count = this.state.teams.length;
                              var currentIndex = this.state.teams.findIndex(
                                (item) => item.id == this.state.selectedTeam.id
                              );
                              var targetIndex = (currentIndex + 1) % count;
                              this.setState(
                                {
                                  selectedTeam: {
                                    id: this.state.teams[targetIndex].id,
                                    name: this.state.teams[targetIndex].name,
                                  },
                                },
                                this.populateData
                              );
                            }}
                            disabled={this.state.loading}
                          />
                        </>
                      )}

                      <DatePicker
                        id="currentDatePicker"
                        minDate={new Date()}
                        firstDayOfWeek={DayOfWeek.Monday}
                        value={this.state.currentDate}
                        onSelectDate={(date) =>
                          this.setState(
                            {
                              currentDate: date,
                              currentWeekStartDate: this.getMonday(date),
                            },
                            this.populateData
                          )
                        }
                        placeholder="Select a date..."
                        ariaLabel="Select a date"
                        // DatePicker uses English strings by default. For localized apps, you must override this prop.
                        strings={defaultDatePickerStrings}
                      />
                    </Stack>

                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                      <DefaultButton
                        style={{
                          borderRadius: "50%",
                          minWidth: "0",
                          height: "50px",
                          width: "50px",
                        }}
                        onClick={() => this.backwardOneWeek()}
                        disabled={
                          this.state.loading ||
                          this.state.currentWeekStartDate.getTime() <
                            new Date().getTime()
                        }
                      >
                        <Icon iconName="DoubleChevronLeft" />
                      </DefaultButton>

                      {this.state.userSummary &&
                        this.state.userSummary.map((day) => {
                          return (
                            <div style={{ width: "50px" }}>
                              <DaySelectChart
                                key={new Date(day.date).getTime()}
                                isSelected={
                                  this.state.currentDate &&
                                  new Date(day.date).getTime() ==
                                    this.state.currentDate.getTime()
                                }
                                date={new Date(day.date)}
                                pieData={day.pieChart}
                                onClick={() => {
                                  this.setState(
                                    {
                                      currentDate: new Date(day.date),
                                      loading: true,
                                    },
                                    this.updateMyDay
                                  );
                                }}
                              />
                            </div>
                          );
                        })}

                      <DefaultButton
                        style={{
                          borderRadius: "50%",
                          minWidth: "0",
                          height: "50px",
                          width: "50px",
                        }}
                        onClick={() => this.forwardOneWeek()}
                        disabled={this.state.loading}
                      >
                        <Icon iconName="DoubleChevronRight" />
                      </DefaultButton>
                    </Stack>
                  </Stack>
                </div>
              </div>

              <div className={this.classNames.stackRoot}>
                <div className={this.classNames.firstStack}>
                  <Stack
                    horizontal
                    tokens={stackTokens}
                    horizontalAlign="space-around"
                  >
                    <Stack.Item
                      style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Label>Events</Label>
                          <div
                            style={{
                              display: "flex",
                              alignSelf: "center",
                            }}
                          >
                            {events && events.length > 1 && (
                              <Link
                                onClick={() => {
                                  this.setState({
                                    showEvents: !this.state.showEvents,
                                  });
                                }}
                              >
                                View all
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="card"
                        style={{
                          maxHeight: "530px",
                          overflow: "scroll",
                          flex: 1,
                        }}
                        onClick={() => {
                          this.setState({ eventModalVisible: true });
                        }}
                      >
                        {this.state.eventsLoading && (
                          <div>
                            <Shimmer style={{ padding: "5px 20px" }} />
                            <Shimmer
                              style={{ padding: "5px 20px" }}
                              width="75%"
                            />
                            <Shimmer
                              style={{ padding: "5px 20px" }}
                              width="50%"
                            />
                          </div>
                        )}
                        {!this.state.eventsLoading &&
                          events &&
                          events.length > 0 && (
                            <>
                              <EventsList
                                events={events}
                                userId={userId}
                                refreshEvents={() => {
                                  this.populateEvents();
                                }}
                              />
                            </>
                          )}
                      </div>
                    </Stack.Item>

                    <Stack.Item
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                      }}
                    >
                      <Label>Day View</Label>
                      {/* <h4
                              className={this.classNames.itemHeader}
                              style={{
                                textAlign: "center",
                                marginTop: "0",
                                padding: "8px",
                                borderRadius: "10px 10px 0px 0px",
                              }}
                            >
                              Day View
                            </h4> */}
                      <div className="card">
                        {!this.state.loading && (
                          <div style={{ padding: "20px" }}>
                            <TeamMyDayChart
                              key={this.state.refreshWidgets}
                              teamId={this.state.selectedTeam.id}
                              date={this.state.currentDate}
                            />
                          </div>
                        )}
                        {this.state.loading && (
                          <div>
                            <Shimmer style={{ padding: "5px 20px" }} />
                            <Shimmer
                              style={{ padding: "5px 20px" }}
                              width="75%"
                            />
                            <Shimmer
                              style={{ padding: "5px 20px" }}
                              width="50%"
                            />
                          </div>
                        )}
                      </div>
                    </Stack.Item>
                  </Stack>

                  <Stack
                    horizontal
                    tokens={stackTokens}
                    horizontalAlign="space-around"
                  >
                    <Stack.Item
                      style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: "100%",
                      }}
                    >
                      <ActivityFeeds selectedDate={this.state.currentDate} />
                    </Stack.Item>
                    <Stack.Item
                      style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: "100%",
                      }}
                    >
                      <BookedAssetsLight
                        selectedDate={this.state.currentDate}
                        userId={this.state.userId}
                        refresh={this.state.assetRefresh}
                      />
                    </Stack.Item>
                    <Stack.Item
                      style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: "100%",
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      }}
                    >
                      <h4
                        className={this.classNames.itemHeader}
                        style={{
                          textAlign: "center",
                          marginTop: "0",
                          padding: "8px",
                          borderRadius: "10px 10px 0px 0px",
                        }}
                      >
                        Check In
                      </h4>

                      {!this.state.loading && (
                        <div style={{ padding: "20px" }}>
                          {this.state.initComplete &&
                            (this.state.myDay.id ||
                              this.state.currentDate.getTime() >=
                                new Date(
                                  new Date().toDateString()
                                ).getTime()) && (
                              <div>
                                {!this.state.myDay.id && (
                                  <MessageBar
                                    messageBarType={MessageBarType.info}
                                    isMultiline={false}
                                  >
                                    No planned check in for selected date
                                  </MessageBar>
                                )}

                                <ChoiceGroup
                                  sytle={{ marginRight: "5px" }}
                                  selectedKey={this.state.myDay.parts[0].state}
                                  options={[
                                    { key: "notWorking", text: "Not Working" },
                                    {
                                      key: "workFromHome",
                                      text: "Work From Home",
                                    },
                                    {
                                      key: "workFromOffice",
                                      text: "Work From Site",
                                    },
                                  ]}
                                  onChange={(value, option) => {
                                    var myDay = this.state.myDay;
                                    myDay.parts[0].state = option.key;
                                    this.setState({
                                      myDay: myDay,
                                    });
                                  }}
                                  required={true}
                                  disabled={
                                    this.state.myDay?.status == "confirmed" ||
                                    this.state.currentDate.getTime() <
                                      new Date(
                                        new Date().toDateString()
                                      ).getTime()
                                  }
                                />

                                {this.state.myDay.parts[0].state ==
                                  "notWorking" && (
                                  <Dropdown
                                    label="Reason"
                                    placeholder="Not working reason"
                                    disabled={
                                      this.state.myDay?.status == "confirmed" ||
                                      this.state.currentDate.getTime() <
                                        new Date(
                                          new Date().toDateString()
                                        ).getTime()
                                    }
                                    selectedKey={
                                      this.state.myDay.parts[0]
                                        .notWorkingReasonId
                                    }
                                    options={this.state.notWorkingReasons.map(
                                      (item) => {
                                        return {
                                          key: item.id,
                                          text: item.name,
                                        };
                                      }
                                    )}
                                    onChanged={(item) => {
                                      var myDay = this.state.myDay;
                                      myDay.parts[0].notWorkingReasonId =
                                        item.key;
                                      this.setState({
                                        myDay: myDay,
                                      });
                                    }}
                                  />
                                )}

                                {this.state.myDay.parts[0].state ==
                                  "workFromOffice" && (
                                  <Dropdown
                                    label="Site"
                                    placeholder="Site working at"
                                    disabled={
                                      this.state.myDay?.status == "confirmed" ||
                                      this.state.currentDate.getTime() <
                                        new Date(
                                          new Date().toDateString()
                                        ).getTime()
                                    }
                                    selectedKey={
                                      this.state.myDay.parts[0].siteId
                                    }
                                    options={this.state.sites.map((item) => {
                                      return {
                                        key: item.id,
                                        text: item.name,
                                      };
                                    })}
                                    onChanged={(item) => {
                                      var myDay = this.state.myDay;
                                      myDay.parts[0].siteId = item.key;
                                      this.setState({
                                        myDay: myDay,
                                      });
                                    }}
                                  />
                                )}

                                <DefaultButton
                                  style={{ marginTop: "20px" }}
                                  text={
                                    this.state.myDay?.status == "confirmed"
                                      ? "Checked in"
                                      : this.state.currentDate.getTime() ==
                                        new Date(
                                          new Date().toDateString()
                                        ).getTime()
                                      ? "Check in"
                                      : this.state.currentDate.getTime() <
                                        new Date(
                                          new Date().toDateString()
                                        ).getTime()
                                      ? "Past check in"
                                      : "Plan check in"
                                  }
                                  disabled={
                                    this.state.myDay?.status == "confirmed" ||
                                    this.state.currentDate.getTime() <
                                      new Date(
                                        new Date().toDateString()
                                      ).getTime()
                                  }
                                  onClick={() => {
                                    var today = new Date(
                                      new Date().toDateString()
                                    );

                                    var myDay = this.state.myDay;

                                    if (
                                      this.state.currentDate.getTime() <
                                      today.getTime()
                                    ) {
                                      console.log("Past date - ignore");
                                    } else if (
                                      this.state.currentDate.getTime() ==
                                      today.getTime()
                                    ) {
                                      myDay.status = "confirmed";
                                      myDay.confirmed = new Date();
                                      myDay.dates = [myDay.date];

                                      WorkDaysClient.Create(myDay);
                                    } else {
                                      myDay.status = "planned";
                                      myDay.dates = [myDay.date];

                                      WorkDaysClient.Create(myDay);
                                    }

                                    this.setState({
                                      myDay: myDay,
                                    });
                                    this.update();
                                  }}
                                />
                              </div>
                            )}

                          {!this.state.loading &&
                            this.state.initComplete &&
                            !this.state.myDay.id &&
                            this.state.currentDate.getTime() <
                              new Date(new Date().toDateString()).getTime() && (
                              <MessageBar
                                messageBarType={MessageBarType.info}
                                isMultiline={false}
                              >
                                No check-in data for selected day
                              </MessageBar>
                            )}
                        </div>
                      )}

                      {this.state.loading && (
                        <div>
                          <Shimmer style={{ padding: "5px 20px" }} />
                          <Shimmer
                            style={{ padding: "5px 20px" }}
                            width="75%"
                          />
                          <Shimmer
                            style={{ padding: "5px 20px" }}
                            width="50%"
                          />
                        </div>
                      )}
                    </Stack.Item>
                  </Stack>
                </div>
                <div className={this.classNames.secondStack}>
                  <Stack
                    horizontal
                    tokens={stackTokens}
                    horizontalAlign="space-around"
                  >
                    <Stack.Item grow disableShrink>
                      {this.state.loading && (
                        <Shimmer
                          customElementsGroup={
                            <ShimmerElementsGroup
                              shimmerElements={[
                                {
                                  type: ShimmerElementType.circle,
                                  width: 40,
                                  height: 40,
                                },
                                {
                                  type: ShimmerElementType.gap,
                                  width: 10,
                                  height: 40,
                                },
                              ]}
                            />
                          }
                        />
                      )}
                      {!this.state.loading && (
                        <>
                          {this.state.selectedTeam && (
                            <Label>{this.state.selectedTeam?.name}</Label>
                          )}
                          <div
                            className="card"
                            style={{
                              padding: "10px 20px",
                              maxHeight: "1000px",
                              overflow: "scroll",
                            }}
                          >
                            <TeamTable
                              key={this.state.refreshWidgets}
                              teamId={this.state.selectedTeam.id}
                              siteId={this.state.siteId}
                              date={this.state.currentDate}
                              onClick={(user) => {
                                this.setState({
                                  selectedTeamMember: user,
                                  isOpen: true,
                                });
                              }}
                            />
                          </div>
                        </>
                      )}
                    </Stack.Item>
                  </Stack>
                </div>
              </div>
            </>
          )}

          {this.state.selectedTeam == null && !this.state.loading && <NoTeam />}

          {this.renderEventsPanel()}

          {this.renderPanel()}

          {this.renderDatePicker()}

          {this.renderCheckInScreen()}
        </div>
        <div className="phoneContent">
          <div
            style={{
              maxWidth: "600px",
              padding: "30px",
              textAlign: "center",
              backgroundColor: "rgb(255 255 255 / 10%)",
            }}
          >
            <img
              src="/assets/images/daysie_logo.png"
              style={{ height: "50px", position: "relative", top: "15px" }}
            />
            <h3 className="mb-4">Mobile client coming soon 1</h3>
          </div>
        </div>
      </>
    );
  }

  renderEventsPanel() {
    return (
      <Panel
        isLightDismiss
        onOuterClick={() => {}}
        isOpen={this.state.showEvents}
        onDismiss={() => this.setState({ showEvents: false })}
        closeButtonAriaLabel="Close"
        headerText="Events"
        type={3}
      >
        <EventsTable
          events={this.state.events}
          userId={this.state.userId}
          refreshEvents={() => {
            this.populateEvents();
          }}
        />
      </Panel>
    );
  }

  renderPanel() {
    return (
      <Panel
        isLightDismiss
        onOuterClick={() => {}}
        isOpen={this.state.isOpen}
        onDismiss={() => this.setState({ isOpen: false })}
        closeButtonAriaLabel="Close"
        headerText="User Plan"
      >
        <div
          className={this.classNames.itemCell}
          data-is-focusable={true}
          style={{ backgroundColor: "white", borderRadius: "5px" }}
        >
          <Image
            className={this.classNames.itemImage}
            src={
              this.state.selectedTeamMember?.profilePhoto ??
              "/assets/images/blank.png"
            }
            alt=""
            width={50}
            height={50}
            imageFit={ImageFit.cover}
          />

          <div className={this.classNames.itemContent}>
            <div className={this.classNames.panelItemName}>
              {this.state.selectedTeamMember?.name}{" "}
              {this.state.selectedTeamMember?.surname}
            </div>
            <div
              className="hover"
              onClick={() => {
                this.setState({ isDatePickerOpen: true });
              }}
            >
              <strong>
                {this.state.currentWeekStartDate.getDate()} -{" "}
                {addDays(this.state.currentWeekStartDate, 6).getDate()}{" "}
                {addDays(this.state.currentWeekStartDate, 6).toLocaleString(
                  "en-nz",
                  { month: "long" }
                )}{" "}
                <Icon
                  iconName="Calendar"
                  style={{
                    position: "relative",
                    marginLeft: "5px",
                  }}
                />
              </strong>
            </div>
          </div>

          <DefaultButton
            style={{
              borderRadius: "50%",
              minWidth: "0",
              height: "35px",
              width: "35px",
              position: "relative",
              top: "10px",
            }}
            onClick={() => this.forwardOneWeek()}
            disabled={this.state.loading}
          >
            <Icon iconName="DoubleChevronRight" />
          </DefaultButton>
        </div>

        {this.state.selectedTeam && this.state.selectedTeamMember && (
          <PlanTable
            key={this.state.refreshWidgets}
            teamId={this.state.selectedTeam.id}
            date={this.state.currentWeekStartDate}
            userId={this.state.selectedTeamMember?.id}
            selectedDate={this.state.currentDate}
            onClick={(date, myDay) => {
              if (myDay && myDay.parts.length == 0) {
                myDay.parts = [
                  {
                    state: "workFromOffice",
                  },
                ];
              }

              this.setState({
                selectedMyDay: myDay ?? {
                  date: date,
                  status: "planned",
                  parts: [
                    {
                      state: "workFromOffice",
                    },
                  ],
                },
                isCheckInOpen: true,
                selectedDatesForUpdate: this.state.selectedMyDay?.date
                  ? [this.state.selectedMyDay?.date]
                  : [],
              });
            }}
          />
        )}
      </Panel>
    );
  }

  renderDatePicker() {
    return (
      <Dialog
        type={DialogType.normal}
        title="Choose date"
        hidden={!this.state.isDatePickerOpen}
        onDismiss={() => this.setState({ isDatePickerOpen: false })}
      >
        <DatePicker
          firstDayOfWeek={DayOfWeek.Monday}
          id="currentDatePicker2"
          minDate={new Date()}
          value={this.state.currentDate}
          onSelectDate={(date) =>
            this.setState(
              {
                currentDate: date,
                currentWeekStartDate: this.getMonday(date),
                isDatePickerOpen: false,
              },
              this.populateData
            )
          }
          placeholder="Select a date..."
          ariaLabel="Select a date"
          // DatePicker uses English strings by default. For localized apps, you must override this prop.
          strings={defaultDatePickerStrings}
        />

        <DialogFooter>
          <DefaultButton
            onClick={(ev) => {
              this.setState({ isDatePickerOpen: false });
              ev.preventDefault();
            }}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
    );
  }

  renderCheckInScreen() {
    //TODO: DISABLE IF NOT FOR CURRENT USER

    return (
      <Dialog
        title="Work Plan"
        minWidth={400}
        type={DialogType.normal}
        hidden={!this.state.isCheckInOpen}
        onDismiss={() => this.setState({ isCheckInOpen: false })}
      >
        <div>
          <ChoiceGroup
            sytle={{ marginRight: "5px" }}
            selectedKey={this.state.selectedMyDay.parts[0]?.state}
            options={[
              { key: "notWorking", text: "Not Working" },
              { key: "workFromHome", text: "Work From Home" },
              { key: "workFromOffice", text: "Work From Site" },
            ]}
            onChange={(value, option) => {
              var myDay = this.state.selectedMyDay;
              myDay.parts[0].state = option.key;
              this.setState({
                selectedMyDay: myDay,
              });
            }}
            required={true}
          />

          {this.state.selectedMyDay.parts[0]?.state == "notWorking" && (
            <Dropdown
              label="Reason"
              placeholder="Not working reason"
              selectedKey={
                this.state.selectedMyDay.parts[0]?.notWorkingReasonId
              }
              options={this.state.notWorkingReasons.map((item) => {
                return {
                  key: item.id,
                  text: item.name,
                };
              })}
              onChanged={(item) => {
                var myDay = this.state.selectedMyDay;
                myDay.parts[0].notWorkingReasonId = item.key;
                this.setState({
                  selectedMyDay: myDay,
                });
              }}
            />
          )}

          {this.state.selectedMyDay.parts[0]?.state == "workFromOffice" && (
            <Dropdown
              label="Site"
              placeholder="Site working at"
              selectedKey={this.state.selectedMyDay.parts[0]?.siteId}
              options={this.state.sites.map((item) => {
                return {
                  key: item.id,
                  text: item.name,
                };
              })}
              onChanged={(item) => {
                var myDay = this.state.selectedMyDay;
                myDay.parts[0].siteId = item.key;
                this.setState({
                  selectedMyDay: myDay,
                });
              }}
            />
          )}
        </div>

        <div style={{ paddingTop: "10px" }}>
          {this.state.userSummary && (
            <WeekSelector
              dates={this.state.userSummary.map((a) => new Date(a.date))}
              selectedDate={this.state.selectedMyDay?.date}
              onSelectedDatesUpdate={(dates) =>
                this.setState({ selectedDatesForUpdate: dates })
              }
            />
          )}
        </div>

        <DialogFooter>
          <PrimaryButton
            onClick={(ev) => {
              var today = new Date(new Date().toDateString());

              var myDay = this.state.selectedMyDay;

              if (myDay.parts.length == 0) {
                myDay.parts = [
                  {
                    state: "workFromOffice",
                  },
                ];
              }

              if (
                new Date(this.state.selectedMyDay.date).getTime() ==
                today.getTime()
              ) {
                myDay.confirmed = new Date();
                myDay.dates = this.state.selectedDatesForUpdate;

                console.log(myDay);

                WorkDaysClient.Create(myDay);
              } else {
                myDay.status = "planned";
                myDay.dates = this.state.selectedDatesForUpdate;

                WorkDaysClient.Create(myDay);
              }

              this.setState({
                selectedMyDay: myDay,
              });

              this.setState({ isCheckInOpen: false });
              this.update();

              ev.preventDefault();
            }}
            text="Save"
          />
          <DefaultButton
            onClick={(ev) => {
              this.setState({ isCheckInOpen: false });
              ev.preventDefault();
            }}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
    );
  }

  forwardOneWeek() {
    var newDate = addDays(this.state.currentDate, 7);
    var newWeekStartDate = this.getMonday(newDate);

    if (
      newWeekStartDate.getTime() < new Date().getTime() &&
      this.state.currentWeekStartDate.getTime() > new Date().getTime()
    ) {
      this.setState(
        {
          currentDate: new Date(new Date().toDateString()),
          currentWeekStartDate: newWeekStartDate,
        },
        this.populateData
      ); //If current week, default to current
    } else {
      this.setState(
        {
          currentDate: newWeekStartDate,
          currentWeekStartDate: newWeekStartDate,
        },
        this.populateData
      ); //If other week, default to Monday
    }
  }

  backwardOneWeek() {
    var newDate = addDays(this.state.currentDate, -7);
    var newWeekStartDate = this.getMonday(newDate);

    if (
      newWeekStartDate.getTime() < new Date().getTime() &&
      this.state.currentWeekStartDate.getTime() > new Date().getTime()
    ) {
      this.setState(
        {
          currentDate: new Date(new Date().toDateString()),
          currentWeekStartDate: newWeekStartDate,
        },
        this.populateData
      ); //If current week, default to current
    } else {
      this.setState(
        {
          currentDate: newWeekStartDate,
          currentWeekStartDate: newWeekStartDate,
        },
        this.populateData
      ); //If other week, default to Monday
    }
  }

  async populateData() {
    if (!this.state.loading) {
      this.setState({ loading: true });

      var currentUser = await UsersClient.Get("current");

      if (currentUser.successful && currentUser.data.site) {
        var siteId = currentUser.data.site.id;
        this.setState({ siteId: siteId, userId: currentUser.data.id });

        if (currentUser.data.teams && currentUser.data.teams.length > 0) {
          var teamId = currentUser.data.teams[0].id;

          if (!this.state.selectedTeam) {
            this.setState({
              teams: currentUser.data.teams,
              selectedTeam: {
                id: currentUser.data.teams[0].id,
                name: currentUser.data.teams[0].name,
              },
            });

            this.populateEvents();
          }

          //Get currentUser MyDay
          var userSummary = await HomeClient.DayView(
            new HomeQueryParams()
              .WithSiteId(siteId)
              .WithTeamId(teamId)
              .WithUserId(currentUser.data.id)
              .WithNumberOfDays(7)
              .WithParam("startDate", this.state.currentWeekStartDate)
          );

          console.log(userSummary);

          this.setState({
            userSummary: userSummary.data,
            myDay: this.getMyDayForDate(
              userSummary.data,
              this.state.currentDate
            ),
            initComplete: true,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      } else {
        this.setState({ loading: false });
      }

      var notWorkingReasons = await NotWorkingReasonClient.List(
        new NotWorkingReasonQueryParams()
      );
      this.setState({ notWorkingReasons: notWorkingReasons.data.data });

      var sites = await SitesClient.List(new SiteQueryParams());
      this.setState({
        sites: sites.data.data,
        refreshWidgets: new Date().getTime(),
      });
    }
  }

  async populateEvents() {
    this.setState({ eventsLoading: true });
    var eventParams = new EventsQueryParams();
    if (this.state.selectedTeam?.id)
      eventParams.WithTeamIds([this.state.selectedTeam.id]);

    eventParams.Sort("startDate", "asc");

    var events = await EventsClient.List(eventParams);
    this.setState({
      events: events.data.data,
    });
    this.setState({ eventsLoading: false });
  }

  updateMyDay() {
    this.setState({
      myDay: this.getMyDayForDate(
        this.state.userSummary,
        this.state.currentDate
      ),
      refreshWidgets: new Date().getTime(),
    });
  }

  getMyDayForDate(data, date) {
    var myDay = {
      date: this.state.currentDate,
      status: "planned",
      parts: [
        {
          state: "workFromOffice",
        },
      ],
    };

    if (data && data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        if (new Date(data[i].date).getTime() == date.getTime()) {
          if (data[i].users.length > 0 && data[i].users[0].myDay) {
            myDay = data[i].users[0].myDay;
          }
        }
      }
    }

    return myDay;
  }
}
