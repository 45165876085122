import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  TooltipHost,
  Panel,
  PanelType,
  DefaultButton,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import {
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import _ from "lodash";
import { ReactComponent as Logo } from "../../../../assets/svg/memo.svg";
import "./TeamOverview.css";
import { SiteSelector } from "../../../Selectors/SiteSelector";
import { UsersClient } from "../../../../infrastructure/api/UsersClient";
import { ReactComponent as AddLogo } from "../../../../assets/svg/add.svg";
import { ReactComponent as CheckLogo } from "../../../../assets/svg/check-circle.svg";
import { NoticeClient } from "../../../../infrastructure/api/NoticeClient";
import Moment from "react-moment";
import { OtherNoticeModal } from "../../../Common/WorkPlanSiteDropdown/OtherNoticeModal";

export class NoticeOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentDate: new Date(new Date().toDateString()),
      currentUser: null,
      notice: {},
      showSidePanel: false,
      notices: [],
      showNoticeModal: false,
      selectedNotice: {},
    };

    this.inputElementRef = React.createRef();
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
  }

  componentWillReceiveProps(props) {}

  classNames = mergeStyleSets({
    container: {
      width: "100%",
      overflow: "hidden",
    },
    cardShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)" },
        },
      },
    ],
    buttonShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)" },
        },
      },
    ],
    centerItem: {
      display: "flex",
      justifyContent: "center",
    },
  });

  renderModal() {
    return (
      <OtherNoticeModal
        notices={[this.state.selectedNotice]}
        showModal={this.state.showNoticeModal}
        onClose={(reload) => {
          this.setState({ showNoticeModal: false });
        }}
        viewOnly
      />
    );
  }

  renderPanel() {
    const { currentUser, reloadUser } = this.props;
    const { notices } = this.state;
    return (
      <Panel
        isLightDismiss={false}
        onOuterClick={() => {}}
        isOpen={this.state.showSidePanel}
        onDismiss={() => {
          this.setState({
            showSidePanel: false,
          });
        }}
        onOpen={() => {}}
        closeButtonAriaLabel="Close"
        headerText={
          <div>
            <span style={{ fontSize: "18px" }}>Viewed Notices</span>
          </div>
        }
        type={PanelType.smallFixedFar}
        styles={{
          content: {
            background: "white",
          },
          contentInner: {
            background: "white",
          },
          header: {
            background: "white",
          },
          navigation: {
            background: "white",
          },
          commands: {
            background: "white",
          },
        }}
      >
        {this.renderModal()}
        <div style={{ marginTop: "20px" }}></div>
        <div style={{ minWidth: "200px" }}>
          {notices &&
            notices.length > 0 &&
            notices.map((notice) => (
              <div
                className={this.classNames.buttonShadow}
                style={{
                  padding: "10px",
                  borderRadius: "15px",
                  marginTop: "10px",
                }}
                onClick={() => {
                  this.setState({
                    selectedNotice: notice.notice,
                    showNoticeModal: true,
                  });
                }}
              >
                <div style={{ cursor: "default" }}>
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        flexGrow: 1,
                        marginLeft: "10px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <b
                          style={{
                            marginRight: "5px",
                          }}
                        >
                          {notice.notice?.name}
                        </b>{" "}
                        - {notice.notice.noticeType?.name}{" "}
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          display: "flex",
                          alignItems: "center",
                          padding: notice.notice.acknowledge ? "" : "10px 0",
                        }}
                      >
                        <Moment utc local format="ddd DD MMM, YYYY hh:mm A">
                          {notice.created}
                        </Moment>
                        {notice.notice.acknowledge && (
                          <CheckLogo
                            height="16"
                            width="16"
                            style={{
                              overflow: "visible",
                              padding: "10px 0",
                              marginLeft: "5px",
                            }}
                            stroke={"#00838f"}
                            fill={"#00838f"}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </Panel>
    );
  }

  render() {
    const { loading, notices } = this.state;
    return (
      <div>
        {notices && notices.length > 0 && (
          <>
            {this.renderPanel()}
            {this.renderModal()}
            {loading ? (
              <div
                className="card__image"
                style={{
                  height: "120px",
                  width: "120px",
                }}
              ></div>
            ) : (
              <div
                style={{
                  borderRadius: "10px",
                  height: "120px",
                  width: "120px",
                  background: "#FA5B55",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.setState({ showSidePanel: true });
                }}
              >
                <Logo
                  height="60"
                  width="60"
                  style={{ overflow: "visible", padding: "10px 0" }}
                  stroke={"white"}
                  fill={"white"}
                />
                <b style={{ color: "white", fontSize: "12px" }}>Notices</b>
              </div>
            )}
          </>
        )}
      </div>
    );
  }

  async populateData() {
    this.setState({ loading: true });

    const userId = localStorage.getItem("userId");
    var notices = await NoticeClient.UserViewedNoticesList(userId);

    this.setState({
      notices: notices.data,
      loading: false,
    });
  }
}
