export enum UserPermissionTypes {
  MANAGE_USERS = "users.modify",
  VIEW_ALL_USERS = "users.view.all",
  MANAGE_MESSAGES = "messages.modify",
  VIEW_ALL_MESSAGES = "messages.view.all",
  MANAGE_TEAMS = "teams.modify",
  VIEW_ALL_TEAMS = "teams.view.all",
  MANAGE_INVITATIONS = "invitations.modify",
  VIEW_ALL_INVITATIONS = "invitations.view.all",
  MANAGE_MYDAYS = "myDays.modify",
  VIEW_ALL_MYDAYS = "myDays.view.all",
  VIEW_ALL_ROLES = "roles.view.all",
  MANAGE_ROLES = "role.modify",
  MANAGE_SITETYPES = "siteTypes.modify",
  VIEW_ALL_SITETYPES = "siteTypes.view.all",
  MANAGE_NEIGHBORHOODS = "neighborhoods.modify",
  VIEW_NEIGHBORHOODS = "neighborhoods.view.all",
  MANAGE_SITES = "sites.modify",
  VIEW_ALL_SITES = "sites.view.all",
  MANAGE_NOT_WORKING_REASONS = "notWorkingReasons.modify",
  VIEW_NOT_WORKING_REASONS = "notWorkingReasons.view.all",
  MANAGE_BUSINESS_UNITS = "businessUnit.modify",
  VIEW_BUSINESS_UNITS = "businessUnit.view.all",
  MANAGE_ATTRIBUTES = "attributes.modify",
  VIEW_ALL_ATTRIBUTES = "attributes.view.all",
  MANAGE_ASSETS = "assets.modify",
  VIEW_ALL_ASSETS = "assets.view.all",
  MANAGE_ASSETTYPES = "assetTypes.modify",
  VIEW_ALL_ASSETTYPES = "assetTypes.view.all",
  MANAGE_ALLOCATIONS = "allocations.modify",
  VIEW_ALL_ALLOCATIONS = "allocations.view.all",
  MANAGE_CHECKINSTRATEGY = "checkInStrategies.modify",
  VIEW_ALL_CHECKINSTRATEGY = "checkInStrategies.view.all",
  MANAGE_USERTYPE = "userTypes.modify",
  VIEW_USERTYPE = "userTypes.view.all",
  MANAGE_ACTIVITYFEED = "activityFeed.modify",
  VIEW_ACTIVITYFEED = "activityFeed.view.all",
  MANAGE_NOTIFICATION = "notification.modify",
  VIEW_NOTIFICATION = "notification.view.all",
  MANAGE_PUBLIC_HOLIDAYS = "publicHoliday.modify",
  VIEW_PUBLIC_HOLIDAYS = "publicHoliday.view.all",
  MENU_BOOKINGS = "bookings.menu",
  VIEW_BOOKINGS = "bookings.view.all",
  MANAGE_EVENTS = "events.modify",
  VIEW_EVENTS = "events.view.all",
  MENU_EVENTS = "events.menu",
  VIEW_REPORTS = "reports.view.all",
  MENU_REPORTS = "reports.menu",
  MANAGE_USER_SKILLS = "userSkills.modify",
  VIEW_USER_SKILLS = "userSkills.view.all",
  MENU_USER_SKILLS = "userSkills.menu",
  MANAGE_AUTOMATIONS = "automation.modify",
  VIEW_AUTOMATIONS = "automation.view.all",
  MENU_AUTOMATIONS = "automation.menu",
  VIEW_ALL_VEHICLES = "vehicles.view.all",
}
