import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import { Shimmer } from "@fluentui/react";
import {
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import _ from "lodash";
import { ReactComponent as Logo } from "../../../../assets/svg/car.svg";
import { ReactComponent as RemoveLogo } from "../../../../assets/svg/cross-circle.svg";
import { VehiclesClient } from "../../../../infrastructure/api/VehiclesClient";

export class VehicleItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
  }

  classNames = mergeStyleSets({
    container: {
      width: "100%",
      overflow: "hidden",
    },
  });

  render() {
    const { vehicle, refreshVehicles } = this.props;
    return (
      <>
        <div
          style={{
            borderRadius: "10px",
            height: "120px",
            width: "120px",
            background: "#00838f",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Logo
            height="60"
            width="60"
            style={{ overflow: "visible", padding: "10px 0" }}
            stroke={"white"}
            fill={"white"}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <b style={{ color: "white", marginRight: "5px", fontSize: "13px" }}>
              {vehicle?.name}
            </b>
          </div>

          <div
            style={{
              position: "absolute",
              top: "-15px",
              right: "-5px",
              cursor: "pointer",
            }}
          >
            <RemoveLogo
              height="25"
              width="25"
              style={{ overflow: "visible", padding: "10px 0" }}
              stroke={"#EF6D6C"}
              fill={"#EF6D6C"}
              onClick={async () => {
                await VehiclesClient.Delete(vehicle.id);

                if (refreshVehicles) refreshVehicles();
              }}
            />
          </div>
        </div>
      </>
    );
  }

  async populateData() {}
}
