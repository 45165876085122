import { Label, ActivityItem, Link, Shimmer } from "@fluentui/react";
import React, { Component } from "react";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import { Icon } from "@fluentui/react/lib/Icon";
import {
  ActivityFeedClient,
  ActivityFeedQueryParams,
} from "../../infrastructure/api/ActivityFeedsClient";
import moment from "moment";

export class ActivityFeeds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      total: 0,
      search: "",
      sort: "companyName",
      direction: "asc",
      showSuspended: false,
      status: "",
    };
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
  }

  classNames = mergeStyleSets({
    exampleRoot: {
      marginTop: "20px",
    },
    nameText: {
      fontWeight: "bold",
    },
  });

  render() {
    return (
      <>
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Label>Activity Feeds</Label>
            <div
              style={{
                display: "flex",
                alignSelf: "center",
              }}
            ></div>
          </div>
          <div
            className="card"
            style={{ padding: "10px 20px", height: "450px" }}
          >
            {this.state.loading && (
              <div>
                <Shimmer style={{ padding: "5px 20px" }} />
                <Shimmer style={{ padding: "5px 20px" }} width="75%" />
                <Shimmer style={{ padding: "5px 20px" }} width="50%" />
              </div>
            )}
            {!this.state.loading &&
              this.state.data &&
              this.state.data.map((item) => (
                <ActivityItem
                  {...item}
                  key={item.key}
                  className={this.classNames.exampleRoot}
                />
              ))}
          </div>
        </div>
      </>
    );
  }

  iconSelector = (type) => {
    switch (type) {
      case "message":
        return <Icon iconName={"ChangeEntitlements"} />;
      case "bookAsset":
        return <Icon iconName={"Devices2"} />;
      case "externalWebLink":
        return <Icon iconName={"Link"} />;
    }
  };

  async populateData(skip, take) {
    console.log("getting activity feeds");
    const { selectedDate, teamId, date, siteId, userId } = this.props;

    this.setState({
      loading: true,
    });

    var response = await ActivityFeedClient.List(
      new ActivityFeedQueryParams()
        .WithStart(selectedDate)
        .WithEnd(moment(selectedDate).add(1, "d").toISOString())
        .WithCurrentUser(false)
    );

    if (response.data) {
      const { data } = response.data;

      if (data && data.length > 0) {
        var mappedData = data.map((item, index) => ({
          key: index,
          activityDescription: [
            <span key={1} className={this.classNames.nameText}>
              {item.title}
            </span>,
            <span key={2}> for audience: </span>,
            <span key={3} className={this.classNames.nameText}>
              {item.audience}
            </span>,
          ],
          activityIcon: this.iconSelector(item.type),
          comments:
            item.type === "externalWebLink"
              ? [
                  <span key={1}>{item.message} </span>,
                  <Link
                    key={2}
                    className={this.classNames.nameText}
                    href={item.url}
                  >
                    Link
                  </Link>,
                ]
              : [<span key={1}>{item.message} </span>],
        }));

        this.setState({
          data: mappedData,
        });
      }
    }

    this.setState({
      loading: false,
    });
  }
}
