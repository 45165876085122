import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import { Dropdown, Label } from "@fluentui/react";
import {
  TeamQueryParams,
  TeamsClient,
} from "../../infrastructure/api/TeamsClient";

export class TeamSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeStatus: "",
      loading: false,
      selectedTeam: null,
      teams: [],
    };
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
  }

  classNames = mergeStyleSets({
    dropdown: { borderRadius: "5px" },
  });

  render() {
    const { onSelect, teamId, hideLabel } = this.props;
    return (
      <>
        {!hideLabel && <Label>Team</Label>}
        <Dropdown
          placeholder="Select a team"
          selectedKey={
            this.state.selectedTeam
              ? this.state.selectedTeam.id
              : teamId
              ? teamId
              : null
          }
          options={this.state.teams.map((item) => {
            return {
              key: item.id,
              text: item.name,
            };
          })}
          onChanged={(item) => {
            this.setState({
              selectedTeam: {
                id: item.key,
                name: item.text,
              },
            });

            onSelect({
              id: item.key,
              name: item.text,
            });
          }}
          styles={{ title: { borderRadius: "5px" } }}
        />
      </>
    );
  }

  async populateData() {
    const { setOptions, teamId, onSelect, setDefaultTeam } = this.props;
    var teams = await TeamsClient.List(new TeamQueryParams());
    this.setState({
      teams: teams.data.data,
    });

    if (setOptions) {
      setOptions(teams.data.data);
    }

    if (setDefaultTeam) {
      if (teamId) {
        var team = teams.data.data.filter((team) => team.id === teamId)[0];

        if (team) {
          onSelect({
            id: team.id,
            name: team.name,
          });
        }
      } else if (!teamId && this.state.selectedTeam === null) {
        var team = teams.data.data[0];

        if (team) {
          onSelect({
            id: team.id,
            name: team.name,
          });
        }
      }
    }
  }
}
