import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  TooltipHost,
  Panel,
  PanelType,
  DefaultButton,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import {
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import _ from "lodash";
import { ReactComponent as TeamsLogo } from "../../../../assets/svg/users-alt-solid.svg";
import "./TeamOverview.css";
import { TeamSelector } from "../../../Selectors/TeamSelector";
import { UsersClient } from "../../../../infrastructure/api/UsersClient";

export class TeamOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentDate: new Date(new Date().toDateString()),
      currentUser: null,
      team: {},
      teamCount: 0,
      reRender: false,
    };

    this.inputElementRef = React.createRef();
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
  }

  componentWillReceiveProps(props) {
    const { currentUser } = this.props;
    if (!this.state.currentUser) this.populateData();

    if (this.state.reRender !== this.props.reRender) {
      this.setState({ reRender: this.props.reRender }, () =>
        this.populateData()
      );
    }
  }

  classNames = mergeStyleSets({
    container: {
      width: "100%",
      // background:
      //   "linear-gradient(170deg, rgba(0,0,0,0) 0%, rgba(214,237,241,1) 85%, rgba(221,244,248,1) 100%)",
      overflow: "hidden",
    },
    cardShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)" },
        },
      },
    ],
    buttonShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)" },
        },
      },
    ],
    centerItem: {
      display: "flex",
      justifyContent: "center",
    },
    textCenterEllipsis: {
      color: "white",
      fontSize: "12px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: "110px",
      textAlign: "center",
    },
  });

  renderPanel() {
    const { currentUser, reloadUser } = this.props;
    return (
      <Panel
        isLightDismiss={false}
        onOuterClick={() => {}}
        isOpen={this.state.showSidePanel}
        onDismiss={() => {
          this.setState({
            showSidePanel: false,
          });
        }}
        onOpen={() => {}}
        closeButtonAriaLabel="Close"
        headerText={
          <div>
            <span style={{ fontSize: "18px" }}>Set Default Team</span>
          </div>
        }
        type={PanelType.smallFixedFar}
        styles={{
          content: {
            background: "white",
          },
          contentInner: {
            background: "white",
          },
          header: {
            background: "white",
          },
          navigation: {
            background: "white",
          },
          commands: {
            background: "white",
          },
        }}
      >
        <div style={{ marginTop: "20px" }}></div>
        <div style={{ minWidth: "200px" }}>
          <div style={{ fontWeight: "bold", marginBottom: "3px" }}>Team</div>{" "}
          <TeamSelector
            onSelect={async (item) => {
              this.setState({ selectedTeam: item, teamId: item.id });
            }}
            setOptions={async (teams) => {}}
            teamId={this.state?.team?.id}
            setDefaultTeam
            hideLabel
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginTop: "10px",
          }}
        >
          <DefaultButton
            onClick={() => {
              this.setState({
                showSidePanel: false,
              });
            }}
            style={{ marginRight: "5px" }}
          >
            Cancel
          </DefaultButton>
          <PrimaryButton
            onClick={async () => {
              this.setState({ teamBtnLoading: true });

              await UsersClient.SetTeam(
                currentUser.id,
                this.state.teamId,
                true
              );
              this.setState({
                showSidePanel: false,
                teamBtnLoading: false,
              });
              reloadUser();
            }}
          >
            {" "}
            {this.state.teamBtnLoading && <Spinner size={SpinnerSize.medium} />}
            Set Default
          </PrimaryButton>
        </div>
      </Panel>
    );
  }

  render() {
    const { loading: propLoading } = this.props;
    const { teamCount, team, loading } = this.state;
    return (
      <>
        {this.renderPanel()}
        {propLoading || loading ? (
          <div
            className="card__image"
            style={{
              height: "120px",
              width: "120px",
            }}
          ></div>
        ) : (
          <>
            {team?.id ? (
              <div
                style={{
                  borderRadius: "10px",
                  height: "120px",
                  width: "120px",
                  background: team?.color ? team?.color : "#fcc250",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  // cursor: "pointer",
                }}
                onClick={() => {
                  // this.setState({ showSidePanel: true });
                }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <b
                    style={{
                      color: "white",
                      fontSize: "large",
                      marginLeft: "15px",
                      position: "absolute",
                      top: "-5px",
                      fontSize: "13px",
                    }}
                  >
                    {teamCount}
                  </b>
                </div>

                <TeamsLogo
                  height="60"
                  width="60"
                  style={{ overflow: "visible", padding: "10px 0" }}
                  stroke={"white"}
                  fill={"white"}
                />
                <b className={this.classNames.textCenterEllipsis}>
                  {team?.name}
                </b>
              </div>
            ) : (
              <TooltipHost content="Click to set a default team">
                <div
                  style={{
                    borderRadius: "10px",
                    height: "120px",
                    width: "120px",
                    background: "#e2e2e2",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({ showSidePanel: true });
                  }}
                >
                  <TeamsLogo
                    height="60"
                    width="60"
                    style={{ overflow: "visible", padding: "10px 0" }}
                    stroke={"white"}
                    fill={"white"}
                  />
                  <b style={{ color: "white", fontSize: "12px" }}>
                    No default set
                  </b>
                </div>
              </TooltipHost>
            )}
          </>
        )}
      </>
    );
  }

  async populateData() {
    this.setState({ loading: true });
    const { currentUser, reloadPhoto } = this.props;

    if (currentUser?.teamMembersList) {
      var primary = currentUser?.teamMembersList?.filter(
        (item) => item.isPrimary
      )[0];

      if (primary)
        this.setState({
          currentUser: currentUser,
          team: primary?.team,
          teamCount: primary?.teamMembers.length,
        });
    }

    this.setState({
      loading: false,
    });
  }
}
