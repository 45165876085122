import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import {
  HomeClient,
  HomeQueryParams,
} from "../../infrastructure/api/HomeClient";
import { FocusZone, Spinner, List, Image, ImageFit } from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";

export class TeamTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      total: 0,
      search: "",
      sort: "companyName",
      direction: "asc",
      showSuspended: false,
      status: "",
    };
    this.onRenderCell = this.onRenderCell.bind(this);
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
  }

  classNames = mergeStyleSets({
    container: {
      overflow: "auto",
      maxHeight: 1000,
      borderRadius: "10px",
    },
    itemCell: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: "border-box",
        borderBottom: `1px solid ${getTheme().semanticColors.bodyDivider}`,
        display: "flex",
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        transition: "0.3s",
        margin: "10px",
        selectors: {
          "&:hover": {
            background: getTheme().palette.neutralLight,
            boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)",
          },
        },
      },
    ],
    itemImage: {
      flexShrink: 0,
    },
    itemContent: {
      marginLeft: 10,
      overflow: "hidden",
      flexGrow: 1,
    },
    itemName: [
      getTheme().fonts.xLarge,
      {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    ],
    itemIndex: {
      fontSize: getTheme().fonts.small.fontSize,
      color: getTheme().palette.neutralTertiary,
      marginBottom: 10,
    },
    chevron: {
      alignSelf: "center",
      marginLeft: 10,
      color: getTheme().palette.neutralTertiary,
      fontSize: getTheme().fonts.large.fontSize,
      flexShrink: 0,
    },
    contentClass: {
      backgroundColor: getTheme().palette.neutralLight,
      color: getTheme().palette.neutralDark,
      lineHeight: "50px",
      padding: "0 20px",
    },
  });

  render() {
    return (
      //<FocusZone direction={FocusZoneDirection.vertical}>
      <div className={this.classNames.container} data-is-scrollable>
        {this.state.data && (
          <List items={this.state.data} onRenderCell={this.onRenderCell} />
        )}
      </div>
      // </FocusZone>
    );
  }

  onRenderCell(item, index, isScrolling) {
    const { onClick } = this.props;

    var bg = "#dcf0f2";
    if (item.myDay && item.myDay.parts && item.myDay.parts.length > 0) {
      if (item.myDay.parts[0].state == "workFromOffice") {
        bg = "#fcc250";
      } else if (item.myDay.parts[0].state == "workFromHome") {
        bg = "#00838f";
      } else if (item.myDay.parts[0].state == "notWorking") {
        bg = "#EF6E6C";
      }
    }

    return (
      <div
        className={this.classNames.itemCell + " hover"}
        data-is-focusable={true}
        style={{
          backgroundColor: "white",
          borderRadius: "15px",
        }}
        onClick={() => {
          if (onClick) onClick(item.user);
        }}
      >
        <Image
          className={this.classNames.itemImage}
          src={
            this.state.currentUser?.profilePhoto ?? "/assets/images/blank.png"
          }
          alt=""
          width={50}
          height={50}
          imageFit={ImageFit.cover}
        />

        <div
          style={{
            borderRadius: "50%",
            minWidth: "0",
            height: "20px",
            width: "20px",
            backgroundColor: "#ffffff",
            position: "absolute",
            top: "45px",
            left: "45px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "#00000080 1px 1px 3px",
          }}
        >
          {item.checkIn && (
            <Icon iconName="CheckMark" style={{ fontSize: "8px" }} />
          )}
          {!item.checkIn && (
            <Icon iconName="Remove" style={{ fontSize: "8px" }} />
          )}
        </div>

        <div className={this.classNames.itemContent}>
          <div className={this.classNames.itemName}>
            {item.user.name} {item.user.surname}
          </div>
          <div className={this.classNames.itemIndex}>
            {item.myDay && item.myDay.parts && item.myDay.parts.length > 0 && (
              <>
                {item.myDay.parts[0].state == "workFromOffice" && (
                  <>
                    <Icon
                      iconName="Location"
                      style={{ position: "relative", top: "1px" }}
                    />{" "}
                    {item.myDay.parts[0].site && item.myDay.parts[0].site.name}
                    {!item.myDay.parts[0].site && <i>Site not specified</i>}
                  </>
                )}
                {item.myDay.parts[0].state == "notWorking" && (
                  <>
                    <Icon
                      iconName="AwayStatus"
                      style={{ position: "relative", top: "1px" }}
                    />{" "}
                    {item.myDay.parts[0].notWorkingReason &&
                      item.myDay.parts[0].notWorkingReason.name}
                    {!item.myDay.parts[0].notWorkingReason && (
                      <i>Reason not specified</i>
                    )}
                  </>
                )}
                {item.myDay.parts[0].state == "workFromHome" && (
                  <>
                    <Icon
                      iconName="Home"
                      style={{ position: "relative", top: "1px" }}
                    />{" "}
                    Working from home
                  </>
                )}
              </>
            )}
            {!item.myDay && (
              <>
                <Icon
                  iconName="SunQuestionMark"
                  style={{ position: "relative", top: "1px" }}
                />{" "}
                No response
              </>
            )}
            {item.checkIn && (
              <>
                {" "}
                -{" "}
                <Icon
                  iconName="CellPhone"
                  style={{ position: "relative", top: "1px" }}
                />{" "}
                {item.checkIn.asset.name}
              </>
            )}
          </div>
        </div>
        <div style={{ backgroundColor: bg, width: "5px" }}></div>
      </div>
    );
  }

  async populateData(skip, take) {
    const { teamId, date, siteId } = this.props;

    var response = await HomeClient.DayView(
      new HomeQueryParams()
        .WithSiteId(siteId)
        .WithTeamId(teamId)
        .WithParam("startDate", date)
    );

    if (response.data) {
      this.setState({
        data: [...response.data[0].users],
      });

      console.log(response);
    }

    this.setState({
      loading: false,
    });
  }
}
