import React, { Component, useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import {
  ChoiceGroup,
  Dropdown,
  Label,
  Panel,
  PanelType,
} from "@fluentui/react";
import {
  FloorsQueryParams,
  FloorsClient,
} from "../../infrastructure/api/FloorsClient";
import { ReactComponent as AngleLogo } from "../../assets/svg/angle-right.svg";

export class FloorSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeStatus: "",
      loading: false,
      selectedData: null,
      data: [],
      siteId: null,
      isPanelOpen: false,
    };
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps, prevState) {
    const { siteId, defaultValue } = this.props;
    if (siteId !== this.state.siteId)
      this.setState({ siteId: siteId }, () => this.update());
    else if (defaultValue && defaultValue?.id !== this.state.selectedData?.id) {
      this.setState({ selectedData: defaultValue }, () => this.update());
    }
  }

  async update() {
    await this.populateData();
  }
  classNames = mergeStyleSets({
    dropdown: { borderRadius: "10px" },
  });

  render() {
    const { onSelect, hideLabel, isMobileView } = this.props;

    if (isMobileView) {
      return (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={() => {
              this.setState({ isPanelOpen: true });
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <Logo
                fill={"#000"}
                stroke={"white"}
                height="25"
                width="25"
                style={{ overflow: "visible" }}
              /> */}
              <div
                style={{
                  marginLeft: "5px",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                Level
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {this.state.selectedData && (
                <div
                  style={{
                    color: "grey",
                    fontWeight: 300,
                    marginRight: "5px",
                  }}
                >
                  {this.state.selectedData?.name}
                </div>
              )}
              <AngleLogo
                fill={"grey"}
                stroke={"grey"}
                height="12"
                width="12"
                style={{ overflow: "visible" }}
              />
            </div>
          </div>

          {this.renderPanel()}
        </>
      );
    } else {
      return (
        <>
          {!isMobileView && (
            <>
              {!hideLabel && <Label>Level</Label>}
              <Dropdown
                placeholder="Select a floor"
                selectedKey={
                  this.state.selectedData ? this.state.selectedData.id : null
                }
                options={this.state.data.map((item) => {
                  return {
                    key: item.id,
                    text: item.name,
                    plottable: item.plottable,
                  };
                })}
                onChanged={(item) => {
                  this.setState({
                    selectedData: {
                      id: item.key,
                      name: item.text,
                      plottable: item.plottable,
                    },
                  });

                  onSelect({
                    id: item.key,
                    name: item.text,
                    plottable: item.plottable,
                  });
                }}
                styles={{ title: { borderRadius: "5px" } }}
              />
            </>
          )}
        </>
      );
    }
  }

  renderPanel = () => {
    const { isPanelOpen, mobileData, selectedData } = this.state;
    return (
      <Panel
        type={PanelType.smallFluid}
        headerText="Choose Floor"
        isOpen={isPanelOpen}
        onDismiss={() => {
          this.setState({ isPanelOpen: false });
        }}
        // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
        closeButtonAriaLabel="Close"
      >
        <ChoiceGroup
          defaultSelectedKey={selectedData?.id}
          options={mobileData}
          onChange={(event, option) => {
            var selectedData = {
              id: option.key,
              name: option.text,
              plottable: option.plottable,
            };

            this.setState({ isPanelOpen: false, selectedData });
          }}
        />
      </Panel>
    );
  };

  async populateData() {
    const { siteId, onSelect, defaultValue, onNoFloorData } = this.props;

    if (siteId) {
      var result = await FloorsClient.List(
        new FloorsQueryParams()
          .WithSiteId(siteId)
          .Sort("name", "asc")
          .WithExpand(false)
      );

      var mobileData = result?.data?.data.map((item) => ({
        key: item.id,
        text: item.name,
      }));
      this.setState({
        data: result?.data?.data,
        mobileData,
        siteId: siteId,
      });

      if (!defaultValue) {
        if (result?.data?.data && result?.data?.data.length > 0) {
          this.setState({
            selectedData: result?.data?.data[0],
          });

          onSelect({
            id: result?.data?.data[0]?.id,
            name: result?.data?.data[0]?.name,
            plottable: result?.data?.data[0]?.plottable,
          });
        } else {
          this.setState({
            selectedData: null,
          });
          if (onNoFloorData) onNoFloorData();
        }
      } else {
        onSelect(defaultValue);
      }
    }
  }
}
