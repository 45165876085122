import { ChartColors, ChartLabels } from "../constants/chartColors";

export const setDefaults = () => {
  //theme
  localStorage.setItem(`theme_OnSite`, ChartColors.OnSite);
  localStorage.setItem(`theme_Home`, ChartColors.Home);
  localStorage.setItem(`theme_NoResponse`, ChartColors.NoResponse);
  localStorage.setItem(`theme_NotWorking`, ChartColors.NotWorking);
  localStorage.setItem(`theme_WhatsOnHoliday`, ChartColors.Holiday);
  localStorage.setItem(`theme_WhatsOnEvent`, ChartColors.Event);
  localStorage.setItem(`theme_HighlightTeam`, ChartColors.HighlightTeam);
  localStorage.setItem(`theme_Available`, ChartColors.Available);
  localStorage.setItem(`theme_Reserved`, ChartColors.Reserved);
  localStorage.setItem(`theme_InUse`, ChartColors.InUse);
  localStorage.setItem(`theme_Booked`, ChartColors.Booked);
  localStorage.setItem(`theme_Item`, ChartColors.Item);
  //localization
  localStorage.setItem(`localization_OnSite`, ChartLabels.OnSite);
  localStorage.setItem(`localization_Home`, ChartLabels.Home);
  localStorage.setItem(`localization_NoResponse`, ChartLabels.NoResponse);
  localStorage.setItem(`localization_NotWorking`, ChartLabels.NotWorking);
  localStorage.setItem(`localization_Monday`, ChartLabels.Monday);
  localStorage.setItem(`localization_Tuesday`, ChartLabels.Tuesday);
  localStorage.setItem(`localization_Wednesday`, ChartLabels.Wednesday);
  localStorage.setItem(`localization_Thursday`, ChartLabels.Thursday);
  localStorage.setItem(`localization_Friday`, ChartLabels.Friday);
  localStorage.setItem(`localization_Saturday`, ChartLabels.Saturday);
  localStorage.setItem(`localization_Sunday`, ChartLabels.Sunday);
};

export const setAccountTheme = (data) => {
  if (data) {
    if (data.accountThemes && data.accountThemes.length > 0) {
      var color = data.accountThemes[0];

      if (color?.values) {
        const onSiteColor =
          color.values.find((x) => x.key === "OnSite")?.value ??
          ChartColors.OnSite;
        const homeColor =
          color.values.find((x) => x.key === "Remote")?.value ??
          ChartColors.Home;
        const noResponseColor =
          color.values.find((x) => x.key === "NoResponse")?.value ??
          ChartColors.NoResponse;
        const notWorkingColor =
          color.values.find((x) => x.key === "Leave")?.value ??
          ChartColors.NotWorking;
        const whatsOnHoliday =
          color.values.find((x) => x.key === "WhatsOnHoliday")?.value ??
          ChartColors.Holiday;
        const whatsOnEvent =
          color.values.find((x) => x.key === "WhatsOnEvent")?.value ??
          ChartColors.Event;
        const highlightTeam =
          color.values.find((x) => x.key === "HighlightTeam")?.value ??
          ChartColors.HighlightTeam;
        const Available =
          color.values.find((x) => x.key === "Available")?.value ??
          ChartColors.Available;
        const Reserved =
          color.values.find((x) => x.key === "Reserved")?.value ??
          ChartColors.Reserved;
        const InUse =
          color.values.find((x) => x.key === "InUse")?.value ??
          ChartColors.InUse;
        const Booked =
          color.values.find((x) => x.key === "Booked")?.value ??
          ChartColors.Booked;
        const Item =
          color.values.find((x) => x.key === "Item")?.value ?? ChartColors.Item;

        var mappedColors = {
          OnSite: onSiteColor,
          Home: homeColor,
          NoResponse: noResponseColor,
          NotWorking: notWorkingColor,
          WhatsOnHoliday: whatsOnHoliday,
          WhatsOnEvent: whatsOnEvent,
          HighlightTeam: highlightTeam,
          Available,
          Reserved,
          InUse,
          Booked,
          Item,
        };

        localStorage.setItem(`theme_OnSite`, mappedColors.OnSite);
        localStorage.setItem(`theme_Home`, mappedColors.Home);
        localStorage.setItem(`theme_NoResponse`, mappedColors.NoResponse);
        localStorage.setItem(`theme_NotWorking`, mappedColors.NotWorking);
        localStorage.setItem(
          `theme_WhatsOnHoliday`,
          mappedColors.WhatsOnHoliday
        );
        localStorage.setItem(`theme_WhatsOnEvent`, mappedColors.WhatsOnEvent);
        localStorage.setItem(`theme_HighlightTeam`, mappedColors.HighlightTeam);
        localStorage.setItem(`theme_Available`, Available);
        localStorage.setItem(`theme_Reserved`, Reserved);
        localStorage.setItem(`theme_InUse`, InUse);
        localStorage.setItem(`theme_Booked`, Booked);
        localStorage.setItem(`theme_Item`, Item);
      }
    }

    if (data.localizations && data.localizations.length > 0) {
      var localization = data.localizations[0];

      if (localization?.values) {
        const onSiteLabel =
          localization.values.find((x) => x.key === "OnSite")?.value ??
          ChartLabels.OnSite;
        const homeLabel =
          localization.values.find((x) => x.key === "Home")?.value ??
          ChartLabels.Home;
        const noResponseLabel = ChartLabels.NoResponse;
        const notWorkingLabel =
          localization.values.find((x) => x.key === "NotWorking")?.value ??
          ChartLabels.NotWorking;
        const monday =
          localization.values.find((x) => x.key === "Monday")?.value ??
          ChartLabels.Monday;

        const tuesday =
          localization.values.find((x) => x.key === "Tuesday")?.value ??
          ChartLabels.Tuesday;

        const wednesday =
          localization.values.find((x) => x.key === "Wednesday")?.value ??
          ChartLabels.Wednesday;

        const thursday =
          localization.values.find((x) => x.key === "Thursday")?.value ??
          ChartLabels.Thursday;

        const friday =
          localization.values.find((x) => x.key === "Friday")?.value ??
          ChartLabels.Friday;
        const saturday =
          localization.values.find((x) => x.key === "Saturday")?.value ??
          ChartLabels.Saturday;
        const sunday =
          localization.values.find((x) => x.key === "Sunday")?.value ??
          ChartLabels.Sunday;

        var mappedLabels = {
          OnSite: onSiteLabel,
          Home: homeLabel,
          NoResponse: noResponseLabel,
          NotWorking: notWorkingLabel,
          Monday: monday,
          Tuesday: tuesday,
          Wednesday: wednesday,
          Thursday: thursday,
          Friday: friday,
          Saturday: saturday,
          Sunday: sunday,
        };

        localStorage.setItem(`localization_OnSite`, mappedLabels.OnSite);
        localStorage.setItem(`localization_Home`, mappedLabels.Home);
        localStorage.setItem(
          `localization_NoResponse`,
          mappedLabels.NoResponse
        );
        localStorage.setItem(
          `localization_NotWorking`,
          mappedLabels.NotWorking
        );
        localStorage.setItem(`localization_Monday`, mappedLabels.Monday);
        localStorage.setItem(`localization_Tuesday`, mappedLabels.Tuesday);
        localStorage.setItem(`localization_Wednesday`, mappedLabels.Wednesday);
        localStorage.setItem(`localization_Thursday`, mappedLabels.Thursday);
        localStorage.setItem(`localization_Friday`, mappedLabels.Friday);
        localStorage.setItem(`localization_Saturday`, mappedLabels.Saturday);
        localStorage.setItem(`localization_Sunday`, mappedLabels.Sunday);
      }
    }
  }
};
