import React, { Component, useContext } from "react";
import {
  Dialog,
  DialogFooter,
  DialogType,
  TextField,
  Calendar,
  defaultCalendarStrings,
  Modal,
} from "@fluentui/react";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
  mergeStyles,
} from "@fluentui/react/lib/Styling";
import { Icon, FontIcon } from "@fluentui/react/lib/Icon";
import moment from "moment";
import "./MobileCalendarPicker.css";
import Moment from "react-moment";
import { DialogTitle } from "@fluentui/react-components";

export class MobileCalendarPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDatePickerDialog: false,
    };
  }

  componentDidMount() {
    this.update();
  }
  async update() {}

  classNames = mergeStyleSets({
    buttonShadow: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 5%)",
        selectors: {
          "&:hover": { boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.05)" },
        },
      },
    ],
  });

  render() {
    const { date, labelComponent } = this.props;
    const iconProps = { iconName: "Calendar" };
    return (
      <>
        {labelComponent ? (
          <div
            onClick={() => {
              this.setState({
                showDatePickerDialog: true,
              });
            }}
          >
            {labelComponent}
          </div>
        ) : (
          <TextField
            label="Date"
            iconProps={iconProps}
            readOnly
            placeholder="Select Date"
            onClick={() => {
              this.setState({
                showDatePickerDialog: true,
              });
            }}
            value={moment(date).format("MMMM DD, YYYY")}
          />
        )}

        {/* {this.datePickerDialog()} */}
        {this.datePickerModal()}
      </>
    );
  }

  datePickerModal = () => {
    const { showDatePickerDialog } = this.state;
    const { onChangeDate, date } = this.props;
    var onSiteColor = localStorage.getItem("theme_OnSite");
    return (
      <Modal
        isOpen={showDatePickerDialog}
        onDismiss={() =>
          this.setState({
            showDatePickerDialog: false,
          })
        }
        isBlocking={false}
        styles={{
          main: {
            padding: 0,
            width: "300px",
            height: "400px",
            overflow: "hidden",
            boxShadow: "0 10px 10px 3px rgb(0 0 0 / 50%)",
            borderRadius: "5px",
          },
          root: { padding: 0 },
        }}
      >
        <div
          style={{
            height: "70px",
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            backgroundColor: onSiteColor,
            color: "white",
            fontSize: "23px",
            paddingLeft: "10px",
          }}
        >
          <div>
            <b>
              <Moment utc local format="dddd, MMM DD">
                {date}
              </Moment>
            </b>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "340px",
            zoom: "130%",
          }}
        >
          <Calendar
            showMonthPickerAsOverlay
            highlightSelectedMonth
            showSixWeeksByDefault
            showGoToToday={false}
            onSelectDate={(date) => {
              this.setState({
                showDatePickerDialog: false,
              });

              if (onChangeDate) onChangeDate(date);
            }}
            value={new Date(date)}
            // Calendar uses English strings by default. For localized apps, you must override this prop.
            strings={defaultCalendarStrings}
            minDate={new Date(moment())}
          />
        </div>
      </Modal>
    );
  };

  datePickerDialog = () => {
    //UNFINISHED, TITLE WILL NOT RENDER PROPERLY- MOVED TO MODAL UNLESS PICKER WOULD BE CONVERTED TO BOTTOM PANEL
    const { showDatePickerDialog } = this.state;
    const { onChangeDate, date } = this.props;
    var onSiteColor = localStorage.getItem("theme_OnSite");
    return (
      <Dialog
        hidden={!showDatePickerDialog}
        onDismiss={() =>
          this.setState({
            showDatePickerDialog: false,
          })
        }
        dialogContentProps={{
          type: DialogType.normal,
          styles: {
            innerContent: {
              maxHeight: "275px",
              padding: 0,
            },
            inner: {
              padding: 0,
            },
            root: { padding: 0 },
          },
          //   title: `${moment(date).format("dddd, MMM DD")}`,
        }}
        styles={{
          main: {
            padding: 0,
            width: "300px",
            height: "350px",
          },
          root: { padding: 0 },
        }}
      >
        {/* <div
          style={{
            height: "30px",
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            backgroundColor: onSiteColor,
          }}
        >
          <div>
            <b>
              <Moment utc local format="dddd, MMM DD">
                {date}
              </Moment>
            </b>
          </div>
        </div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "320px",
            zoom: "130%",
          }}
        >
          <Calendar
            showMonthPickerAsOverlay
            highlightSelectedMonth
            showSixWeeksByDefault
            showGoToToday={false}
            onSelectDate={(date) => {
              this.setState({
                showDatePickerDialog: false,
              });

              if (onChangeDate) onChangeDate(date);
            }}
            value={new Date(date)}
            // Calendar uses English strings by default. For localized apps, you must override this prop.
            strings={defaultCalendarStrings}
          />
        </div>
        <DialogFooter></DialogFooter>
      </Dialog>
    );
  };
}
