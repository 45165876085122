import { ApiResponse, ListQueryParams } from "../ApiClient";
import {
  GetAPIDomain,
  GetAPIHeaders,
  GetApiHeadersFormData,
  GetAPIHeadersPatch,
  MyDayAPIHelper,
} from "../MyDayAPIHelper";

export class WorkDaysClient {
  static async Create(plan: any) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    var response = await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + "/workday-plans/", {
        method: "post",
        headers: headers,
        body: JSON.stringify(plan),
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(apiDomain + "/workday-plans/", {
            method: "post",
            headers: headers,
            body: JSON.stringify(plan),
          })
        );
      }
    }

    return response;
  }

  static async CheckIn(assetId, duration, dates) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    var response = await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + "/workday-plans/check-in", {
        method: "post",
        headers: headers,
        body: JSON.stringify({ assetId, duration, dates }),
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(apiDomain + "/workday-plans/check-in", {
            method: "post",
            headers: headers,
            body: JSON.stringify({ assetId, duration, dates }),
          })
        );
      }
    }

    return response;
  }

  static async ConfirmMyDay(id) {
    var apiDomain = await GetAPIDomain();
    var headers = await GetAPIHeaders();
    var response = await ApiResponse.CreateFromResponse(
      await fetch(apiDomain + `/workday-plans/${id}/confirm`, {
        method: "post",
        headers: headers,
        // body: JSON.stringify({ assetId, duration, dates }),
      })
    );

    if (!response.authenticated) {
      if (await MyDayAPIHelper.RefreshToken()) {
        var headers = await GetAPIHeaders();
        console.log(headers);
        return await ApiResponse.CreateFromResponse(
          await fetch(apiDomain + `/workday-plans/${id}/confirm`, {
            method: "post",
            headers: headers,
            // body: JSON.stringify({ assetId, duration, dates }),
          })
        );
      }
    }

    return response;
  }
}
