import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  HomeClient,
  HomeQueryParams,
} from "../../infrastructure/api/HomeClient";

export class TeamMyDayChart extends Component {
  constructor(props) {
    Chart.register(ArcElement, Tooltip, Legend);
    super(props);
    this.state = {
      data: [0, 0, 0, 0],
      loading: false,
      total: 0,
      search: "",
      sort: "companyName",
      direction: "asc",
      showSuspended: false,
      status: "",
    };
  }

  componentDidMount() {
    this.update();
  }

  async update() {
    await this.populateData();
  }

  renderTable(data) {}

  render() {
    var data = {
      labels: ["Home", "On Site", "Not Working", "No Response"],
      datasets: [
        {
          data: this.state.data,
          backgroundColor: ["#00838f", "#fcc250", "#EF6E6C", "#dcf0f2"],
        },
      ],
    };

    var options = {
      animation: false,
      cutout: 65,
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          labels: {
            usePointStyle: true,
            boxWidth: 10,
            font: {
              family: 'Poppins, Helvetica, "sans-serif"',
            },
          },
        },
        title: {
          position: "top",
          display: true,
          text: "Team Day",
        },
      },
    };

    return <Doughnut data={data} id="homePieChart" options={options} />;
  }

  async populateData(skip, take) {
    const { teamId, date } = this.props;

    var response = await HomeClient.PieChart(
      new HomeQueryParams()
        .WithTeamIds(teamId)
        .WithDate(date)
        .Paginate(0, 99999)
    );

    if (response.data) {
      this.setState({
        data: [
          response.data.workingFromHome,
          response.data.workingOnSite,
          response.data.notWorking,
          response.data.noResponse,
        ],
      });
      console.log(response);

      console.log("Fetching graph");
      console.log(teamId);
      console.log(date);
    }
  }
}
