import L, { CRS, LatLng } from "leaflet";

import DefaultEventImg1 from "../assets/default-event-imgs/Daysie1.jpg";
import DefaultEventImg2 from "../assets/default-event-imgs/Daysie2.jpg";
import DefaultEventImg3 from "../assets/default-event-imgs/Daysie3.jpg";
import DefaultEventImg4 from "../assets/default-event-imgs/Daysie4.jpg";
import DefaultEventImg5 from "../assets/default-event-imgs/Daysie5.jpg";
import DefaultEventImg6 from "../assets/default-event-imgs/Daysie6.jpg";
import DefaultEventImg7 from "../assets/default-event-imgs/Daysie7.jpg";
import DefaultEventImg8 from "../assets/default-event-imgs/Daysie8.jpg";

export const getOverlayCenterLatLng = (width, height) => {
  var pointToLatLng = layerPointToLatLng(width / 2, height / 2);
  return pointToLatLng;
};

export const getMapBounds = (width, height) => {
  return new L.LatLngBounds(
    layerPointToLatLng(0, height),
    layerPointToLatLng(width, 0)
  );
};

export const CustomPoint = (latLng, zoom) => {
  var customPoint = CRS.Simple.latLngToPoint(latLng, zoom);
  return customPoint;
};

export const layerPointToLatLng = (x, y) => {
  const point = L.point(x, y);
  var layerPointToLatLng = CRS.Simple.pointToLatLng(point, 5);
  return layerPointToLatLng;
};

export const getRandomEventImage = () => {
  const images = [
    DefaultEventImg1,
    DefaultEventImg2,
    DefaultEventImg3,
    DefaultEventImg4,
    DefaultEventImg5,
    DefaultEventImg6,
    DefaultEventImg7,
    DefaultEventImg8,
  ];

  var random = images[Math.floor(Math.random() * images.length)];

  return random;
};
